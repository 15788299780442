import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../utilities/storeUtilities';
import HiddenField from './HiddenField';

class HiddenFieldContainer extends Component {
	render() {
		return (
			<HiddenField
				screenId={this.props.screenId}
				panelId={this.props.panelId}
				fieldId={this.props.fieldId}
				value={this.props.value}
				onLoad={this.props.onLoad}
			/>
		);
	}
}

export const mapStateToProps = (_state, props) => {
	const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
	const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
	return {
		value: valueInStore
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
			const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
			const value = valueInStore !== undefined ? valueInStore : props.value;
			storeUtilities.initializeValue(dispatch, path, value, false);
		}
	};
};

HiddenFieldContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	value: PropTypes.any,
	overridePath: PropTypes.array
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(HiddenFieldContainer);

connectedComponent.defaultProps = {
};

export default connectedComponent;