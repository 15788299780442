import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { 
	ButtonContainer,
	DataGridContainer 
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import XGridCellIcon from '../../formelements/ImageComponents/XGridCellIcon';
class SearchProductsListResultsPanel extends Component {
	componentDidMount(){
		return this.props.onLoad ? this.props.onLoad() : undefined;
	}
	handlekeyDown = (e) => {
		if(e.ctrlKey && e.which === 65) {
			e.preventDefault();
			this.api.selectAll();
		}
		
	};
	selectAll = () => {
		if(this.props.selectAllButtonDisabled){
			return;
		}
		this.api.selectAll();
		if(this.props.handleSelectAllClicked){
			this.props.handleSelectAllClicked(this.api.getSelectedRows());
		}
	};
	deselectAll = () => {
		if(this.props.deselectAllButtonDisabled){
			return;
		}
		this.api.deselectAll();
		if(this.props.handleDeselectAllClicked){
			this.props.handleDeselectAllClicked(this.api.getSelectedRows());
		}
	};
	handleSubmitClicked = () => {
		if(this.props.handleSubmitClicked && !this.props.submitButtonDisabled){
			this.props.handleSubmitClicked(this.api.getSelectedRows());
		}
	};
	handleRowClicked = () => {
		if(this.props.handleRowClicked){
			this.props.handleRowClicked(this.api.getSelectedRows());
		}
	};
	onGridReady = (obj) => {
		this.api = obj.api;
		if(this.props.onGridReady !== undefined){
			this.props.onGridReady(obj);
		}
	};
	onSubmitButtonMouseOver = () => {
		if(this.props.onSubmitButtonMouseOver){
			this.props.onSubmitButtonMouseOver(this.props.submitButtonDisabled);
		}
	};
	getIsXCellHidden = (cellProps) => {
		return this.props.getIsXCellHidden
			? this.props.getIsXCellHidden(cellProps)
			: true;
	};	
	render() {
		const resultCount = this.props.rowData ? this.props.rowData.length : 0;
		return (
			<Card className={'h-100'}>
				<Card.Header>
					<Container className={'h-100'} fluid={true}>
						<Row className='px-0' noGutters={true}>
							<Col xs={12} sm={12} md={12} lg={3} xl={3}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Results Found: { resultCount }</h5>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none'></Col>
							<Col xs={8} sm={8} md={6} lg={3} xl={3} className={'pr-1 mb-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectAll'} 
									onClick={this.selectAll} 
									text='Select All' 
									disabled={this.props.selectAllButtonDisabled}
									fill
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none'></Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none'></Col>
							<Col xs={8} sm={8} md={6} lg={3} xl={3} className={'pr-1 mb-1'}>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'clearSelections'} 
									onClick={this.deselectAll} 
									text='Clear' 
									disabled={this.props.deselectAllButtonDisabled}
									fill
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none'></Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none'></Col>
							<Col xs={8} sm={8} md={6} lg={3} xl={3} className={'pr-1 mb-1'}>
								<div onMouseOver={this.onSubmitButtonMouseOver}>
									<ButtonContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'submit'} 
										onClick={this.handleSubmitClicked}
										text='Submit' 
										disabled={this.props.submitButtonDisabled}
										fill
										store={store}
										tooltips={this.props.submitButtonTooltips}
									/>
								</div>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none'></Col>
						</Row>
					</Container>
				</Card.Header>
				<div className={'h-100 w-100'} style={{minHeight:'500px'}} onKeyDown={this.handlekeyDown}>
					<DataGridContainer
						screenId={this.props.screenId} 
						panelId={this.props.panelId}
						fieldId={'ProductSearchResultsGrid'} 
						defaultColDef={{resizable:true, filter:true, sortable: true}}
						rowSelection={'multiple'}
						columnDefs={[
							{ headerName: '', field: 'PdmIcon', width: 50, hide:true, filter:false }, 
							{ headerName: 'SKU', field: 'sku', width: 90, minWidth: 90}, 
							{ headerName: 'Vendor', field: 'vendor', width: 70, minWidth: 70},
							{ headerName: 'Style', field: 'style', width: 70, minWidth:70},
							{ headerName: 'Description', field: 'description', width: 175, minWidth: 175},
							{ headerName: 'Color', field: 'color', width: 70, minWidth: 70},
							{ headerName: 'Factory Color', field: 'factoryColor', width: 70, minWidth: 70},
							{ headerName: 'Theme', field: 'theme', width: 100, minWidth: 100},
							{ headerName: 'Finish', field: 'finish', width: 70, minWidth: 70},
							{ headerName: 'Family', field: 'family', width: 70, minWidth: 70},
							{ headerName: 'Factory', field: 'factory', width: 90, minWidth: 90},
							{ headerName: 'FOB', field: 'fob', width: 70, minWidth: 70},
							{ 
								headerName: 'Discontinued', 		
								field: 'discontinuedSku',			
								colId: 'discontinuedSku',
								headerClass: 'ag-center-header',
								cellRenderer: XGridCellIcon, 
								cellRendererParams:
								{
									screenId: this.props.screenId,
									height: '16px', 
									width: '16px',
									getIsHidden: this.getIsXCellHidden, 
									color: 'red'
								},
								width: 70,
								minWidth: 70,
								cellStyle: {textAlign: 'center'},
								filter: false
							},	
							{ 
								headerName: 'Inactive', 		
								field: 'inactiveSku',			
								colId: 'inactiveSku',
								headerClass: 'ag-center-header',
								cellRenderer: XGridCellIcon, 
								cellRendererParams:
								{
									screenId: this.props.screenId,
									height: '16px', 
									width: '16px',
									getIsHidden: this.getIsXCellHidden,
									color: 'red'
								},
								width: 70,
								minWidth: 70,
								cellStyle: {textAlign: 'center'},
								filter: false
							},	
							{ 
								headerName: 'To Be Dropped', 		
								field: 'toBeDropped',			
								colId: 'toBeDropped',
								headerClass: 'ag-center-header',
								cellRenderer: XGridCellIcon, 
								cellRendererParams:
								{
									screenId: this.props.screenId,
									height: '16px', 
									width: '16px',
									getIsHidden: this.getIsXCellHidden,
									color: 'red'
								},
								width: 70,
								minWidth: 70,
								cellStyle: {textAlign: 'center'},
								filter: false
							}
						]}
						rowData={this.props.rowData}
						onRowClicked={this.handleRowClicked}
						onGridReady={this.onGridReady}
						onModelUpdated={this.onGridReady}
						showColumnsPanel
						store={store}
					/>
				</div>
			</Card>
		);
	}
}

SearchProductsListResultsPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	handleSubmitClicked: PropTypes.func,
	handleRowClicked: PropTypes.func,
	handleSelectAllClicked: PropTypes.func,
	handleDeselectAllClicked: PropTypes.func,
	submitButtonDisabled: PropTypes.bool,
	selectAllButtonDisabled: PropTypes.bool,
	deselectAllButtonDisabled: PropTypes.bool,
	rowData: PropTypes.array,
	onGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	onSubmitButtonMouseOver: PropTypes.func,
	submitButtonTooltips: PropTypes.array,
	getIsXCellHidden: PropTypes.func
};

export default SearchProductsListResultsPanel;
