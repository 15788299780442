import deleteCurrentValueAction from '../actions/deleteCurrentValueAction';
import deleteDefaultValueAction from '../actions/deleteDefaultValueAction';
import updateCurrentValueAction from '../actions/updateCurrentValueAction';
import updateDefaultValueAction from '../actions/updateDefaultValueAction';
import setUserAction from '../actions/setUserAction';
import updateGlobalAppStateValueAction from '../actions/updateGlobalAppStateValueAction';
import updateValidationErrorsAction from '../actions/updateValidationErrorsAction';
import store from '../configureStore';
import * as persistentValues from '../utilities/persistentValues';

export const getValue = (path, defaultValueIfNotInStore=undefined) => {
	const state = store.getState();
	let returnValue;
	const defaultValue = state.app.getIn(['defaultValues'].concat(path));
	const currentValue = state.app.getIn(['currentValues'].concat(path));
	if(!isNullOrUndefined(defaultValue) && !isNullOrUndefined(currentValue)){
		if(defaultValue?.toJS !== undefined && currentValue?.toJS !== undefined){
			returnValue = (defaultValue.mergeDeep(currentValue));
		}
		else {
			/**
			 * Here, we know both values are not undefined.
			 * We also know that either one of them is a map or none of them are maps.
			 * If neither are maps, then just return the current value.
			 * If one of them is a map, then we have conflicting types. We must assume this is intentional, so we will return the current version
			 */
			returnValue = currentValue;
		}
	}
	else if (!isNullOrUndefined(defaultValue)){
		/**
		 * Here, we know that NOT (both values are not undefined), which translates to: one or none of the values is undefined.
		 * If defaultValue !== undefined, then the other must be undefined.
		 * So, we return the defaultValue.
		 */
		returnValue = defaultValue;
	}
	else if (!isNullOrUndefined(currentValue)){
		/**
		 * Here, we know that NOT (both values are not undefined), which translates to: one or none of the values is undefined.
		 * If currentValue !== undefined, then the other must be undefined.
		 * So, we return the currentValue.
		 */
		returnValue = currentValue;
	}
	else{
		/**
		 * Here, we've already checked both values to see if they are not undefined, so both values are undefined
		 * If initialValue !== undefined, then the other must be undefined.
		 * So, we return the defaultValue (which is defaulted to undefined).
		 */
		returnValue = defaultValueIfNotInStore;
	}
	if(!isNullOrUndefined(returnValue)){
		if(returnValue?.toJS){
			return returnValue.toJS();//this should handle all values of type 'object' in the store
		}
		if(defaultValueIfNotInStore !== undefined){
			//two other types to consider: number and string
			if(typeof returnValue === 'string' && typeof defaultValueIfNotInStore === 'number'){
				return parseFloat(Number(returnValue) || defaultValueIfNotInStore);
			}
			else if(typeof returnValue === 'number' && typeof defaultValueIfNotInStore === 'string'){
				return String(returnValue) || defaultValueIfNotInStore;
			}
			else{
				return returnValue;
			} 
		}
		else{
			return returnValue;
		}
	}
	return undefined;
};

export const getDefaultValue = (path, defaultValueIfNotInStore) => {
	const state = store.getState();
	const defaultValue = state.app.getIn(['defaultValues'].concat(path));
	return defaultValue !== undefined ? defaultValue : defaultValueIfNotInStore;
};

export const deleteValue = (dispatch, path) => {
	dispatch(deleteDefaultValueAction(path));
	dispatch(deleteCurrentValueAction(path));
};
export const updateValue = (dispatch, path, value, isInitialValue=false, persistValue=false) => {
	if(path !== undefined) {
		if(persistValue){
			persistentValues.set(path, value);
		}
		dispatch((isInitialValue === true) 
			? updateDefaultValueAction(path, value) 
			: updateCurrentValueAction(path, value));
	}
};
export const initializeValue = (dispatch, path, value, persistValue=false) => {
	if(path !== undefined){
		updateValue(dispatch, path, value, true, persistValue);
	}
};

export const getGlobalAppStateValue = (path) => {
	const state = store.getState();
	const values = state.app.getIn(['globalAppState'].concat(path));
	return values && values.toJS ? values.toJS() : values;
};
export const getDialogs = () => {
	const state = store.getState();
	return Array.isArray(state.app.getIn(['dialogs'])) ? state.app.getIn(['dialogs']).slice() : [];
};
export const updateGlobalAppStateValue = (dispatch, path, value) => {
	dispatch(updateGlobalAppStateValueAction(path, value));
};
export const getRpcDropdownOptions = (path) => {
	const values = store.getState().app.getIn(['rpcDropdowns', 'Options'].concat(path));
	return values && values.toJS ? values.toJS() : values;
};
export const getRpcDropdownArgs = (path) => {
	const values = store.getState().app.getIn(['rpcDropdowns', 'Args'].concat(path));
	return values && values.toJS ? values.toJS() : values;
};
export const getRpcDropdownState = (path) => {
	const state = store.getState().app.getIn(['rpcDropdowns', 'State'].concat(path));
	return state && state.toJS ? state.toJS() : Object.assign({}, state);
};
export const getUser = () => {
	const userMap = store.getState().app.getIn(['user']);
	return userMap && userMap.size === 0 ? undefined :  userMap;
};
export const setUser = (user) => {
	store.dispatch(setUserAction(user));
};
export const getValidationErrors = (path) => {
	const validationErrors = store.getState().app.getIn(['validationErrors'].concat(path));
	return validationErrors !== undefined ? (validationErrors.toJS ? validationErrors.toJS() : validationErrors) : {};
};
export const hasValidationErrors = (path) => {
	const validationErrors = getValidationErrors(path);
	return validationErrors !== undefined && validationErrors.errors !== undefined && Array.isArray(validationErrors.errors) && validationErrors.errors.length > 0;
};
export const getPath = (screenId, fieldId, overridePath) => {
	return overridePath ? overridePath : (screenId !== undefined && fieldId !== undefined ? [screenId, fieldId] : undefined);
};
export const hideValidationErrors = (dispatch, path, isDisabled, fnValidate) => {
	/**
	 * if the field is disabled, remove its validation errors, 
	 * else re-validate the field to get the most up-to-date validation tooltips based the field's current state
	 */
	if(isDisabled === true){
		dispatch(updateValidationErrorsAction(path, [], true));
	}
	else {
		if(fnValidate !== undefined){
			fnValidate();
		}
	}
};
/**
 * function stashValues
 * @param {function} dispatch
 * @param {Array of Arrays of strings} paths 
 */
export const stashValues = (dispatch, paths) => {
	const keyPrefix = 'ORIGINAL|';
	if(Array.isArray(paths)){
		paths.forEach(path => {
			if(Array.isArray(path)){
				if(path.length > 1){
					const rootPath = path.slice(0, path.length -1);
					const leaf = path[path.length - 1];
					const value = getValue(path);
					const stashValuePath = [...rootPath, keyPrefix + leaf];
					updateValue(dispatch, stashValuePath, value);
				}
				else if (path.length === 1){ //useful if stashing all values on a screen
					const leaf = path[0];
					const value = getValue(path);
					updateValue(dispatch, [keyPrefix + leaf], value);
				}
				else {
					//path length is 0 - nothing to do
					return;
				}
			}
		});
	}
};
/**
 * function stashValues
 * @param {function} dispatch
 * @param {Array of Arrays of strings} paths - i.e., an array of paths where each path is an array of strings
 */
export const restoreStashedValues = (dispatch, paths) => {
	const keyPrefix = 'ORIGINAL|';
	if(Array.isArray(paths)){
		paths.forEach(path => {
			if(Array.isArray(path)){
				if(path.length > 1){
					const rootPath = path.slice(0, path.length -1);
					const leaf = path[path.length - 1];
					const stashedValuePath = [...rootPath, keyPrefix + leaf];
					const value = getValue(stashedValuePath);
					if(value !== undefined){
						updateValue(dispatch, path, value);
					}
					//delete the temporary value
					deleteValue(dispatch, [...rootPath, keyPrefix + leaf]);
				}
				else if (path.length === 1){ //useful if restoring all values on a screen
					const leaf = path[0];
					const value = getValue([keyPrefix + leaf]);
					if(value !== undefined){
						updateValue(dispatch, [leaf], value);
					}
					//delete the temporary value(s)
					deleteValue(dispatch, [keyPrefix + leaf]);
				}
				else {
					//path length is 0 - nothing to do
					return;
				}
			}
		});
	}
};
const isNullOrUndefined = (value) => {
	return value === null || value === undefined;
};