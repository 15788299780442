import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLabel from '../../higherordercomponents/WithLabel';
import withTooltip from '../../higherordercomponents/WithTooltip';
import Label from './Label';
class RadioGroup extends Component {
	constructor(props){
		super(props);
		this.onOuterDivClick = this.onOuterDivClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.optionRefs = {};
	}
	onChange(e){
		if(this.props.onChange !== undefined){
			this.props.onChange(e.target.value);
		}
	}
	getId(){
		if(this.props.id && this.props.id.trim && this.props.id.trim() !== ''){
			return this.props.id;
		}
		return (this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	}
	onOuterDivClick(){
		Object.entries(this.optionRefs).forEach(([key, ref])=>{
			window.setTimeout(()=>{
				ref.focus();
			}, 0);
		});
	}
	handleRef = (ref, option) => {
		const optionRefsKeys = Object.keys(this.optionRefs);
		if(optionRefsKeys.includes(this.getId() + option.name) === false){
			this.optionRefs[this.getId() + option.name] = ref;
		}
	}
	render() {
		let radios = [];
		if(this.props.options !== undefined && Array.isArray(this.props.options) && this.props.options.length > 0){
			const firstOptionValue = this.props.options[0].value;
			radios = this.props.options.map(option => {
				let label = undefined;
				if(option.name){
					label = (
						<div style={{position:'relative', top:'-6px'}} className={'pl-2'}>
							<Label 
								screenId={this.props.screenId}
								fieldId={this.props.fieldId + '_' + option.name.replace(/\s+/g, '_')}
								value={option.name}
							/>
						</div>
					);
				}
				return (
					<div className={'d-flex' + (this.props.horizontal ? ' pr-3' : '')} key={option.value}>
						<input 
							type={'radio'} 
							id={this.getId() + '_' + option.name} 
							name={this.getId()}
							value={option.value}
							onChange={this.onChange}
							checked={this.props.value === undefined ? firstOptionValue === option.value : this.props.value === option.value}
							ref={(r) => this.handleRef(r, option)}
						/>
						{label}
					</div>);
			});
		}
		else {
			radios.push(<Label 
				screenId={this.props.screenId}
				fieldId={this.props.fieldId + '_No_Options_Provided'}
				value={'No Options Provided'}
			/>);
		}
		return <div className={'d-flex pt-2' + (this.props.horizontal ? ' flex-row' : '')} id={this.getId()} onClick={this.onOuterDivClick.bind(this)}>
			{radios}
		</div>;
	}
}

RadioGroup.propTypes = {
	screenId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.object),
	value: PropTypes.string,
	onChange: PropTypes.func
};

export default withLabel(withTooltip(RadioGroup));
