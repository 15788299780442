import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { 
	CheckBoxCellRendererContainer,
	DataGridContainer, 
	DetailsViewCellRenderer,
	ExpandCollapseColumnHeader,
	NumberTextBoxCellEditorContainer,
	NumberTextBoxCellRendererContainer
} from '@rtg/shared-react-components';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import store from '../../../configureStore';
import deepEqual from 'deep-equal';
import OpenInWindowIcon from '../../formelements/ImageComponents/OpenInWindowIcon';
class SuggestedOrdersOrdersTab extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	componentDidUpdate(prevProps){
		if(deepEqual(this.props.rowData, prevProps.rowData) === false){
			if(this.props.onDataChanged){
				this.props.onDataChanged(this.gridApi);
			}
		}
	}

	cannotBePrimary = (params) => this.props.cannotBePrimary 
		? this.props.cannotBePrimary(params) 
		: false;
	
	getTooltips = (params) => this.props.getTooltips 
		? this.props.getTooltips(params) 
		: '';

	getHasNoSalesHistory = (params) => this.props.getHasNoSalesHistory
		? this.props.getHasNoSalesHistory(params)
		: false;
	
	handlePrimaryChanged = (gridApi, node, value) => this.props.handlePrimaryChanged
		? this.props.handlePrimaryChanged(gridApi, node, value)
		: undefined;

	onGridReady = (params) => {
		this.gridApi = params.api;
		if(this.props.onGridReady){
			this.props.onGridReady(params);
		}
	};
	
	getFormattedColumnValue = (params) => this.props.getFormattedColumnValue 
		? this.props.getFormattedColumnValue(params) 
		: undefined;
	
	getColumnValue = (params) => this.props.getColumnValue 
		? this.props.getColumnValue(params) 
		: undefined;
	
	getIsCellHidden = (params) => this.props.getIsCellHidden 
		? this.props.getIsCellHidden(params) 
		: undefined;
	
	getAttentionIconMessage = (params) => this.props.getOrderColumnAttentionMessage 
		? this.props.getOrderColumnAttentionMessage(params) 
		: undefined;
	
	onOrderChanged = ({gridApi, node, value}) => this.props.onOrderChanged
		? this.props.onOrderChanged(gridApi, node, value)
		: undefined;
	
	onAAOChanged = ({gridApi, node, value}) => this.props.onAAOChanged
		? this.props.onAAOChanged(gridApi, node, value)
		: undefined;
	
	onRatioChanged = ({gridApi, node, value}) => this.props.onRatioChanged 
		? this.props.onRatioChanged(gridApi, node, value) 
		: undefined;
	
	compareDescriptions = (desc1, desc2, node1, node2, isInverted) => this.props.compareDescriptions 
		? this.props.compareDescriptions(desc1, desc2, node1, node2, isInverted) 
		: undefined;

	onDescriptionSortClicked = (colId, sort) => {
		if(this.gridApi){
			const columnState = this.gridApi.getColumnState();
			columnState.forEach(column => {
				const id = colId;
				column.sort = (column.colId === id ? sort : '');
			});
			this.gridApi.applyColumnState({
				state: columnState,
				applyOrder: true
			});
		}
	};

	onDescriptionColumnExpandCollapseClicked = (gridApi, isExpanded) => this.props.onDescriptionColumnExpandCollapseClicked
		? this.props.onDescriptionColumnExpandCollapseClicked(gridApi, isExpanded)
		: undefined;
	
	onCellClicked = (params) => this.props.selectedRowCount <= 1 && this.props.onCellClicked
		? this.props.onCellClicked(params)
		: undefined;
	
	tabToNextCell = (params) => this.props.tabToNextCell 
		? this.props.tabToNextCell(params, params.api)
		: undefined;
	
	getRowHeight = () => this.props.getRowHeight 
		? this.props.getRowHeight()
		: undefined;
	
	handleRowClicked = (params) => this.props.handleRowClicked
		? this.props.handleRowClicked(params)
		: undefined;

	handleKeyDown = (e) => {
		if(e.ctrlKey && e.which === 65) {
			e.preventDefault();
			this.gridApi.selectAll();
		}
	};
	postSortRows = (params) => {
		return this.props.postSortRows
			? this.props.postSortRows(params)
			: undefined;
	};
	getFlowAllLink = (params) => {
		return this.props.getFlowAllLink
			? this.props.getFlowAllLink(params)
			: undefined;
	};
	render() {
		return (
			<div className={'h-100 w-100'} style={{overflow: 'hidden'}} onKeyDown={this.handleKeyDown}>
				<DataGridContainer
					screenId={this.props.screenId} 
					panelId={this.props.panelId}
					fieldId='SuggestedOrdersOrdersGrid' 
					forwardRef={this.props.forwardRef} 
					defaultColDef={{resizable:true}}
					getRowHeight={this.getRowHeight}
					onCellClicked={(args) => {this.onCellClicked(args);} }
					tabToNextCell={this.tabToNextCell}
					rowSelection={'multiple'}
					sizeColumnsToFit
					onRowClicked={this.handleRowClicked}
					postSortRows={this.postSortRows}
					excludeColumnsFromColumnsPanel={['STAR', 'ORDER', 'AAO', 'AAOR']}
					showColumnsPanel
					columnDefs={
						[
							{ 
								headerName: '', 
								field: 'ORDERLINE', 
								colId: 'ORDERLINE', 
								hide: true,
								valueFormatter: () => {}
							}, 
							{
								headerName: '', 
								field: 'ORIGINAL_ORDERLINE', 
								colId: 'ORIGINAL_ORDERLINE', 
								hide: true,
								valueFormatter: () => {}
							},
							{ 
								headerName: 'Primary',
								field: 'PRIMARYSKU', 
								colId: 'PRIMARYSKU',
								width: 90,
								minWidth: 90,
								resizable: false,
								headerClass: 'ag-center-header',
								cellRenderer: CheckBoxCellRendererContainer, 
								cellRendererParams:	{
									getDisabled: this.cannotBePrimary,
									getTooltips: this.getTooltips,
									onChange: this.handlePrimaryChanged,
									gridName: 'SuggestedOrdersOrdersGrid',
									visible: !this.props.excludeRatios,
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									tooltipPlacement: 'bottom'
								},
								cellStyle: {textAlign:'center'}
							},
							{ 
								headerName: 'SKU', 
								field: 'SKU', 
								colId: 'SKU', 
								sortable: true, 
								width: 90, 
								minWidth: 90,
								valueGetter: this.getColumnValue, 
								cellStyle: () => {
									const selectedRowCount = this.props.selectedRowCount;
									return selectedRowCount <= 1 ? {cursor: 'pointer'} : {cursor: 'default'};
								} 
							},
							{
								headerName: 'Flow',
								headerTooltip: 'Open Flow',
								field: 'OPENFLOWALL',
								colId: 'OPENFLOWALL',
								sortable: false,
								width: 50,
								minWidth: 50,
								headerClass: 'ag-center-header',
								cellStyle: {textAlign:'center'},
								tooltipValueGetter: ({node, api}) => this.getFlowAllLink({node, api}),
								cellRenderer: OpenInWindowIcon,
								cellRendererParams: {
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									fieldId: 'btnOpenFlowAll',
									onClick: (params) => {
										const link = this.getFlowAllLink(params);
										window.open(link, '_blank');
									}
								}
							},
							{ 
								headerName: '*', 
								field: 'STAR', 
								colId: 'STAR', 
								sortable: false, 
								width: 35,
								minWidth: 35, 
								resizable: false, 
								valueGetter: this.getColumnValue
							}, 
							{ 
								headerName: 'Description', 
								field: 'DESCRIPTION', 
								colId: 'DESCRIPTION',
								sortable: true,
								width: 400,
								minWidth: 400,
								headerComponent: ExpandCollapseColumnHeader,
								headerComponentParams: { 
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									fieldId: 'btnExpandCollapseDescriptionColumnHeader',
									text: 'Description', 
									onExpandCollapseClicked: this.onDescriptionColumnExpandCollapseClicked, 
									colId: 'DESCRIPTION', 
									onSortClicked: this.onDescriptionSortClicked,
									disabled: this.props.disablePanel
								},
								cellRenderer: DetailsViewCellRenderer,
								comparator: this.compareDescriptions,
								valueGetter: this.getColumnValue
							},
							{ 
								headerName: 'Theme', 
								field: 'THEME', 
								colId: 'THEME',
								sortable: true,
								width: 130,
								minWidth: 130,
								valueGetter: this.getColumnValue
							},
							{ 
								headerName: 'Available', 
								field: 'AVAILABLE', 
								colId: 'AVAILABLE', 
								sortable: true, 
								width: 80, 
								minWidth: 80,
								type:'numericColumn', 
								valueGetter: this.getColumnValue, 
								valueFormatter: this.getFormattedColumnValue
							},
							{ 
								headerName: 'ExPr Avail', 
								field: 'EXPRAVAIL', 
								colId: 'EXPRAVAIL', 
								sortable: true, 
								width: 80, 
								minWidth: 80,
								type:'numericColumn', 
								valueGetter: this.getColumnValue, 
								valueFormatter: this.getFormattedColumnValue
							},
							{ 
								headerName: 'ExPr AAO', 
								field: 'EXPRAAO', 
								colId: 'EXPRAAO', 
								sortable: true, 
								width: 80, 
								minWidth: 80,
								valueGetter: this.getColumnValue, 
								type:'numericColumn', 
								valueFormatter: this.getFormattedColumnValue
							},
							{ 
								headerName: 'Weekly Sales', 
								field: 'WEEKLYSALES', 
								colId: 'WEEKLYSALES', 
								sortable: true, 
								width: 100,
								minWidth: 100, 
								valueGetter: this.getColumnValue, 
								type:'numericColumn', 
								valueFormatter: this.getFormattedColumnValue
							},
							{ 
								headerName: 'New WOS', 
								field: 'NEWWOS', 
								colId: 'NEWWOS', 
								sortable: true, 
								width: 80, 
								minWidth: 80,
								valueGetter: this.getColumnValue, 
								type:'numericColumn', 
								valueFormatter: this.getFormattedColumnValue
							},
							{ 
								headerName: 'Sales Ratio', 
								field: 'SALESRATIO', 
								colId: 'SALESRATIO', 
								sortable: true, 
								width: 90,
								minWidth: 90, 
								valueGetter: this.getColumnValue, 
								type:'numericColumn', 
								valueFormatter: this.getFormattedColumnValue
							},
							{ 
								headerName: 'Order',
								field: 'ORDER', 
								colId: 'ORDER',
								sortable: true,  
								width: 105,
								minWidth: 105,
								editable: true,
								singleClickEdit: true,
								headerClass: 'ag-center-header',
								cellRenderer: NumberTextBoxCellRendererContainer, 
								cellRendererParams: {
									getIsInvalid: this.getHasNoSalesHistory,
									getIsHidden: this.getIsCellHidden,
									dataType: sharedUtilities.dataTypes.integer,
									gridName: 'SuggestedOrdersOrdersGrid',
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									tooltipPlacement: 'left',
									getTooltips: this.getTooltips,
									getAttentionIconMessage: this.getAttentionIconMessage,
									includeInfoIcon: true
								},
								cellEditor: NumberTextBoxCellEditorContainer, 
								cellEditorParams: { 
									getIsInvalid: this.getHasNoSalesHistory,
									getIsHidden: this.getIsCellHidden,
									dataType: sharedUtilities.dataTypes.integer,
									gridName: 'SuggestedOrdersOrdersGrid',
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									tooltipPlacement: 'left',
									getTooltips: this.getTooltips,
									onChange: this.onOrderChanged,
									getAttentionIconMessage: this.getAttentionIconMessage,
									includeInfoIcon: true
								}, 
								valueGetter: this.getColumnValue,
								valueFormatter: this.getFormattedColumnValue,
								type:'numericColumn'
							},
							{ 
								headerName: 'AAO', 
								field: 'AAO', 
								colId: 'AAO', 
								sortable: true, 
								width: 90,
								minWidth: 90, 
								editable: true,
								singleClickEdit: true,
								headerClass: 'ag-center-header',
								cellRenderer: NumberTextBoxCellRendererContainer, 
								cellRendererParams: {
									getIsHidden: this.getIsCellHidden,
									dataType: sharedUtilities.dataTypes.integer,
									gridName: 'SuggestedOrdersOrdersGrid',
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									tooltipPlacement: 'left'
								},
								cellEditor: NumberTextBoxCellEditorContainer, 
								cellEditorParams: { 
									getIsHidden: this.getIsCellHidden,
									dataType: sharedUtilities.dataTypes.integer,
									gridName: 'SuggestedOrdersOrdersGrid',
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									tooltipPlacement: 'left',
									onChange: this.onAAOChanged
								},
								valueGetter: this.getColumnValue, 
								valueFormatter: this.getFormattedColumnValue,
								type:'numericColumn'
							},
							{ 
								headerName: 'AAOR', 
								field: 'AAOR', 
								colId: 'AAOR',
								sortable: true, 
								width: 90,
								minWidth: 90, 
								editable: true,
								singleClickEdit: true,
								headerClass: 'ag-center-header',
								cellRenderer: NumberTextBoxCellRendererContainer, 
								cellRendererParams: {
									getIsHidden: this.getIsCellHidden,
									gridName: 'SuggestedOrdersOrdersGrid',
									dataType: sharedUtilities.dataTypes.number,
									screenId: this.props.screenId,
									panelId: this.props.panelId
								},
								cellEditor: NumberTextBoxCellEditorContainer, 
								cellEditorParams: { 
									getIsHidden: this.getIsCellHidden,
									dataType: sharedUtilities.dataTypes.number,
									gridName: 'SuggestedOrdersOrdersGrid',
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									tooltipPlacement: 'left',
									getTooltips: this.getTooltips,
									onChange: this.onRatioChanged,
									nonNegativeOnly: true
								}, 
								valueGetter: this.getColumnValue,
								valueFormatter: this.getFormattedColumnValue,
								type:'numericColumn'
							}
						]
					}
					rowData={this.props.rowData}
					onGridReady={this.onGridReady}
					store={store}
				/>
			</div>
		);
	}
}

SuggestedOrdersOrdersTab.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	disablePanel: PropTypes.bool,
	height: PropTypes.number,
	rowData: PropTypes.array,
	selectedRowCount: PropTypes.number,

	//functions
	cannotBePrimary: PropTypes.func,
	compareDescriptions: PropTypes.func,
	getTooltips: PropTypes.func,
	handlePrimaryChanged: PropTypes.func,
	getColumnValue: PropTypes.func,
	getFormattedColumnValue: PropTypes.func,
	getHasNoSalesHistory: PropTypes.func,
	getIsCellHidden: PropTypes.func,
	getOrderColumnAttentionMessage: PropTypes.func,
	getRowHeight: PropTypes.func,
	handleRowClicked: PropTypes.func,
	onCellClicked: PropTypes.func,
	onDataChanged: PropTypes.func,
	onDescriptionColumnExpandCollapseClicked: PropTypes.func,
	onGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	onAAOChanged: PropTypes.func,
	onOrderChanged: PropTypes.func,
	onRatioChanged: PropTypes.func,
	tabToNextCell: PropTypes.func,
	postSortRows: PropTypes.func
};
export default SuggestedOrdersOrdersTab;