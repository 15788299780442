import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as gridUtilities from '../../../utilities/gridUtilities';
import { 
	DeleteButtonCellRenderer,
	NumberTextBoxCellEditorContainer,
	NumberTextBoxCellRendererContainer
} from '@rtg/shared-react-components';
const vendorWarehouseOrderGridColumnDefs = (screenId, panelId, gridName, getColumnValue, onValueChanged=()=>{}, onEnterPressed, getIsInvalid, getTooltips, onDeleteRowClicked) => {
	/**
	 * TODO: change the order of the columns to this before making final commit.
	 * [
	 * 	'ROWMODEL_ORIGINAL', //used to track cells that have had their values changed
	 * 	'ROWMODEL', 
	 * 	'SKU', 
	 * 	'DESCRIPTION',
	 * 	'QOH', 
	 * 	'QTYONORDER', 
	 * 	'OPENORDERS', 
	 * 	'T30SALES', 
	 * 	'QTYONDISPLAY', 
	 * 	'AVAILABLE', 
	 * 	'AAO', 
	 * 	'DAYSTOSTOCK', 
	 * 	'PROJECTED', 
	 * 	'TARGETQTY', 
	 * 	'ORD' 
	 * ]
	 */
	return [
		{ headerName: '', field: 'ROWMODEL_ORIGINAL', colId: 'ROWMODEL_ORIGINAL', hide: true},
		{ headerName: '', field: 'ROWMODEL', colId: 'ROWMODEL', hide: true},
		{ 
			headerName: 'SKU', 
			field: 'PARTSKU',
			colId: 'PARTSKU',
			width: 55,
			minWidth: 55,
			valueGetter: getColumnValue
		}, 
		{ 
			headerName: 'Mod', 
			field: 'PARTMOD',
			colId: 'PARTMOD',
			width: 55,
			minWidth: 55,
			valueGetter: getColumnValue
		}, 
		{ 
			headerName: 'Alias', 	
			field: 'ALIAS',
			colId: 'ALIAS',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue
		}, 
		{ 
			headerName: 'Part Description', 	
			field: 'PARTDESCRIPTION',
			colId: 'PARTDESCRIPTION',
			width: 185,
			minWidth: 185,
			valueGetter: getColumnValue
		}, 
		{ 
			headerName: 'Avg Dy Ship',
			field: 'AVERAGEDAILYSHIPMENTS',	
			colId: 'AVERAGEDAILYSHIPMENTS',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		}, 
		{ 
			headerName: 'Avg Wk Ship',
			field: 'AVERAGEWEEKLYSHIPMENTS',
			colId: 'AVERAGEWEEKLYSHIPMENTS',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{ 
			headerName: 'Tot Ship',
			field: 'TOTALSHIPMENTS',
			colId: 'TOTALSHIPMENTS',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			type:'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		}, 
		{ 
			headerName: 'Pend Ship',
			field: 'PENDINGSHIPMENTS',
			colId: 'PENDINGSHIPMENTS',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			type:'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		}, 
		{ 
			headerName: 'QOH', 
			field: 'QUANTITYONHAND',
			colId: 'QUANTITYONHAND',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		}, 
		{ 
			headerName: 'InB Qty',	
			field: 'INBOUNDQUANTITY',
			colId: 'INBOUNDQUANTITY',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		}, 
		{ 
			headerName: 'Avail Qty',
			field: 'AVAILABLEQUANTITY',
			colId: 'AVAILABLEQUANTITY',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},  
		{ 
			headerName: 'Wks InB',
			field: 'WEEKSOFSTOCKINBOUND',
			colId: 'WEEKSOFSTOCKINBOUND',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		}, 
		{ 
			headerName: 'Ord Qty Sug',
			field: 'CALCULATEDORDERQUANTITY',	
			colId: 'CALCULATEDORDERQUANTITY',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		}, 
		{ 
			headerName: 'Order',
			field: 'ORD',
			colId: 'ORD',
			sortable: true,
			editable: true,
			singleClickEdit:true,
			headerClass: 'ag-center-header',
			cellRenderer: NumberTextBoxCellRendererContainer, 
			cellRendererParams:
			{
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				tooltipPlacement: 'left',
				getIsHidden: () => { return false; }
			},
			cellEditor: NumberTextBoxCellEditorContainer, 
			cellEditorParams: 
			{ 
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => { return false; },
				onChange: onValueChanged,
				onEnterPressed: onEnterPressed
			},
			width: 90,
			minWidth: 90,
			valueGetter: getColumnValue,
			type:'numericColumn'
		}, 
		{ 
			headerName: '', 		
			field: 'DELETE',			
			colId: 'DELETE',
			cellRenderer: DeleteButtonCellRenderer, 
			cellRendererParams:
			{
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				fieldId: 'deleteRow',
				onClick: onDeleteRowClicked
			},
			width:40,
			minWidth:40,
			maxWidth:40
		}
	];
};
export default vendorWarehouseOrderGridColumnDefs;