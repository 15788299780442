import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import TransferScreen from './TransferScreen';

const SCREEN_ID = 'transferscreen';
const PANEL_ID = 'transferpanel';

class TransferScreenContainer extends Component {
	render() {
		return (
			<TransferScreen {...this.props}
				screenId={this.props.screenId || SCREEN_ID}
				panelId={this.props.panelId || PANEL_ID}
			/>
		);
	}
}

const mapStateToProps = () => {
	const user = storeUtilities.getUser();
	return {
		user
	};
};

const mapDispatchToProps = () => {
	return {};
};

TransferScreenContainer.propTypes = {
	user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferScreenContainer);
