import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import * as constants from '../../../constants';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import EditPurchaseOrderInstructionsDialogContainer from '../../dialogs/EditPurchaseOrderInstructionsDialogContainer';
import * as orderUtilities from '../orderproductsscreen/OrderProductScreenUtilities';
import PurchaseOrderQueuePOGridPanel from './PurchaseOrderQueuePOGridPanel';
import * as poQueueUtilities from './PurchaseOrderQueueScreenUtilities';

class PurchaseOrderQueuePOGridPanelContainer extends Component {
	render() {
		return (
			<PurchaseOrderQueuePOGridPanel {...this.props}
				panelId={this.props.panelId + '_' + this.props.poNumber}
				fieldId={'po_' + this.props.poNumber}
				cubesOverLimit={false}
				isQtyCellInvalid={() => false}
				isQueuedPO
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	return {
		instructions: storeUtilities.getValue([...props.path, 'instructions'], undefined),
		cubes: storeUtilities.getValue([...props.path, 'poCubes'], 0),
		poCost: storeUtilities.getValue([...props.path, 'poCost'], 0)
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		editInstructionsClicked: (poNumber)=>{
			dispatch(showDialogAction(
				<EditPurchaseOrderInstructionsDialogContainer 
					poNumber={poNumber} 
					screenId={props.screenId} 
					panelId={props.panelId}
					parentPath={props.path}
					onDialogClosed={()=>{
						dispatch(closeDialogAction());
					}}
					isQueuedPO
					title={'PO Queue#: ' + poNumber}/>, 
				325, 
				500));
		},
		getColumnValue: (node) => {
			switch(node.colDef.field){
				case 'NEEDBYDATE':
					return moment(node.data.NEEDBYDATE).toDate();
				default:
					return null;
			}
		},
		getFormattedColumnValue: (node)=>{
			switch(node.colDef.field){
				case 'NEEDBYDATE':
					return moment(node.data.NEEDBYDATE, constants.cfQueryDateFormat).format('L');
				default:
					return null;
			}
		},
		onCancelClicked: () => {
			storeUtilities.deleteValue(dispatch, props.path);
			dispatch(closeDialogAction());
		},
		onSaveClicked: (gridRef) => {
			const skus = [];
			gridRef.api.forEachNode((node)=>{
				const sku = node.data.ORDERLINE;
				sku.quantity = node.data.QTY;
				skus.push(sku);
			});
			const deliverTo = storeUtilities.getValue([...props.path, 'deliverTo'], undefined);
			const arrivalDate = getSqlDate(storeUtilities.getValue([...props.path, 'arrivalDate'], undefined));
			const shipDate = getSqlDate(storeUtilities.getValue([...props.path, 'shipDate'], undefined));
			const instructions = storeUtilities.getValue([...props.path, 'instructions'], undefined);
			dispatch(callRpcAction({skus, id: props.poNumber, deliverTo, arrivalDate, shipDate, instructions}, constants.nodeRoutes.IFRService, 'UpdateQueuedPOSkusJsonBody', (queue) => {
				if(queue && Array.isArray(queue))
				{
					poQueueUtilities.reloadQueue(dispatch, props.screenId);
					dispatch(closeDialogAction());
				}},
			true,
			'POST',
			false)
			);
		},
		formatCurrency: (value) => {
			return sharedUtilities.formatCurrency(value || 0);
		},
		onQtyChange: (gridApi, changedRowNode, newValue) => {
			const data = changedRowNode.data;
			const qtyDiff = orderUtilities.getRowQtyDiff(changedRowNode, newValue);
			const costDiff = qtyDiff * numberUtilities.getNumberOrDefault(data.ORDERLINE.FIRSTCOST, 0);
			data.QTY += qtyDiff;
			const currentPOCost = storeUtilities.getValue([...props.path, 'poCost'], 0);
			storeUtilities.updateValue(dispatch, [...props.path, 'poCost'], currentPOCost + costDiff);        
			const currentPOCubes = storeUtilities.getValue([...props.path, 'poCubes'], 0);
			const newPOCubes = currentPOCubes + (qtyDiff * data.ORDERLINE.Cube);
			storeUtilities.updateValue(dispatch, [...props.path, 'poCubes'], newPOCubes);   
			changedRowNode.setData(data);
			gridApi.refreshCells({rowNodes: [changedRowNode], force: true});
		}
	};
};
const getSqlDate = (date) => {
	if(date === undefined){
		return undefined;
	}
	const momentDate = moment(date);
	return momentDate.format('YYYY-MM-DD');
};
PurchaseOrderQueuePOGridPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	whsNum: PropTypes.string,
	poNumber: PropTypes.number,
	rowData: PropTypes.array,
	cubes: PropTypes.number,
	poCost: PropTypes.number,
	getColumnValue: PropTypes.func,
	formatColumnValue: PropTypes.func,
	instructions: PropTypes.string,
	editInstructionsClicked: PropTypes.func,
	fob: PropTypes.string,
	deliverToOptions: PropTypes.array,
	path: PropTypes.array,
	formatCurrency: PropTypes.func,
	onBeforeLoad: PropTypes.func,
	onQtyChange: PropTypes.func,
	title: PropTypes.string
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderQueuePOGridPanelContainer);

export default connectedComponent;