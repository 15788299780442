import { push } from 'connected-react-router';
import { parse } from 'dirty-json';
import Cookies from 'js-cookie';
import moment from 'moment';
import queryString from 'query-string';
import * as ramda from 'ramda';
import React from 'react';
import rpcAction from '../actions/rpcAction';
import callRpcAction from '../actions/callRpcAction';
import deleteGlobalAppStateValueAction from '../actions/deleteGlobalAppStateValueAction';
import showDialogAction from '../actions/showDialogAction';
import updateGlobalAppStateValueAction from '../actions/updateGlobalAppStateValueAction';
import store from '../configureStore';
import * as storeUtilities from './storeUtilities';
import { nodeRoutes, maintenanceModeStatusType, loggingLevels } from '../constants';
import emailValidator from 'email-validator';
export const isAuthenticated = (callback) => {
	store.dispatch(rpcAction({
		nodeRoute: nodeRoutes.IsAuthenticated,
		callback: (data) => {
			if(callback){
				callback(data);
			}
		}
	}));
};
export const getWhsListFromServer = (callback) => {
	store.dispatch(rpcAction({
		args: {},
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Warehouse/Warehouse',
		callback: (data) => {
			if (Array.isArray(data)) {
				const whsList = data.map(whs => {
					const division = whs?.whs_ID?.split ? whs.whs_ID.split('_')[0] : 'NA';
					return {
						division,
						whsId: whs.whs_ID,
						whsName: whs.whs_name
					};
				}).filter((whs) => {
					return whs.division !== 'NA';
				});
				storeUtilities.updateGlobalAppStateValue(store.dispatch, ['whsList'], whsList);
				if (callback) {
					callback();
				}
			}
		},
		showLoadingMask: false
	}));
};
export const getWhsList = (filterDups = false) => {
	const whsList = storeUtilities.getGlobalAppStateValue(['whsList']);
	let returnList = undefined;
	if (Array.isArray(whsList)) {
		returnList = whsList.filter((whs) => {
			const val = whs.whsId.toUpperCase();
			return filterDups === true ? (val !== 'FL_0' && val !== 'SE_20') : true;
		});
	}
	return returnList;
};
export const getAppInsightsKey = (callback) => {
	store.dispatch(callRpcAction({}, nodeRoutes.AppInsightsInstrumentationKey, '', (data) => {
		if (callback) {
			callback(data);
		}
	}, false));
};
export const checkMaintenance = (user, callback) => {
	store.dispatch(rpcAction({
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Management/Maintenance',
		callback: (data) => {
			if (data) {
				const inMaintenanceMode = (data['maintenanceMode'] === maintenanceModeStatusType.ACTIVATED);
				const maintNotes = data['notes'];
				const divisions = data['divisions'];
				const abbrArray = divisions;
				const divisionsArray = abbrArray.map(divisionAbbrev => {
					if (divisionAbbrev === 'FL') {
						return 'Florida';
					}
					if (divisionAbbrev === 'SE') {
						return 'Southeast';
					}
					if (divisionAbbrev === 'TX') {
						return 'Texas';
					}
					if (divisionAbbrev === 'PR') {
						return 'Puerto Rico';
					}
					return undefined;
				});
				let message = undefined;
				if (Array.isArray(divisionsArray)) {
					divisionsArray.forEach(division => {
						if (division !== undefined) {
							if (message === undefined) {
								message = 'WARNING: The following divisions are under maintenance: ' + division;
							}
							else {
								message += ', ' + division;
							}
						}
					});
				}
				if (inMaintenanceMode) {
					if (!user.hasAdminAccess()) {
						window.setTimeout(() => {
							store.dispatch(push('/maintenanceview'));
						});
					}
					else {
						if (message !== undefined) {
							store.dispatch(updateGlobalAppStateValueAction(['messageBar'], message));
						}
					}
				}
				else {
					store.dispatch(deleteGlobalAppStateValueAction(['messageBar']));
				}
				if (callback !== undefined) {
					callback(inMaintenanceMode, maintNotes, divisions);
				}
			}
		},
		showLoadingMask: false,
		method: 'GET',
		hideLoadingMaskOnComplete: true
	}));
};

export const isDevMode = () => {
	return window.location.href.startsWith('http://localhost:3000/') || window.location.href.startsWith('http://127.0.0.1:3000/');
};
export const formatCurrency = (value) => {
	return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};
export const getObjectFromCookie = (cookieName) => {
	const strValue = Cookies.get(cookieName);
	let obj = {};
	if (strValue && strValue.trim && strValue.trim() !== '') {
		const parsedObj = JSON.parse(strValue);
		if (parsedObj !== undefined)
			obj = parsedObj;

	}
	return obj;
};
export const setCookieFromObject = (cookieName, obj) => {
	Cookies.set(cookieName, obj);
};

export const dataTypes = { number: 'number', integer: 'integer' };
export const pruneObject = (obj) => {
	/**
	 * This will automatically get rid of fields in the object whose value is undefined
	 * Example: obj => {field1:undefined, field2:'someValue'}
	 * 			will return {field2:'someValue'}
	 */
	return JSON.parse(JSON.stringify(obj));
};
export const getValueFromObject = (obj, path, defaultValue = undefined) => {
	return ramda.pathOr(defaultValue, path)(obj);
};
export const getEnvVar = (varName) => {
	const returnValue = getValueFromObject(process.env, [varName], undefined);
	return returnValue;
};
export const getScreenInitializationDataFromProps = (props, arrRequestedProperties) => {
	let initializationData = props.history?.location?.state;
	if (initializationData === undefined) {
		const dirtyHash = props.history?.location?.hash;
		const hash = dirtyHash.replace ? dirtyHash.replace(/&/g, '%26') : '';
		const dirtyJson = queryString.parse(hash)['initializationData'];
		try {
			const data = parse(dirtyJson);
			if (data !== undefined) {
				initializationData = data;
			}
		}
		catch (e) {
			initializationData = undefined;
		}
	}
	const returnObj = {};
	if (arrRequestedProperties !== undefined && Array.isArray(arrRequestedProperties)) {
		arrRequestedProperties.forEach(property => {
			returnObj[property] = getValueFromObject(initializationData, [property]);
		});
	}
	return returnObj;
};
export const contains = (options, valueField, value) => {
	let found = false;
	if (Array.isArray(options) && options.length > 0) {
		options.forEach((option) => {
			if (option[valueField] === undefined) {
				found = false;
			}
			else if (option[valueField] === value) {
				found = true;
			}
		});
		return found;
	}
	return false;
};
const DIALOG_HEIGHT = 300;
const DIALOG_WIDTH = 400;
export const showError = (message, detail, title='ERROR') => {
	showMessage('errorDialog', 'errorDialog', message, detail, title);
};
export const showMessage = (screenId='messageDialog', dialogId='messageDialog', message, detail, title='INFO') => {
	const OkCancelDialogContainer = React.lazy(() => import('../components/dialogs/OkCancelDialogContainer'));
	store.dispatch(updateGlobalAppStateValueAction(['showLoadingMask'], false));
	store.dispatch(showDialogAction(
		<React.Suspense fallback={<div>Dialog failed to render.</div>}>
			<OkCancelDialogContainer 
				id={dialogId}
				screenId={screenId} 
				title={title}
				message={message && message.toString ? message.toString() : message}
				height={DIALOG_HEIGHT}
				width={DIALOG_WIDTH}>
				{(detail && detail.toString ? detail.toString() : detail)}
			</OkCancelDialogContainer>
		</React.Suspense>, 
		DIALOG_HEIGHT, 
		DIALOG_WIDTH, 
		()=>{}));
};
export const showOkCancelDialog = (
	{
		screenId='okCancelDialog', 
		dialogId='okCancelDialog', 
		message, 
		detail, 
		title='INFO',
		hasCancelButton=true,
		okClickedHandler=()=>{}
	}) => {
	const OkCancelDialogContainer = React.lazy(() => import('../components/dialogs/OkCancelDialogContainer'));
	store.dispatch(updateGlobalAppStateValueAction(['showLoadingMask'], false));
	store.dispatch(showDialogAction(
		<React.Suspense fallback={<div>Dialog failed to render.</div>}>
			<OkCancelDialogContainer 
				id={dialogId}
				screenId={screenId} 
				title={title}
				message={message && message.toString ? message.toString() : message}
				height={DIALOG_HEIGHT}
				width={DIALOG_WIDTH}
				handleOkClicked={okClickedHandler}
				hasCancelButton={hasCancelButton}>
				{(detail && detail.toString ? detail.toString() : detail)}
			</OkCancelDialogContainer>
		</React.Suspense>, 
		DIALOG_HEIGHT, 
		DIALOG_WIDTH, 
		()=>{}));
};
/**
 * A comparator to compare date values
 * @param {string} strDate1 a string representation of a date
 * @param {string} dateFormat1 the format of strDate1
 * @param {string} strDate2 a string representation of a date
 * @param {string} dateFormat2 the format of strDate2
 * @param {boolean} isInverted a boolean that indicates whether sort is ASCENDING (true) or DESCENDING (false)
 * @example <caption>Example usage of compareDates.</caption>
 * // returns 1
 * sharedUtilities.compareDates('November, 19 2020 00:00:00', 'November, 30 2020 00:00:00', 'MMMM, DD YYYY hh:mm:ss', 'MMMM, DD YYYY hh:mm:ss', isInverted);
 * @returns {Number} 
 * =>Returns 0 if dates are the same
 * =>Returns 1 if (isInverted === true and date1 occurs after date2) OR if (isInverted === false and date2 occurs after date1)
 * =>Returns -1 if (isInverted === true and date2 occurs after date1) OR if (isInverted === false and date1 occurs after date2)
 */
export const compareStringDates = (strDate1, dateFormat1, strDate2, dateFormat2, isInverted) => {
	if (typeof strDate1 === 'string' && typeof strDate2 === 'string') {
		const date1 = moment(strDate1, dateFormat1).toDate();
		const date2 = moment(strDate2, dateFormat2).toDate();
		return compareDates(date1, date2, isInverted);
	}
	else if (typeof strDate1 === 'string' && typeof strDate2 !== 'string') {
		return 1;
	}
	else if (typeof strDate1 !== 'string' && typeof strDate2 === 'string') {
		return -1;
	}
	else {
		return 0;
	}
};
export const compareDates = (date1, date2, isInverted) => {
	if (moment.isDate(date1) && moment.isDate(date2)) {
		const moment1 = moment(date1);
		const moment2 = moment(date2);
		return compareMoments(moment1, moment2, isInverted);
	}
	else if (moment.isDate(date1) && !moment.isDate(date2)) {
		return 1;
	}
	else if (!moment.isDate(date1) && moment.isDate(date2)) {
		return -1;
	}
	else {
		return 0;
	}
};
export const compareMoments = (moment1, moment2, isInverted) => {
	if (moment.isMoment(moment1) && moment.isMoment(moment2)) {
		if (moment1.isSame(moment2)) {
			return 0;
		}
		return isInverted ? (moment1.isAfter(moment2) ? 1 : -1) : (moment2.isAfter(moment1) ? -1 : 1);
	}
	else if (moment.isMoment(moment1) && !moment.isMoment(moment2)) {
		return -1;
	}
	else if (!moment.isMoment(moment1) && moment.isMoment(moment2)) {
		return 1;
	}
	else {
		return 0;
	}
};
export const whsDropdownComparator = (whs1, whs2) => {
	if (whs1.whsName > whs2.whsName) {
		return 1;
	}
	else if (whs1.whsName < whs2.whsName) {
		return -1;
	}
	else {
		return 0;
	}
};
export const getMultiSelectDivisionOptions = (selectedOptions) => {
	if (!Array.isArray(selectedOptions)) {
		return [];
	}
	const options = [{ value: 'FL', name: 'Florida' }, { value: 'SE', name: 'Southeast' }, { value: 'TX', name: 'Texas' }, { value: 'PR', name: 'Puerto Rico' }];
	const returnOptions = options.map(option => {
		if (selectedOptions.includes('FL') || selectedOptions.includes('SE')) {
			if (option.value !== 'FL' && option.value !== 'SE') {
				option.disabled = true;
			}
		}
		else {
			option.disabled = selectedOptions.length === 1 && !selectedOptions.includes(option.value);
		}
		return option;
	});
	return returnOptions;
};
export const getRecipients = (emailInfoArray) => {
	let recipients = '';
	if (Array.isArray(emailInfoArray)) {
		emailInfoArray.forEach((emailInfo, emailInfoIndex) => {
			const emailListStr = emailInfo?.destinationAddress?.trim ? emailInfo.destinationAddress.trim().replaceAll(',',';') : '';
			const emailList = [];
			const splitOnSemiColon = emailListStr.split(';');
			splitOnSemiColon.forEach(value => {
				const v = value.trim ? value.trim() : '';
				if(emailValidator.validate(v)){
					emailList.push(v);
				}
			});
			emailList.forEach((email, emailIndex) => {
				recipients += email + ((emailIndex === emailList.length - 1) 
					? ((emailInfoIndex === emailInfoArray.length - 1) 
						? '' //last emailInfo object in array
						: ' ') //if not on the last emailInfo object in array, insert a space email from next emailInfo object in array
					: ', '); //between each email in the destinationAddress field, insert a comma
			});
		});
	}
	return recipients;
};
export const logMessage = (message, level) => {
	let _level;
	switch(level){
		case loggingLevels.info:
			_level = loggingLevels.info.value;
			break;
		case loggingLevels.warn:
			_level = loggingLevels.warn.value;
			break;
		case loggingLevels.error:
			_level = loggingLevels.error.value;
			break;
		case loggingLevels.debug:
			_level = loggingLevels.debug.value;
			break; 
		default:
			_level = loggingLevels.info.value;
			break;
	}
	store.dispatch(rpcAction({
		args: { message, level: _level },
		showLoadingMask: false,
		nodeRoute: nodeRoutes.LogMessage,
		method: 'POST',
		retryOnFailure: false,
		requestInBody: true
	}));
};
export const delay = ms => new Promise(res => setTimeout(res, ms));
export const parseResponse = async (response) => {
	let parsedResponse;
	if(response.status < 200 || response.status > 299){
		throw new Error(JSON.stringify({status: response.status, statusText: response.statusText}));
	}

	try {
		parsedResponse = await response.json();
	}
	catch {
		try{
			parsedResponse = await response.text();
		}
		catch {
			try {
				parsedResponse = await response.body();
			}
			catch {
				parsedResponse = undefined;
				console.log('Unable to parse response.');
			}
		}
	}
	return parsedResponse;
};
export const uppercaseObjectKeys = (obj) => {
	return Object.keys(obj).reduce((acc, k) => {
		acc[k.toUpperCase()] = obj[k];
		return acc;
	}, {});
};
export function convertToJsonArray(columnHeadersAndDataObject, columnsToInclude = undefined, columnsKey='COLUMNS', dataKey='DATA', valueGetterOverrides){
	const resultSet = [];
	if(columnHeadersAndDataObject && columnHeadersAndDataObject[dataKey] && Array.isArray(columnHeadersAndDataObject[dataKey])){
		for(let i = 0; i < columnHeadersAndDataObject[dataKey].length; i++){
			const obj = {};
			for(let j = 0; j < columnHeadersAndDataObject[columnsKey].length; j++){
				if(columnsToInclude === undefined || columnsToInclude.includes(columnHeadersAndDataObject[columnsKey][j])){
					obj[columnHeadersAndDataObject[columnsKey][j]] = getRowValue(columnHeadersAndDataObject[columnsKey], 
						columnHeadersAndDataObject[dataKey][i], 
						columnHeadersAndDataObject[columnsKey][j],
						valueGetterOverrides);
				}
			}
			resultSet.push(obj);
		}
	}
	return resultSet;
}
export function getRowValue(columns, row, columnName, valueGetterOverrides){
	if(columns && columns.length > 0){
		let idx = null;
		for(let i = 0; i <= columns.length; i++){
			if(columns[i] && columnName && columns[i].toLowerCase() === columnName.toLowerCase()){
				idx = i;
				break;
			}
		}
		if(idx !== null && row && row.length > idx){ //if the length of the array isn't longer than the index we're searching for, this will fail
			if(typeof (valueGetterOverrides) === 'object' && valueGetterOverrides[columnName] !== undefined){
				return valueGetterOverrides[columnName](row, idx);
			}
			return row[idx];
		}
	}
	return undefined;
}
export const flattenResponse = (response) => {
	let flattenedArray = [];
	for (let i=0; i<response.length; i++) {
		for (let j=0; j<response[i].purchaseOrders.length; j++) {
			flattenedArray.push({
				'requestId' : response[i].requestId,
				'purchaseOrderNumber': response[i].purchaseOrders[j].purchaseOrderNumber,
				'status' : response[i].purchaseOrders[j].status,
				'errorMessage' : response[i].purchaseOrders[j].errorMessage,
				'warehouse' : response[i].purchaseOrders[j].destinationDivision + '_' + response[i].purchaseOrders[j].destinationWarehouseId,
				'vendor' : response[i].vendor
			});
		}
	}
	return flattenedArray;
};
export const getVendorEmailInfos = (dispatch, vendorEmailInfosPath, selectedRegions, vendorId, customResponseHandler=undefined, showErrorDialog = true) => {
	const vendorEmailInfos = [];
	console.log('selectedRegions: ' + JSON.stringify(selectedRegions));
	for(let i = 0; i < selectedRegions.length; i++){
		dispatch(rpcAction({
			args: { VendorId: vendorId, Region: selectedRegions[i] },
			nodeRoute: nodeRoutes.IFRServiceNET,
			endpoint: '/Vendor/EmailInfo',
			method: 'GET',
			showLoadingMask: false,
			callback: (response) => {
				if(response?.destinationAddress?.trim && response.destinationAddress.trim() !== ''){
					vendorEmailInfos.push(response);
				}
				if(vendorEmailInfos.length > 0){
					storeUtilities.updateValue(dispatch, vendorEmailInfosPath, vendorEmailInfos);
				}
				else {
					storeUtilities.deleteValue(dispatch, vendorEmailInfosPath);
					if(showErrorDialog === true){
						showError('Vendor Email Error', 'Vendor has no email address configured for this Division.', 'Error');
					}
				}
			},
			customResponseHandler: customResponseHandler
		}));
	}
};
export const vendorHasValidVendorEmailInfos = (vendorId, vendorEmailInfos) => {
	const emailInfosExist = Array.isArray(vendorEmailInfos) && vendorEmailInfos.length > 0;
	if(emailInfosExist){
		let allVendorEmailInfosBelongToVendor = true;
		vendorEmailInfos.forEach(vendorEmailInfo => {
			if(vendorEmailInfo?.vendorKey?.toLowerCase && vendorId?.toLowerCase){
				allVendorEmailInfosBelongToVendor = allVendorEmailInfosBelongToVendor && (vendorEmailInfo.vendorKey.toLowerCase() === vendorId.toLowerCase());
			}
			else allVendorEmailInfosBelongToVendor = false;
		});
		return allVendorEmailInfosBelongToVendor;
	}
	else {
		return false;
	}
};
export const indexOfCaseInsensitive = (arr, str) => {
	arr.findIndex(item => str.toLowerCase() === item.toLowerCase());
};