import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DataGridContainer } from '@rtg/shared-react-components';
import suggestedOrdersDetailsGridColumnDefs from './SuggestedOrdersDetailsGridColumnDefs';
import store from '../../../configureStore';

class SuggestedOrdersDetailsTab extends Component {
	onGridReady = (params) => {
		params.api.sizeColumnsToFit();
	};
	getIsXCellHidden = (cellProps) => {
		return this.props.getIsXCellHidden 
			? this.props.getIsXCellHidden(cellProps) 
			: true;
	};
	render() {
		return (
			<DataGridContainer
				screenId={this.props.screenId} 
				panelId={this.props.panelId}
				fieldId='SuggestedOrdersDetailsGrid' 
				forwardRef={this.props.forwardRef} 
				defaultColDef={{resizable:true}}
				columnDefs={suggestedOrdersDetailsGridColumnDefs(this.props.screenId, this.getIsXCellHidden)}
				rowData={this.props.rowData}
				onGridReady={this.onGridReady.bind(this)}
				showColumnsPanel
				store={store}
			/>
		);
	}
}

SuggestedOrdersDetailsTab.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	onGridReady: PropTypes.func,
	height: PropTypes.number,
	rowData: PropTypes.array
};
export default SuggestedOrdersDetailsTab;