import cloneDeep from 'lodash/cloneDeep';
import * as gridUtilities from '../../../../utilities/gridUtilities';
export const getRowData = (ol, showSigns = false) => {
	if(ol !== undefined){
		const o = cloneDeep(ol);
		return [
			{LABEL:'Safety Stock', 			VALUE: showSigns ? getNumberWithSign(o.SafetyStock) : (gridUtilities.formatDecimal(o.SafetyStock, 2))},
			{LABEL:'Replenishment Stock', 	VALUE: showSigns ? getNumberWithSign(o.ReplenishmentStock) : (gridUtilities.formatDecimal(o.ReplenishmentStock, 2))},
			{LABEL:'Days to Next Order', 	VALUE: showSigns ? getNumberWithSign(o.DaysToNextOrder) : (gridUtilities.formatDecimal(o.DaysToNextOrder, 2))},
			{LABEL:'Add\'l Days to Stock', 	VALUE: showSigns ? getNumberWithSign(o.ADTS) : (gridUtilities.formatDecimal(o.ADTS, 2))},
			{LABEL:'Available', 			VALUE: showSigns ? getNumberWithSign(o.LTAVAIL) : (gridUtilities.formatDecimal(o.LTAVAIL, 2))}
		];
	}
	return [];
};
export const getCalcRowData = (orderLine, altOrderLine, substituteChecked, combinationChecked) => {
	if(!substituteChecked && !combinationChecked){
		return getRowData(orderLine, true);
	}
	else if(substituteChecked && !combinationChecked){
		if(altOrderLine === undefined){
			return getRowData(orderLine, true);
		}
		const o = cloneDeep(orderLine);
		const a = cloneDeep(altOrderLine);
		return [
			{LABEL:'Safety Stock', 			VALUE:getNumberWithSign(a.SafetyStock)},
			{LABEL:'Replenishment Stock', 	VALUE:getNumberWithSign(a.ReplenishmentStock)},
			{LABEL:'Days to Next Order', 	VALUE:getNumberWithSign(a.DaysToNextOrder)},
			{LABEL:'Add\'l Days to Stock', 	VALUE:getNumberWithSign(a.ADTS)},
			{LABEL:'Available', 			VALUE:getNumberWithSign(o.LTAVAIL * -1)}
		];
	}
	else if(!substituteChecked && combinationChecked){
		if(altOrderLine === undefined){
			return getRowData(orderLine, true);
		}
		const o = cloneDeep(orderLine);
		const a = cloneDeep(altOrderLine);
		return [
			{LABEL:'Safety Stock', 			VALUE: getNumberWithSign(a.SafetyStock + o.SafetyStock)},
			{LABEL:'Replenishment Stock', 	VALUE: getNumberWithSign(a.ReplenishmentStock + o.ReplenishmentStock)},
			{LABEL:'Days to Next Order', 	VALUE: getNumberWithSign(a.DaysToNextOrder + o.DaysToNextOrder)},
			{LABEL:'Add\'l Days to Stock', 	VALUE: getNumberWithSign(a.ADTS + o.ADTS)},
			{LABEL:'Available', 			VALUE: getNumberWithSign((a.LTAVAIL + o.LTAVAIL) * -1)}
		];
	}
	return getRowData(orderLine, true);
};
export const getResultRowData = (orderLine, altOrderLine, substituteChecked, combinationChecked) => {
	const ORIG_ORDER_AMT_LABEL = 'Original Order Amount';
	const NEW_ORDER_AMT_LABEL = 'New Order Amount';
	const defaultReturnValue = [
		{LABEL: ORIG_ORDER_AMT_LABEL, 		VALUE: gridUtilities.formatDecimal(orderLine.Order, 2)},
		{LABEL: NEW_ORDER_AMT_LABEL, 		VALUE: gridUtilities.formatDecimal(0, 2)},
	];
	if(!substituteChecked && !combinationChecked){
		return defaultReturnValue;
	}
	else if(substituteChecked && !combinationChecked){
		if(altOrderLine === undefined){
			return defaultReturnValue;
		}
		const o1 = cloneDeep(orderLine);
		const a1 = cloneDeep(altOrderLine);
		const newOrderValue1 = a1.SafetyStock + a1.ReplenishmentStock + a1.DaysToNextOrder + a1.ADTS - o1.LTAVAIL;
		return [
			{LABEL: ORIG_ORDER_AMT_LABEL, 		VALUE: gridUtilities.formatDecimal(orderLine.Order, 2)},
			{LABEL: NEW_ORDER_AMT_LABEL, 		VALUE: gridUtilities.formatDecimal(newOrderValue1 > 0 ? newOrderValue1 : 0, 2)}
		];
	}
	else if(!substituteChecked && combinationChecked){
		if(altOrderLine === undefined){
			return defaultReturnValue;
		}
		const o2 = cloneDeep(orderLine);
		const a2 = cloneDeep(altOrderLine);
		const newOrderValue2 = (
			a2.SafetyStock 
            + o2.SafetyStock
            + a2.ReplenishmentStock 
            + o2.ReplenishmentStock
            + a2.DaysToNextOrder 
            + o2.DaysToNextOrder
            + a2.ADTS 
            + o2.ADTS
            - (o2.LTAVAIL + a2.LTAVAIL));
		return [
			{LABEL: ORIG_ORDER_AMT_LABEL, 		VALUE: gridUtilities.formatDecimal(orderLine.Order, 2)},
			{LABEL: NEW_ORDER_AMT_LABEL, 		VALUE: gridUtilities.formatDecimal(newOrderValue2 > 0 ? newOrderValue2 : 0, 2)},
		];
	}
	return defaultReturnValue;
};
export const getNumberWithSign = (num) => {
	if(num > 0){
		return '+' + gridUtilities.formatDecimal(num, 2);
	}
	else {
		return '' + gridUtilities.formatDecimal(num, 2);
	}
};