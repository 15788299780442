import React, { Component } from 'react';
import NoAccessToIFRScreen from './NoAccessToIFRScreen';
const SCREEN_ID = 'noaccessscreen';
class NoAccessScreenContainer extends Component {
	render() {
		return (
			<NoAccessToIFRScreen screenId={this.props.screenId || SCREEN_ID} />
		);
	}
}
export default NoAccessScreenContainer;