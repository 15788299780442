import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../components/widgets/css/NumberTextBoxCell.css';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as orderUtilities from './OrderPartScreenUtilities';
import * as gridUtilities from '../../../utilities/gridUtilities';
import SuggestedOrdersOrdersTab from './SuggestedOrdersOrdersTab';
import { cloneDeep } from 'lodash';
class SuggestedOrdersOrdersTabContainer extends Component {
	render() {
		return (
			<SuggestedOrdersOrdersTab {...this.props} />
		);
	}
}
const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], []);
	rowData.forEach((row)=>{
		const orderLine = orderUtilities.createOrderLineFromJsonResult(row);
		row['ORDERLINE'] = orderLine;
	});
	const showDetailedDescription = storeUtilities.getValue([props.screenId, 'ShowDetailedDescription'], false);
	return {
		rowData,
		showDetailedDescription
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'totalOrderQty'], 0);
		},
		cannotBePrimary: (node) => {
			return node && node.data && node.data.ORDERLINE && node.data.ORDERLINE.Order <= 0;
		},
		getHasNoSalesHistory: (cellProps) => {
			return cellProps !== undefined && cellProps.data !== undefined && cellProps.data.ORDERLINE !== undefined
				? numberUtilities.getNumberOrDefault(cellProps.data.ORDERLINE.WeeklySales) <= 0
				: true;
		},
		compareDescriptions: (desc1, desc2, node1, node2, isInverted) => {
			return isInverted ? (desc1.detail >= desc2.detail) : (desc1.detail < desc2.detail);
		},
		handleOrderChanged: (gridApi, changedRowNode, newValue) => {
			handleOrderChanged(dispatch, props.screenId, gridApi, changedRowNode, newValue);
		},
		getColumnValue: (obj) => {
			if(obj.data && obj.data.ORDERLINE){
				return orderUtilities.getSuggestedOrdersOrdersGridValueFromOrderLine(obj.data.ORDERLINE, obj.colDef.field, props.excludeRatios);
			}
		},
		onGridReady: (obj) => {
			obj.api.sizeColumnsToFit();
			storeUtilities.updateValue(dispatch, [props.screenId, 'sizeSuggestedOrdersOrdersTabGridColumnsToFit'], () => {
				obj.api.sizeColumnsToFit(); 
			});
		},
		tabToNextCell: (params) => {
			const editableColumns = params.api.getAllDisplayedColumns().filter(x => x.colDef.editable).map(x => x.colId);
			const canMoveTo = () => {
				return true;
			};
			const boundary = {
				xMin: 0,
				xMax: editableColumns.length - 1,
				yMin: 0,
				yMax: params.api.getDisplayedRowCount() - 1
			};
			const prevPos = {
				y: params.previousCellPosition.rowIndex,
				x: editableColumns.findIndex(x => x === params.previousCellPosition.column.colId)
			};
			const nextPos = gridUtilities.getNextCellPos(prevPos, boundary, canMoveTo, params.backwards);
			const nextCol = params.api.getColumn(editableColumns[nextPos.x]);
			const tabToNextCellParams = {
				rowIndex: nextPos.y,
				column: nextCol,
				rowPinned: params.previousCellPosition.rowPinned
			};
			return tabToNextCellParams;
		}
	};
};

const handleOrderChanged = (dispatch, screenId, gridApi, changedRowNode, newValue) => {
	const qty = numberUtilities.getNumberOrDefault(newValue, 0); 
	changedRowNode.data.ORDERLINE.CALCULATEDORDERQUANTITY = qty;
	const orderLinesRowData = storeUtilities.getValue([screenId, 'OrderLinesRowData'], undefined);
	if(Array.isArray(orderLinesRowData)){
		for(let rowIndex = 0; rowIndex < orderLinesRowData.length; rowIndex++){
			if(orderLinesRowData[rowIndex]['PARTSKU'] === changedRowNode.data.ORDERLINE.PARTSKU){
				orderLinesRowData[rowIndex]['ORDECALCULATEDORDERQUANTITY'] = qty;
				orderLinesRowData[rowIndex]['ORDERLINE'] = cloneDeep(changedRowNode.data.ORDERLINE);
			}
		}
	}
	changedRowNode.setDataValue('CALCULATEDORDERQUANTITY', qty);
	calculateAndUpdateTotalOrderQty(dispatch, screenId, gridApi);
	gridApi.refreshCells({rowNodes: [changedRowNode], force:true});
	storeUtilities.updateValue(dispatch, [screenId, 'OrderLinesRowData'], orderLinesRowData);
};
const calculateAndUpdateTotalOrderQty = (dispatch, screenId, gridApi) => {
	let totalOrderQty = 0;
	gridApi.forEachNode((node)=>{
		if(parseFloat(node.data.ORDERLINE.CALCULATEDORDERQUANTITY) > 0){
			totalOrderQty += parseFloat(node.data.ORDERLINE.CALCULATEDORDERQUANTITY);
		}
	});
	storeUtilities.updateValue(dispatch, [screenId, 'totalOrderQty'], totalOrderQty);
};

SuggestedOrdersOrdersTabContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	showDetailedDescription: PropTypes.bool,
	onDescriptionColumnExpandCollapseClicked: PropTypes.func,
	compareDescriptions: PropTypes.func,
	handleOrderChanged: PropTypes.func,
	getIsCellHidden: PropTypes.func,
	getColumnValue: PropTypes.func,
	cannotBePrimary: PropTypes.func,
	rowData: PropTypes.array,
	getTooltips: PropTypes.func,
	onCellClicked: PropTypes.func,
	excludeRatios: PropTypes.bool,
	disablePanel: PropTypes.bool,
	height: PropTypes.number,
	forwardRef: PropTypes.any,
	onGridReady: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(SuggestedOrdersOrdersTabContainer);