import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import showDialogAction from '../../../actions/showDialogAction';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import AddClearanceSkusDialogContainer from './addclearanceskusdialog/AddClearanceSkusDialogContainer';
import ClearanceOrderQueueDialogContainer from './ClearanceOrderQueueDialogContainer';
import ClearanceScreen from './ClearanceScreen';
import { nodeRoutes } from '../../../constants';
import rpcAction from '../../../actions/rpcAction';

const SCREEN_ID = 'clearancescreen';
const PANEL_ID = 'clearanceOrdering';
class ClearanceScreenContainer extends Component {
	render() {
		return (
			<ClearanceScreen
				screenId={this.props.screenId || SCREEN_ID}
				panelId={this.props.panelId || PANEL_ID}
				user={this.props.user}
				division={this.props.division}
				handleDivisionChanged={this.props.handleDivisionChanged}
				handleWarehouseChanged={this.props.handleWarehouseChanged}
				clearanceSkuData={this.props.clearanceSkuData}
				viewPoOrderQueue={this.props.viewPoOrderQueue}
				addSku={this.props.addSku}
				whsNum={this.props.whsNum}
				queueStoreOrders={this.props.queueStoreOrders}
				onLoad={this.props.onLoad}
				queueCount={this.props.queueCount}
				gridRefsUpdated={this.props.gridRefsUpdated}
			/>
		);
	}
}

const mapStateToProps = (stae, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const panelId = props.panelId || PANEL_ID;
	const user = storeUtilities.getUser();
	const division = storeUtilities.getValue([screenId, 'division'], undefined);
	const clearanceSkuData = storeUtilities.getValue([screenId, 'clearanceSkuData'], undefined);
	const whsNum = storeUtilities.getValue([screenId, 'whsNum'], undefined);
	const queueCount = storeUtilities.getValue([screenId, panelId, 'QueueCount'], 0);
	return {
		user,
		division,
		clearanceSkuData,
		whsNum,
		queueCount
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const panelId = props.panelId || PANEL_ID;
	return {
		onLoad: () => {
			const user = storeUtilities.getUser();
			const args = { poUser: user.rtgID };
			dispatch(callRpcAction(args, nodeRoutes.IFRService, 'GetQueuedClearanceOrdersCount', (data) => {
				if (data && Array.isArray(data) && data.length === 1) {
					const queueCount = numberUtilities.getNumberOrDefault(data[0]['QUEUECOUNT']);
					storeUtilities.updateValue(dispatch, [screenId, panelId, 'QueueCount'], queueCount);
				}
			}, false));
		},
		handleDivisionChanged: () => {
			storeUtilities.updateValue(dispatch, [screenId, 'clearanceSkuData'], []);
		},
		handleWarehouseChanged: (value) => {
			storeUtilities.updateValue(dispatch, [screenId, 'clearanceSkuData'], []);
			dispatch(rpcAction({ 
				args: { store: value },
				nodeRoute: nodeRoutes.IFRServiceNET,
				endpoint: '/Clearance/Skus',
				callback: (data) => {
					if (data && Array.isArray(data)) {
						storeUtilities.updateValue(dispatch, [screenId, 'clearanceSkuData'], data);
					}
				}
			}));
		},
		viewPoOrderQueue: () => {
			dispatch(showDialogAction(
				<ClearanceOrderQueueDialogContainer
					screenId={screenId}
				/>,
				500,
				800,
				() => { },
				true,
				undefined,
				true,
				false));
		},
		addSku: () => {
			dispatch(showDialogAction(
				<AddClearanceSkusDialogContainer
					screenId={screenId}
				/>,
				600,
				1200,
				() => { },
				true,
				undefined,
				true,
				false));
		},
		queueStoreOrders: (gridRefs) => {
			const validationErrors = storeUtilities.getValidationErrors([screenId]);
			const panelsOverCubeLimit = [];
			Object.entries(validationErrors).forEach(([key, value]) => {
				if (key !== undefined && key.startsWith !== undefined && key.startsWith('panel_')) {
					if (value && value.cubesTotal && value.cubesTotal.errors && Array.isArray(value.cubesTotal.errors)) {
						const matchingErrors = value.cubesTotal.errors.filter(x => {
							return x === 'Cubes over limit';
						});
						if (matchingErrors.length === 1) {
							panelsOverCubeLimit.push(key.split('_')[1]);
						}
					}
				}
			});
			if (panelsOverCubeLimit.length > 0) {
				const vendorList = panelsOverCubeLimit.map(vendor => {
					return (<li key={vendor}>{vendor}</li>);
				});
				const height = 300;
				const width = 400;
				dispatch(showDialogAction(
					<OkCancelDialogContainer
						screenId={screenId + '_cubeSizeWarning'}
						title={'WARNING'}
						message={'Cube limit violation(s)'}
						handleOkClicked={() => {
							queueOrders(dispatch, gridRefs);
						}}
						hasCancelButton>
						The following Vendors have orders that exceed the max cubes set on their corresponding panels:
						<br />
						<br />
						{vendorList}
						<br />
						Click OK to continue or Click Cancel to return to Clearance Ordering.
					</OkCancelDialogContainer>,
					height,
					width,
					() => { }));
			}
			else {
				queueOrders(dispatch, screenId, panelId, gridRefs);
			}
		}
	};
};
const queueOrders = (dispatch, screenId, panelId, gridRefs) => {
	const orderArray = [];
	const division = storeUtilities.getValue([screenId, 'division'], undefined);
	if (gridRefs !== undefined && Array.isArray(gridRefs) && gridRefs.length > 0) {
		gridRefs.forEach((gridRef) => {
			if (gridRef !== undefined) {
				gridRef.api.forEachNode((node) => {
					const rowModel = sharedUtilities.getValueFromObject(node, ['data', 'ROWMODEL']);
					if (rowModel !== undefined && parseInt(rowModel.ORD) > 0) {//value exists in path
						orderArray.push({
							sku: rowModel.SKU,
							qty: numberUtilities.getNumberOrDefault(rowModel.ORD, 0),
							whs: rowModel.WHS,
							div: division,
							vendor: rowModel.VENDOR
						});
					}
				});
			}
		});
	}
	if (orderArray.length > 0) {
		const user = storeUtilities.getUser();
		dispatch(callRpcAction({ orderArray: JSON.stringify(orderArray), poUser: user.rtgID }, nodeRoutes.IFRService, 'QueueClrPOsJsonBody', () => {
			const args = { poUser: user.rtgID };
			dispatch(callRpcAction(args, nodeRoutes.IFRService, 'GetQueuedClearanceOrdersCount', (data) => {
				if (data && Array.isArray(data) && data.length === 1) {
					const queueCount = numberUtilities.getNumberOrDefault(data[0]['QUEUECOUNT']);
					storeUtilities.updateValue(dispatch, [screenId, panelId, 'QueueCount'], queueCount);
					gridRefs.forEach(gridRef => {
						if(gridRef?.api?.sizeColumnsToFit){
							gridRef.api.sizeColumnsToFit();
						}
					});
				}
			}, false));
		}, true, 'POST'));
	}
};
ClearanceScreenContainer.propTypes = {
	user: PropTypes.object,
	clearanceSkuData: PropTypes.array,
	whsNum: PropTypes.string,
	onLoad: PropTypes.func,
	queueCount: PropTypes.number
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ClearanceScreenContainer);

export default connectedComponent;
