import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

class AllocationsTab extends Component {
	componentDidMount(){
		ChartJS.register(
			LinearScale,
			CategoryScale,
			BarElement,
			PointElement,
			LineElement,
			Legend,
			Tooltip,
			LineController,
			BarController,
			{
				id: 'vertical_line_under_cursor',
				afterDraw: function (chart) {
					if (chart.tooltip._active && chart.tooltip._active.length) {
						const activePoint = chart.tooltip._active[0];
						const ctx = chart.ctx;
						const x = activePoint.element.x;
						const topY = chart.scales.y.top;
						const bottomY = chart.scales.y.bottom;
						ctx.save();
						ctx.beginPath();
						ctx.moveTo(x, topY);
						ctx.lineTo(x, bottomY);
						ctx.lineWidth = 2;
						ctx.strokeStyle = '#e23fa9';
						ctx.stroke();
						ctx.restore();
					}
				}
			},
			{
				id: 'chart_background_color',
				beforeDraw: (chart) => {
					const ctx = chart.canvas.getContext('2d');
					ctx.save();
					ctx.globalCompositeOperation = 'destination-over';
					ctx.fillStyle = '#FFFFFF';
					ctx.fillRect(0, 0, chart.width, chart.height);
					ctx.restore();
				}
			}
		);	
		Tooltip.positioners.cursor = function(elements, pos) {
			return pos;
		};
	}
	render() {
		const options = {
			responsive: true,
			maintainAspectRatio: false, 
			interaction: {
				mode: 'index',
				position: 'cursor',
				intersect: false
			},
			plugins: {
				legend: {
					display: true,
					position: 'bottom'
				}
			}
		};
		return <div style={{width: '100%', height: (this.props.height - 30) + 'px'}}><Chart style={{width:'100%'}} type='bar' data={this.props.data} options={options} /></div>;
	}
}

AllocationsTab.protoTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired
};

export default AllocationsTab;