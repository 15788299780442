import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AltSkuCellEditor from './AltSkuCellEditor.jsx';
import '../../../widgets/css/NumberTextBoxCell.css';

class AltSkuCellEditorContainer extends Component {
	constructor(props){
		super(props);
		this.state = { 
			hide: props.getIsHidden ? props.getIsHidden(props) : false,
			tooltipPlacement: props.tooltipPlacement || 'left',
			isInvalid: false,
			disabled: props.getDisabled ? props.getDisabled(props) : false
		};
		this.validate = this.validate.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}
	onChange(params){
		if(this.props.onChange){
			this.props.onChange(params);
		}		
	}
	validate() {
		const isInvalid = this.props.getIsInvalid === undefined ? false : this.props.getIsInvalid(this.props, false);//second arg: isRenderer
		const disabled = this.props.getDisabled === undefined ? false : this.props.getDisabled(this.props, true);
		this.setState({isInvalid, disabled});
	}
	onBlur(){
		if(this.props.api && this.props.api.stopEditing){
			this.props.api.stopEditing();
		}
	}
	getValue(){
		return this.props.api.getCellValue({rowNode: this.props.node, colKey: this.props.colDef.colId});
	}
	render() {
		const fieldId = this.props.colDef.colId + '_' + this.props.rowIndex;
		const tooltips = this.props.getTooltips ? this.props.getTooltips(this.props, false) : [];
		const value = this.props.api.getCellValue({rowNode: this.props.node, colKey: this.props.colDef.colId});
		return <AltSkuCellEditor {...this.props} 
			fieldId={fieldId} 
			tooltips={tooltips !== undefined ? tooltips : []}
			hide={this.state.hide}
			value={String(value)}
			dataType={this.state.dataType}
			tooltipPlacement={this.state.tooltipPlacement}
			isInvalid={this.state.isInvalid}
			validate={this.validate}
			onChange={this.onChange}
			disabled={this.state.disabled}
			onBlur={this.onBlur}
		/>;
	}
}

AltSkuCellEditorContainer.propTypes = {
	getIsInvalid: PropTypes.func,
	getIsHidden: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	validators: PropTypes.arrayOf(PropTypes.func),
	validationErrors: PropTypes.object,
	onEnterPressed: PropTypes.func,
	tooltipPlacement: PropTypes.string,
	validate: PropTypes.func,
	onLoadSkuClicked: PropTypes.func
};

export default AltSkuCellEditorContainer;