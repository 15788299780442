import { fromJS } from 'immutable';

const initialState = fromJS({
	defaultValues: {},
	currentValues: {},
	untrackedValues: {},
	validationErrors: {},
	rpcDropdowns: { Options: {}, Args: {}, State: {}},
	globalAppState: { showLoadingMask: false },
	dialogs: [],
	user: {}
});

export default initialState;