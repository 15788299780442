import { fromJS } from 'immutable';
import Cookies from 'js-cookie';
import moment from 'moment';
const key = 'persistent_values';
const emptyObjectString = '{}';
const EXPIRATION_DAYS = 10000;
export const set = (path, value, expirationDays = undefined) => {
	let item = undefined;
	if(localStorage){
		item = localStorage.getItem(key);
	}
	else{
		item = Cookies.get(key);
	}
	if(item === undefined || item === null){
		item = emptyObjectString;
	}
	let persistentValues = undefined;
	try{
		persistentValues = JSON.parse(item);
	}
	catch(e){
		//the data stored at the key is corrupted, force it to a valid empty object
		item = emptyObjectString;
		if(localStorage){
			localStorage.setItem(key, item);
		}
		else{
			Cookies.set(key, item, { expires: (expirationDays === undefined ? EXPIRATION_DAYS : expirationDays)});
		}
	}
	finally {
		let persistentValuesMap = persistentValues !== undefined ? fromJS(persistentValues) : undefined;
		persistentValuesMap = persistentValuesMap.setIn(path, value);
		const persistentValuesString = JSON.stringify(persistentValuesMap !== undefined && persistentValuesMap.toJS !== undefined ? persistentValuesMap.toJS() : persistentValuesMap);
		if(localStorage){
			localStorage.setItem(key, persistentValuesString);
		}
		else {
			Cookies.set(key, persistentValuesString, { expires: (expirationDays === undefined ? EXPIRATION_DAYS : expirationDays) });
		}
	}
};
export const get = (path, fnConvertFromString) => {
	let item = undefined;
	if(localStorage){
		item = localStorage.getItem(key);
	}
	else
	{	
		item = Cookies.get(key);
	}
	if(item === undefined || item === null){
		return undefined;
	}
	let persistentValues = undefined;
	try{
		persistentValues = JSON.parse(item);
	}
	catch(e){
		return undefined;
	}

	const persistentValuesMap = persistentValues !== undefined ? fromJS(persistentValues) : undefined;
	const value = persistentValuesMap !== undefined ? persistentValuesMap.getIn(path) : undefined;
	const returnValue = value !== undefined && value.toJS !== undefined ? value.toJS() : value;
	if(fnConvertFromString !== undefined){
		return returnValue.trim && returnValue.trim() !== '' ? fnConvertFromString(returnValue) : undefined;
	}
	return returnValue;
};
export const deleteValue = (path) => {
	let item = undefined;
	if(localStorage){
		item = localStorage.getItem(key);
	}
	else
	{	
		item = Cookies.get(key);
	}
	if(item === undefined || item === null){
		return;
	}
	let persistentValues = undefined;
	try{
		persistentValues = JSON.parse(item);
	}
	catch(e){
		window.console.log('Unable to delete persistentValue at path: ' + path);
	}
	let persistentValuesMap = persistentValues !== undefined ? fromJS(persistentValues) : undefined;
	if(persistentValuesMap !== undefined && persistentValuesMap.getIn(path) !== undefined){
		persistentValuesMap = persistentValuesMap.deleteIn(path);
	}
	if(persistentValuesMap.toJS !== undefined){
		set([], persistentValuesMap.toJS());
	}
};
export const toDate = (dateString) => {
	const dateValue = moment(dateString).toDate(); 
	return dateValue;
};