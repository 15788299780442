import { call, cancel, cancelled, fork, put, take } from 'redux-saga/effects';
import updateGlobalAppStateValueAction from '../actions/updateGlobalAppStateValueAction';
import { CANCEL_RPC_ACTION } from '../actionTypes';
import * as api from '../utilities/api';

export default function* rpcSaga(action) {
	const task = yield fork(executeCall, action);
	yield take(CANCEL_RPC_ACTION);
	yield cancel(task);
}
function* executeCall(action){
	let response = undefined;
	const req = action.args ? (action.args.toJS ? action.args.toJS() : action.args) : {};
	try{
		if(action.showLoadingMask === true){
			yield put(updateGlobalAppStateValueAction(['showLoadingMask'], true));
		}
		response = yield call(api.request, action.nodeRoute, action.endpoint, req, action.method, action.headers, action.customResponseHandler, action.requestInBody, action.retryOnFailure);
	}
	catch(e){
		if(action.fnOnCatch !== undefined){
			action.fnOnCatch(e);
		}
	}
	finally {
		if(action.fnOnFinally !== undefined){
			action.fnOnFinally();
		}
		if(yield cancelled()){
			if(action.showLoadingMask === true){
				yield put(updateGlobalAppStateValueAction(['showLoadingMask'], false));
			}
		}
		else{
			if(action.showLoadingMask === true && action.hideLoadingMaskOnComplete === true){
				yield put(updateGlobalAppStateValueAction(['showLoadingMask'], false));
			}
			if(action.callback){
				yield call(action.callback, response);
			}
			else {
				yield response;
			}
		}
	}
}