import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './TransferScreen.css';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import ReportsScreenContainer from './ReportsScreenContainer';
import SuggestedTransfersMainScreenContainer from './SuggestedTransfersMainScreenContainer';

class TransferScreen extends Component {
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| (!this.props.user.hasAdminAccess())){
			return <NoAccessToScreenPanel />;
		}
		return (
			<Container className={'h-100'} fluid={true}>
				<Card className={'h-100'} >
					<Card.Header>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<h3 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Transfers</h3>
							</Col>
						</Row>
					</Card.Header>
					<Card.Body className={'h-100 m-0 pt-0'}>
						<Tabs defaultActiveKey="transfers" style={{backgroundColor: 'gainsboro'}}>
							<Tab eventKey="reports" title="Reports" className={'h-100'} >
								<ReportsScreenContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
								/>
							</Tab>
							<Tab eventKey="transfers" title="Suggested Transfers" className={'h-100'} >
								<SuggestedTransfersMainScreenContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
								/>
							</Tab>
						</Tabs>
					</Card.Body>
				</Card>
			</Container>
		);
	}
}

TransferScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired
};

export default TransferScreen;