import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { keyCodes } from '../../../../constants';
import withTooltip from '../../../../higherordercomponents/WithTooltip.jsx';
import * as gridUtilities from '../../../../utilities/gridUtilities.jsx';
import store from '../../../../configureStore';
import { ButtonContainer, withPopover } from '@rtg/shared-react-components';
import '../../../widgets/css/NumberTextBoxCell.css';
class AltSkuCellEditor extends Component {
	constructor(props){
		super(props);
		this.id = gridUtilities.getCellId(props.node.rowIndex, props.column.colId, props.gridName, true);
		this.state = { 
			value: props.value,
			hide: props.getIsHidden ? props.getIsHidden(props) : false,
			disabled: false
		};
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
		if(this.props.validate){
			this.props.validate();
		}
		window.setTimeout(()=>{
			if(this.ref?.select){
				this.ref.select();
			}
		}, 50);
	}
	handleChange(e){
		this.setState({value: e.target.value});        
		if(this.props.onChange){
			this.props.onChange({
				gridApi: this.props.api, 
				node: this.props.node, 
				value: e.target.value, 
				colId: this.props.colDef.colId, 
				rowIndex: this.props.rowIndex
			});
		}
		this.validate();
	}
	onBlur(){
		if(this.props.onBlur){
			this.props.onBlur();
		}
		this.validate();
	}
	validate(){
		if(this.props.validate){
			this.props.validate();
		}
	}
	disable(){
		this.setState({disabled: true});
	}
	enable(){
		this.setState({disabled: false});
	}
	hasValidationErrors(){
		return this.props.validationErrors !== undefined && this.props.validationErrors.errors && Array.isArray(this.props.validationErrors.errors) && this.props.validationErrors.errors.length > 0;
	}
	onLoadSkuClicked(){
		if(this.props.onLoadSkuClicked){
			this.props.onLoadSkuClicked({
				gridApi: this.props.api, 
				node: this.props.node, 
				value: this.state.value, 
				colId: this.props.colDef.colId, 
				rowIndex: this.props.rowIndex
			});
		}
	}
	onKeyUp(e){
		if(e.keyCode === keyCodes.ENTER && this.props.onEnterPressed){
			this.props.onEnterPressed({
				gridApi: this.props.api, 
				node: this.props.node, 
				value: e.target.value, 
				colId: this.props.colDef.colId, 
				validate: () => { 
					this.validate(); 
				},
				disable: () => {
					this.disable();
				},
				enable: () => {
					this.enable();
				}});
		}
	}
	render() {
		const style = {};
		if(this.props.hide){
			style.display = 'none';
		}
		const component = (
			<Container fluid={true} className={'w-100 m-0 p-0'} style={style}>
				<Row className={'w-100 m-0 p-0'} noGutters={true} style={{marginTop:'2px'}}>
					<Col style={{paddingRight: '3px'}}>
						<Form.Control 
							id={this.id}
							data-testid={this.id}
							type={'number'}
							className={'form-control'} 
							style={{display: (this.props.hide || false ? 'none': 'block'), width:'100%'}}
							value={this.props.value}
							onChange={this.handleChange.bind(this)}
							isInvalid={this.props.isInvalid}
							disabled={this.props.disabled}
							onFocus={this.props.onFocus}
							ref={(ref) => {this.ref = ref;}}
							onBlur={this.props.onBlur}
						/>
					</Col>
					<Col>
						<ButtonContainer screenId={this.props.screenId} 
							panelId={this.props.panelId}
							fieldId={this.props.fieldId}
							overrideId={this.id + 'LoadSKU_Button'}
							store={store}
							text={'Load SKU'}
							disabled={this.props.disabled || this.props.value.length !== 8}
							onClick={this.onLoadSkuClicked.bind(this)}
						/>
					</Col>
				</Row>
			</Container>);
		return component;
	}
}

AltSkuCellEditor.propTypes = {
	value: PropTypes.string,
	getIsHidden: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	validators: PropTypes.arrayOf(PropTypes.func),
	validationErrors: PropTypes.object,
	isInvalid: PropTypes.bool,
	onEnterPressed: PropTypes.func,
	tooltips: PropTypes.arrayOf(PropTypes.string).isRequired,
	tooltipPlacement: PropTypes.string,
	onLoadSkuClicked: PropTypes.func.isRequired
};

export default withTooltip(withPopover(AltSkuCellEditor));