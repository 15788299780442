import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchProductsScreen from './SearchProductsScreen';
import * as storeUtilities from '../../../utilities/storeUtilities';
import { history } from '../../../configureStore';

export const SCREEN_ID = 'searchproductsscreen';
class SearchProductsScreenContainer extends Component {
	render() {
		return (
			<SearchProductsScreen {...this.props}
				screenId={this.props.screenId || SCREEN_ID}
				history={history}
			/>
		);
	}
}

const mapStateToProps = () => {
	return {
		user: storeUtilities.getUser() 
	};
};

SearchProductsScreenContainer.propTypes = {
	user: PropTypes.object.isRequired,
	history: PropTypes.any
};

const connectedComponent = connect(mapStateToProps, null)(SearchProductsScreenContainer);

export default connectedComponent;