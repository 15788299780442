import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import store from '../../configureStore';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import ModalContent from './ModalContent';
import { keyCodes } from '../../constants';

class Modal extends Component {
	constructor(props){
		super(props);
		this.updateStyles = this.updateStyles.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.state = {
			height: 0, 
			width: 0,
			minHeight: 0,
			minWidth: 0,
			x: 0,
			y: 0,
			contentStyle: {},
			overlayStyle: {}
		};
	}
	componentDidMount(){
		this.modalTarget = document.createElement('div');
		document.body.appendChild(this.modalTarget);
		document.body.addEventListener('keydown', this.onKeyDown);
		this.initialize();
	}
	initialize(){
		let dialogWidth = 0;
		let dialogHeight = 0;
		let x = 0;
		let y = 0;
		if(this.props.dialogWidth < (window.innerWidth - 60)){
			dialogWidth = this.props.dialogWidth;
			if(this.props.position !== undefined){
				x = this.props.position.x;
			}
			else{
				x = ((window.innerWidth/2) - (this.props.dialogWidth/2));
			}
		}
		if(this.props.dialogHeight < (window.innerHeight)){
			dialogHeight = this.props.dialogHeight;
			if(this.props.position !== undefined){
				y = this.props.position.y;
			}
			else{
				y = ((window.innerHeight/2) - (this.props.dialogHeight/2));
			}
		}
		let overlayStyle = {};
		let contentStyle = {
			minWidth: dialogWidth, 
			minHeight: dialogHeight,
			x: x,
			y: y,
			transform: this.props.doSlideInEffect ? 'translateY(35%)' : '',
			opacity: '0'
		};
		this.setState(
			{
				minHeight: dialogHeight || 0, 
				minWidth: dialogWidth || 0,
				height: dialogHeight || 0,
				width: dialogWidth || 0,
				x: x || 0,
				y: y || 0,
				contentStyle: contentStyle,
				overlayStyle: overlayStyle
			}
		);
		this._render(this.props, overlayStyle, contentStyle);
	}
	updateStyles(prevState){
		let {overlayStyle, contentStyle} = this.state;
		if(prevState.beginShowDialogAnimation !== this.props.beginShowDialogAnimation && this.props.beginShowDialogAnimation === true){
			contentStyle = {
				opacity: '1',
				transform: this.props.doSlideInEffect ? 'translateY(0)' : ''
			};
			overlayStyle = {
				opacity: '1'
			};
			this.setState({
				contentStyle: contentStyle,
				overlayStyle: overlayStyle
			});
		}
		if(prevState.beginCloseDialogAnimation !== this.props.beginCloseDialogAnimation && this.props.beginCloseDialogAnimation === true){
			contentStyle = {
				opacity: '0',
				transform: this.props.doSlideInEffect ? 'translateY(35%)' : ''
			};
			overlayStyle = {
				opacity: this.props.isOnlyDialog ? '0' : '1'
			};
			this.setState({
				contentStyle: contentStyle,
				overlayStyle: overlayStyle
			});
		}
		this._render(this.props, overlayStyle, contentStyle);
	}
	componentDidUpdate(prevProps){
		if(prevProps.dialogId !== this.props.dialogId){
			this.initialize();
		}
		else {
			this.updateStyles(prevProps);
		}
	}
	componentWillUnmount(){
		ReactDOM.unmountComponentAtNode(this.modalTarget);
		document.body.removeChild(this.modalTarget);
		document.body.removeEventListener('keydown', this.onKeyDown);
	}
	handleSize(size){
		if(size && size.height && size.width){
			if(size.height >= this.props.dialogHeight){
				this.setState({height: size.height});
			}
			if(size.width >= this.props.dialogWidth){
				this.setState({width: size.width});
			}
		}
	}
	getLargest(val1, val2){
		const val1Int = window.parseInt(val1);
		const val2Int = window.parseInt(val2);
		const largest = val2Int > val1Int ? val2Int : val1Int;
		return largest;
	}
	handleOverlayClicked(){
		if(this.props.handleOverlayClicked)
			this.props.handleOverlayClicked();
	}
	onKeyDown(event){
		switch(event.keyCode){
			case keyCodes.ESCAPE:
				if(this.props.handleEscapeKeyPressed && this.props.closeOnEscapeKeyPressed === true){
					this.props.handleEscapeKeyPressed();
				}
				break;
			default:
				return;
		}
	}
	_render(props, overlayStyle, contentStyle){	
		const dialogContent = props.isUnitTest
			? <div onClick={e => e.stopPropagation()} className={'h-100 w-100'}>{props.children}</div> //sizeMe library does not work with Jest - must render control without sizeMe for unit tests to work
			: <ModalContent onSize={this.handleSize.bind(this)}>{props.children}</ModalContent>;
		ReactDOM.render(
			<Provider store={store}>
				<div className={'Overlay'} style={overlayStyle} onClick={this.handleOverlayClicked.bind(this)}>
					<div className={'Modal'} style={contentStyle}>
						<Rnd 
							dragHandleClassName={'dlgdraggable'}
							size={{
								width: this.getLargest(this.state.minWidth, this.state.width), 
								height: this.getLargest(this.state.minHeight, this.state.height)
							}}
							position={{x: this.state.x, y: this.state.y}}
							onResizeStop={(e, direction, ref, delta, position) => {
								this.setState({
									width: this.getLargest(ref.style.width, this.state.minWidth),
									height: this.getLargest(ref.style.height, this.state.minHeight),
									...position
								});
							}}
							onDragStop={(e, d) => {
								this.setState({ x: d.x, y: d.y });
							}}
							enableResizing={{
								bottom: this.props.enableResizing,
								bottomLeft: this.props.enableResizing,
								bottomRight: this.props.enableResizing,
								left: this.props.enableResizing,
								right: this.props.enableResizing,
								top: this.props.enableResizing,
								topLeft: this.props.enableResizing,
								topRight: this.props.enableResizing
							}}
							minWidth={this.state.minWidth}
							minHeight={this.state.minHeight}>
							{dialogContent}
						</Rnd>
					</div>
				</div>
			</Provider>, this.modalTarget);
	}
	render() {
		return <noscript />;
	}
}
Modal.propTypes = {
	dialogId: PropTypes.string.isRequired,
	children: PropTypes.any,
	beginShowDialogAnimation: PropTypes.bool,
	beginCloseDialogAnimation: PropTypes.bool,
	dialogHeight: PropTypes.number,
	dialogWidth: PropTypes.number,
	overlayVisible: PropTypes.bool,
	isOnlyDialog: PropTypes.bool,
	handleOverlayClicked: PropTypes.func,
	closeOnEscapeKeyPressed: PropTypes.bool,
	handleEscapeKeyPressed: PropTypes.func,
	isUnitTest: PropTypes.bool
};

export default Modal;
