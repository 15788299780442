import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import SearchPartsListPanelContainer from './SearchPartsListPanelContainer';
import SearchPartsListResultsPanelContainer from './SearchPartsListResultsPanelContainer';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
class SearchPartsScreen extends Component {
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| this.props.user.hasBuyerAccess === undefined
			|| this.props.user.hasPartsAccess === undefined
			|| (!this.props.user.hasAdminAccess() && !this.props.user.hasBuyerAccess() && !this.props.user.hasPartsAccess())){
			return <NoAccessToScreenPanel />;
		}
		return (
			<Container className={'h-100'} fluid={true}>
				<Row className='h-100'>
					<Col xs={12} sm={12} md={12} lg={3} xl={2} className={'mr-0 pr-0 ml-0 pl-0'}>
						<SearchPartsListPanelContainer 
							screenId={this.props.screenId}
							panelId={'searchPartsListPanel'}
						/>
					</Col>
					<Col xs={12} sm={12} md={12} lg={9} xl={10} className={'mr-0 pr-0 ml-0 pl-0'}>
						<SearchPartsListResultsPanelContainer 
							screenId={this.props.screenId}
							panelId={'searchPartsListResultsPanel'}
							history={this.props.history}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

SearchPartsScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	onLoad: PropTypes.func,
	resultsPanelId: PropTypes.string,
	user: PropTypes.object
};

export default SearchPartsScreen;