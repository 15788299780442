import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as orderUtilities from './OrderProductScreenUtilities';
import * as numberUtilities from '../../../utilities/numberUtilities';
import SuggestedPOsTabs from './SuggestedPOsTabs';

class SuggestedPOsTabsContainer extends Component {
	render() {
		return (
			<SuggestedPOsTabs {...this.props}/>);
	}
}

const mapStateToProps = (state, props) => {
	const whsNum = storeUtilities.getValue([props.screenId, 'whsNum']);
	const whsList = sharedUtilities.getWhsList(false);
	const poCountsPerWhs = Array.isArray(whsList) && whsList.length > 0 ? orderUtilities.getPOCountsPerWhs(props.screenId, whsList.map(x => x.whsId)) : 0;
	return {
		whsNum,
		whsList,
		poCountsPerWhs
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSuggestedPosTotalCostChange: (costDiff) => {
			const posTotalCost = storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'totalCost'], 0);
			storeUtilities.updateValue(dispatch, [props.screenId, 'SuggestedPOs', 'totalCost'], posTotalCost + costDiff, true);
		},
		checkAllQtyCells: (gridRefs) => {
			disableCreatePOsButtonIfNecessary(dispatch, props.screenId, gridRefs);
		}
	};
};

const disableCreatePOsButtonIfNecessary = (dispatch, screenId, gridRefs) => {
	let disable = false;
	const maxPO = numberUtilities.getNumberOrDefault(storeUtilities.getValue([screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
	const usingCubes = orderUtilities.useCubes(screenId);
	const usePDMPieceCountInUse = storeUtilities.getValue([screenId, 'usePDMPieceCountInUse'], false);
	if(Array.isArray(gridRefs)){
		gridRefs.forEach(gridRef => {
			const qtySum = orderUtilities.getQtySum(gridRef.api);
			disable = disable || (usingCubes === false ? (qtySum > maxPO) : false);
			gridRef.api.forEachNode(node => {
				const pieceCount = usePDMPieceCountInUse === true ? node.data.ORDERLINE.PDMPieceCount : node.data.ORDERLINE.PieceCount;
				disable = disable || (pieceCount > 0 && node.data.QTY % pieceCount !== 0);
			});
		});
	}
	storeUtilities.updateValue(dispatch, [screenId, 'QTYCELLSVALIDATIONERROR'], disable);
};

SuggestedPOsTabsContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	poCountPerWhs: PropTypes.number,
	viewPort: PropTypes.string,
	whsList: PropTypes.array,
	whsNum: PropTypes.string,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedPOsTabsContainer);

export default connectedComponent;