import * as sharedUtilities from '../../../utilities/sharedUtilities';
import PdfGridCellIcon from '../../formelements/ImageComponents/PdfGridCellIcon';
const comparator = (date1, date2, node1, node2, isInverted) => {
	return sharedUtilities.compareDates(date1, date2, isInverted);
};
const searchDailyOrdersResultsGridColumnDefs = (screenId, panelId, gridName, onPdfIconClick=()=>{}, getPdfCellIsHidden) => {
	return [
		{
			headerName: 'SKU',
			field: 'SKU',
			colId: 'SKU',
			width: 120,
			minWidth: 120
		},
		{
			headerName: 'Warehouse',
			field: 'WHS',
			colId: 'WHS',
			width: 60,
			minWidth: 60
		},
		{
			headerName: 'Division',
			field: 'DIV',
			colId: 'DIV',
			width: 50,
			minWidth: 50
		},
		{
			headerName: 'PO Number',
			field: 'PONUM',
			colId: 'PONUM',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'QTY',
			field: 'QTY',
			colId: 'QTY',
			width: 50,
			minWidth: 50,
			type: 'numericColumn'
		},
		{
			headerName: 'Order QTY',
			field: 'ORDERQTY',
			colId: 'ORDERQTY',
			width: 80,
			minWidth: 80,
			type: 'numericColumn'
		},
		{
			headerName: 'Grouping',
			field: 'GRP',
			colId: 'GRP',
			width: 130,
			minWidth: 130
		},
		{
			headerName: 'Buyer',
			field: 'BUYERINITIALS',
			colId: 'BUYERINITIALS',
			width: 70,
			minWidth: 70
		},
		{
			headerName: 'Days To Stock',
			field: 'DAYSTOSTOCK',
			colId: 'DAYSTOSTOCK',
			width: 110,
			minWidth: 110,
			type: 'numericColumn'
		},
		{
			headerName: 'DTNO',
			field: 'DAYSTONEXTORDER',
			colId: 'DAYSTONEXTORDER',
			width: 140,
			minWidth: 140,
			type: 'numericColumn'
		},
		{
			headerName: 'Days to Next Order',
			field: 'USERDAYSTONEXTORDER',
			colId: 'USERDAYSTONEXTORDER',
			width: 90,
			minWidth: 90,
			type: 'numericColumn'
		},
		{
			headerName: 'PO Flag',
			field: 'POFLAG',
			colId: 'POFLAG',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'Exclude Promo Flag',
			field: 'PROMOFLAG',
			colId: 'PROMOFLAG',
			width: 160,
			minWidth: 160
		},
		{
			headerName: 'Prod Time',
			field: 'PRODLEADTIME',
			colId: 'PRODLEADTIME',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Transit Time',
			field: 'TRANSLEADTIME',
			colId: 'TRANSLEADTIME',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Lead Time',
			field: 'LEADTIME',
			colId: 'LEADTIME',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Num Sales Days',
			field: 'NUMSALESDAYS',
			colId: 'NUMSALESDAYS',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Max Cube',
			field: 'MAXCUBE',
			colId: 'MAXCUBE',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'PO Cube',
			field: 'POCUBE',
			colId: 'POCUBE',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Order Date',
			field: 'ORDDATE',
			colId: 'ORDDATE',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Ship Date',
			field: 'SHIPDATE',
			colId: 'SHIPDATE',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Arrival Date',
			field: 'ARRIVALDATE',
			colId: 'ARRIVALDATE',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Vendor',
			field: 'VENDOR',
			colId: 'VENDOR',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'Combine WHS List',
			field: 'COMBINEWHSLIST',
			colId: 'COMBINEWHSLIST',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'Begin Sales Date',
			field: 'SALESBEGINDATE',
			colId: 'SALESBEGINDATE',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'End Sales Date',
			field: 'SALESENDDATE',
			colId: 'SALESENDDATE',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Cube',
			field: 'CUBE',
			colId: 'CUBE',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Avg Daily Sales',
			field: 'AVERAGEDAILYSALES',
			colId: 'AVERAGEDAILYSALES',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Weekly Sales',
			field: 'WEEKLYSALES',
			colId: 'WEEKLYSALES',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Total Sales',
			field: 'TOTALSALES',
			colId: 'TOTALSALES',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'QOH',
			field: 'QOH',
			colId: 'QOH',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Inbound',
			field: 'INBOUND',
			colId: 'INBOUND',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Available',
			field: 'AVAILABLE',
			colId: 'AVAILABLE',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Promo Avail',
			field: 'PROMOAVAIL',
			colId: 'PROMOAVAIL',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Weeks of Stock',
			field: 'WEEKSOFSTOCK',
			colId: 'WEEKSOFSTOCK',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Std. Deviation',
			field: 'STANDARDDEVIATION',
			colId: 'STANDARDDEVIATION',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Safety Stock',
			field: 'SAFETYSTOCK',
			colId: 'SAFETYSTOCK',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Replenishment Stock',
			field: 'REPLENISHMENTSTOCK',
			colId: 'REPLENISHMENTSTOCK',
			width: 150,
			minWidth: 150,
			type: 'numericColumn'
		},
		{
			headerName: 'Order Point',
			field: 'ORDERPOINT',
			colId: 'ORDERPOINT',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Weeks of Stock on Hand',
			field: 'WEEKSOFSTOCKONHAND',
			colId: 'WEEKSOFSTOCKONHAND',
			width: 180,
			minWidth: 180,
			type: 'numericColumn'
		},
		{
			headerName: 'Available Today',
			field: 'AVAILABLETODAY',
			colId: 'AVAILABLETODAY',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'QTY to WHS',
			field: 'QTYTOWHS',
			colId: 'QTYTOWHS',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'QTY from WHS',
			field: 'QTYFROMWHS',
			colId: 'QTYFROMWHS',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Store Qty on Hand',
			field: 'STOREQUANTITYONHAND',
			colId: 'STOREQUANTITYONHAND',
			width: 140,
			minWidth: 140,
			type: 'numericColumn'
		},
		{
			headerName: 'Piece Count',
			field: 'PIECECOUNT',
			colId: 'PIECECOUNT',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'AAO',
			field: 'AVAILABLEAFTERORDER',
			colId: 'AVAILABLEAFTERORDER',
			width: 160,
			minWidth: 160,
			type: 'numericColumn'
		},
		{
			headerName: 'Order Ratio',
			field: 'ORDERRATIO',
			colId: 'ORDERRATIO',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Intradivisional',
			field: 'INTRADIVISIONAL',
			colId: 'INTRADIVISIONAL',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Send as Fax',
			field: 'SENDASFAX',
			colId: 'SENDASFAX',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Style',
			field: 'STYLE',
			colId: 'STYLE',
			width: 80,
			minWidth: 80
		},
		{
			headerName: 'Need By Date',
			field: 'NEEDBYDATE',
			colId: 'NEEDBYDATE',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Date Created',
			field: 'CREATEDDATETIME',
			colId: 'CREATEDDATETIME',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Last Modified',
			field: 'LASTMODIFIEDDATETIME',
			colId: 'LASTMODIFIEDDATETIME',
			width: 130,
			minWidth: 130,
			comparator
		},
		{ 
			headerName: 'PO', 		
			field: 'HASPDF',			
			colId: 'HASPDF',
			cellRenderer: PdfGridCellIcon, 
			cellRendererParams:
			{
				height: '25px', 
				width: '25px',
				onClick: onPdfIconClick,
				getIsHidden: getPdfCellIsHidden,
				screenId
			},
			width:70,
			minWidth:70,
			maxWidth:70
		},		
		{
			headerName: 'Sent to Email',
			field: 'RECIPIENT',
			colId: 'RECIPIENT',
			width: 130,
			minWidth: 130
		},
		{
			headerName: 'Sent to CC',
			field: 'CC',
			colId: 'CC',
			width: 130,
			minWidth: 130
		},
		{
			headerName: 'Date Sent',
			field: 'DATESENT',
			colId: 'DATESENT',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Over Allocation',
			field: 'OVERALLOCATION',
			colId: 'OVERALLOCATION',
			width: 130,
			minWidth: 130
		}
	];
};
export default searchDailyOrdersResultsGridColumnDefs;