import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import SearchDailyOrdersPanelContainer from './SearchDailyOrdersPanelContainer';
import SearchDailyOrdersResultsPanelContainer from './SearchDailyOrdersResultsPanelContainer';
class DailyOrderLogScreen extends Component {
	constructor(props){
		super(props);
		this.searchDailyOrdersResultsGridRef = React.createRef();
	}
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| (!this.props.user.hasAdminAccess() 
				&& !this.props.user.hasBuyerAccess() 
				&& !this.props.user.hasPartsAccess() 
				&& !this.props.user.hasClearanceAccess() 
				&& this.props.user.hasReadOnlyAccess())){
			return <NoAccessToScreenPanel />;
		}
		return (
			<Container className={'h-100 p-0 m-0'} fluid={true}>
				<Row className={'h-100'} noGutters={true}>
					<Col xs={12} sm={12} md={12} lg={4} xl={3}>
						<SearchDailyOrdersPanelContainer
							screenId={this.props.screenId} 
						/>
					</Col>
					<Col xs={12} sm={12} md={12} lg={8} xl={9}>
						<SearchDailyOrdersResultsPanelContainer 
							screenId={this.props.screenId}
							searchDailyOrdersResultsGridRef={this.searchDailyOrdersResultsGridRef} 
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

DailyOrderLogScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	columnsPanelId: PropTypes.string.isRequired,
	handleSearchDailyOrdersResultsGridCheckBoxChanged: PropTypes.func,
	onSearchDailyOrdersResultsGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	user: PropTypes.object.isRequired,
	selectAllChecked: PropTypes.bool
};

export default DailyOrderLogScreen;