import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import updateValidationErrorsAction from '../../actions/updateValidationErrorsAction';
import * as persistentValues from '../../utilities/persistentValues';
import * as storeUtilities from '../../utilities/storeUtilities';
import TextBox from './TextBox';

class TextBoxContainer extends Component {
	handleChange(value) {
		this.props.updateValue(value);
		if(this.props.onChange)
			this.props.onChange(value);
	}
	render() {
		return (
			<TextBox 
				screenId={this.props.screenId}
				panelId={this.props.panelId}
				fieldId={this.props.fieldId}
				onChange={this.handleChange.bind(this)}
				onEnterPressed={this.props.onEnterPressed}
				value={this.props.value}
				disabled={this.props.disabled}
				type={this.props.type}
				onLoad={this.props.onLoad}
				visible={this.props.visible}
				size={this.props.size}
				height={this.props.height}
				validate={this.props.validate}
				label={this.props.label}
				showRequiredAsterisk={this.props.showRequiredAsterisk}
				inputMinWidth={this.props.inputMinWidth}
				mainComponentBootstrapSize={this.props.mainComponentBootstrapSize}
				maxCharacters={this.props.maxCharacters}
				caption={this.props.caption}
				tooltips={this.props.tooltips}
				isInvalid={this.props.isInvalid}
				tooltipPlacement={this.props.tooltipPlacement}
				trimValue={this.props.trimValue}
				hideValidationErrors={this.props.hideValidationErrors}
				path={this.props.path}
			/>
		);
	}
}

export const mapStateToProps = (state, props) => {
	const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
	const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
	const visible = props.visible;
	const hasValidationErrors = storeUtilities.hasValidationErrors(path);
	const validationErrors = storeUtilities.getValidationErrors(path);
	const tooltips = hasValidationErrors === true && validationErrors.hideValidationErrors === false
		? validationErrors.errors 
		: (props.defaultTooltip !== undefined ? [props.defaultTooltip] : []);
	const isInvalid = hasValidationErrors === true && validationErrors.hideValidationErrors === false;
	return {
		value: valueInStore,
		visible,
		tooltips,
		isInvalid,
		path
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: (fnAfterLoad) => {
			const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
			const persistedValue = persistentValues.get(path);
			const persistValue = props.persistValue && persistedValue !== undefined && persistedValue.trim && persistedValue.trim() !== '';
			const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
			const value = valueInStore !== undefined ? valueInStore : (persistValue === true ? persistedValue : props.value);
			storeUtilities.initializeValue(dispatch, path, value, persistValue);
			if(fnAfterLoad){
				fnAfterLoad(value);
			}
		},
		updateValue: (value) => {
			if(value !== undefined){
				updateValue(dispatch, props.screenId, props.fieldId, props.overridePath, value, false, props.persistValue);
			}
			else {
				const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
				storeUtilities.deleteValue(dispatch, path);
			}
		},
		validate: (args, isInitialValidationCheck) => {
			if(props.validators !== undefined && Array.isArray(props.validators) && props.validators.length > 0){
				const errors = props.validators.map(validate => {
					return validate(args, isInitialValidationCheck);
				});
				dispatch(updateValidationErrorsAction(storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath), errors, isInitialValidationCheck));
			}
		},
		hideValidationErrors: (path, isDisabled, validate) => {
			storeUtilities.hideValidationErrors(dispatch, path, isDisabled, validate);
		}
	};
};
const updateValue = (dispatch, screenId, fieldId, overridePath, value, isInitialValue, persistValue) => {
	persistValue = persistValue && (value !== undefined && value.toString && value.toString().trim() !== '');
	const path = storeUtilities.getPath(screenId, fieldId, overridePath);
	storeUtilities.updateValue(dispatch, path, value, isInitialValue, persistValue);
};
TextBoxContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onEnterPressed: PropTypes.func,
	onLoad: PropTypes.func,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	overridePath: PropTypes.array,
	size: PropTypes.string,
	height: PropTypes.string,
	validators: PropTypes.arrayOf(PropTypes.func),
	validate: PropTypes.func,
	showRequiredAsterisk: PropTypes.bool,
	label: PropTypes.string,
	visible: PropTypes.bool,
	inputMinWidth: PropTypes.string,
	mainComponentBootstrapSize: PropTypes.number,
	maxCharacters: PropTypes.number,
	caption: PropTypes.string,
	persistValue: PropTypes.bool,
	defaultTooltip: PropTypes.string,
	tooltips: PropTypes.arrayOf(PropTypes.string),
	isInvalid: PropTypes.bool,
	tooltipPlacement: PropTypes.string,
	trimValue: PropTypes.bool,
	hideValidationErrors: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(TextBoxContainer);

connectedComponent.defaultProps = {
	visible: true,
	type: 'text'
};

export default connectedComponent;