import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../../utilities/commonValidators';
import { 
	ButtonContainer,
	DropdownContainer,
	TextBoxContainer 
} from '@rtg/shared-react-components';
import store from '../../../../configureStore';
class AddClearanceSkusSearchProductsListPanel extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onSearchClicked(){
		if(this.props.onSearchClicked && !this.props.searchButtonDisabled){
			this.props.onSearchClicked();
		}
	}
	render() {
		return (
			<Card className={'h-100'}>
				<Card.Header>
					<h5 className='text-left'>Search Product List</h5>
				</Card.Header>
				<Card.Body className={'h-100'}>
					<Row className={'mb-1'}>
						<Col>
							<DropdownContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Region'}
								options={[{value:'FL', name:'Florida'},{value:'SE', name:'Southeast'},{value:'TX', name:'Texas'},{value:'PR', name:'Puerto Rico'}]}
								label={'Division'}
								overridePath={this.props.addNewSkusTabPath.concat(['Region'])}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'VendorCode'}
								onEnterPressed={() => {}}
								validators={[validators.required]}
								label={'Vendor Code'}
								overridePath={this.props.addNewSkusTabPath.concat(['VendorCode'])}
								showRequiredAsterisk
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'BeginSKU'}
								onEnterPressed={() => {}}
								label='Begin SKU'
								overridePath={this.props.addNewSkusTabPath.concat(['BeginSKU'])}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'EndSKU'}
								onEnterPressed={() => {}}
								label={'End SKU'}
								overridePath={this.props.addNewSkusTabPath.concat(['EndSKU'])}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'SortCode'}
								onEnterPressed={() => {}} 
								label={'Sort Code'}
								overridePath={this.props.addNewSkusTabPath.concat(['SortCode'])}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mt-5 mb-1 align-items-center'}>
						<Col>
							<div className={'d-flex justify-content-center'}>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'Search'} 
									text='Search'
									disabled={this.props.searchButtonDisabled}
									onClick={this.onSearchClicked.bind(this)}
									overridePath={this.props.addNewSkusTabPath.concat(['Search'])}
									width={'100px'}
									store={store}
								/>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}
}

AddClearanceSkusSearchProductsListPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	addNewSkusTabPath: PropTypes.array.isRequired,
	onSearchClicked: PropTypes.func,
	onBeforeLoad: PropTypes.func,
	searchButtonDisabled: PropTypes.bool,
	onLoad: PropTypes.func
};
export default AddClearanceSkusSearchProductsListPanel;
