import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../utilities/commonValidators';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import LabelContainer from '../../formelements/LabelContainer';
import TextBoxContainer from '../../formelements/TextBoxContainer';
import SuggestedPOsTabsContainer from './SuggestedPOsTabsContainer';
import MenuButton from '../../formelements/MenuButton';
import withID from '../../../higherordercomponents/WithID';
import {
	ButtonContainer,
	DropdownContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
const SUGGESTED_POS_PANEL_HEIGHT = '405px';
class SuggestedPOsPanel extends Component {
	constructor(props) {
		super(props);
		this.formatCurrency = this.formatCurrency.bind(this);
		this.queuePurchaseOrders = this.queuePurchaseOrders.bind(this);
		this.createPurchaseOrders = this.createPurchaseOrders.bind(this);
		this.previewInFlow = this.previewInFlow.bind(this);
		this.onOrderClicked = this.onOrderClicked.bind(this);
	}

	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	createPurchaseOrders() {
		if (this.props.createPurchaseOrders){
			this.props.createPurchaseOrders();
		}
	}
	queuePurchaseOrders() {
		if(this.props.queuePurchaseOrders){
			this.props.queuePurchaseOrders();
		}
	}
	previewInFlow(){
		if(this.props.previewInFlow){
			this.props.previewInFlow();  
		}
	}
	formatCurrency(params) {
		if (this.props.formatCurrency){
			return this.props.formatCurrency(params.value);
		}
		return params.value;
	}
	onOrderClicked(){
		if(this.props.onOrderClicked){
			this.props.onOrderClicked();
		}
	}

	render() {
		const poCount = this.props.totalPOsCount - this.props.deletedPOsCount;
		let poCountString = '';
		if(poCount > 0){
			poCountString = '(' + poCount + ')';
		}
		return (
			<Card style={{overflow:'hidden', minHeight:'150px'}} id={this.props.id} data-testid={this.props.id}>
				<Card.Header>
					<Row className={'p-0 m-0'} noGutters={true}>
						<Col xs={1} sm={1} md={1} lg={1} xl={1}>
							<h5 className={'text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'}>
									Suggested POs&nbsp;{poCountString}
							</h5>
							<div className={'pb-1 text text-muted'}>
									Total Cost: <span id={this.props.screenId+'_totalCost'}>{this.formatCurrency({value:this.props.totalCost})}</span>
							</div>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-5 pt-2'}>
							<DropdownContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'ddlPOFlag'}
								label={'Flag PO As'}
								labelStyle={{textAlign:'right'}}
								disabled={this.props.disablePanel}
								options={[{ value: 'CNY', name: 'CNY' }, { value: 'MGR', name: 'MGR' }]}
								hasBlankOption
								store={store} 
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-5 pt-2'}>
							<DropdownContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'ddlTerms'}
								label={'Terms'}
								labelStyle={{textAlign:'right'}}
								disabled={this.props.disablePanel}
								options={[{ value: 'WIRE', name: 'WIRE' }, { value: 'NET', name: 'NET' }]}
								showRequiredAsterisk
								validators={[validators.required]}
								hasBlankOption 
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-5 pt-2'}>
							<DropdownContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'ddlShipVia'}
								label={'Ship Via'}
								labelStyle={{textAlign:'right'}}
								disabled={this.props.disablePanel}
								options={[{ value: 'TRUCK', name: 'TRUCK' }, { value: 'OCEAN', name: 'OCEAN' }, { value: 'OTHER', name: 'OTHER' }]}
								showRequiredAsterisk
								validators={[validators.required]}
								hasBlankOption 
								store={store}
							/>
						</Col>
						<Col xs={1} sm={1} md={1} lg={1} xl={1} className={'pl-5'}>
									&nbsp;
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1 pt-2'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnCreatePOs'}
								text={'Create POs'}
								disabled={this.props.disablePanel || this.props.disableBtnCreatePOs}
								onClick={this.createPurchaseOrders.bind(this)}
								fill 
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1 pt-2'}>
							<MenuButton
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'MoreButton'}
								mainButtonText={'More'}
								buttons={[
									{key:'queue', text:'Queue POs', handler: this.queuePurchaseOrders.bind(this), disabled: this.props.disablePanel || this.props.disableBtnCreatePOs},
									{key:'preview', text:'Preview in Flow', handler: this.previewInFlow.bind(this), disabled: this.props.disablePanel || this.props.disableBtnPreviewInFlow},
									{key:'onorder', text: 'On Order', handler: this.onOrderClicked.bind(this)}
								]}
							/>
						</Col>
					</Row>
				</Card.Header>
				<div style={{height: SUGGESTED_POS_PANEL_HEIGHT, overflow:'hidden', backgroundColor:'gainsboro'}}>
					<SuggestedPOsTabsContainer 
						screenId={this.props.screenId}
						panelId={this.props.panelId}
						viewPort={this.props.viewPort}
						divisions={this.props.divisions}
						height={SUGGESTED_POS_PANEL_HEIGHT}
					/>
				</div>
				<Card.Footer>
					<Container fluid={true}>
						<Row noGutters={true}>
							<Col xs={12} sm={12} md={3} lg={3} xl={3} style={{ textAlign: 'left' }}>
								<LabelContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'recipients'}
									useStore
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'sendToFaxQueue'}
									label={'Send to BluVista Fax Queue'}
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'reqConfirmation'}
									label={'Request Email Confirmation'}
									width={'155px'}
									mainComponentBootstrapSize={1}
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'confirmationEmailAddress'}
									label={'Confirmation Email Address'}
									width={'200px'}
									showRequiredAsterisk
									validators={[validators.required, validators.isValidEmail]}
								/>
							</Col>
						</Row>
					</Container>
				</Card.Footer>
			</Card>
		);
	}
}

SuggestedPOsPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	disablePanel: PropTypes.bool,
	rowData: PropTypes.array,
	createPurchaseOrders: PropTypes.func,
	queuePurchaseOrders: PropTypes.func,
	disableBtnCreatePOs: PropTypes.bool,
	disableBtnPreviewInFlow: PropTypes.bool,
	formatCurrency: PropTypes.func,
	totalCost: PropTypes.number,
	onLoad: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	datePickerPopupLeft: PropTypes.number,
	windowSize: PropTypes.object,
	onOrderClicked: PropTypes.func,
	totalPOsCount: PropTypes.number,
	deletedPOsCount: PropTypes.number
};
export default withID(SuggestedPOsPanel);