import PropTypes from 'prop-types';
import React, { Component } from 'react';
import sizeMe from 'react-sizeme';

class ModalContent extends Component {
	handleContentClicked(e){
		e.stopPropagation();
	}
	render() {
		return (<div onClick={this.handleContentClicked} className={'h-100 w-100'}>{this.props.children}</div>);
	}
}
ModalContent.propTypes = {
	children: PropTypes.any
};

export default sizeMe({ monitorHeight: true })(ModalContent);
