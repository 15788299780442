export const getQueryString = (obj) => {
	if(!obj)
		return '';
	const returnValue = Object.keys(obj).length > 0 
		? Object.keys(obj).map(key => {
			return key + '=' + window.encodeURIComponent(obj[key]);
		}).join('&') 
		: '';
	return returnValue;
};
