import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../utilities/storeUtilities';
import EditPurchaseOrderInstructionsDialog from './EditPurchaseOrderInstructionsDialog';
const DIALOG_ID = 'EditPurchaseOrderInstructionsDialog';
class EditPurchaseOrderInstructionsDialogContainer extends Component {
	render() {
		return (
			<EditPurchaseOrderInstructionsDialog {...this.props}/>
		);
	}
}
const getPath = (parentPath, dialogId) => {
	return [...parentPath, dialogId];
};
const mapStateToProps = (state, props) => {
	const dialogId = props.dialogId || DIALOG_ID;
	return {
		path: getPath(props.parentPath, dialogId)
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const dialogId = props.dialogId || DIALOG_ID;
	return {
		onLoad: () => {
			const instructions = storeUtilities.getValue([...props.parentPath, 'instructions'], '');
			storeUtilities.initializeValue(dispatch, [...getPath(props.parentPath, dialogId), 'instructions'], instructions, false);
		},
		handleOkClicked: (callback) => {	
			const instructions = storeUtilities.getValue([...getPath(props.parentPath, dialogId), 'instructions'], undefined);
			if(instructions !== undefined){
				storeUtilities.updateValue(dispatch, [...props.parentPath, 'instructions'], instructions, false);
			}
			if(callback !== undefined){
				callback();
			}
		},
		handleCancelClicked: (callback) => {
			storeUtilities.deleteValue(dispatch, [...getPath(props.parentPath, dialogId), 'instructions']);
			if(callback !== undefined){
				callback();
			}
		}
	};
};

EditPurchaseOrderInstructionsDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	parentPath: PropTypes.array.isRequired,
	path: PropTypes.array.isRequired,
	handleCancelClicked: PropTypes.func,
	handleOkClicked: PropTypes.func,
	poNumber: PropTypes.number.isRequired,
	onDialogClosed: PropTypes.func,
	isQueuedPO: PropTypes.any,
	title: PropTypes.string,
	onLoad: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(EditPurchaseOrderInstructionsDialogContainer);

export default connectedComponent;