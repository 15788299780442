import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import updateDefaultValuesAction from '../../../../actions/updateDefaultValuesAction';
import * as sharedUtilities from '../../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import AddClearanceSkusSearchProductsListPanel from './AddClearanceSkusSearchProductsListPanel';
import { nodeRoutes } from '../../../../constants';
import rpcAction from '../../../../actions/rpcAction';
export const PANEL_ID = 'addClearanceSkusSearchProductsListPanel';
class AddClearanceSkusSearchProductsListPanelContainer extends Component {
	render() {
		return (
			<AddClearanceSkusSearchProductsListPanel {...this.props}
				panelId={this.props.panelId || PANEL_ID}
			/>);
	}
}

const mapStateToProps = (state, props) => {
	const validationErrors = storeUtilities.getValidationErrors([...props.addNewSkusTabPath, 'VendorCode']);
	const searchButtonDisabled = validationErrors !== undefined && validationErrors.errors !== undefined && Array.isArray(validationErrors.errors) && validationErrors.errors.length > 0;
	return {
		searchButtonDisabled
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {  
		onLoad: () => {
			dispatch(updateDefaultValuesAction(props.addNewSkusTabPath, props.defaultValues));
		}, 
		onSearchClicked: () => {
			const args = {
				BeginSKU: storeUtilities.getValue(props.addNewSkusTabPath.concat(['BeginSKU'])),
				EndSKU: storeUtilities.getValue(props.addNewSkusTabPath.concat(['EndSKU'])),
				SortCode: storeUtilities.getValue(props.addNewSkusTabPath.concat(['SortCode']), ''),
				VendorCode: storeUtilities.getValue(props.addNewSkusTabPath.concat(['VendorCode'])),
				Division: storeUtilities.getValue(props.addNewSkusTabPath.concat(['Region'])),
				Discontinued: 'EXCLUDE',
				Inactive: 'INCLUDE',
				ToBeDropped: 'EXCLUDE',
				Color: '',
				Description: '',
				Family: '',
				Finish: '',
				Factory: '',
				Style: ''
			};
			const prunedArgs = sharedUtilities.pruneObject(args);
			dispatch(rpcAction({
				args: prunedArgs, 
				nodeRoute: nodeRoutes.IFRServiceNET, 
				endpoint: '/Products/Skus', 
				method: 'GET',
				callback: (rowData) => {
					if(Array.isArray(rowData)){
						storeUtilities.updateValue(dispatch, props.addNewSkusTabPath.concat(['ProductSearchResultsGrid', 'rowData']), rowData, true, false);
						const sizeColumnsToFit = storeUtilities.getValue(props.addNewSkusTabPath.concat(['sizeProductSearchResultsGridColumnsToFit']), undefined);
						if(sizeColumnsToFit !== undefined){
							sizeColumnsToFit();
						}
					}
				}
			}));
		}
	};
};

AddClearanceSkusSearchProductsListPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	addNewSkusTabPath: PropTypes.array.isRequired,
	onSearchClicked: PropTypes.func,
	onLoad: PropTypes.func,
	searchButtonDisabled: PropTypes.bool
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AddClearanceSkusSearchProductsListPanelContainer);
connectedComponent.defaultProps = {
	defaultValues: {
		Region:'FL',
		SortCode: 'CFR'
	}
};
export default connectedComponent;
