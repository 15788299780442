import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import MaintenanceManagerScreen from './MaintenanceManagerScreen';
import { nodeRoutes, maintenanceModeStatusType } from '../../../constants';
import rpcAction from '../../../actions/rpcAction';

export const SCREEN_ID = 'maintenancemanagerscreen';
export const PANEL_ID = 'maintenancepanel';

class MaintenanceManagerScreenContainer extends Component {
	render() {
		return (
			<MaintenanceManagerScreen {...this.props}
				screenId={this.props.screenId || SCREEN_ID}
				panelId={this.props.panelId || PANEL_ID}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const chkAllSelected = storeUtilities.getValue([screenId, 'chkAll'], false);
	const flInMaint = storeUtilities.getDefaultValue([screenId, 'chkFL'], false);
	const seInMaint = storeUtilities.getDefaultValue([screenId, 'chkSE'], false);
	const txInMaint = storeUtilities.getDefaultValue([screenId, 'chkTX'], false);
	const prInMaint = storeUtilities.getDefaultValue([screenId, 'chkPR'], false);
	const user = storeUtilities.getUser();
	return {
		chkAllSelected,
		flInMaint,
		seInMaint,
		txInMaint,
		prInMaint,
		user
	};
};
const getData = (dispatch, screenId) => {
	storeUtilities.deleteValue(dispatch, [screenId, 'maintNotes']);
	storeUtilities.deleteValue(dispatch, [screenId, 'chkAll']);
	storeUtilities.deleteValue(dispatch, [screenId, 'chkFL']);
	storeUtilities.deleteValue(dispatch, [screenId, 'chkSE']);
	storeUtilities.deleteValue(dispatch, [screenId, 'chkTX']);
	storeUtilities.deleteValue(dispatch, [screenId, 'chkPR']);
	const user = storeUtilities.getUser();
	sharedUtilities.checkMaintenance(user, (inMaintenanceMode, maintNotes, divisions)=>{
		storeUtilities.updateValue(dispatch, [screenId, 'maintNotes'], maintNotes, true);
		const abbrArray = divisions;
		let flSelected = false;
		let seSelected = false;
		let txSelected = false;
		let prSelected = false;
		abbrArray.forEach((divisionAbbreviation) => {
			if(divisionAbbreviation === 'FL'){
				flSelected = true;
				storeUtilities.updateValue(dispatch, [screenId, 'chkFL'], true, true);
			}
			if(divisionAbbreviation === 'SE'){
				seSelected = true;
				storeUtilities.updateValue(dispatch, [screenId, 'chkSE'], true, true);
			}
			if(divisionAbbreviation === 'TX'){
				txSelected = true;
				storeUtilities.updateValue(dispatch, [screenId, 'chkTX'], true, true);
			}
			if(divisionAbbreviation === 'PR'){
				prSelected = true;
				storeUtilities.updateValue(dispatch, [screenId, 'chkPR'], true, true);
			}
		});
		storeUtilities.updateValue(dispatch, [screenId, 'inMaintenanceMode'], inMaintenanceMode, true);
		storeUtilities.updateValue(dispatch, [screenId, 'chkAll'], (flSelected === true && seSelected === true && txSelected === true && prSelected === true), true);
	});
};
const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	return {
		onLoad: () => {
			dispatch(updateDefaultValuesAction([screenId], props.defaultValues));
			getData(dispatch, screenId);
		},
		handleChkAllChanged: (value) => {
			if(value === true){
				//save original values
				const chkFLSelectedOriginalValue = storeUtilities.getValue([screenId, 'chkFL'], false);
				const chkSESelectedOriginalValue = storeUtilities.getValue([screenId, 'chkSE'], false);
				const chkTXSelectedOriginalValue = storeUtilities.getValue([screenId, 'chkTX'], false);
				const chkPRSelectedOriginalValue = storeUtilities.getValue([screenId, 'chkPR'], false);
                
				storeUtilities.updateValue(dispatch, [screenId, 'chkFL_ORIGINAL_VALUE'], chkFLSelectedOriginalValue, true, false);
				storeUtilities.updateValue(dispatch, [screenId, 'chkSE_ORIGINAL_VALUE'], chkSESelectedOriginalValue, true, false);
				storeUtilities.updateValue(dispatch, [screenId, 'chkTX_ORIGINAL_VALUE'], chkTXSelectedOriginalValue, true, false);
				storeUtilities.updateValue(dispatch, [screenId, 'chkPR_ORIGINAL_VALUE'], chkPRSelectedOriginalValue, true, false);
                
				//set all to true
				storeUtilities.updateValue(dispatch, [screenId, 'chkFL'], true);
				storeUtilities.updateValue(dispatch, [screenId, 'chkSE'], true);
				storeUtilities.updateValue(dispatch, [screenId, 'chkTX'], true);
				storeUtilities.updateValue(dispatch, [screenId, 'chkPR'], true);
			}
			else{
				//restore original values
				const chkFLSelectedOriginalValue = storeUtilities.getValue([screenId, 'chkFL_ORIGINAL_VALUE'], false);
				const chkSESelectedOriginalValue = storeUtilities.getValue([screenId, 'chkSE_ORIGINAL_VALUE'], false);
				const chkTXSelectedOriginalValue = storeUtilities.getValue([screenId, 'chkTX_ORIGINAL_VALUE'], false);
				const chkPRSelectedOriginalValue = storeUtilities.getValue([screenId, 'chkPR_ORIGINAL_VALUE'], false);
                
				storeUtilities.updateValue(dispatch, [screenId, 'chkFL'], chkFLSelectedOriginalValue);
				storeUtilities.updateValue(dispatch, [screenId, 'chkSE'], chkSESelectedOriginalValue);
				storeUtilities.updateValue(dispatch, [screenId, 'chkTX'], chkTXSelectedOriginalValue);
				storeUtilities.updateValue(dispatch, [screenId, 'chkPR'], chkPRSelectedOriginalValue);
			}
		},
		handleActivateMaintenanceMode: () => {
			const chkFLValue = storeUtilities.getValue([screenId, 'chkFL'], false);
			const chkSEValue = storeUtilities.getValue([screenId, 'chkSE'], false);
			const chkTXValue = storeUtilities.getValue([screenId, 'chkTX'], false);
			const chkPRValue = storeUtilities.getValue([screenId, 'chkPR'], false);
			const divisions = [];
			[[chkFLValue, 'FL'], [chkSEValue, 'SE'], [chkTXValue, 'TX'], [chkPRValue, 'PR']].forEach((arr)=>{
				if(arr[0] === true){
					divisions.push(arr[1]);
				}
			});
			const inMaintenanceMode = storeUtilities.getValue([screenId, 'inMaintenanceMode']);
			const args = {
				applicationCode: inMaintenanceMode ? '' : 'IFR',
				notes: inMaintenanceMode ?  '' : storeUtilities.getValue([screenId, 'maintNotes'], ''),
				maintenanceMode: inMaintenanceMode ? maintenanceModeStatusType.DEACTIVATED : maintenanceModeStatusType.ACTIVATED,
				divisions: inMaintenanceMode ? [] : divisions
			};			
			dispatch(rpcAction({
				args: args,
				nodeRoute: nodeRoutes.IFRServiceNET,
				endpoint: '/Management/Maintenance',
				callback: () => {
					getData(dispatch, screenId);
				},
				method: 'PUT'
			}))

		}
	};
};

MaintenanceManagerScreenContainer.propTypes = {
	screenId: PropTypes.string,
	panelId: PropTypes.string,
	chkAllSelected: PropTypes.bool,
	handleChkAllChanged: PropTypes.func,
	handleActivateMaintenanceMode: PropTypes.func,
	user: PropTypes.object.isRequired,
	onLoad: PropTypes.func,
	flInMaint: PropTypes.bool,
	seInMaint: PropTypes.bool,
	txInMaint: PropTypes.bool,
	prInMaint: PropTypes.bool
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MaintenanceManagerScreenContainer);

connectedComponent.defaultProps = {
	defaultValues: {
		chkAll: false,
		chkFL: false,
		chkSE: false,
		chkTX: false,
		chkPR: false
	}
};

export default connectedComponent;