import { goBack } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import rpcAction from '../../../actions/rpcAction.jsx';
import closeDialogAction from '../../../actions/closeDialogAction.jsx';
import showDialogAction from '../../../actions/showDialogAction.jsx';
import updateGlobalAppStateValueAction from '../../../actions/updateGlobalAppStateValueAction.jsx';
import * as constants from '../../../constants.jsx';
import * as numberUtilities from '../../../utilities/numberUtilities.jsx';
import * as sharedUtilities from '../../../utilities/sharedUtilities.jsx';
import * as storeUtilities from '../../../utilities/storeUtilities.jsx';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer.jsx';
import * as orderUtilities from './OrderProductScreenUtilities.jsx';
import SuggestedPOsPanel from './SuggestedPOsPanel.jsx';
const CREATE = 'createPOsJsonBody';
const QUEUE = 'queuePOsJsonBody';

class SuggestedPOsPanelContainer extends Component {
	constructor(props){
		super(props);
		this.state = {windowSize: undefined};
	}
	render() {
		return (
			<Media queries={{
				xs: '(min-width: 0px)',
				sm: '(min-width: 576px)',
				md: '(min-width: 768px)',
				lg: '(min-width: 992px)',
				xl: '(min-width: 1200px)'
			}}>
				{ matches => {
					let viewPort = 'xl';
					if(matches.xs === true){
						viewPort = 'xs';
					}
					if(matches.sm === true){
						viewPort = 'sm';
					}
					if(matches.md === true){
						viewPort = 'md';
					}
					if(matches.lg === true){
						viewPort = 'lg';
					}
					if(matches.xl === true){
						viewPort = 'xl';
					}
					return (
						<SuggestedPOsPanel {...this.props}
							disablePanel={this.props.disableSuggestedPOsPanel}
							viewPort={viewPort}
							windowSize={this.state.windowSize}//only meant to trigger a new render
						/>
					);
				}}
			</Media>
		);
	}
}

const mapStateToProps = (state, props) => {
	const disableSuggestedPOsPanel = storeUtilities.getValue([props.screenId, 'disableSuggestedPOsPanel'], true);
	const user = storeUtilities.getUser();
	const isReadOnlyUser = !user.hasAdminAccess() && !user.hasBuyerAccess() && user.hasReadOnlyAccess();

	const reqConfirmation = storeUtilities.getValue([props.screenId, 'reqConfirmation'], false);
	const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
	const failsValidation = 
	'ddlShipVia' in validationErrors
      || 'ddlTerms' in validationErrors
      || 'ddlPOFlag' in validationErrors
      || ('confirmationEmailAddress' in validationErrors && reqConfirmation === true);
	const qtyCellsValidationErrorExists = storeUtilities.getValue([props.screenId, 'QTYCELLSVALIDATIONERROR'], false);
	const deletedPOsCount = orderUtilities.getDeletedPOsCount(props.screenId);
	const totalPOsCount = orderUtilities.getTotalPOsCount(props.screenId);
	const disable = totalPOsCount <= 0 || failsValidation === true || qtyCellsValidationErrorExists === true || isReadOnlyUser;
	return {
		disableSuggestedPOsPanel: disableSuggestedPOsPanel,
		disableBtnCreatePOs: disable,
		disableConfirmationEmail: reqConfirmation === false,
		totalCost: storeUtilities.getValue([props.screenId, props.panelId, 'totalCost'], 0),
		totalPOsCount,
		deletedPOsCount
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			const user = storeUtilities.getUser();
			const userEmail = user !== undefined ? user.email : '';
			storeUtilities.updateValue(dispatch, [props.screenId, 'confirmationEmailAddress'], userEmail, true, false);
			const recipients=sharedUtilities.getRecipients(props.vendorEmailInfos);
			storeUtilities.updateValue(dispatch, [props.screenId, 'recipients'], recipients);
			orderUtilities.setFlowURLFromAppConfig(dispatch, [props.screenId, 'flowURL']);
			orderUtilities.setFlowAllURLFromAppConfig(dispatch, [props.screenId, 'flowAllURL']);
		},
		createPurchaseOrders: () => {
			queueOrCreatePOs(dispatch, props.screenId, props.panelId, props.vendor, props.vendorEmailInfos, 'Are you sure you want to create these POs?', CREATE);
		},
		queuePurchaseOrders: () => {
			queueOrCreatePOs(dispatch, props.screenId, props.panelId, props.vendor, props.vendorEmailInfos, 'Are you sure you want to add these POs to the Purchase Order Queue?', QUEUE);
		},
		previewInFlow: () => {
			const whsIsSavannah = storeUtilities.getValue([props.screenId, 'whsIsSavannah']);
	
			if(whsIsSavannah === true){
				dispatch(showDialogAction(
					<OkCancelDialogContainer
						screenId={props.screenId + '_previewInFlowForSavannahComingSoon'}
						title={'INFO'}
						message={'Functionality Coming Soon!'}
						handleOkClicked={()=>{}}>
						PreviewInFlow has not been implemented for Savannah yet.
					</OkCancelDialogContainer>,
					200,
					325,
					() => { }));
				return;
			}
			const poArray = getPOArray(dispatch, props.screenId);
			if(poArray === undefined){
				return;
			}
			const previewInFlowArray = [];
			for(let i = 0; i < poArray.length; i++){
				for(let j = 0; j < poArray[i].skus.length; j++){
					const o = {
						poNum: (i + 1).toString(),
						sku: poArray[i].skus[j].sku,
						whs: poArray[i].deliverTo === undefined ? '' : poArray[i].deliverTo.split('_')[1],
						div: props.divisions[0],
						buyerInitials: storeUtilities.getValue([props.screenId, 'txtBuyerInitials']),
						qty: poArray[i].skus[j].quantity,
						needByDate: moment(poArray[i].skus[j].NeedByDate).format('YYYY-MM-DD'),
						shipDate: moment(poArray[i].shipDate).format('YYYY-MM-DD'),
						arrivalDate: moment(poArray[i].arrivalDate).format('YYYY-MM-DD')
					};
					previewInFlowArray.push(o);
				}
			}
			dispatch(rpcAction({
				args: previewInFlowArray,
				nodeRoute: constants.nodeRoutes.IFRServiceNET,
				endpoint: 'PreviewFlow/SetFlowData',
				method: 'PUT',
				showLoadingMask: true,
				callback: (data)=>{
					if(data && data.scenarioID && data.scenarioID.length > 0){
						const flowURL = storeUtilities.getValue([props.screenId, 'flowURL']);
						window.open(flowURL + data.scenarioID, '_blank');
					}
				}
			}));
		},
		formatCurrency:(value) => {
			return sharedUtilities.formatCurrency(value);
		},
		onOrderClicked: () => {
			orderUtilities.showOnOrderDialog(dispatch, props.screenId, props.vendor);
		}
	};
};
const queueOrCreatePOs = (dispatch, screenId, panelId, vendor, vendorEmailInfos, mainMessage, endpoint) => {
	const { useWarningMessage, headerMessage, warningMessages } = getWarningMessages(screenId, panelId);
	showPOsConfirmationDialog(dispatch, screenId, mainMessage, useWarningMessage ? warningMessages : [], headerMessage, () => { 
		callQueueOrCreatePOs(dispatch, screenId, vendor, vendorEmailInfos, endpoint); 
	});
};
const showPOsConfirmationDialog = (dispatch, screenId, mainMessage, warningMessages, heading, callback) => {
	const warningMessage = warningMessages.map ? <ul>{warningMessages.map((message, idx) => {
		return <li key={idx}><div>{message}</div></li>;
	})}</ul> : warningMessages;
	dispatch(showDialogAction(
		<OkCancelDialogContainer
			screenId={screenId} 
			title={heading}
			message={mainMessage}
			handleOkClicked={()=>{
				dispatch(closeDialogAction());
				callback();
			}}
			hasCancelButton>
			<h5>{warningMessage}</h5>
		</OkCancelDialogContainer>,
		300, 
		500, 
		()=>{},
		true,
		undefined,
		true,
		false,
		true));
};
const getWarningMessages = (screenId, panelId) => {
	const warningsToRender = [];
	const discontinuedSkus = [];
	const inactiveSkus = [];
	const toBeDropped = [];

	const selectedRows = storeUtilities.getValue([screenId, 'selectedRows'], []);
	const usingCubes = orderUtilities.useCubes(screenId);
	const warehousePOs = storeUtilities.getValue([screenId, panelId, 'purchaseOrders'], {});
	const maxValue = usingCubes 
		? numberUtilities.getNumberOrDefault(storeUtilities.getValue([screenId, 'poCube'], 0), 0, (x) => { return x >= 0; })
		: numberUtilities.getNumberOrDefault(storeUtilities.getValue([screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
	const checkPOFunction = usingCubes ? isOverMaxPOCubes : isOverMaxPOQty;
	const checkSKUsFunction = (po) => {
		po.rowData.forEach(row => {
			const matches = selectedRows.filter(x => x.sku === row.SKU);
			if(matches.length === 1){
				const selectedRow = matches[0];
				if(selectedRow.discontinuedSku === 'Y' && discontinuedSkus.indexOf(selectedRow.sku) === -1){
					discontinuedSkus.push(selectedRow.sku);
				}
				if(selectedRow.inactiveSku === 'Y' && inactiveSkus.indexOf(selectedRow.sku) === -1){
					inactiveSkus.push(selectedRow.sku);
				}
				if(selectedRow.toBeDropped === 'Y' && toBeDropped.indexOf(selectedRow.sku) === -1){
					toBeDropped.push(selectedRow.sku);
				}
			}
		});
	};
	Object.entries(warehousePOs).forEach(([, purchaseOrders]) => {
		Object.entries(purchaseOrders).forEach(([key, po]) => {
			if(!isNaN(key) && po !== undefined){
				checkSKUsFunction(po);
				const warning = checkPOFunction(po, maxValue);
				if(warning !== undefined && warningsToRender.indexOf(warning) === -1){
					warningsToRender.push(warning);
				}
			}
		});
	});
	if(discontinuedSkus.length > 0){
		const discontinuedSkusWarning = 'The following SKUs are DISCONTINUED: ' + discontinuedSkus.join(', ') + '.';
		if(warningsToRender.indexOf(discontinuedSkusWarning) === -1){
			warningsToRender.push(discontinuedSkusWarning);
		}
	}
	if(inactiveSkus.length > 0){
		const inactiveSkusWarning = 'The following SKUs are INACTIVE: ' + inactiveSkus.join(', ') + '.';
		if(warningsToRender.indexOf(inactiveSkusWarning) === -1){
			warningsToRender.push(inactiveSkusWarning);
		}
	}
	if(toBeDropped.length > 0){
		const toBeDroppedWarning = 'The following SKUs are set TO BE DROPPED: ' + toBeDropped.join(', ') + '.';
		if(warningsToRender.indexOf(toBeDroppedWarning) === -1){
			warningsToRender.push(toBeDroppedWarning);
		}
	}
	return {
		useWarningMessage: warningsToRender.length > 0,
		headerMessage: warningsToRender.length > 0 ? 'WARNING!' : 'Confirmation',
		warningMessages: warningsToRender
	};
};
const isOverMaxPOCubes = (po, maxValue) => {
	if(po.cubes !== undefined){
		const cubes = numberUtilities.getNumberOrDefault(po.cubes, 0);
		return cubes > maxValue ? 'One or more Purchase Orders exceed the PO Cube value set on the Merchandise panel.' : undefined;
	}
};
const isOverMaxPOQty = (po, maxValue) => {
	if(po.rowData !== undefined){
		let qtySum = 0;
		Object.entries(po.rowData).forEach(([rowKey, row]) => {
			if(!isNaN(rowKey) && row.QTY !== undefined){
				qtySum += numberUtilities.getNumberOrDefault(row.QTY, 0);
			}
		});
		return qtySum > maxValue ? 'One or more Purchase Orders exceed the Max PO Quantity value set on the Merchandise panel.' : undefined;
	}
};
/**
 * constuct PO array to pass to other to other systems  
 * @param {object} dispatch - redux dispatch object
 * @param {string} screenId - Screen ID where this component will appear
 */
const getPOArray = (dispatch, screenId) =>{
	const whsList = [storeUtilities.getValue([screenId, 'whsNum'], undefined)];
	const poCountsPerWhs = orderUtilities.getPOCountsPerWhs(screenId, whsList);
	const poArray = [];
	for(let whsIdx = 0; whsIdx < whsList.length; whsIdx++){
		let requestedPONumber = 1;
		const whs = whsList[whsIdx];
		const poCountEntries = Object.entries(poCountsPerWhs).filter(x => x[0] === whs);
		const poCountEntry = poCountEntries.length === 1 ? poCountEntries[0] : undefined;
		const poCount = poCountEntry !== undefined && poCountEntry[1] !== undefined ? poCountEntry[1] : 0; 
		for(let poNumInWhs = 1; poNumInWhs <= poCount; poNumInWhs++){
			const deleted = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNumInWhs, 'deleted'], true);
			if(deleted){  
				continue;  
			}
			const rowData = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNumInWhs, 'rowData'], []);
			if(!Array.isArray(rowData) || rowData.length === 0){
				continue;
			}
			let skusArray = [];
			let rowIdx = 0;
			for(rowIdx = 0; rowIdx < rowData.length; rowIdx++){
				const qty = parseInt(rowData[rowIdx].QTY);
				if(isNaN(qty) || qty <= 0){
					continue;
				}
				const m = moment(rowData[rowIdx].NEEDBYDATE, constants.cfQueryDateFormat);
				const needByDate = m.format('L');
				if(needByDate && needByDate.toLowerCase && needByDate.toLowerCase() === 'invalid date'){
					sharedUtilities.showError('Invalid date', 'Needed By Date, ' + rowData[rowIdx].NEEDBYDATE + ', is not in the expected format. Expected Format: ' + constants.cfQueryDateFormat);
					return undefined;
				}
				const skuObj = {
					sku: rowData[rowIdx].SKU,
					quantity: qty,
					description: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.VendorDescription : '',
					altQty: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.CalcOrder : 0,
					orderQty: rowData[rowIdx].QTY,
					cube: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Cube : 0,
					AverageDailySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyMean : 0,
					WeeklySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeklySales : 0,
					TotalSales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.TotalSales : 0,
					qoh: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVQOH : 0,
					inbound: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
					available: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.LTAVAIL : 0,
					WeeksOfStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeksOfStock : 0,
					StandardDeviation: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyStdDev : 0,
					SafetyStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.SafetyStock : 0,
					ReplenishmentStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.ReplenishmentStock : 0,
					OrderPoint: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Trigger : 0,
					WeeksOfStockOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WOSOH : 0,
					AvailableToday: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
					QtyToWHS: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyToWHS : 0,
					QtyFromWHS: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyFromWHS : 0,
					StoreQuantityOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.STOREQTY : 0,
					PieceCount: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PieceCount : 0,
					AvailableAfterOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.AvailableAfterOrder : 0,
					OrderRatio: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Ratio : 0,
					Intradivisional: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.IntraDivisional : 0,
					DaysToNextOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DaysToNextOrder : 0,
					PromoAvail: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PromoAvail : 0,
					NeedByDate: needByDate,
					FirstCost: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.FIRSTCOST : 0,
					UserDaysToNextOrder: 0,
					Alias: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.ALIAS : '',
					Color: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Color : '',
					Factory: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.FACTORY : ''
				};
				skusArray.push(skuObj);
			}
			const shipDate = moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNumInWhs, 'shipDate'], new Date())).format('L');
			const arrivalDate = moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNumInWhs, 'arrivalDate'], new Date())).format('L');
			const deliverTo = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNumInWhs, 'deliverTo']);
			const skusObj = {
				skus: skusArray,
				instructions: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNumInWhs, 'instructions'], ''), 
				shipDate: shipDate,
				arrivalDate: arrivalDate,
				shipVia: storeUtilities.getValue([screenId, 'ddlShipVia']),
				terms: storeUtilities.getValue([screenId, 'ddlTerms']),
				fob: rowData[0].ORDERLINE.FOB ? rowData[0].ORDERLINE.FOB : '0',
				calcArrivalDate: arrivalDate,
				poFlag: storeUtilities.getValue([screenId, 'ddlPOFlag'], false),
				deliverTo,
				requestedPONumber,
				overAllocation: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNumInWhs, 'overAllocatedWarning'], false)
			};
			poArray.push(skusObj);
			requestedPONumber++;
		}
	}
	return poArray;
};

const getPOArgs = (screenId, vendor, vendorEmailInfos, poArray) => {
	const user = storeUtilities.getUser();
	const args = {};
	const whsNum = storeUtilities.getValue([screenId, 'whsNum'], undefined);
	args.rtgID = user.rtgID;
	args.vendor = vendor;
	args.emailAddress = storeUtilities.getValue([screenId, 'confirmationEmailAddress']);
	args.emailRecipients = sharedUtilities.getRecipients(vendorEmailInfos);//TODO: add email that used to come from 'ApplicationManager.sharedObject.data.poEmailRecipients'
	args.buyerInitials = storeUtilities.getValue([screenId, 'txtBuyerInitials']);
	args.marketAdj = storeUtilities.getValue([screenId, 'marketAdjustment'], 0);
	args.grouping = storeUtilities.getValue([screenId, 'merchGroup']);
	args.leadTime = storeUtilities.getValue([screenId, 'txtTotalDays'], 0);
	args.numSalesDays = storeUtilities.getValue([screenId, 'salesDays'], 0);
	args.maxCube = storeUtilities.getValue([screenId, 'maximizeCube'], false);
	args.poCube = storeUtilities.getValue([screenId, 'poCube'], 0);
	args.daysToStock = storeUtilities.getValue([screenId, 'daysToStock'], 0);
	args.prodLeadTime = storeUtilities.getValue([screenId, 'prodTime'], 0);
	args.transLeadTime = storeUtilities.getValue([screenId, 'transitTime'], 0);
	args.sendToFaxQueue = storeUtilities.getValue([screenId, 'sendToFaxQueue'], false);
	args.reqConfirmation = storeUtilities.getValue([screenId, 'reqConfirmation'], false);
	args.combineWhsList = storeUtilities.getValue([screenId, 'combineWhsList'], [whsNum]);
	args.beginSalesDate = moment(storeUtilities.getValue([screenId, 'beginSalesDate'], moment())).format('L');
	args.endSalesDate = moment(storeUtilities.getValue([screenId, 'endSalesDate'], moment())).format('L');
	args.showPromoAvail = storeUtilities.getValue([screenId, 'showPromoAvail'], false);
	args.userDTNO = storeUtilities.getValue([screenId, 'daysToNextOrder'], 0);
	args.orderDate = moment(storeUtilities.getValue([screenId, 'orderDate'], moment())).format('L');
	args.purchaseOrders = JSON.stringify(poArray);
	args.dateQueuedUTC = moment.utc().format('YYYY-MM-DD HH:mm:ss');//2021-02-11 17:02:00.000

	return args;
};
const callQueueOrCreatePOs = (dispatch, screenId, vendor, vendorEmailInfos, endpoint) =>{
	const poArray = getPOArray(dispatch, screenId);
	if(poArray === undefined){
		return;
	}
	const args = getPOArgs(screenId, vendor, vendorEmailInfos, poArray);
	dispatch(rpcAction({
		args,
		nodeRoute: constants.nodeRoutes.IFRService,
		endpoint,
		method: 'POST',
		hideLoadingMaskOnComplete: false,
		callback: (data) => {
			const identifier = endpoint === CREATE ? 'PO#' : 'PO Queue#';
			const e = orderUtilities.getCreatePOResponseDialogElements(data, identifier, endpoint === CREATE ? 'Created' : 'Sent To PO Queue');
			let hasErrors = e.unsuccessfulJSXElements.length > 0;
			if(Array.isArray(data) && data.length > 0){
				const posNotCreated = [];
				poArray.forEach(po => {
					const createdPO = data.filter(row => parseInt(row['REQUESTEDPONUMBER']) === po.requestedPONumber);
					if(createdPO.length === 0){
						hasErrors = true;
						posNotCreated.push(identifier + ' ' + po.requestedPONumber);
					}
				});
				if(e.successfulJSXElements.length > 0){
					e.successfulJSXElements.push(<br key={e.successfulJSXElements.length + 1} />);
				}
				if(e.unsuccessfulJSXElements.length > 0){
					e.unsuccessfulJSXElements.push(<br key={e.unsuccessfulJSXElements.length + 1} />);
				}
				window.setTimeout(() => {
					dispatch(updateGlobalAppStateValueAction(['showLoadingMask'], false));
					dispatch(showDialogAction(
						<OkCancelDialogContainer 
							id={'purchaseOrdersCreatedDialog'}
							screenId={ screenId + '_informationDialog'} 
							title={'Information'}
							handleOkClicked={()=>{ 
								dispatch(goBack());
								storeUtilities.deleteValue(dispatch, [screenId]);
							}}
							hasCancelButton={hasErrors}
							okButtonText={'Return To Search'}
							cancelButtonText={'Close Dialog and Stay'}>
							<div>
								{e.successfulJSXElements}
								{e.unsuccessfulJSXElements}
								{posNotCreated.length > 0 ? ('The following Suggested PO\'s were not created successfully: ' + posNotCreated.join(', ')): ''}
							</div>
						</OkCancelDialogContainer>, 
						350, 
						550, 
						()=>{}));
				}, 500);
			}
			else {
				if(data !== undefined){
					sharedUtilities.showError(data.error ? data.error : 'An error has occured', data.detail ? data.detail : '', 'Error Creating Purchase Orders');
				}
			}
		},
		retryOnFailure: endpoint === QUEUE
	}));
};

SuggestedPOsPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	vendor: PropTypes.string.isRequired,
	disableSuggestedPOsPanel: PropTypes.bool,
	createPurchaseOrders: PropTypes.func,
	disableBtnCreatePOs: PropTypes.bool,
	vendorEmailInfos: PropTypes.arrayOf(PropTypes.object),
	vendorEmail: PropTypes.string,
	previewInFlow: PropTypes.func,
	formatCurrencty: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	userEmail: PropTypes.string,
	onLoad: PropTypes.func,
	totalCost: PropTypes.number,
	whsList: PropTypes.array,
	totalPOsCount: PropTypes.number,
	deletedPOsCount: PropTypes.number,
	onOrderClicked: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedPOsPanelContainer);

export default connectedComponent;