import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SuggestedPOGridPanel from '../orderproductsscreen/SuggestedPOGridPanel';
import {
	ButtonContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
class PurchaseOrderQueuePOGridPanel extends Component {
	constructor(props){
		super(props);
		this.onCancelClicked = this.onCancelClicked.bind(this);
		this.onSaveClicked = this.onSaveClicked.bind(this);
		this.gridRef = React.createRef();
	}
	onCancelClicked(){
		if(this.props.onCancelClicked){
			this.props.onCancelClicked();
		}
	}
	onSaveClicked(){
		if(this.props.onSaveClicked && this.gridRef && this.gridRef.current){
			this.props.onSaveClicked(this.gridRef.current);
		}
	}
	render() {
		return (
			<Card>
				<SuggestedPOGridPanel {...this.props}
					panelId={this.props.panelId}
					fieldId={'po_' + this.props.poNumber}
					cubesOverLimit={false}
					isQtyCellInvalid={() => false}
					gridRef={this.gridRef}
					hideDeletePOButton
					isQueuedPO

				/>
				<Card.Footer>
					<Row>
						<Col className={'mr-1'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'cancelButton'}
								text={'Cancel'}
								buttonClasses={'btn btn-light'}
								onClick={this.onCancelClicked}
								fill
								store={store}
							/>
						</Col>
						<Col>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'saveButton'}
								text={'Save'}
								onClick={this.onSaveClicked}
								fill
								store={store}
							/>
						</Col>
					</Row>
				</Card.Footer>
			</Card>);
	}
}

PurchaseOrderQueuePOGridPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	whsNum: PropTypes.string,
	poNumber: PropTypes.number,
	rowData: PropTypes.array,
	cubes: PropTypes.number,
	poCost: PropTypes.number,
	getColumnValue: PropTypes.func,
	formatColumnValue: PropTypes.func,
	instructions: PropTypes.string,
	editInstructionsClicked: PropTypes.func,
	fob: PropTypes.string,
	deliverToOptions: PropTypes.array,
	path: PropTypes.array,
	formatCurrency: PropTypes.func,
	onBeforeLoad: PropTypes.func,
	onCancelClicked: PropTypes.func,
	onQtyChange: PropTypes.func,
	title: PropTypes.string
};

export default PurchaseOrderQueuePOGridPanel;
