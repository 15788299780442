export default class VendorClearanceOrderLine {
	constructor(){		
		this.AAO = '';		
		this.AVAILABLE = '';
		this.CUBE = 0;
		this.DAYSTOSTOCK = 0;
		this.DESCRIPTION = '';
		this.OPENORDERS = 0;
		this.ORD = 0;
		this.QOH = 0;
		this.QTYONDISPLAY = 0;
		this.QTYONORDER = 0;
		this.SKU = 0;
		this.T30SALES = 0;
		this.TARGETQTY = 0;
		this.VENDOR = '';
		this.WHS = '';
	}
}