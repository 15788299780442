import download from 'downloadjs';
import { Base64 } from 'js-base64';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import rpcAction from '../../../actions/rpcAction';
import * as constants from '../../../constants';
import * as gridUtilities from '../../../utilities/gridUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import { nodeRoutes } from '../../../constants';
import SearchDailyOrdersResultsPanel from './SearchDailyOrdersResultsPanel';
export const PANEL_ID = 'searchDailyOrdersResultsPanel';
class SearchDailyOrdersResultsPanelContainer extends Component {
	render() {
		return (
			<SearchDailyOrdersResultsPanel 
				screenId={this.props.screenId}
				panelId={PANEL_ID}
				rowData={this.props.rowData}
				searchDailyOrdersResultsGridRef={this.props.searchDailyOrdersResultsGridRef}
				onSearchDailyOrdersResultsGridReady={this.props.onSearchDailyOrdersResultsGridReady}
				getExcelDataSet={this.props.getExcelDataSet}
				onPdfCellClicked={this.props.onPdfCellClicked}
				getColumnValue={this.props.getColumnValue}
				getFormattedColumnValue={this.props.getFormattedColumnValue}
				handleExportToExcelClicked={this.props.handleExportToExcelClicked}
			/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, 'SearchDailyOrdersResultsGrid', 'rowData']);
	return {
		rowData: rowData === undefined ? [] : rowData
	};
};

const mapDispatchToProps = (dispatch, props) => {

	return {
		getExcelDataSet: (gridRef) => { 
			const rowData = storeUtilities.getValue([props.screenId, 'SearchDailyOrdersResultsGrid', 'rowData']);
			const excludeColumns = gridUtilities.getGridHiddenColumns(gridRef, ['HASPDF']);
			const results = gridUtilities.excludeColumnsForExcelExportDataSet(gridRef, rowData, excludeColumns);
			const obj = sharedUtilities.convertToJsonArray(results, undefined, 'COLUMNS', 'DATA');
			return obj;
		},
		onPdfCellClicked: (params) => {
			const args = {poNum:params.data.PONUM};
			dispatch(callRpcAction(args, constants.nodeRoutes.IFRService, 'DowloadPurchaseOrderPDF', (data) => {
				if(data !== ''){
					const bytes = Base64.atob(data);
					download(bytes, params.data.PONUM + '.pdf', 'application/pdf');
				}
			}));
		},
		getColumnValue: (params) => {
			const value = ((params.colDef.colId in params.data) ? params.data[params.colDef.colId] : undefined);
			let returnValue;
			switch(params.colDef.colId){

				/*regular date columns*/
				case 'ORDDATE':
				case 'SHIPDATE':
				case 'ARRIVALDATE':
				case 'SALESBEGINDATE':
				case 'SALESENDDATE':
				case 'NEEDBYDATE':
				case 'CREATEDDATETIME':
				case 'LASTMODIFIEDDATETIME':
				case 'DATESENT':
					if(!value?.trim || (value?.trim && value.trim() === '')){
						returnValue = undefined;
					}
					else {
						returnValue = moment(value, constants.cfQueryDateFormat).toDate();
					}
					break;				
				/*all other columns*/
				default:
					returnValue = value;
			}
			return returnValue;
		},
		getFormattedColumnValue: (params) => {
			const value = ((params.colDef.colId in params.data) ? params.data[params.colDef.colId] : undefined);
			let returnValue;
			switch(params.colDef.colId){

				/*regular date columns*/
				case 'ORDDATE':
				case 'SHIPDATE':
				case 'ARRIVALDATE':
				case 'SALESBEGINDATE':
				case 'SALESENDDATE':
				case 'NEEDBYDATE':
				case 'CREATEDDATETIME':
				case 'LASTMODIFIEDDATETIME':
				case 'DATESENT':
					if(!value?.trim || (value?.trim && value.trim() === ''))
					{
						returnValue = '';
					} 
					else {
						returnValue = moment(value, constants.cfQueryDateFormat).format('MM/DD/YYYY');
					}
					break;
				/*all other columns*/
				default:
					returnValue = value;
			}
			return returnValue;
		},
		handleExportToExcelClicked: (dataset) => {
			if(dataset.length <= 0){
				sharedUtilities.showError('No data to export', 'You must click Search and have at least 1 result before clicking Export to Excel', 'ERROR');
				return;
			}
			dispatch(rpcAction({
				args: dataset,
				nodeRoute: nodeRoutes.DownloadExcel,
				method: 'POST',
				requestInBody: true,
				customResponseHandler: async (response) => {
					const now = moment().format('YYYY-MM-DD, h-mm.SSS A');
					const blob = await response.blob();
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement('a');
					a.href = url;
					a.download = 'Daily Orders ' + now + '.xlsx';
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();    
					a.remove(); //afterwards we remove the element again    
				}
			}));
		}
	};
};
SearchDailyOrdersResultsPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	searchDailyOrdersResultsGridRef: PropTypes.any,
	onSearchDailyOrdersResultsGridReady: PropTypes.func,
	getExcelDataSet: PropTypes.func,
	onPdfCellClicked: PropTypes.func,
	getColumnValue: PropTypes.func,
	getFormattedColumnValue: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SearchDailyOrdersResultsPanelContainer);

connectedComponent.defaultProps = {};

export default connectedComponent;