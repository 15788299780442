import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import DailyOrderLogScreen from './DailyOrderLogScreen';
export const SCREEN_ID = 'dailyorderlogscreen';
export const PANEL_ID = 'searchDailyOrdersResultsColumnsPanel';
class DailyOrderLogScreenContainer extends Component {
	render() {
		return (
			<DailyOrderLogScreen 
				screenId={this.props.screenId || SCREEN_ID} 
				columnsPanelId={this.props.panelId || PANEL_ID}
				handleSearchDailyOrdersResultsGridCheckBoxChanged={this.props.handleSearchDailyOrdersResultsGridCheckBoxChanged}
				user={this.props.user}
				handleSelectAllChanged={this.props.handleSearchDailyOrdersResultsGridSelectAll}
				selectAllChecked={this.props.selectAllChecked}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const selectAllChecked = storeUtilities.getValue([screenId, 'selectAll'], false);
	return {
		user: storeUtilities.getUser(),
		selectAllChecked
	};
};
const mapDispatchToProps = () => {
	return {};
};

DailyOrderLogScreenContainer.propTypes = {
	handleSearchDailyOrdersResultsGridCheckBoxChanged: PropTypes.func,
	onSearchDailyOrdersResultsGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	user: PropTypes.object.isRequired
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(DailyOrderLogScreenContainer);

export default connectedComponent;