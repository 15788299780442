import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import SuggestedOrdersDetailsTab from './SuggestedOrdersDetailsTab';
class SuggestedOrdersDetailsTabContainer extends Component {
	render() {
		return (
			<SuggestedOrdersDetailsTab {...this.props} />
		);
	}
}
const mapStateToProps = (state, props) => {
	const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], undefined);

	return {
		rowData: orderLinesRowData === undefined ? [] : orderLinesRowData
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		getIsXCellHidden: (cellProps) => {
			switch(cellProps.colDef.colId){
				case 'discontinuedSku':
				case 'inactiveSku':
				case 'toBeDropped':
					return cellProps?.node?.data ? cellProps.node.data[cellProps.colDef.colId] === 'N' : true;
				default: 
					return false;
			}
		}
	};
};
SuggestedOrdersDetailsTabContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	forwardRef: PropTypes.any,
	getIsXCellHidden: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(SuggestedOrdersDetailsTabContainer);