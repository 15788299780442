import PropTypes from 'prop-types';
import React, { useState } from 'react';
import withID from '../../../higherordercomponents/WithID';
import '../../../css/CustomFont1.css';
const OpenInNewWindowIcon = ({fill, color, height, width, id, onClick, node, api}) => {
    const initialColor = color ? color : 'darkgray';
    const [currentColor, setColor] = useState(initialColor);
    const [currentStyle, setStyle] = useState({cursor:'auto'});
    const onMouseDown = () => {
        setColor('#A9A9A9');
    };
    const onMouseUp = () => {
        setColor('black');
    };
    const onMouseOver = () => {
        setColor('black');
        setStyle({cursor:'pointer'});
    };
    const onMouseOut = () => {
        setColor(initialColor);
        setStyle({cursor:'auto'});
    };
    const click = () => {
		if(onClick){
			onClick({node, api});
		}
	}
	return (
        <svg id={id} 
            style={currentStyle}
            width={width?width:'20'} 
            height={height?height:'20'} 
            viewBox='0 0 25 25' 
            strokeWidth='1.5' 
            fill={fill?fill:'none'} 
            xmlns='http://www.w3.org/2000/svg'
            onMouseOver={onMouseOver} 
            onMouseOut={onMouseOut} 
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onClick={click}>
            <path d='M21 3L15 3M21 3L12 12M21 3V9' 
                stroke={currentColor}  
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path d='M21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H11' 
                stroke={currentColor}  
                strokeLinecap="round"
            />
        </svg>
	);
};
OpenInNewWindowIcon.propTypes = {
	fill: PropTypes.string,
	color: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
    id: PropTypes.string
};
export default withID(OpenInNewWindowIcon);