import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import { nodeRoutes } from '../../../constants';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import VendorWarehouseOrderGridPanel from './VendorWarehouseOrderGridPanel';

class VendorWarehouseOrderGridPanelContainer extends Component {
	render() {
		return (
			<VendorWarehouseOrderGridPanel 
				screenId={this.props.screenId}
				rowData={this.props.rowData}
				vendorCode={this.props.vendorCode}
				index={this.props.index}
				panelStyle={this.props.panelStyle}
				getColumnValue={this.props.getColumnValue}
				onValueChanged={this.props.onValueChanged}
				onEnterPressed={this.props.onEnterPressed}
				getIsInvalid={this.props.getIsInvalid}
				getTooltips={this.props.getTooltips}
				onDeleteRowClicked={this.props.onDeleteRowClicked}
				gridRef={this.props.gridRef}
			/>
		);
	}
}
const mapStateToProps = () => {
	return {
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		getColumnValue: (params) => {
			/** 
			 * All updates are made to the ROWMODEL. It can be assumed that its value is the most current. 
			 * Therefore, we'll always grab the values from it to display 
			 **/
			switch(params.colDef.colId){
				case 'QTYONDISPLAY':
				case 'DAYSTOSTOCK':
				case 'TARGETQTY':
				case 'ORD':
					return (params.colDef.colId in params.data.ROWMODEL) ? numberUtilities.getNumberOrDefault(params.data.ROWMODEL[params.colDef.colId]) : undefined;
				case 'AAO':
					return numberUtilities.getNumberOrDefault(params.data.ROWMODEL['AAO']);
				case 'PROJECTED':
					return (
						numberUtilities.getNumberOrDefault(params.data.ROWMODEL['AVAILABLE'])
					+	numberUtilities.getNumberOrDefault(params.data.ROWMODEL['AAO'])
					);
				default:
					return (params.colDef.colId in params.data.ROWMODEL) ? params.data.ROWMODEL[params.colDef.colId] : undefined;
			}
		},
		onValueChanged: (params) => {
			/**
			 * It is critically important that you only change the values in params.node.data.ROWMODEL.
			 * Since we *do not* save these values to the store (the grid itself is the store), we will 
			 * compare the values in params.node.data.ROWMODEL to their corresponding values in params.node.data.ROWMODEL_ORIGINAL.
			 * If they are different and the node has not been saved, then it will show the tooltip that it needs to be saved.
			 * ONLY once saved, will we update the value in params.node.data.ROWMODEL_ORIGINAL.
			 */
			const data = params.node.data;
			switch(params.colId){
				case 'QTYONDISPLAY':
				case 'DAYSTOSTOCK':
				case 'TARGETQTY':
				case 'ORD':
					data.ROWMODEL[params.colId] = numberUtilities.getNumberOrDefault(params.value);
					break;
				default:
					data.ROWMODEL[params.colId] = params.value;
			}
			params.node.setData(data);
			params.gridApi.refreshCells({rowNodes: [params.node], force:true});
		},
		onEnterPressed: (params) => {
			const whs = storeUtilities.getValue([props.screenId, 'whsNum']);
			const qty = numberUtilities.getNumberOrDefault(params.value);
			const sku = params.node.data.ROWMODEL.SKU;
			const args = { whs, sku };
			if(params.colId === 'TARGETQTY'){
				args.target = qty;
			}
			else {
				args.qty = qty;
			}
			params.disable();
			const updateFunction = (endpoint) => {
				dispatch(callRpcAction(args, nodeRoutes.IFRService, endpoint, () => {
					const data = params.node.data;
					switch(params.colId){
						case 'QTYONDISPLAY':
						case 'DAYSTOSTOCK':
						case 'TARGETQTY':
							data.ROWMODEL_ORIGINAL[params.colId] = numberUtilities.getNumberOrDefault(params.value);
							break;
						default:
							data.ROWMODEL_ORIGINAL[params.colId] = params.value;
					}
					const rowModel = data.ROWMODEL;

					const tempAvailable = rowModel.QOH - rowModel.OPENORDERS - rowModel.QTYONDISPLAY + rowModel.QTYONORDER;
					const dailySalesRate = rowModel.T30SALES / 30;
					const dailySales = dailySalesRate * rowModel.DAYSTOSTOCK;

					if(rowModel.TARGETQTY > dailySales && (rowModel.TARGETQTY - tempAvailable) > 0)
					{
						rowModel.ORD = Math.ceil(rowModel.TARGETQTY - tempAvailable);
						rowModel.AVAILABLE = tempAvailable;	
					}					
					else if((dailySales - tempAvailable) > 0)
					{
						rowModel.ORD = Math.ceil(dailySales - tempAvailable);
						rowModel.AVAILABLE = tempAvailable;
					}	
					else
					{
						rowModel.ORD = 0;
						rowModel.AVAILABLE = tempAvailable;
					}
					params.node.setDataValue('ROWMODEL', rowModel);
					params.node.setDataValue('ROWMODEL_ORIGINAL', cloneDeep(rowModel));
					params.gridApi.refreshCells({rowNodes: [params.node], force:true});
					params.enable();
					params.validate();
				}, false));
			};
			switch(params.colId){
				case 'QTYONDISPLAY':
					updateFunction('UpdateDisplayQuantity');
					break;
				case 'DAYSTOSTOCK':
					updateFunction('UpdateDayToStock');
					break;
				case 'TARGETQTY':
					updateFunction('UpdateSKUTarget');
					break;
				default:
					return;
			}
		},
		getIsInvalid: (params) => {
			return cellIsUnsaved(params);
		},
		getTooltips: (params, isRenderer) => {
			return cellIsUnsaved(params) 
				? (isRenderer ? ['Click cell to save'] : ['Press \'Enter\' to save'])
				: []; 
		},
		onDeleteRowClicked: () => {}
	};
};
const cellIsUnsaved = (params) => {
	const original = numberUtilities.getNumberOrDefault(params.data.ROWMODEL_ORIGINAL[params.column.colId]);
	const updated = numberUtilities.getNumberOrDefault(params.data.ROWMODEL[params.column.colId]);
	const isUnsaved = original !== updated;
	return isUnsaved;
};

VendorWarehouseOrderGridPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	vendorCode: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	panelStyle: PropTypes.object,
	gridRef: PropTypes.any
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(VendorWarehouseOrderGridPanelContainer);
export default connectedComponent;