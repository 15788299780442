import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as sharedUtilities from '../../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import OnOrderDialog from './OnOrderDialog';
class OnOrderDialogContainer extends Component {
	render() {
		return (
			<OnOrderDialog
				screenId={this.props.screenId}
				onBeforeLoad={this.props.onBeforeLoad}
				rowData={this.props.rowData}
				getColumnValue={this.props.getColumnValue}
				getFormattedValue={this.props.getFormattedValue}
				onGridReady={this.props.onGridReady}
				compareValues={this.props.compareValues}
				onModelUpdated={this.props.onModelUpdated}
				vendor={this.props.vendor}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, 'OnOrderGrid', 'rowData'], []);
	return {
		rowData
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onGridReady: (params) => {
			params.api.sizeColumnsToFit();
		},
		onModelUpdated: (params) => {
			let total = 0;
			params.api.getModel().forEachNodeAfterFilter(node => {
				total += node.data.ONORDERAMOUNT;
			});
			storeUtilities.updateValue(dispatch, [props.screenId, 'OnOrderGrid', 'onOrderTotal'], total.toString());
			params.api.sizeColumnsToFit();
		},
		getColumnValue: (params) => {
			switch(params.colDef.colId){
				case 'WHS':
					return getWhsName(params.data);
				default:
					return undefined;
			}
		},
		getFormattedValue: (params) => {
			switch(params.colDef.colId){
				case 'ONORDERAMOUNT':
					return sharedUtilities.formatCurrency(params.data.ONORDERAMOUNT);
				default:
					return undefined;
			}
		},
		compareValues: (colId, value1, value2, node1, node2) => {
			switch (colId){
				case 'MONTH':
					return compareMonths(value1, value2, node1, node2);
				case 'WHS':
					return compareWarehouses(value1, value2, node1, node2);
				default:
					return undefined;
			}
		}
	};
};

const compareMonths = (month1, month2, node1, node2) => {
	const dateFormat = 'YYYY-M-D';
	const strDate1 = node1.data.YEAR + '-' + node1.data.MONTHNUMBER + '-1'; 
	const strDate2 = node2.data.YEAR + '-' + node2.data.MONTHNUMBER + '-1';
	return sharedUtilities.compareStringDates(strDate1, dateFormat, strDate2, dateFormat);
};

const compareWarehouses = (whs1, whs2, node1, node2, isInverted) => {
	return (whs1<whs2?-1:(whs1>whs2?1:0));//isInverted doesn't seem to affect string sorting properly in grids
};

const getWhsName = (data) => {
	const whsList = storeUtilities.getValue(['whsList']);
	const filtered = whsList.filter(whs => { 
		return whs.division === data.DIVISION 
			&& whs.whsId.split('_')[1] === data.WHS.toString(); 
	});
	return filtered.length === 1 
		? filtered[0].whsName + ' ' + filtered[0].whsId.split('_')[1]
		: data.WHS.toString();
};

OnOrderDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	vendor: PropTypes.string.isRequired,
	compareValues: PropTypes.func,
	getColumnValue: PropTypes.func,
	getFormattedValue: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(OnOrderDialogContainer);

export default connectedComponent;