import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';
import rootSaga from './sagas/rootSaga';
import * as ramda from 'ramda';
const getEnvVar = (varName) => {
	const p = import.meta.env !== undefined
		? import.meta.env 
		: process.env;
	switch(varName){
		case 'PUBLIC_URL':
			return p.BASE_URL;
		default:
			return ramda.pathOr('/', [varName])(p);
	}
};
const root = getEnvVar('PUBLIC_URL') || '/';
export const history = createBrowserHistory(
	{
		basename: root
	}
);

const sagaMiddleware = createSagaMiddleware();

const middleWares = [
	sagaMiddleware,
	routerMiddleware(history)
];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	createRootReducer(history),
	composeEnhancer(applyMiddleware(...middleWares))
);
sagaMiddleware.run(rootSaga);

export default store;
