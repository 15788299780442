import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

class MenuButton extends Component {
	constructor(props){
		super(props);
		this.getId = this.getId.bind(this);
		this.onItemClicked = this.onItemClicked.bind(this);
		this.state={isOpen: false, isHovered: false};
	}
	onItemClicked(key){
		const buttons = this.props.buttons.filter(button => button.key === key);
		if(buttons !== undefined && buttons.length === 1 && buttons[0].handler !== undefined){
			buttons[0].handler();
		}
		this.setState({isOpen: false});
	}
	getId(){
		return this.props.screenId + '_' + (this.props.panelId ? (this.props.panelId + '_') :'')  + this.props.fieldId;
	}
	handleMouseEnter(){
		this.setState({isHovered: true});
	}
	handleMouseOut(){
		this.setState({isHovered: false});
	}
	handleMouseUp(){
		this.setState({isOpen: this.state.isOpen === false});
	}
	getMenuButtonId(key){
		return this.getId() + '_' + key;
	}
	render() {
		const menuItems = this.props.buttons.map(button => {
			return (
				<Dropdown.Item 
					key={button.key} 
					eventKey={button.key} 
					disabled={button.disabled} 
					id={this.getMenuButtonId(button.key)}
					data-testid={this.getMenuButtonId(button.key)}>
					{button.text}
				</Dropdown.Item>
			);
		});
		return (
			<Dropdown 
				onSelect={this.onItemClicked.bind(this)} 
				onMouseEnter={this.handleMouseEnter.bind(this)} 
				onMouseLeave={this.handleMouseOut.bind(this)}
				onMouseUp={this.handleMouseUp.bind(this)}
				show={this.state.isOpen === true || (this.state.isHovered === true && this.state.isOpen === false)}>
				<Dropdown.Toggle 
					id={this.getId()}
					data-testid={this.getId()} 
					className={'w-100'} 
					disabled={this.props.disabled}>
					{this.props.mainButtonText}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{menuItems}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

MenuButton.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	buttons: PropTypes.arrayOf(PropTypes.object).isRequired, //ex: [{key:'myButton', text:'Click Me!', handler: ()=>{window.alert('w00t!');}, disabled: this.props.disabled}]
	mainButtonText: PropTypes.string.isRequired,
	disabled: PropTypes.bool
};

export default MenuButton;
