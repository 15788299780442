import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DataGridContainer } from '@rtg/shared-react-components';
import * as ramda from 'ramda';
import store from '../../../../configureStore';
class AddClearanceSkusInactiveSkusListTab extends Component {
	constructor(props){
		super(props);
		this.gridRef = React.createRef();
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onGridReady(params){
		if(this.props.onGridReady){
			this.props.onGridReady(params);
		}
	}
	handleRowClicked(params){
		if(this.props.handleRowClicked){
			this.props.handleRowClicked(params);
		}
	}
	componentDidUpdate(prevProps){
		const api = ramda.pathOr(undefined, ['current', 'api'], this.gridRef);
		if(api !== undefined){
			if(api.sizeColumnsToFit){
				api.sizeColumnsToFit();
			}
			if(this.props.onComponentDidUpdate !== undefined){
				this.props.onComponentDidUpdate(api);
			}
		}
		if(this.props.displayedTab !== prevProps.displayedTab){
			if(this.props.onTabChanged){
				this.props.onTabChanged();
			}
		}
	}
	render() {
		const noRowsMessage = this.props.rowData === undefined ? 'Getting inactive clearance SKUs...' : undefined;
		return (
			<DataGridContainer
				screenId={this.props.screenId} 
				panelId={this.props.panelId}
				forwardRef={this.gridRef}
				fieldId='InactiveSkusGrid' 
				defaultColDef={{resizable: true, sortable: true, filter: true}}
				rowSelection={'multiple'}
				columnDefs={
					[
						{ headerName: 'SKU', field: 'sku', colId:'sku'}, 
						{ headerName: 'Vendor', field: 'vendorId', colId:'vendorId'},
						{ headerName: 'Style', field: 'style', colId:'style'},
						{ headerName: 'Description', field: 'vendorDescription', colId:'vendorDescription'},
						{ headerName: 'Color', field: 'color', colId:'color'},
						{ headerName: 'Finish', field: 'finish', colId:'finish'},
						{ headerName: 'Family', field: 'family', colId:'family'}
					]
				}
				onGridReady={this.onGridReady.bind(this)}
				rowData={this.props.rowData || []}
				onRowClicked={this.handleRowClicked.bind(this)}
				overridePath={this.props.addInactiveSkusTabPath.concat(['InactiveSkusGrid'])}
				noRowsMessage={noRowsMessage}
				store={store}
			/>
		);
	}
}

AddClearanceSkusInactiveSkusListTab.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	onLoad: PropTypes.func,
	onGridReady: PropTypes.func,
	addInactiveSkusTabPath: PropTypes.array.isRequired,
	handleRowClicked: PropTypes.func,
	onTabChanged: PropTypes.func
};
export default AddClearanceSkusInactiveSkusListTab;
