import { push } from 'connected-react-router';
import queryString from 'query-string';
import { parse } from 'dirty-json';
export const secureNavigation = (dispatch, user, history, pathname, querystring, hashArg) => {
	let fromPath = pathname ? pathname : history?.location?.pathname;
	const search = querystring ? querystring : history?.location?.search;
	const dirtyHash = hashArg ? hashArg : history?.location?.hash;
	const hash = dirtyHash.replace ? dirtyHash.replace(/&/g, '%26') : '';
	let initializationData;
	try{
		const dirtyJson = queryString.parse(hash)['initializationData'];
		initializationData = parse(dirtyJson);
	} 
	catch{
		//no-op
	}
	if(user === undefined 
        || user.hasAdminAccess === undefined /* this method should be defined. If it isn't, then the user object hasn't been instantiated correctly for some reason */ 
        || (!user.hasAdminAccess() && !user.hasBuyerAccess() && !user.hasPartsAccess() && !user.hasClearanceAccess() && !user.hasReadOnlyAccess())){
		navigate(dispatch, '/noaccesstoifr' + search);
	}
	if(fromPath === '/noaccesstoifr'){
		fromPath = '/';
	}
	let toPath = '';
	switch(fromPath){
		case '/':
			/**
                 * When there is no history (when the user cookie is expired, as mentioned above), 
                 * this is the block that will execute. In this case, we redirect to one of the main screens (Products, Parts, or Clearance)
                 */
			if(user.hasAdminAccess() || user.hasBuyerAccess() || user.hasReadOnlyAccess()){
				toPath = '/products';
			}
			else if(user.hasPartsAccess() && (!user.hasAdminAccess() && !user.hasBuyerAccess() && !user.hasReadOnlyAccess())){
				toPath = '/parts';
			}
			else if(user.hasClearanceAccess() && (!user.hasAdminAccess() && !user.hasBuyerAccess() && !user.hasPartsAccess() && !user.hasReadOnlyAccess())){
				toPath = '/clearance';
			}
			else {
				toPath = '/noaccesstoifr';
			}
			break;
		case '/products':
		case '/orderproducts':
			if(!user.hasAdminAccess() && !user.hasBuyerAccess() && !user.hasReadOnlyAccess()){
				//we need to get them off the products screen
				if(user.hasPartsAccess()){
					toPath = '/parts';
				}
				else if(user.hasClearanceAccess()){
					toPath = '/clearance';
				}
				else {
					toPath = '/noaccesstoifr';
				}
			}
			break;
		case '/parts':
		case '/orderparts':
			if(!user.hasAdminAccess() && !user.hasBuyerAccess() && !user.hasReadOnlyAccess() && !user.hasPartsAccess()){
				//we need to get them off the parts screen.
				if(user.hasClearanceAccess()){
					toPath = '/clearance';
				}
				else {
					toPath = '/noaccesstoifr';
				}
			}
			break;
		case '/maintenance':
			if(!user.hasAdminAccess()){//must have admin access for this screen. If the user doesn't get them off this screen.
				if(user.hasBuyerAccess() || user.hasReadOnlyAccess()){
					toPath = '/products';
				}
				else if(user.hasPartsAccess()){
					toPath = '/parts';
				}
				else if(user.hasClearanceAccess()){
					toPath = '/clearance';
				}
				else {
					toPath = '/noaccesstoifr';
				}
			}
			break;
		case '/dailyorderlog':
			if(!user.hasAdminAccess() && !user.hasBuyerAccess() && !user.hasReadOnlyAccess() && !user.hasPartsAccess()){
				if(user.hasClearanceAccess()){
					toPath = '/clearance';
				}
				else{
					toPath = '/noaccesstoifr';
				}
			}
			break;
		case '/purchaseorderqueue':
			if(!user.hasAdminAccess() && !user.hasBuyerAccess()){
				toPath = '/noaccesstoifr';
			}
			break;
		case '/clearance':
			if(!user.hasAdminAccess() && !user.hasBuyerAccess() && !user.hasClearanceAccess()){
				toPath = '/noaccesstoifr';
			}
			break;
		default:
			break;
	}
	if(initializationData !== undefined){
		navigate(dispatch, fromPath + search + hash);
	}
	else if(toPath !== ''){
		navigate(dispatch, toPath + search + hash);
	}
	else {
		navigate(dispatch, fromPath + search + hash);
	}
};
const navigate = (dispatch, path) => {
	window.setTimeout(()=>{	
		dispatch(push(path));
	}, 1000);
};