import React, { Component } from 'react';
import MaintenanceViewScreen from './MaintenanceViewScreen';
const SCREEN_ID = 'maintenanceviewscreen';

class MaintenanceViewScreenContainer extends Component {
	render() {
		return (
			<MaintenanceViewScreen screenId={this.props.screenId || SCREEN_ID} />
		);
	}
}
export default MaintenanceViewScreenContainer;