import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import PurchaseOrderQueueGridPanelContainer from './PurchaseOrderQueueGridPanelContainer';
class PurchaseOrderQueueScreen extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	setRowModel(data){
		const rowModel = {...{ROWMODEL: Object.assign({}, data)}, ...data};
		return rowModel;
	}
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| this.props.user.hasBuyerAccess === undefined
			|| (!this.props.user.hasAdminAccess() && !this.props.user.hasBuyerAccess())){
			return <NoAccessToScreenPanel />;
		}
		const queueDataByVendor = new Map();
		if(this.props.purchaseOrderQueueData !== undefined){	
			for(let i = 0; i < this.props.purchaseOrderQueueData.length; i++){
				const vendorCode = (String(this.props.purchaseOrderQueueData[i]['VENDOR'])).toUpperCase();
				if(!queueDataByVendor.has(vendorCode)){
					queueDataByVendor.set(vendorCode, [this.setRowModel(this.props.purchaseOrderQueueData[i])]);
				}
				else{
					queueDataByVendor.get(vendorCode).push(this.setRowModel(this.props.purchaseOrderQueueData[i]));
				}
			}
		}
		const skuDataByVendorIterator = queueDataByVendor.entries();
		const queueGridPanelsToRender = [];
		for(let j = 0; j < queueDataByVendor.size; j++){
			const entry = skuDataByVendorIterator.next();
			if(entry === undefined || entry.value === undefined){
				break;
			}
			const panelStyle = j === 0 ? {marginTop:'5px', marginBottom:'5px'} : {marginTop:'0px', marginBottom:'5px'};
			queueGridPanelsToRender.push(
				<PurchaseOrderQueueGridPanelContainer 
					screenId={this.props.screenId}
					panelId={'purchaseOrderQueueGridPanel_' + entry.value[0]}
					rowData={entry.value[1]}
					vendorCode={entry.value[0]}
					key={j}
					index={j}
					panelStyle={panelStyle}
				/>);
		}
		return (
			<Card className={'h-100'}>
				<Card.Header>
					<Row>
						<Col>
							<h3 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Purchase Order Queue</h3>
						</Col>
					</Row>
				</Card.Header>
				<div style={{overflow: 'auto'}}>
					{queueGridPanelsToRender}
				</div>
			</Card>
		);
	}
}

PurchaseOrderQueueScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	purchaseOrderQueueData: PropTypes.array,
	onLoad: PropTypes.func
};

export default PurchaseOrderQueueScreen;