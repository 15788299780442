import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as sharedUtilities from '../../../utilities/sharedUtilities.jsx';
import * as storeUtilities from '../../../utilities/storeUtilities.jsx';
import * as persistentValues from '../../../utilities/persistentValues.jsx';
import * as numberUtilities from '../../../utilities/numberUtilities.jsx';
import OrderProductsScreen from './OrderProductsScreen.jsx';

export const SCREEN_ID = 'orderproductsscreen';
const DEFAULT_SPLITTER_POSITION_PCT = 35;

class OrderProductsScreenContainer extends Component {
	render() {
		return (
			<OrderProductsScreen {...this.props}
				screenId={this.props.screenId || SCREEN_ID}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const screenId = props.screenId || SCREEN_ID;

	const vendor = storeUtilities.getValue([screenId, 'vendor'], undefined);
	const divisions = storeUtilities.getValue([screenId, 'divisions'], []);
	const selectedRows = storeUtilities.getValue([screenId, 'selectedRows'], []);
	const vendorEmailInfos = storeUtilities.getValue([screenId, 'vendorEmailInfos'], []);
	const user = storeUtilities.getUser();
	const splitterPosition = storeUtilities.getValue([screenId, 'splitterPosition'], undefined);
	const skusList = getSkusList(selectedRows);
	return {
		selectedRows,
		vendor,
		divisions,
		vendorEmailInfos,
		user,
		splitterPosition,
		skusList
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	return {
		onLoad: () => {
			const { vendor, divisions, vendorEmailInfos, selectedRows } = sharedUtilities.getScreenInitializationDataFromProps(props, ['vendor', 'divisions', 'vendorEmailInfos', 'selectedRows']);
			const search = props.history?.location?.hash;
			const dirtyHash = props.history?.location?.hash;
			const hash = dirtyHash.replace ? dirtyHash.replace(/&/g, '%26') : '';
			const splitterPosition = numberUtilities.getNumberOrDefault(persistentValues.get([screenId, 'splitterPosition']), DEFAULT_SPLITTER_POSITION_PCT);
			storeUtilities.initializeValue(dispatch, [screenId, 'splitterPosition'], splitterPosition, true);
			if(vendor === undefined
				|| divisions === undefined
				|| vendorEmailInfos === undefined
				|| selectedRows === undefined){
				dispatch(push('/products' + search + hash));
				return;
			}
			storeUtilities.updateValue(dispatch, [screenId, 'vendor'], vendor);
			storeUtilities.updateValue(dispatch, [screenId, 'divisions'], divisions);
			storeUtilities.updateValue(dispatch, [screenId, 'selectedRows'], selectedRows);
			storeUtilities.updateValue(dispatch, [screenId, 'vendorEmailInfos'], vendorEmailInfos);
		},
		handleSplitterMoved: (newValue) => {
			storeUtilities.updateValue(dispatch, [screenId, 'splitterPosition'], newValue, false, true);
		}
	};
};
const getSkusList = (selectedRows) => {
	let skus = '';
	let first = true;
	if(selectedRows !== undefined && Array.isArray(selectedRows) && selectedRows.sort !== undefined){
		selectedRows.sort((a, b) => {
			return (a.sku < b.sku) ? -1 : 1; 
		});
		selectedRows.forEach(x => {
			skus += (first ? '' : ', ')+ x.sku;
			first = false;
		});
	}
	return skus;
};

OrderProductsScreenContainer.propTypes = {
	screenId: PropTypes.string,
	useCubes: PropTypes.bool,
	history: PropTypes.any,
	user: PropTypes.object.isRequired,
	selectedRows: PropTypes.array,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	vendorEmailInfos: PropTypes.arrayOf(PropTypes.object).isRequired,
	onLoad: PropTypes.func,
	handleSplitterMoved: PropTypes.func,
	splitterPosition: PropTypes.number
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(OrderProductsScreenContainer);
export default connectedComponent;