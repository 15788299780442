import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
class NoAccessToIFRScreen extends Component {
	render() {
		const message = this.props.message || 'You do not have access to this screen.';
		return (
			<Container className={'h-100'} fluid={true}>
				<Card>
					<Card.Header>
						<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>No Access</h5>
					</Card.Header>
					<Card.Body>
						{message}
					</Card.Body>
				</Card>
			</Container>
		);
	}
}
NoAccessToIFRScreen.propTypes = {
	message: PropTypes.string
};
export default NoAccessToIFRScreen;