import * as numberUtilities from './numberUtilities';
import * as sharedUtilities from './sharedUtilities';
export const getGridHiddenColumns = (gridRef, excludedColumns=[]) => {
	const displayedColumns = gridRef.api.getAllDisplayedColumns();
	const allColumns = gridRef.api.getColumns();
	for(let i = 0; i < allColumns.length; i++){
		let found = false;
		for(let j = 0; j < displayedColumns.length; j++){
			if(displayedColumns[j].colId === allColumns[i].colId){
				found = true;
			}
		}
		if(found === false && excludedColumns.includes(allColumns[i].colId) === false){
			excludedColumns.push(allColumns[i].colId);
		}
	}
	return excludedColumns;
};

export const getGridExcelExportDataSet = (gridRef, rowData) => {
	const hiddenColumns = getGridHiddenColumns(gridRef);
	const results = excludeColumnsForExcelExportDataSet(gridRef, rowData, hiddenColumns);
	const returnValue = [{
		columns: results.COLUMNS,
		data: results.DATA
	}];
	return returnValue;
};
export function excludeColumnsForExcelExportDataSet(gridRef, rowData, excludeColumns){
	const rowDataUpperCaseKeys = Array.isArray(rowData) ? rowData.map(row => sharedUtilities.uppercaseObjectKeys(row)) : undefined;
	const revisedColumnSet = [];
	const revisedDataSet = [];
	const columns = gridRef.api.getAllDisplayedColumns();
	const allColumns = gridRef.api.getColumns();
	/**
	 * display the headerNames instead of the colId's (since they will visually match what is shown on the grids)
	 */
	for(let j = 0; j < columns.length; j++){
		if(!excludeColumns.includes(columns[j].colId)){
			if(!revisedColumnSet.includes(columns[j].colId)){
				revisedColumnSet.push(columns[j].colId);
			}
		}
	}
	const columnHeaderNames = revisedColumnSet.map(column => {
		let returnValue = '';
		for(let i = 0; i < allColumns.length; i++){
			if(column === allColumns[i].colId){
				returnValue = allColumns[i].colDef.headerName;
			}
		}
		return returnValue;
	});
	if(rowDataUpperCaseKeys && Array.isArray(rowDataUpperCaseKeys)){
		for(let i = 0; i < rowDataUpperCaseKeys.length; i++){
			const dataRowArray = [];
			for(let j = 0; j < columns.length; j++){
				if(columns[j]?.colId?.toUpperCase && !excludeColumns.includes(columns[j].colId.toUpperCase())){
					dataRowArray.push(rowDataUpperCaseKeys[i][columns[j].colId.toUpperCase()]); 
				}
			}
			revisedDataSet.push(dataRowArray);
		}
	}
	return {
		COLUMNS: columnHeaderNames,
		DATA: revisedDataSet
	};
}
export function formatDecimal(value, radix, removeTrailingZeroes = false){
	const val = numberUtilities.getNumberOrDefault(value, 0).toFixed(radix);
	var returnValue = String(removeTrailingZeroes === true ? Number(val) : val);
	return returnValue;
}
export const getCellId = (rowIndex, colId, gridName, isEditor=false) => {
	return (colId && !window.isNaN(rowIndex)) 
		? gridName + '_' + colId + (isEditor === true ? '_EDITOR_' : '_RENDERER_') + rowIndex
		: 'no-id';
};

export const getNextCellPos = (prevPos, boundary, canMoveTo, backwards) => {
	const next = backwards ? findPreviousCellPos(prevPos, boundary, canMoveTo) : findNextCellPos(prevPos, boundary, canMoveTo);
	if(next === undefined){
		return prevPos;
	}
	else{
		return next;
	}	
};
const findNextCellPos = (pos, boundary, canMoveTo) => {
	const next = {
		x: pos.x,
		y: pos.y
	};
	if(next.x < boundary.xMax){
		next.x++;
	}
	else {
		if(next.y < boundary.yMax){
			next.y++;
			next.x = 0;
		}
		else {
			return undefined;//terminating case
		}
	}
	if(canMoveTo(next)){
		return next;
	}
	else { 
		return findNextCellPos(next, boundary, canMoveTo);
	}
};
const findPreviousCellPos = (pos, boundary, canMoveTo) => {
	const prev = {
		x: pos.x,
		y: pos.y
	};
	if(prev.x > boundary.xMin){
		prev.x--;
	}
	else {
		if(prev.y > boundary.yMin){
			prev.y--;
			prev.x = boundary.xMax;
		}
		else {
			return undefined;//terminating case
		}
	}
	if(canMoveTo(prev, boundary)){
		return prev;
	}
	else { 
		return findPreviousCellPos(prev, boundary, canMoveTo);
	}
};