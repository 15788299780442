import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../utilities/storeUtilities';
import closeDialogAction from '../../actions/closeDialogAction';
import Modal from './Modal';

class ModalContainer extends Component {
	render() {
		return (
			<Modal
				dialogId={this.props.dialogId}
				title={this.props.title}
				message={this.props.message}
				hasCancelButton={this.props.hasCancelButton}
				height={this.props.dialogHeight}
				width={this.props.dialogWidth}
				overlayVisible={this.props.overlayVisible}
				isOnlyDialog={this.props.isOnlyDialog}
				enableResizing={this.props.enableResizing}
				position={this.props.position}
				doSlideInEffect={this.props.doSlideInEffect}
				closeOnOverlayClick={this.props.closeOnOverlayClick}
				handleDialogClosed={this.props.handleDialogClosed}
				closeOnEscapeKeyPressed={this.props.closeOnEscapeKeyPressed}
				handleEscapeKeyPressed={this.props.handleEscapeKeyPressed}>
				{this.props.children}
			</Modal>
		);
	}
}

const mapStateToProps = (state, props) => {
	const dialogs = storeUtilities.getDialogs();
	const beginShowDialogAnimation = storeUtilities.getGlobalAppStateValue(['beginShowDialogAnimation']) === true;
	const beginCloseDialogAnimation = storeUtilities.getGlobalAppStateValue(['beginCloseDialogAnimation']) === true;
	const additionalProps = {
		isUnitTest: storeUtilities.getGlobalAppStateValue(['isUnitTest'], false)
	};
	if(dialogs.length > 0){
		const activeDialog = dialogs.pop();
		if(activeDialog && activeDialog.dialogId === props.dialogId){
			additionalProps.overlayVisible = true;
			additionalProps.beginShowDialogAnimation = beginShowDialogAnimation;
			additionalProps.beginCloseDialogAnimation = beginCloseDialogAnimation;
		}
		additionalProps.isOnlyDialog = dialogs.length === 0;//after the pop
	}
	else {
		additionalProps.overlayVisible = false;
		additionalProps.beginShowDialogAnimation = false;
		additionalProps.beginCloseDialogAnimation = false;
	}
	return additionalProps;
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		handleOverlayClicked: () => {
			if(props.closeOnOverlayClick === true){
				if(props.handleDialogClosed){
					props.handleDialogClosed();
				}
				dispatch(closeDialogAction());
			}
		},
		handleEscapeKeyPressed: () => {
			const loadingMaskShown = storeUtilities.getGlobalAppStateValue(['showLoadingMask']);
			if(props.closeOnEscapeKeyPressed === true && loadingMaskShown === false){
				if(props.handleDialogClosed){
					props.handleDialogClosed();
				}
				dispatch(closeDialogAction());
			}
		}
	};
};

ModalContainer.propTypes = {
	dialogId: PropTypes.string.isRequired,
	title: PropTypes.string,
	message: PropTypes.string,
	hasCancelButton: PropTypes.bool,
	dialogWidth: PropTypes.number,
	dialogHeight: PropTypes.number,
	overlayVisible: PropTypes.bool,
	position: PropTypes.object,
	doSlideInEffect: PropTypes.bool,
	closeOnOverlayClick: PropTypes.bool,
	handleDialogClosed: PropTypes.func,
	closeOnEscapeKeyPressed: PropTypes.bool
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default connectedComponent;