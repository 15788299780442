export default class OrderLine {
	constructor(){
		this.ADTS = 0;
		this.ALIAS = '';
		this.Available = 0;
		this.AvailableAfterOrder = 0;
		this.BVAVAIL = 0;
		this.BVINBOUND = 0;
		this.BVQOH = 0;
		this.CalcOrder = 0;
		this.Color = '';
		this.ColorFinish = '';
		this.Cube = 0;
		this.DailyMean = 0;
		this.DailyStdDev = 0;
		this.DaysToNextOrder = 0;
		this.DiscontinuedSKU = 'N';
		this.ExcessJson = '';
		this.EOLAVAIL = 0;
		this.FACTORY = '';
		this.FactoryColor = '';
		this.Family	= '';
		this.Finish	= '';
		this.FOB = '';
		this.InactiveSKU = 'N';
		this.IntraDivisional = 0;
		this.LastSoldDate = '';
		this.LoadFactor = 0;
		this.LogOrder = 0;
		this.LTAVAIL = 0;
		this.NETSALES = 0;
		this.NO_OF_SKUS_IN_CARTON = 0;
		this.OnOrder = 0;
		this.Order = 0;
		this.OrderSafe = 0;
		this.PDMPieceCount = 0;
		this.PDMProductId = 0;
		this.Picture = '';
		this.PieceCount	= 0;
		this.PriPromoAvail = 0;
		this.ProductionTime	= 0;
		this.PromoAvail	= 0;
		this.QOH = 0;
		this.QtyFromWHS = 0;
		this.QtyToWHS = 0;
		this.Ratio = 0;
		this.ReceivedToday = 0;
		this.REPLACEMENTSKU	= '';
		this.ReplenishmentStock	= 0;
		this.SafetyStock = 0;
		this.SalesRatio = 0;
		this.SecPromoAvail = 0; 
		this.SKU = '';
		this.SkuCube = 0;
		this.SkuGroup = '';
		this.SoldToday = 0;
		this.STOREQTY = 0;
		this.SubSKU = 0; //unsure what this is used for...might be a duplicate of SUBSTITUTIONSKU
		this.SUBSTITUTIONSKU = '';
		this.Theme = '';
		this.ThresMax = 0;
		this.ThresMin = 0;
		this.ToBeDropped = 'N';
		this.TotalSales = 0;
		this.TransitTime = 0;
		this.Trigger = 0;
		this.VendorDescription = '';
		this.WeeklySales = 0;
		this.WeeksOfStock = 0;
		this.WOSOH = 0;
		this.ZeroAvailOnly = 0;
		this.FIRSTCOST = 0;
		this.rand = 0;
	}
}