import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLabel from '../../higherordercomponents/WithLabel';
import withTooltip from '../../higherordercomponents/WithTooltip';
import withID from '../../higherordercomponents/WithID';
class CheckBox extends Component {
	constructor(props){
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onOuterDivClick = this.onOuterDivClick.bind(this);
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onChange(e){
		if(this.props.onChange){
			this.props.onChange(e.target.checked);
		}
	}
	onOuterDivClick(){
		setTimeout(() => {
			document.activeElement.blur();
		  }, 0);
	}
	render() {
		const style = Object.assign(
			{
				display: (this.props.visible !== false ? 'block':'none'),
				position: 'relative',
				top: '4px'
			}, (this.props.style ? this.props.style : {}));
		const value = this.props.value === undefined ? false : this.props.value;
		const checkbox = (
			<div 
				style={style} 
				id={this.props.id + '_container'}
				data-testid={this.props.id + '_container'} 
				onClick={this.onOuterDivClick}>
				<input type={'checkbox'} 
					checked={value}
					disabled={this.props.disabled}
					id={this.props.id}
					data-testid={this.props.id}
					onChange={this.onChange}
				/>
			</div>);
		return checkbox;
	}
}

CheckBox.propTypes = {
	label: PropTypes.string,
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	fieldId: PropTypes.string.isRequired,
	name: PropTypes.string,
	value: PropTypes.bool,
	disabled: PropTypes.bool,
	visible: PropTypes.bool,
	onChange: PropTypes.func,
	/**
	 * mainComponentBootstrapSize is a number 0 through 12 that represents the width of a column in a bootstrap layout grid in which this checkbox is placed
	 */
	mainComponentBootstrapSize: PropTypes.number,
	labelPosition: PropTypes.string,
	style: PropTypes.object,
	id: PropTypes.string //optional...if not provided, one will be generated from the screenId and fieldId
};

export default withLabel(withTooltip(withID(CheckBox)));
