import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import ComingSoonScreen from '../screens/comingsoonscreen/ComingSoonScreen';
import DailyOrderLogScreenContainer from '../screens/dailyorderlogscreen/DailyOrderLogScreenContainer';
import MaintenanceManagerScreenContainer from '../screens/maintenancemanagerscreen/MaintenanceManagerScreenContainer';
import NoAccessToIFRScreenContainer from '../screens/noaccessscreen/NoAccessToIFRScreenContainer';
import OrderProductsScreenContainer from '../screens/orderproductsscreen/OrderProductsScreenContainer';
import OrderPartsScreenContainer from '../screens/orderpartsscreen/OrderPartsScreenContainer';
import SearchPartsScreenContainer from '../screens/searchpartsscreen/SearchPartsScreenContainer';
import SearchProductsScreenContainer from '../screens/searchproductsscreen/SearchProductsScreenContainer';
import ClearanceScreenContainer from '../screens/clearancescreen/ClearanceScreenContainer';
import WarehouseScreenContainer from '../screens/warehousescreen/WarehouseScreenContainer';
import PurchaseOrderQueueScreenContainer from '../screens/purchaseorderqueuescreen/PurchaseOrderQueueScreenContainer';
import MaintenanceViewScreenContainer from '../screens/maintenanceviewscreen/MaintenanceViewScreenContainer';
import TransferScreenContainer from '../screens/transferscreen/TransferScreenContainer';
class Routes extends Component {

	render() {
		return (
			<Switch>
				<Route path={'/products'} component={SearchProductsScreenContainer} />
				<Route path={'/orderproducts'} component={OrderProductsScreenContainer} />
				<Route path={'/orderparts'} component={OrderPartsScreenContainer} />
				<Route path={'/parts'} component={SearchPartsScreenContainer} />
				<Route path={'/warehouse'} component={WarehouseScreenContainer} />
				<Route path={'/comingsoon'} component={ComingSoonScreen} />
				<Route path={'/maintenance'} component={MaintenanceManagerScreenContainer} />
				<Route path={'/dailyorderlog'} component={DailyOrderLogScreenContainer} />
				<Route path={'/purchaseorderqueue'} component={PurchaseOrderQueueScreenContainer} />
				<Route path={'/noaccesstoifr'} component={NoAccessToIFRScreenContainer} />
				<Route path={'/clearance'} component={ClearanceScreenContainer}/>
				<Route path={'/maintenanceview'} component={MaintenanceViewScreenContainer} />
				<Route path={'/transfer'} component={TransferScreenContainer} />
			</Switch>
		);
	}
}
export default Routes;