import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ExportToExcelButton from '../../formelements/ExportToExcelButton';
import { DataGridContainer } from '@rtg/shared-react-components';
import searchDailyOrdersResultsGridColumnDefs from './SearchDailyOrdersResultsGridColumnDefs';
import { withWindowSizeListener } from 'react-window-size-listener';
import store from '../../../configureStore';
export const GRID_NAME = 'SearchDailyOrdersResultsGrid';
class SearchDailyOrdersResultsPanel extends Component {
	constructor(props){
		super(props);
		this.handleExportToExcelClicked = this.handleExportToExcelClicked.bind(this);
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
		if(this.props.searchDailyOrdersResultsGridRef.current 
            && this.props.searchDailyOrdersResultsGridRef.current.api 
            && this.props.searchDailyOrdersResultsGridRef.current.api.sizeColumnsToFit)
			this.props.searchDailyOrdersResultsGridRef.current.api.sizeColumnsToFit();
	} 
	handleExportToExcelClicked(excelDataSet){
		if(this.props.handleExportToExcelClicked
            && this.props.searchDailyOrdersResultsGridRef
            && this.props.searchDailyOrdersResultsGridRef.current){
			this.props.handleExportToExcelClicked(excelDataSet);
		}
	}
	getExcelDataSet(){
		if(this.props.getExcelDataSet 
			&& (this.props.searchDailyOrdersResultsGridRef && this.props.searchDailyOrdersResultsGridRef.current)){
			return this.props.getExcelDataSet(this.props.searchDailyOrdersResultsGridRef.current);
		}
	}
	getPdfCellIsHidden(params){
		return params.data.HASPDF === 0;
	}
	onPdfCellClicked(params){
		if(this.props.onPdfCellClicked){
			this.props.onPdfCellClicked(params);
		}
	}
	getFormattedColumnValue(params){
		if(this.props.getFormattedColumnValue){
			return this.props.getFormattedColumnValue(params);
		}
	}
	getColumnValue(params){
		if(this.props.getColumnValue){
			return this.props.getColumnValue(params);
		}
	}
	render() {
		const resultCount = this.props.rowData ? this.props.rowData.length : 0;
		const height = this.props.windowSize.windowHeight - 105;
		return (
			<Card className={'h-100 w-100'}>
				<Card.Header>
					<Container className={'h-100'} fluid={true}>
						<Row className={'py-1 px-0'} noGutters={true}>
							<Col xs={12} sm={12} md={12} lg={8} xl={9}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Results Found: { resultCount }</h5>
							</Col>
							<Col xs={12} sm={12} md={12} lg={4} xl={3} className={'pr-1 mb-1'}>
								<ExportToExcelButton
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'exportToExcel'} 
									onClick={this.handleExportToExcelClicked}
									excelDataSetName={'DailyOrdersReportColumns'}
									text='Export to Excel' 
									getExcelDataSet={this.getExcelDataSet.bind(this)}
									fill
								/>
							</Col>
						</Row>
					</Container>
				</Card.Header>
				<div className={'w-100'}>
					<DataGridContainer
						screenId={this.props.screenId} 
						panelId={this.props.panelId}
						fieldId={GRID_NAME}
						forwardRef={this.props.searchDailyOrdersResultsGridRef} 
						defaultColDef={{
							resizable: true, 
							sortable: true, 
							valueGetter: this.getColumnValue.bind(this), 
							valueFormatter: this.getFormattedColumnValue.bind(this)
						}}
						columnDefs={searchDailyOrdersResultsGridColumnDefs(
							this.props.screenId, 
							this.props.panelId, 
							GRID_NAME, 
							this.onPdfCellClicked.bind(this), 
							this.getPdfCellIsHidden.bind(this))}
						rowData={this.props.rowData}
						onGridReady={this.props.onSearchDailyOrdersResultsGridReady}
						height={height + 'px'}
						showColumnsPanel
						store={store}
					/>
				</div>
			</Card>
		);
	}
}

SearchDailyOrdersResultsPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	onLoad: PropTypes.func,
	rowData: PropTypes.array,
	searchDailyOrdersResultsGrid: PropTypes.any,
	onSearchDailyOrdersResultsGridReady: PropTypes.func,
	getExcelDataSet: PropTypes.func,
	onPdfCellClicked: PropTypes.func,
	getFormattedColumnValue: PropTypes.func,
	getColumnValue: PropTypes.func
};

export default withWindowSizeListener(SearchDailyOrdersResultsPanel);
