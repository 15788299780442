import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SizeMe } from 'react-sizeme';
import * as numberUtilities from '../../../utilities/numberUtilities';
import ExportToExcelButton from '../../formelements/ExportToExcelButton';
import SuggestedOrdersDetailsTabContainer from './SuggestedOrdersDetailsTabContainer';
import SuggestedOrdersOrdersTabContainer from './SuggestedOrdersOrdersTabContainer';
import {
	ButtonContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import * as constants from '../../../constants';

class SuggestedOrdersPanel extends Component {
	onCalculatePOsClicked(){
		if(this.props.onCalculatePOsClicked)
			this.props.onCalculatePOsClicked(this.ordersGridRef.api);
	}
	onResetValuesClicked(){
		if(this.props.onResetValuesClicked)
			this.props.onResetValuesClicked();
	}
	getExcelDataSet(){
		if(this.props.getExcelDataSet 
			&& ((this.ordersGridRef)
				|| (this.detailsGridRef))){
			return this.props.getExcelDataSet(this.ordersGridRef, this.detailsGridRef);
		}
	}
	onTabChanged(key){
		if(this.props.onTabChanged)
			this.props.onTabChanged(key);
	}
	componentDidMount(){
		if(this.props.onLoad)
			this.props.onLoad();
	}
	onMaximizeCubeChecked(newValue){
		if(this.props.onMaximizeCubeChecked){
			this.props.onMaximizeCubeChecked(newValue);
		}
	}
	onExportToExcelClicked(dataset){
		if(this.props.onExportToExcelClicked){
			this.props.onExportToExcelClicked(dataset);
		}
	}
	getComponent = (height) => {
		let clientHeight = 0;
		if(this.headerRef){
			clientHeight = this.headerRef?.clientHeight === undefined ? 0 : this.headerRef.clientHeight;
		}
		const tabHeight = (height - clientHeight - constants.navTabsHeight);
		return (
			<Container fluid={true} className={'h-100 m-0 p-0'} style={{minHeight:'150px'}}>
				<Card className={'h-100 mb-2'} style={{overflow:'hidden', minHeight: '150px'}}>
					<Card.Header ref={(ref) => {this.headerRef = ref;}}>
						<Row className={'p-0 m-0'} noGutters={true}>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Suggested Orders</h5>
							</Col>
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									&nbsp;
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1'}>
								<ExportToExcelButton
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'exportExcel'} 
									text='Export to Excel'
									disabled={this.props.disablePanel}
									excelDataSetName={'SuggestedOrders'}
									getExcelDataSet={this.getExcelDataSet.bind(this)}
									fill
									onClick={this.onExportToExcelClicked.bind(this)}	
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'resetValues'} 
									text='Reset Values'
									disabled={this.props.disablePanel}
									onClick={this.onResetValuesClicked.bind(this)}
									fill
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'calculatePOs'} 
									text='Calculate POs'
									disabled={this.props.disablePanel || this.props.disableCalculatePOsButton}
									onClick={this.onCalculatePOsClicked.bind(this)} 
									fill
									store={store}
								/>
							</Col>
						</Row>
					</Card.Header>
					<Row noGutters={true} className={'h-100'}>
						<Col className={'p-0 m-0 h-100'} style={{overflow:'hidden', backgroundColor:'gainsboro'}}>
							<Tabs 
								style={{position:'relative', left: '10px'}} 
								onSelect={this.onTabChanged.bind(this)}>
								<Tab 
									eventKey={'Orders'}
									title={'Orders'} 
									style={{position:'relative', top:'10px', height: (tabHeight + 'px')}}>
									<SuggestedOrdersOrdersTabContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										forwardRef={(ref) => { this.ordersGridRef = ref; }}
										excludeRatios={this.props.excludeRatios}
										detailsGridRef={this.detailsGridRef}
									/>
								</Tab>
								<Tab 
									eventKey={'Details'} 
									title={'Details'} 
									style={{position:'relative', top:'10px', height: (tabHeight + 'px')}}>
									<SuggestedOrdersDetailsTabContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										forwardRef={(ref) => { this.detailsGridRef = ref; }}
									/>
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</Card>
			</Container>
		);
	};
	render() {
		const { doNotMonitorSize } = this.props;
		//SizeMe does not work in Jest unit tests...so, setting the "doNotMonitorSize" will render it without it
		return doNotMonitorSize 
			? this.getComponent(500) 
			: (
				<SizeMe monitorHeight={true}> 
					{({size}) => {
						const height = numberUtilities.getNumberOrDefault(size.height, 150);
						return (
							this.getComponent(height)
						);}}
				</SizeMe>
			);
	}
}

SuggestedOrdersPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	handleOrderChanged: PropTypes.func,
	excludeRatios: PropTypes.bool,
	onDescriptionColumnExpandCollapseClicked: PropTypes.func,
	showDetailedDescription: PropTypes.bool,
	compareDescriptions: PropTypes.func,
	getIsPrimary: PropTypes.func,
	getIsCellHidden: PropTypes.func,
	getColumnValue: PropTypes.func,
	onCalculatePOsClicked: PropTypes.func,
	onResetValuesClicked: PropTypes.func,
	disablePanel: PropTypes.bool,
	getExcelDataSet: PropTypes.func,
	tab: PropTypes.string,
	handleSelectAllChanged: PropTypes.func,
	handleSuggestedOrdersDetailGridCheckBoxChanged: PropTypes.func,
	onSuggestedOrdersDetailGridReady: PropTypes.func,
	onMaximizeCubeChecked: PropTypes.func,
	disableCalculatePOsButton: PropTypes.bool
};
export default SuggestedOrdersPanel;