import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { 
	ButtonContainer,
	DataGridContainer 
} from '@rtg/shared-react-components';
import store from '../../../../configureStore';

class ViewPODialog extends Component {
	constructor(props) {
		super(props);
		this.getPath = this.getPath.bind(this);
		this.dataGrid = React.createRef();
		this.handleCloseClicked = this.handleCloseClicked.bind(this);
	}
	componentDidMount() {
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	getPath(fieldKey) {
		if (fieldKey) {
			return [...this.props.path, fieldKey];
		}
		else {
			return this.props.path;
		}
	}
	handleCancelClicked() {
		if (this.props.handleCancelClicked)
			this.props.handleCancelClicked(() => {
				if (this.props.onDialogClosed) {
					this.props.onDialogClosed(true);
				}
			});
	}
	handleCloseClicked() {
		if (this.props.handleCloseClicked) {
			this.props.handleCloseClicked(() => {
				if (this.props.onDialogClosed) {
					this.props.onDialogClosed(true);
				}
			});
		}
	}
	handleOkClicked() {
		if (this.props.handleOkClicked) {
			this.props.handleOkClicked(() => {
				if (this.props.onDialogClosed) {
					this.props.onDialogClosed(true);
				}
			});
		}
	}
	onGridReady(obj) {
		if (this.props.onGridReady) {
			this.props.onGridReady(obj);
		}
	}
	render() {
		return (
			<Card className="text-center h-100">
				<Card.Header className={'dlgdraggable'}><h4>Purchase Orders</h4></Card.Header>
				<Card.Body>
					<DataGridContainer
						screenId={this.props.screenId}
						panelId={this.props.panelId}
						fieldId='POListResultsGrid'
						forwardRef={this.dataGrid}
						defaultColDef={{ resizable: true, filter: true }}
						rowSelection={'multiple'}
						columnDefs={[
							{ headerName: 'Purchase Order', field: 'purchaseOrderNumber', sortable: true },
							{ headerName: 'Line Item', field: 'purchaseOrderLineItem', sortable: true },
							{ headerName: 'SKU', field: 'sku', sortable: true },
							{ headerName: 'Qty', field: 'orderQuantity', sortable: true }
						]}
						rowData={this.props.rowData}
						onGridReady={this.onGridReady.bind(this)}
						store={store}
					/>
				</Card.Body>
				<Card.Footer>
					<div className={'float-right'}>
						<ButtonContainer
							screenId={this.props.screenId}
							fieldId={'closePopUp'}
							btnId={'closePopUp'}
							text='Close'
							onClick={this.handleCloseClicked.bind(this)}
							store={store}
						/>
					</div>
				</Card.Footer>
			</Card>
		);
	}
}
ViewPODialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	handleCancelClicked: PropTypes.func,
	handleOkClicked: PropTypes.func,
	handleCloseClicked: PropTypes.func,
	polist: PropTypes.string.isRequired,
	onDialogClosed: PropTypes.func,
	path: PropTypes.array.isRequired,
	closePopup: PropTypes.any,
	rowData: PropTypes.array,
	onGridReady: PropTypes.func,
	onBeforeLoad: PropTypes.func
};

export default ViewPODialog;