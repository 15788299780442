import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../../actions/showDialogAction';
import OkCancelDialogContainer from '../../../components/dialogs/OkCancelDialogContainer';
import * as constants from '../../../constants';
import * as gridUtilities from '../../../utilities/gridUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as orderUtilities from './OrderPartScreenUtilities';
import SuggestedOrdersPanel from './SuggestedOrdersPanel';
import rpcAction from '../../../actions/rpcAction';
class SuggestedOrdersPanelContainer extends Component {
	render() {
		return (
			<SuggestedOrdersPanel {...this.props}
				disablePanel={this.props.disableSuggestedOrdersPanel}
			/>
		);
	}
}

const mapStateToProps = (_state, props) => {
	let resultsCount = 0 ;
	const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], undefined);
	const excludeRatios = storeUtilities.getValue([props.screenId, 'excludeRatios']);
	const tab = storeUtilities.getValue([props.screenId, 'suggestedOrdersTabShown']);
	const disableCalculatePOsButton = storeUtilities.getValue([props.screenId, 'totalOrderQty'], 0) === 0;

	if(orderLinesRowData && orderLinesRowData.length > 0) {
		resultsCount = orderLinesRowData.length;
	}
	return {
		excludeRatios,
		tab,
		disableSuggestedOrdersPanel: resultsCount === 0,
		disableCalculatePOsButton
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'suggestedOrdersTabShown'], 'Orders', true);
		},
		onCalculatePOsClicked: (gridApi) => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'SuggestedPOs']);
			orderUtilities.updateOrderLinesInStore(dispatch, gridApi, [props.screenId, 'OrderLines']);
			getPurchaseOrdersCalc(dispatch, gridApi, props.screenId);
		},
		onResetValuesClicked: () => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'disableSuggestedPOsPanel'], true);
			const rowData = cloneDeep(storeUtilities.getValue([props.screenId, 'OrderLinesRowDataOriginal'], []));
			rowData.forEach((row)=>{
				const orderLine = orderUtilities.createOrderLineFromJsonResult(row);
				row['ORDERLINE'] = orderLine;
			});
			if(rowData){
				window.setTimeout(() => {
					storeUtilities.updateValue(dispatch, [props.screenId, 'OrderLinesRowData'], rowData);
				}, 100);
			}
		},
		getExcelDataSet: (ordersGridRef, detailsGridRef) => {
			const currentTab = storeUtilities.getValue([props.screenId, 'suggestedOrdersTabShown'], 'Orders');
			const gridRef = currentTab.toLowerCase() === 'orders' ? ordersGridRef : detailsGridRef;
			const gridName = currentTab.toLowerCase() === 'orders' ? 'SuggestedOrdersOrdersGrid' : 'SuggestedOrdersDetailsGrid'; 
			const primaryNode = orderUtilities.getPrimaryNodeFromGrid(gridRef.api);
			const orderLines = orderUtilities.getOrderLinesFromGrid(gridRef.api);
			const rowData = currentTab.toLowerCase() === 'orders' 
				? orderUtilities.getSuggestedOrdersOrdersJsonRowData(props.screenId, orderLines, (primaryNode && primaryNode.rowIndex)? primaryNode.rowIndex : undefined) 
				: orderUtilities.getSuggestedOrdersDetailsJsonRowData(props.screenId, orderLines);
			const excludeColumns = currentTab.toLowerCase() === 'orders' ? gridUtilities.getGridHiddenColumns(gridRef, ['ORDERLINE', 'STAR', 'spacer']) : gridUtilities.getGridHiddenColumns(gridRef);
			if(rowData){
				return orderUtilities.getSuggestedOrdersGridExcelExportDataSet(gridRef, gridName, rowData, excludeColumns);
			}
		},
		onTabChanged: (key) => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'suggestedOrdersTabShown'], key);
		},
		onMaximizeCubeChecked: (newValue) => {
			const selectedCategory = storeUtilities.getValue([props.screenId, 'merchGroup']);
			if(selectedCategory === 'Special Order' && newValue === true){
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						screenId={ props.screenId + '_informationDialog'} 
						title={'Information'}
						message={'It is not possible to Maximize Cube when \'Special Order\' is selected as the Category.'}
						hasCancelButton={false}
						handleOkClicked={()=>{
							storeUtilities.updateValue(dispatch, [props.screenId, 'maximizeCube'], false);
						}}
					>
					</OkCancelDialogContainer>, 
					200, 
					300, 
					()=>{}));
			}
		},
		onExportToExcelClicked: (dataset) => {
			orderUtilities.exportToExcelClicked(dispatch, props.screenId, dataset);
		}
	};
};

const getPurchaseOrdersCalc = (dispatch, gridApi, screenId) => {
	const skuList = [];
	gridApi.forEachNode(node => {
		const orderLine = node.data.ORDERLINE;
		const sku = sharedUtilities.pruneObject({
			'additionalDaysToStock': orderLine.ADDITIONALDAYSTOSTOCK,
			'additionalDaysToStockQuantity': orderLine.ADDITIONALDAYSTOSTOCKQUANTITY,
			'availableQuantity': orderLine.AVAILABLEQUANTITY,
			'averageDailyShipments': orderLine.AVERAGEDAILYSHIPMENTS,
			'averageWeeklyShipments': orderLine.AVERAGEWEEKLYSHIPMENTS,
			'calculatedOrderQuantity': orderLine.CALCULATEDORDERQUANTITY,
			'quantity': orderLine.CALCULATEDORDERQUANTITY,
			'daysToNextOrder': orderLine.DAYSTONEXTORDER,
			'daysToNextOrderQuantity': orderLine.DAYSTONEXTORDERQUANTITY,
			'endOfLeadtimeAvailable': orderLine.ENDOFLEADTIMEAVAILABLE,
			'inboundQuantity': orderLine.INBOUNDQUANTITY,
			'partsDescription': orderLine.PARTDESCRIPTION,
			'sku': orderLine.PARTSKU,
			'pendingShipments': orderLine.PENDINGSHIPMENTS,
			'productionDays': orderLine.PRODUCTIONDAYS,
			'quantityOnHand': orderLine.QUANTITYONHAND,
			'replenishmentStock': orderLine.REPLINISHMENTSTOCK,
			'thresholdQuantity': orderLine.THRESHOLDQUANTITY,
			'totalShipments': orderLine.TOTALSHIPMENTS,
			'transitDays': orderLine.TRANSITDAYS,
			'weeksOfStockInbound': orderLine.WEEKSOFSTOCKINBOUND,
			'weeksOfStockOnHand': orderLine.WEEKSOFSTOCKONHAND
		}, true);
		skuList.push(sku);
	});
	const req =
	{
		vendor: storeUtilities.getValue([screenId,'vendor'], ''),
		skuList: skuList,
		prodTime: storeUtilities.getValue([screenId,'prodTime'], 0),
		transitTime: storeUtilities.getValue([screenId,'transitTime'], 0)
	};
	dispatch(rpcAction({
		args: req,
		nodeRoute: constants.nodeRoutes.IFRServiceNET,
		endpoint: '/Parts/Calculation',
		method: 'POST',
		showLoadingMask: true,
		callback: (data) => {
			if(data !== undefined){
				if(data.Errors !== undefined && data.Errors.length > 0){
					let i = 0;
					const errors = data.Errors.map(error => {
						return(<li key={i++}>{error.Message} Detail: {error.Detail}</li>);
					});
					dispatch(showDialogAction(
						<OkCancelDialogContainer 
							screenId={ screenId + '_informationDialog'} 
							title={'Information'}
							message={'Calculate POs Errors'}>
							<ul>{errors}</ul>
						</OkCancelDialogContainer>, 
						250, 
						400, 
						()=>{}));
				}
				else if (data){
					data.PurchaseOrders = formatRestResults(data);
					data.PurchaseOrders.forEach(row=>{insertOrderLine(screenId, row);});
					storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'rowData'], data.PurchaseOrders, true);
					storeUtilities.updateValue(dispatch, [screenId, 'disableSuggestedPOsPanel'], false);
					mapRowDataToSuggestedPOs(dispatch, screenId, data.PurchaseOrders);
				}
			}
			else{
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						screenId={ screenId + '_informationDialog'} 
						title={'Information'}
						message={'Calculate POs Errors'}>
						<ul><li>No data returned by calculate POs call</li></ul>
					</OkCancelDialogContainer>, 
					250, 
					400, 
					()=>{}));
			}
		}
	}));
};

const formatRestResults = (purchaseOrders) => {
	const pos = [];
	for(let poIdx = 0; poIdx < purchaseOrders.length; poIdx++ ){
		for(let skuIdx = 0; skuIdx < purchaseOrders[poIdx].skus.length; skuIdx++){
			pos.push({
				PO: poIdx+1,
				SKU: purchaseOrders[poIdx].skus[skuIdx].sku,
				QTY: purchaseOrders[poIdx].skus[skuIdx].quantity,
				DESCR: purchaseOrders[poIdx].skus[skuIdx].partsDescription,
				CUBES: purchaseOrders[poIdx].cubeTotal,
				SHIPDATE: moment(purchaseOrders[poIdx].shipDate).format('MM/DD/YYYY'),
				ARRIVALDATE: moment(purchaseOrders[poIdx].arrivalDate).format('MM/DD/YYYY'),
				NEEDBYDATE: moment(purchaseOrders[poIdx].arrivalDate).format('MM/DD/YYYY')
			});
		}
	}
	return pos;
};

const insertOrderLine = (screenId, row) => {
	const orderLines = storeUtilities.getValue([screenId, 'OrderLines']);
	const sku = Number(row.SKU);
	if(Array.isArray(orderLines)){
		orderLines.forEach(orderLine => {
			if(sku === orderLine.SKU){
				row['ORDERLINE'] = orderLine;
			}
		});
	}
};
/**
 * For each data for suggested POs from calculate POs or balanced ordering, map that datarow into PO in Redux store expected format: "SuggestedPOs.PurchaseOrders"
 * @param {Object} dispatch - redux dispatch Object
 * @param {string} screenId - Screen ID where this component will appear
 * @param {Array} data - Array of Purchase Orders
 */
const mapRowDataToSuggestedPOs = (dispatch, screenId, data) => {
	var currentPoNum = 0;
	var suggestedPosTotalCost = 0;
	var poTotalCost = 0;
	var rowData = [];
	//loop over each datarow and convert into PurchaseOrder Map
	data.forEach((row) => {
		if(row.PO !== currentPoNum){
			poTotalCost = 0;
			//update PO count, set to current PO row
			currentPoNum = row.PO;
			//dispatch and create new PO
			//poNumber
			const shipDate = moment(row.SHIPDATE, 'MM/DD/YYYY').toDate();
			const arrivalDate = moment(row.ARRIVALDATE, 'MM/DD/YYYY').toDate();
			storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'poNumber'], row.PO, true);
			//deleted
			storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'deleted'], false, true);
			//cubes
			storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'cubes' ], row.CUBES, true);
			//shipdate
			storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'shipDate'], shipDate, true);
			//arrivaldate
			storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'arrivalDate'], arrivalDate, true);
			//deliverTo, default deliver to: to warehouse used for calc of POs
			storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'deliverTo' ], storeUtilities.getValue([screenId, 'whsNum']), true);
			//row data
			//reset
			rowData = [];
		}
		else{
			//row data grab current
			Object.assign(rowData, storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'rowData'], []));
		}
		//add FIRSTCOST to row
		row.FIRSTCOST = 0;
		//add LINE cost to row
		row.LINE = row.QTY * row.FIRSTCOST;
		//add new row to array in store
		rowData.push(row);
		storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'rowData'], rowData, true);
		//add line cost to poTotalCost
		poTotalCost += row.LINE;
		storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', row.PO, 'totalCost'], poTotalCost, true);
		//add line cost to suggestedPosTotalCost
		suggestedPosTotalCost += row.LINE;
	});
	//set initial suggestedPOs total cost in store
	storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'totalCost'], suggestedPosTotalCost, true);
	//set purchase orders count
	storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'purchaseOrders', 'count'], currentPoNum, true);
};

SuggestedOrdersPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	onDescriptionColumnExpandCollapseClicked: PropTypes.func,
	onMaximizeCubeChecked: PropTypes.func
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedOrdersPanelContainer);
export default connectedComponent;