import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withTooltip from '../../../higherordercomponents/WithTooltip';
import NotesIcon from '../../formelements/ImageComponents/NotesIcon';
const DEFAULT_HEIGHT = 25;
const DEFAULT_WIDTH = 25;

const GRAY = '#A9A9A9';
const LIGHTBLUE = '#80bdff';

class EditPurchaseOrderIconButton extends Component {
	constructor(props){
		super(props);
		this.onTooltipVisibleChange = this.onTooltipVisibleChange.bind(this);
		this.timeoutHandler = undefined;
		this.state = {
			pencilColor: GRAY,
			disabled: this.props.disabled,
			cursor: 'auto',
			showTooltip: false
		};
	}
	onTooltipVisibleChange(visible){
		if(visible === true){
			const _this = this;
			this.timeoutHandler = window.setTimeout(()=>{
				if(_this && _this.setState){
					_this.setState({showTooltip:false});
				}
			}, 3000);
		}
		this.setState({showTooltip: visible});
	}
	componentWillUnmount(){
		if(this.timeoutHandler){
			window.clearTimeout(this.timeoutHandler);
			this.timeoutHandler = undefined;
		}
	}
	onMouseOver(){
		if(this.state.disabled === true)
			return;
		this.setState({pencilColor: LIGHTBLUE, cursor: 'pointer'});
	}
	onMouseOut(){
		if(this.state.disabled === true)
			return;
		this.setState({pencilColor: GRAY, cursor: 'auto'});
	}
	onMouseDown(){
		if(this.state.disabled === true)
			return;
		this.setState({pencilColor: GRAY});
	}
	onMouseUp(){
		if(this.state.disabled === true)
			return;
		this.setState({pencilColor: LIGHTBLUE});
	}
	onClick(){
		if(this.props.onClick)
			this.props.onClick();
	}
	render() {
		const height = this.props.height ? this.props.height : DEFAULT_HEIGHT;
		const width = this.props.width ? this.props.width : DEFAULT_WIDTH;
		const editSvg = (
			<NotesIcon
				screenId={this.props.screenId}
				panelId={this.props.panelId}
				fieldId={this.props.fieldId}
				height={height + 'px'}
				width={width + 'px'}
				fill={this.props.tooltips !== undefined && this.props.tooltips.length === 1 && this.props.tooltips[0] !== undefined ? GRAY : 'none'}
				pencilColor={this.state.pencilColor}
			/>
		);
		return 	<div
			style={{left:this.props.left, top:this.props.top, cursor: this.state.cursor, position:'relative', height: (height + 2) + 'px', width: (width + 2) + 'px', overflow:'hidden'}}
			onClick={this.onClick.bind(this)}
			onMouseOver={this.onMouseOver.bind(this)} 
			onMouseOut={this.onMouseOut.bind(this)} 
			onMouseDown={this.onMouseDown.bind(this)}
			onMouseUp={this.onMouseUp.bind(this)}
		>{editSvg}</div>;
	}
}

EditPurchaseOrderIconButton.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	height: PropTypes.number,
	width: PropTypes.number,
	top: PropTypes.string,
	left: PropTypes.string,
	tooltips: PropTypes.arrayOf(PropTypes.string).isRequired,
	tooltipPlacement: PropTypes.string
};

export default withTooltip(EditPurchaseOrderIconButton);
