import {
	ButtonContainer,
	CheckBoxCellRendererContainer,
	DataGridContainer,
	TextCellRendererContainer,
	withID
} from '@rtg/shared-react-components';
import deepEqual from 'deep-equal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';
import store from '../../../../configureStore';
import AltSkuCellEditorContainer from '../datagridcomponents/AltSkuCellEditorContainer';
import AltSkuCellRendererContainer from '../datagridcomponents/AltSkuCellRendererContainer';
import * as altSkuUtilities from './AltSkuUtilities';
const gridName = 'SelectedSkusGrid';

class MultiSkuAltSkuDialog extends Component { 
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onLoadSkuClicked = (params) => {
		if(this.props.onLoadSkuClicked){
			this.props.onLoadSkuClicked(params);
		}
	};
	onAltSkuChanged = (params) => {
		if(this.props.onAltSkuChanged){
			this.props.onAltSkuChanged(params);
		}
	};
	onOkClicked = () => {
		if(this.props.onOkClicked){
			this.props.onOkClicked(this.gridApi);
		}
	};
	onCancelClicked = () => {
		if(this.props.onCancelClicked){
			this.props.onCancelClicked(this.gridApi);
		}
	};
	onChkCombinationChanged = (gridApi, rowNode, value) => {
		if(this.props.onChkCombinationChanged){
			this.props.onChkCombinationChanged(gridApi, rowNode, value);
		}
	};
	onChkSubstituteChanged = (gridApi, rowNode, value) => {
		if(this.props.onChkSubstituteChanged){
			this.props.onChkSubstituteChanged(gridApi, rowNode, value);
		}
	};
	onGridReady = (params) => {
		this.gridApi = params.api;
	};
	onBtnLoadAllAltSkusClicked = () => {
		if(this.props.onBtnLoadAllAltSkusClicked && this.gridApi){
			this.props.onBtnLoadAllAltSkusClicked(this.gridApi);
		}
	};
	onBtnSetAllToCombinationClicked = () => {
		if(this.props.onBtnSetAllToCombinationClicked && this.gridApi){
			this.props.onBtnSetAllToCombinationClicked(this.gridApi);
		}
	};
	onBtnSetAllToSubstituteClicked = () => {
		if(this.props.onBtnSetAllToSubstituteClicked && this.gridApi){
			this.props.onBtnSetAllToSubstituteClicked(this.gridApi);
		}
	};
	onBtnClearAllClicked = () => {
		if(this.props.onBtnClearAllClicked && this.gridApi){
			this.props.onBtnClearAllClicked(this.gridApi);
		}
	};
	componentDidUpdate(prevProps){
		if(this.gridApi && (prevProps.altSkuIsValid !== this.props.altSkuIsValid || !deepEqual(prevProps.altOrderLine, this.props.altOrderLine))){
			const nodes = [];
			this.gridApi.forEachNode((node)=> {
				nodes.push(node);
			});
			this.gridApi.refreshCells({rowNodes: nodes, columns:['CHKSUBSTITUTE', 'CHKCOMBINATION'], force: true});
		}	
	}
	getColumnValue(params){
		if(this.props.getColumnValue){
			return this.props.getColumnValue(params);
		}
	}
	handlekeyDown = (e) => {
		if(e.ctrlKey && e.which === 65) {
			e.preventDefault();
			this.gridApi.selectAll();
		}
	};
	getAltSkuIsInvalid = (params) => {
		return (params?.data?.ALTSKU?.length !== 8) || params?.data?.PRODUCT_EXISTS === false;
	};
	getAltSkuValidationTooltips = (params) => {
		const tooltips = [];
		if(params?.data?.ALTSKU?.trim && params?.data?.ALTSKU?.trim() === ''){
			tooltips.push('SKU is required');
		}
		else if(params?.data?.ALTSKU?.length !== 8){
			tooltips.push('SKU length is invalid');
		}
		if(params?.data?.PRODUCT_EXISTS === false){
			tooltips.push(params?.data?.ALTSKU + ' does not exist');
		}
		return tooltips;
	};
	getSelectedSkuPopoverContent = (params) => {
		const returnValue = params?.data?.ORIGINAL_ORDERLINE !== undefined
			? (    								
				<DataGridContainer 
					className={'h-100'}
					screenId={this.props.screenId}
					panelId={this.props.panelId}
					fieldId={'selectedSkuPopoverGrid'}
					columnDefs={[
						{
							headerName: 'Label',
							field: 'LABEL',
							colId: 'LABEL',
							width: 162
						},
						{
							headerName: 'Value',
							field: 'VALUE',
							colId: 'VALUE',
							type: 'numericColumn',
							width: 84
						}
					]}
					hideHeaders
					sizeColumnsToFit
					rowData={altSkuUtilities.getRowData(params?.data?.ORIGINAL_ORDERLINE)}
					store={store}
				/>)
			: undefined;
		return returnValue;
	};
	getAltSkuPopoverContent = (params) => {
		const returnValue = this.altSkuIsValidAndLoaded(params)
			? (
				<DataGridContainer 
					className={'h-100'}
					screenId={this.props.screenId}
					panelId={this.props.panelId}
					fieldId={'altSkuPopoverGrid'}
					columnDefs={[
						{
							headerName: 'Label',
							field: 'LABEL',
							colId: 'LABEL',
							width: 162
						},
						{
							headerName: 'Value',
							field: 'VALUE',
							colId: 'VALUE',
							type: 'numericColumn',
							width: 84
						}
					]}
					hideHeaders
					sizeColumnsToFit
					noRowsMessage={''}
					rowData={altSkuUtilities.getRowData(params.data.ALT_ORDERLINE)}
					store={store}
				/>
			) : (
				this.getInstructionsPopoverContent(params)
			);
		return returnValue;
	};
	getCBPopoverContent = (params, type) => {
		const isValidAndLoaded = this.altSkuIsValidAndLoaded(params);
		const returnValue = isValidAndLoaded
			? (
				<Container fluid={true} style={{height:'205px'}}>
					<Row style={{height: '142px'}}>
						<Col>
							<DataGridContainer 
								className={'h-100'}
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'altSku' + type + 'PopoverGrid'}
								columnDefs={[
									{
										headerName: 'Label',
										field: 'LABEL',
										colId: 'LABEL',
										width: 165
									},
									{
										headerName: 'Value',
										field: 'VALUE',
										colId: 'VALUE',
										type: 'numericColumn',
										width: 75
									}
								]}
								hideHeaders
								rowData={altSkuUtilities.getCalcRowData(params.data.ORIGINAL_ORDERLINE, params.data.ALT_ORDERLINE, type === 'Substitution', type === 'Combination')}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'m-0 p-0'} style={{height:'58px'}}>
						<Col className={'m-0 p-0'}>
							<DataGridContainer 
								className={'h-100'}
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'resultsGrid'}
								columnDefs={[
									{
										headerName: 'Label',
										field: 'LABEL',
										colId: 'LABEL',
										width: 165
									},
									{
										headerName: 'Value',
										field: 'VALUE',
										colId: 'VALUE',
										type: 'numericColumn',
										width: 75
									}
								]}
								hideHeaders
								rowData={altSkuUtilities.getResultRowData(params.data.ORIGINAL_ORDERLINE, params.data.ALT_ORDERLINE, type === 'Substitution', type === 'Combination')}
								store={store}
							/>
						</Col>
					</Row>
				</Container>)
			: undefined;
		return returnValue;
	};
	getInstructionsPopoverContent = (params) => {
		const altSkuIsCorrectLength = params?.data?.ALTSKU?.length === 8;
		const message = altSkuIsCorrectLength ? 'Click Load SKU' : 'Enter a SKU and click Load SKU';
		const returnValue = (
			<div className={'h-100'}>
				{message}
			</div>
		);
		return returnValue;
	};
	altSkuIsValidAndLoaded = (params) => {
		const altSkuIsInvalid = this.getAltSkuIsInvalid(params);
		const returnValue = altSkuIsInvalid === false && params?.data?.ALT_ORDERLINE !== undefined;
		return returnValue;
	};
	getAltSkuPopoverStyle = (params) => {
		const style = {border: '1px solid gainsboro'};
		if(this.altSkuIsValidAndLoaded(params)) {
			style.height = '145px';
			style.width = '250px';
		}
		else{
			style.height = '20px';
			style.padding = '2px';
		}
		return style;
	};
	getCBPopoverStyle = (params) => {
		const validAndLoaded = this.altSkuIsValidAndLoaded(params);
		const style = {
			border: '1px solid gainsboro'
		};
		if(validAndLoaded) {
			style.height = '205px';
			style.width = '275px';
		}
		return style;
	};
	getSelectedSkuPopoverStyle = () => {
		return {border: '1px solid gainsboro', height:'145px', width: '250px' };
	};
	onSelectionChanged = (params) => {
		return this.props.onSelectionChanged ? this.props.onSelectionChanged({gridApi: params.api}) : undefined;
	};
	render() {
		return (
			<Card className={'h-100'} id={this.props.id} data-testid={this.props.id}>
				<Card.Header className={'dlgdraggable'}>
					<Row>
						<Col xs={10} sm={10} md={10} lg={10} xl={10}>
							<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Alt-SKU</h5>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							<ButtonContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnLoadAllAltSkus'}
								text={'Load all Alt-SKUs'}
								store={store}
								fill
								onClick={this.onBtnLoadAllAltSkusClicked}
							/>
						</Col>
					</Row>
				</Card.Header>
				<Row noGutters className={'h-100'}>
					<Col className={'h-100'} xs={10} sm={10} md={10} lg={10} xl={10} onKeyDown={this.handlekeyDown}>
						<DataGridContainer 
							screenId={this.props.screenId}
							panelId={this.props.panelId}
							fieldId={gridName}
							rowData={this.props.skusRowData}
							sizeColumnsToFit
							store={store}
							suppressClickEdit={true}
							onGridReady={this.onGridReady}
							columnDefs={[
								{
									headerName: '',
									field: 'ORIGINAL_ORDERLINE',
									colId: 'ORIGINAL_ORDERLINE',
									hide: true
								},
								{
									headerName: '',
									field: 'ALT_ORDERLINE',
									colId: 'ALT_ORDERLINE',
									hide: true
								},
								{
									headerName: '',
									field: 'PRODUCT_EXISTS',
									colId: 'PRODUCT_EXISTS',
									hide: true
								},
								{
									headerName: 'SKU',
									field: 'SKU',
									colId: 'SKU',
									width: 80,
									minWidth: 80,
									cellRenderer: TextCellRendererContainer,
									cellRendererParams: {
										gridName,
										screenId: this.props.screenId,
										panelId: this.props.panelId,
										getPopoverContent: this.getSelectedSkuPopoverContent,
										popoverPlacement: 'right',
										getPopoverStyle: this.getSelectedSkuPopoverStyle
									}
								},
								{
									headerName: 'Description',
									field: 'VENDORDESCRIPTION',
									colId: 'VENDORDESCRIPTION',
									width: 150,
									minWidth: 150
								},	
								{
									headerName: 'Alt-SKU',
									field: 'ALTSKU',
									colId: 'ALTSKU',
									width: 150,
									minWidth: 150,
									editable: true,
									singleClickEdit: true,
									cellEditor: AltSkuCellEditorContainer,
									cellEditorParams: {
										gridName,
										screenId: this.props.screenId,
										panelId: this.props.panelId,
										getIsHidden: () => false,
										onChange: this.onAltSkuChanged,
										onLoadSkuClicked: this.onLoadSkuClicked,
										getIsInvalid: this.getAltSkuIsInvalid,
										getTooltips: this.getAltSkuValidationTooltips,
										getPopoverContent: this.getAltSkuPopoverContent,
										popoverPlacement: 'right',
										getPopoverStyle: this.getAltSkuPopoverStyle
									},
									cellRenderer: AltSkuCellRendererContainer,
									cellRendererParams: {
										gridName,
										screenId: this.props.screenId,
										panelId: this.props.panelId,
										getIsHidden: () => false,
										onLoadSkuClicked: this.onLoadSkuClicked,
										getIsInvalid: this.getAltSkuIsInvalid,
										getTooltips: this.getAltSkuValidationTooltips,
										getPopoverContent: this.getAltSkuPopoverContent,
										popoverPlacement: 'right',
										getPopoverStyle: this.getAltSkuPopoverStyle
									}
								},					
								{ 
									headerName: 'Substitute',
									field: 'CHKSUBSTITUTE', 
									colId: 'CHKSUBSTITUTE',
									width: 70,
									minWidth: 70,
									resizable: false,
									editable: false,
									cellRenderer: CheckBoxCellRendererContainer, 
									cellRendererParams:
									{
										onChange: this.onChkSubstituteChanged,
										gridName,
										screenId: this.props.screenId,
										panelId: this.props.panelId,
										getDisabled: (params) => {
											return params?.data?.ALT_ORDERLINE === undefined;
										},
										getTooltips: () => [],
										getPopoverContent: (params) => this.getCBPopoverContent(params, 'Substitution'),
										popoverPlacement: 'right',
										getPopoverStyle: this.getCBPopoverStyle
									},
									cellStyle: {textAlign:'center'},
									headerClass: 'ag-center-header'
								},
								{ 
									headerName: 'Combination',
									field: 'CHKCOMBINATION', 
									colId: 'CHKCOMBINATION',
									width: 70,
									minWidth: 70,
									resizable: false,
									cellRenderer: CheckBoxCellRendererContainer, 
									cellRendererParams:
									{
										onChange: this.onChkCombinationChanged,
										gridName,
										screenId: this.props.screenId,
										panelId: this.props.panelId,
										getDisabled: (params) => {
											return params?.data?.ALT_ORDERLINE === undefined;
										},
										getTooltips: () => [],
										getPopoverContent: (params) => this.getCBPopoverContent(params, 'Combination'),
										popoverPlacement: 'right',
										getPopoverStyle: this.getCBPopoverStyle
									},
									cellStyle: {textAlign:'center'},
									headerClass: 'ag-center-header',
								},
							]}
						/>
					</Col>
					<Col xs={2} sm={2} md={2} lg={2} xl={2}>
						<Row className={'pt-2 mx-1'}>
							<Col>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'btnSetAllToSubstitute'}
									text={'Set all to Substitute'}
									store={store}
									fill
									onClick={this.onBtnSetAllToSubstituteClicked}
									disabled={this.props.disableSelectButtons}
								/>
							</Col>
						</Row>
						<Row className={'mx-1 mt-1'}>
							<Col>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'btnSetAllToCombination'}
									text={'Set all to Combination'}
									store={store}
									fill
									onClick={this.onBtnSetAllToCombinationClicked}
									disabled={this.props.disableSelectButtons}
								/>
							</Col>
						</Row>
						<Row className={'mx-1 mt-1'}>
							<Col>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'btnClearAll'}
									text={'Remove all Alt-SKUs'}
									store={store}
									fill
									onClick={this.onBtnClearAllClicked}
									disabled={this.props.disableSelectButtons}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Card.Footer>
					<Row noGutters>
						<Col className={'p-0 mt-0 mb-0 ml-0 mr-1'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnCancel'} 
								text={'Cancel'} 
								buttonClasses={'mb-2'}
								onClick={this.onCancelClicked}
								variant={'light'}
								store={store}
								fill />
						</Col>
						<Col className={'p-0 m-0'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnOK'} 
								text={'Accept Changes and Close'} 
								buttonClasses={'mb-2'}
								onClick={this.onOkClicked}
								store={store}
								fill />
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		);
	}
}
MultiSkuAltSkuDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	parentPanelId: PropTypes.string.isRequired,
	path: PropTypes.array.isRequired,
	onLoadSkuClicked: PropTypes.func,
	onChkCombinationChanged: PropTypes.func.isRequired,
	onChkSubstituteChanged: PropTypes.func.isRequired,
	onBtnSetAllToCombinationClicked: PropTypes.func.isRequired,
	onBtnSetAllToSubstituteClicked: PropTypes.func.isRequired,
	onBtnClearAllClicked: PropTypes.func.isRequired,
	onOkClicked: PropTypes.func,
	onCancelClicked: PropTypes.func,
	getColumnValue: PropTypes.func,
	skusRowData: PropTypes.array.isRequired
};

export default withID(MultiSkuAltSkuDialog);
