import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import React, { Component } from 'react';
import './WithTooltip.css';
const withTooltip = (WrappedComponent) => {
	class WithTooltipHOC extends Component {
		constructor(props){
			super(props);
			this.state = { showTooltip: false };
			this.onVisibleChange = this.onVisibleChange.bind(this);
		}
		getId(){
			return ((this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random())) + '-tooltip';
		}
		onVisibleChange(visible){
			this.setState({showTooltip: visible});
		}
		render(){
			const tooltips = this.props.tooltips ? this.props.tooltips : [];
			let tooltipWidget = undefined; 
			const tooltipWidgetStyle = {maxWidth: this.props.tooltipWidth ? this.props.tooltipWidth : '200px', paddingLeft:'10px', paddingRight:'5px', paddingBottom: '3px'};
			if(tooltips !== undefined && Array.isArray(tooltips)){
				if(tooltips.length > 1){
					const tooltipListItems = tooltips.map(tooltip => {
						return (<li style={tooltipWidgetStyle} key={Math.random()}>{tooltip}</li>);
					});
					tooltipWidget = ( 
						<div style={tooltipWidgetStyle}>
							<ul style={{marginTop: '0px', marginBottom: '0px'}}>{tooltipListItems}</ul>
						</div>);
				}
				else if(tooltips.length === 1){
					tooltipWidget = <div style={tooltipWidgetStyle}>{tooltips[0]}</div>;
				}
			}
			return (
				<Tooltip
					id={this.props.screenId + '_' + this.props.fieldId + '_tooltip'}
					visible={tooltipWidget !== undefined && this.state.showTooltip === true}
					trigger={this.props.tooltipTriggers ? this.props.tooltipTriggers : ['hover','click','focus']}
					onVisibleChange={this.onVisibleChange}
					mouseLeaveDelay={this.props.isInvalid === true ? 3 : 0.1}
					placement={this.props.tooltipPlacement || 'right'}
					overlay={tooltipWidget}
					destroyTooltipOnHide={true}>
					<div className={'m-0 p-0'}>
						<WrappedComponent {...this.props}/>
					</div>
				</Tooltip>);
		}
	}
	WithTooltipHOC.propTypes = {
		screenId: PropTypes.string.isRequired,
		panelId: PropTypes.string,
		fieldId: PropTypes.string.isRequired,
		tooltips: PropTypes.arrayOf(PropTypes.string),
		tooltipPlacement: PropTypes.string,
		isInvalid: PropTypes.bool,
		tooltipTriggers: PropTypes.array,
		tooltipWidth: PropTypes.string
	};
	return WithTooltipHOC;
};
export default withTooltip;