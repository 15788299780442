import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TextBoxContainer from '../../formelements/TextBoxContainer';
import GroupBox from '../../formelements/GroupBox';
import * as validators from '../../../utilities/commonValidators';
import {
	ButtonContainer,
	DatePickerContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import '../../../css/DatePickerOverrides.css';

class SalesHistoryGroupBox extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	startDateChangedHandler = () => {
		if(this.props.startDateChangedHandler){
			this.props.startDateChangedHandler();
		}
	};
	endDateChangedHandler = () => {
		if(this.props.endDateChangedHandler){
			this.props.endDateChangedHandler();
		}
	};
	excludeDatesButtonClickedHandler = () => {
		if(this.props.excludeDatesButtonClickedHandler){
			this.props.excludeDatesButtonClickedHandler(this.props.excludeDatesButtonClickedCallback);
		}
	};
	render() {
		return (
			<GroupBox 
				screenId={this.props.screenId} 
				panelId={this.props.panelId} 
				fieldId={'grpSalesHistory'} 
				title={'Sales History'} 
				style={{margin:'0', padding:'0'}}>
				<Container fluid={true} className={'pt-2 mb-0'}>
					<Row className={'mb-1'}>
						<Col className={'pr-1'}>
							<DatePickerContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={this.props.startDateFieldId}
								overridePath={this.props.startDatePath}
								onChange={this.startDateChangedHandler}
								label={this.props.startDateLabel}
								maxDate={this.props.endDate}
								validators={[
									(args) => validators.isInDateRange({...args, maxDate: this.props.endSalesDate})
								]}
								persistValue={this.props.startDatePersist}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col className={'pr-1'}>
							<DatePickerContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={this.props.endDateFieldId}
								overridePath={this.props.endDatePath}
								onChange={this.endDateChangedHandler}
								label={this.props.endDateLabel}
								minDate={this.props.startDate}
								validators={[
									(args) => validators.isInDateRange({...args, minDate: this.props.startDate})
								]}
								persistValue={this.props.endDatePersist}
								store={store}
							/>
						</Col>
					</Row>
					{ this.props.hasExcludeDates && (
						<Row noGutters={true}>
							<Col className={'d-flex'}>
								<div className={'ml-auto'} style={{position:'relative', right:'-15px', top:'-5px'}}>
									<ButtonContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={this.props.excludeDatesButtonFieldId} 
										variant={'link'}
										text={this.props.excludeDatesButtonText}
										onClick={this.excludeDatesButtonClickedHandler}
										tooltips={this.props.excludeDatesButtonTooltips}
										tooltipWidth={'300px'}
										store={store}
									/>
								</div>
							</Col>
						</Row>
					)}
					<Row className='mb-1'>
						<Col className={'pr-1 mb-1'}>
							<TextBoxContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={this.props.totalDaysTextBoxFieldId}
								overridePath={this.props.totalDaysTextBoxPath}
								type={'number'}
								label={this.props.totalDaysTextBoxLabel} //'Total Days'}
								disabled
							/>
						</Col>
					</Row>
				</Container>
			</GroupBox>
		);
	}
}

SalesHistoryGroupBox.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	
	//startDate props
	startDateFieldId: PropTypes.string.isRequired,
	startDatePath: PropTypes.arrayOf(PropTypes.string).isRequired,
	startDateLabel: PropTypes.string.isRequired,
	startDateChangedHandler: PropTypes.func.isRequired,
	startDatePersist: PropTypes.bool.isRequired,
    
	//endDate props
	endDateFieldId: PropTypes.string.isRequired,
	endDatePath: PropTypes.arrayOf(PropTypes.string).isRequired,
	endDateLabel: PropTypes.string.isRequired,
	endDateChangedHandler: PropTypes.func.isRequired,
	endDatePersist: PropTypes.bool.isRequired,

	//excludeDate props
	excludeDatesButtonFieldId: PropTypes.string.isRequired,
	excludeDatesButtonText: PropTypes.string.isRequired,
	excludeDatesButtonClickedHandler: PropTypes.func.isRequired,
	excludeDatesButtonTooltips: PropTypes.arrayOf(PropTypes.string).isRequired,
	excludeDatesButtonClickedCallback: PropTypes.func.isRequired,

	//totalDays props
	totalDaysTextBoxFieldId: PropTypes.string.isRequired,
	totalDaysTextBoxPath: PropTypes.arrayOf(PropTypes.string).isRequired,
	totalDaysTextBoxLabel: PropTypes.string.isRequired,

	//misc props
	excludedMomentRangesPath: PropTypes.arrayOf(PropTypes.string).isRequired,
	hasExcludeDates: PropTypes.any
};

export default SalesHistoryGroupBox;