//to do: organize this somehow...alphabetically or by some sort of classification?
export const CALL_RPC_ACTION = 'CALL_RPC_ACTION';
export const RPC_ACTION = 'RPC_ACTION';
export const CANCEL_RPC_ACTION = 'CANCEL_RPC_ACTION';
export const GET_RPC_DROPDOWN_OPTIONS_ACTION = 'GET_RPC_DROPDOWN_OPTIONS_ACTION';

export const UPDATE_CURRENT_VALUE_ACTION = 'UPDATE_CURRENT_VALUE_ACTION';
export const UPDATE_DEFAULT_VALUE_ACTION = 'UPDATE_DEFAULT_VALUE_ACTION';
export const UPDATE_DEFAULT_VALUES_ACTION = 'UPDATE_DEFAULT_VALUES_ACTION';
export const UPDATE_UNTRACKED_VALUE_ACTION = 'UPDATE_UNTRACKED_VALUE_ACTION';
export const UPDATE_UNTRACKED_VALUES_ACTION = 'UPDATE_UNTRACKED_VALUES_ACTION';
export const UPDATE_RPC_DROPDOWN_OPTIONS_ACTION = 'UPDATE_RPC_DROPDOWN_OPTIONS_ACTION';
export const UPDATE_RPC_DROPDOWN_ARGS_ACTION = 'UPDATE_RPC_DROPDOWN_ARGS_ACTION';
export const UPDATE_RPC_DROPDOWN_STATE_ACTION = 'UPDATE_RPC_DROPDOWN_STATE_ACTION';
export const UPDATE_GLOBAL_APP_STATE_VALUE_ACTION = 'UPDATE_GLOBAL_APP_STATE_VALUE_ACTION';
export const UPDATE_VALIDATION_ERRORS_ACTION = 'UPDATE_VALIDATION_ERRORS_ACTION';

export const DELETE_UNTRACKED_VALUE_ACTION = 'DELETE_UNTRACKED_VALUE_ACTION';
export const DELETE_UNTRACKED_VALUES_ACTION = 'DELETE_UNTRACKED_VALUES_ACTION';
export const DELETE_CURRENT_VALUE_ACTION = 'DELETE_CURRENT_VALUE_ACTION';
export const DELETE_CURRENT_VALUES_ACTION = 'DELETE_CURRENT_VALUES_ACTION';
export const DELETE_DEFAULT_VALUE_ACTION = 'DELETE_DEFAULT_VALUE_ACTION';
export const DELETE_DEFAULT_VALUES_ACTION = 'DELETE_DEFAULT_VALUES_ACTION';
export const DELETE_GLOBAL_APP_STATE_VALUE_ACTION = 'DELETE_GLOBAL_APP_STATE_VALUE_ACTION';

export const SHOW_DIALOG_ACTION = 'SHOW_DIALOG_ACTION';
export const CLOSE_DIALOG_ACTION = 'CLOSE_DIALOG_ACTION';
export const POP_DIALOG_ACTION = 'POP_DIALOG_ACTION';
export const PUSH_DIALOG_ACTION = 'PUSH_DIALOG_ACTION';
export const SET_USER_ACTION = 'SET_USER_ACTION';