export const cfQueryDateFormat = 'MMMM, DD YYYY hh:mm:ss';
export const loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
export const keyCodes = {
	TAB: 9,
	ENTER: 13,
	ESCAPE: 27,
	UP_ARROW: 38,
	DOWN_ARROW: 40,
	DIGIT_0: 48,
	NUMPAD_0: 96,
	NUMPAD_SUBTRACT: 109,
	NUMPAD_DECIMAL: 110,
	MINUS: 189,
	PERIOD: 190
};
export const nodeRoutes = {
	IsAuthenticated:					{ value: '/IsAuthenticated' },
	IFRService: 						{ value: '/IFRService' }, //ifr.cfc
	IFRServiceNET: 						{ value: '/IFRServiceNET' }, //.NET IFR Service API
	POCalculateService: 				{ value: '/POCalculateService' },
	FlowURL: 							{ value: '/flowURL' },
	FlowAllURL: 						{ value: '/flowAllURL' },
	AllocationManagerURL: 				{ value: '/AllocationManagerURL' },
	MaxPOManagerURL:					{ value: '/MaxPOManagerURL' },
	TransitTimeManagerURL:				{ value: '/TransitTimeManagerURL' },
	AppInsightsInstrumentationKey: 		{ value: '/appInsightsInstrumentationKey' },
	FeatureEnabledTransfers:			{ value: '/featureEnabledTransfers'},
	EnableTransitTimeFeature:			{ value: '/enableTransitTimeFeature'},
	AllocationChartSubtractionDays: 	{ value: '/allocationChartSubtractionDays'},
	LogMessage: 						{ value: '/LogMessage'},
	AuthOpenID:							{ value: '/auth/openid'},
	AuthOpenIDReturn:					{ value: '/auth/openid/return'},
	DownloadExcel:						{ value: '/DownloadExcel'}
};
export const server = { target: 'http://localhost:9999', secure: false };
export const proxy = {
	[nodeRoutes.IsAuthenticated.value]: server,
	[nodeRoutes.AppInsightsInstrumentationKey.value]: server,
	[nodeRoutes.FlowURL.value]: server,
	[nodeRoutes.FlowAllURL.value]: server,
	[nodeRoutes.AllocationManagerURL.value]: server,
	[nodeRoutes.MaxPOManagerURL.value]: server,
	[nodeRoutes.TransitTimeManagerURL.value]: server,
	[nodeRoutes.FeatureEnabledTransfers.value]: server,
	[nodeRoutes.EnableTransitTimeFeature.value]: server,
	[nodeRoutes.AllocationChartSubtractionDays.value]: server,
	[nodeRoutes.AuthOpenID.value]: server,
	[nodeRoutes.AuthOpenIDReturn.value]: server,
	[nodeRoutes.LogMessage.value]: server,
	[nodeRoutes.IFRService.value]: server,
	[nodeRoutes.POCalculateService.value]: server,
	[nodeRoutes.IFRServiceNET.value]: server,
	[nodeRoutes.DownloadExcel.value]: server
};
export const loggingLevels = {
	info:	{ value: 'info' },
	warn:	{ value: 'warn' },
	error:	{ value: 'error' },
	debug:	{ value: 'debug' }
};
export const altSkuType = {
	COMBINATION: 'COMBINATION',
	SUBSTITUTION: 'SUBSTITUTION',
	DEFAULT: 'DEFAULT'
};
export const maintenanceModeStatusType = {
	ACTIVATED: 'Activated',
	DEACTIVATED: 'Deactivated'
};
export const navTabsHeight = 25;
