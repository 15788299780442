import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import rpcAction from '../../../actions/rpcAction';
import showDialogAction from '../../../actions/showDialogAction';
import * as constants from '../../../constants';
import OrderLine from '../../../models/OrderLine';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import * as orderUtilities from '../orderproductsscreen/OrderProductScreenUtilities';
import PurchaseOrderQueueGridPanel from './PurchaseOrderQueueGridPanel';
import PurchaseOrderQueuePOGridPanelContainer from './PurchaseOrderQueuePOGridPanelContainer';
import * as poQueueUtilities from './PurchaseOrderQueueScreenUtilities';
const DELETE = 'deleteFromPOQueue';
const DISPATCH = 'dispatchFromPOQueue';
class PurchaseOrderQueueGridPanelContainer extends Component {
	render() {
		return (
			<PurchaseOrderQueueGridPanel 
				screenId={this.props.screenId}
				panelId={this.props.panelId}
				rowData={this.props.rowData}
				vendorCode={this.props.vendorCode}
				index={this.props.index}
				panelStyle={this.props.panelStyle}
				getColumnValue={this.props.getColumnValue}
				formatColumnValue={this.props.formatColumnValue}
				onDeleteClicked={this.props.onDeleteClicked}
				onDispatchClicked={this.props.onDispatchClicked}
				onModelUpdated={this.props.onModelUpdated}
				onOrderClicked={this.props.onOrderClicked}
				onViewPOClicked={this.props.onViewPOClicked}
				onCellClicked={this.props.onCellClicked}
			/>
		);
	}
}
const mapStateToProps = () => {
	return {
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		getColumnValue: (params) => {
			switch(params.colDef.colId){
				case 'TOTAL':
					return params.data.ROWMODEL.QUANTITY * params.data.ROWMODEL.FIRSTCOST;
				default:
					return (params.colDef.colId in params.data.ROWMODEL) ? params.data.ROWMODEL[params.colDef.colId] : undefined;
			}
		},
		formatColumnValue: (params) => {
			switch(params.colDef.colId){
				case 'TOTAL':
				case 'FIRSTCOST':
					return sharedUtilities.formatCurrency(params.value);
				case 'SHIPDATE':
				case 'ARRIVALDATE':
				case 'ORDERDATE':
					return formatLocalDate(params.data[params.colDef.colId], false); 
				case 'DATEQUEUEDUTC':
					return formatUTCDateToLocalDateAndTime(params.data[params.colDef.colId], true); 
				default:
					return (params.colDef.colId in params.data.ROWMODEL) ? params.data.ROWMODEL[params.colDef.colId] : undefined;
			}
		},
		onDeleteClicked: (gridApi) => {
			deleteOrDispatchFromQueue(dispatch, props.screenId, gridApi, DELETE);
		},
		onDispatchClicked: (gridApi) => {
			deleteOrDispatchFromQueue(dispatch, props.screenId, gridApi, DISPATCH);
		},
		onModelUpdated: (params) => {
			const total = calcSelectedTotal(params);
			storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'selectedTotal'], total.toString());
		},
		onOrderClicked: () => {
			orderUtilities.showOnOrderDialog(dispatch, props.screenId, props.vendorCode);
		},
		onViewPOClicked: (node, gridApi) => {
			const queueID = node.data.QUEUEID;
			const {rowData, cubes, poCost} = getPOData(node, gridApi);
			dispatch(callRpcAction({div:node.data.ROWMODEL.DELIVERTO.split('_')[0], divWhsOrder:true}, constants.nodeRoutes.IFRService, 'getWhs', data=>{
				const deliverToOptions = data.map(dataRow => { 
					return {
						name: dataRow['WHS_NAME'],
						value: dataRow['WHS_ID']
					};
				});
				const poDialogPath = [props.screenId, props.panelId, 'poDialog', queueID];
				storeUtilities.updateValue(dispatch, [...poDialogPath, 'deliverTo'], node.data.ROWMODEL.DELIVERTO);
				storeUtilities.updateValue(dispatch, [...poDialogPath, 'arrivalDate'], moment(node.data.ROWMODEL.ARRIVALDATE, constants.cfQueryDateFormat).toDate());
				storeUtilities.updateValue(dispatch, [...poDialogPath, 'shipDate'], moment(node.data.ROWMODEL.SHIPDATE, constants.cfQueryDateFormat).toDate());
				storeUtilities.updateValue(dispatch, [...poDialogPath, 'poCubes'], cubes);
				storeUtilities.updateValue(dispatch, [...poDialogPath, 'poCost'], poCost);
				storeUtilities.updateValue(dispatch, [...poDialogPath, 'instructions'], node.data.INSTRUCTIONS);
				dispatch(showDialogAction(
					<PurchaseOrderQueuePOGridPanelContainer
						screenId={props.screenId}
						panelId={props.panelId}
						whsNum={node.data.ROWMODEL.DELIVERTO}
						rowData={rowData}
						poNumber={queueID}
						title={'PO ID: ' + queueID}
						fob={node.data.ROWMODEL.FOB}
						deliverToOptions={deliverToOptions}
						path={poDialogPath}
					/>, 
					400, 
					390, 
					()=>{},
					true,
					undefined,
					true,
					false,
					true));
			}, false));
		},
		onCellClicked: (params) => {
			if(params.colDef.colId === 'VIEW'){
				return;
			}
			var batchNumber = params.data.BATCHNUMBER;
			var clickedNodeIsSelected = params.node.isSelected();
			const nodeSelectedValues = [];
			params.api.forEachNodeAfterFilter(node => {
				if(node.data.BATCHNUMBER === batchNumber){
					const value = (params.event.ctrlKey === true) ? clickedNodeIsSelected : true;
					nodeSelectedValues.push({node, value});
				}
			});
			nodeSelectedValues.forEach(nodeSelectedValue => {
				nodeSelectedValue.node.setSelected(nodeSelectedValue.value);
			});

			const total = calcSelectedTotal(params);
			storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'selectedTotal'], total.toString());
		}
	};
};
const calcSelectedTotal = (params) => {
	let total = 0;
	params.api.forEachNodeAfterFilter(node => {
		if(node.isSelected() === true){
			total += node.data.ROWMODEL.QUANTITY * node.data.ROWMODEL.FIRSTCOST;
		}
	});
	return total;
};
const getPOData = (node, gridApi) => {
	const rowData = [];
	const headerID = node.data.ID;
	const poQueueID = node.data.QUEUEID;
	let cubes = 0;
	let poCost = 0;
	gridApi.forEachNodeAfterFilter(node => {
		if(node.data.ID === headerID && node.data.QUEUEID === poQueueID){
			const row = {};
			row.ORDERLINE = new OrderLine();
			Object.entries(row.ORDERLINE).forEach(([newOrderLineRowKey]) => {
				Object.entries(node.data.ROWMODEL).forEach(([dataRowModelKey, dataRowModelValue]) => {
					if(newOrderLineRowKey && newOrderLineRowKey.toLowerCase &&
						dataRowModelKey && dataRowModelKey.toLowerCase &&
						newOrderLineRowKey.toLowerCase() === dataRowModelKey.toLowerCase()){
						row.ORDERLINE[newOrderLineRowKey] = dataRowModelValue;
					}
				});
			});
			row.CUBES = node.data.ROWMODEL.CUBE * node.data.ROWMODEL.QUANTITY;
			cubes += row.CUBES;
			row.FIRSTCOST = node.data.ROWMODEL.FIRSTCOST;
			row.LINE = (node.data.ROWMODEL.QUANTITY * node.data.ROWMODEL.FIRSTCOST);
			poCost += row.LINE;
			row.NEEDBYDATE = moment(node.data.ROWMODEL.NEEDBYDATE, constants.cfQueryDateFormat).toDate();
			row.PO = node.data.ROWMODEL.ID;
			row.QTY = node.data.ROWMODEL.QUANTITY;
			row.SKU = node.data.ROWMODEL.SKU;
			rowData.push(row);
		}
	});
	return {rowData, cubes, poCost};
};
const formatLocalDate = (value) =>{
	const formattedValue = moment(value, constants.cfQueryDateFormat).format('L');
	return formattedValue;
};
const formatUTCDateToLocalDateAndTime = (value, includeTime = false) => {
	const momentValue = moment.utc(value, constants.cfQueryDateFormat).local();
	const datePart = momentValue.format('L');
	const timePart = momentValue.format('LT');
	return includeTime === true ? (datePart + ' ' + timePart) : datePart;
};
const deleteOrDispatchFromQueue = (dispatch, screenId, gridApi, endpoint) => {
	const queueIDs = [];
	if(gridApi !== undefined){
		const selectedRows = gridApi.getSelectedRows ? gridApi.getSelectedRows() : [];
		if(selectedRows.length === 0){
			sharedUtilities.showError('No rows selected', 'One or more rows must be selected in order to ' + (endpoint === DISPATCH ? 'dispatch':'delete') + ' POs from the queue.');
			return;
		}
		selectedRows.forEach(row => {
			if(queueIDs.includes(row.QUEUEID) === false){
				queueIDs.push(row.QUEUEID);
			}
		});
		dispatch(showDialogAction(
			<OkCancelDialogContainer
				screenId={screenId} 
				title={'Confirmation'}
				handleOkClicked={()=>{
					dispatch(rpcAction({
						args: {queueIDs},
						nodeRoute: constants.nodeRoutes.IFRService,
						endpoint,
						method: 'GET',
						callback: (data) => {
							if(endpoint === DISPATCH){
								const e = orderUtilities.getCreatePOResponseDialogElements(data,'PO #', 'Created');
								dispatch(showDialogAction(
									<OkCancelDialogContainer 
										id={'purchaseOrdersCreatedDialog'}
										screenId={ screenId + '_informationDialog'} 
										title={'Information'}
										handleOkClicked={()=>{ 
											poQueueUtilities.reloadQueue(dispatch, screenId);
										}}
										hasCancelButton={false}
										okButtonText={'OK'}>
										<div>
											{e.successfulJSXElements}
											{e.unsuccessfulJSXElements}
										</div>
									</OkCancelDialogContainer>, 
									350, 
									550, 
									()=>{}));
							}
							else {
								poQueueUtilities.reloadQueue(dispatch, screenId);
							}
						},
						retryOnFailure: endpoint === DELETE
					}));
				}}
				hasCancelButton>
				<h5>{'Are you sure you want to ' + (endpoint === DISPATCH ? 'create POs from the selected items?':'remove the selected items from the Purchase Order Queue?')}</h5>
			</OkCancelDialogContainer>,
			200, 
			350, 
			()=>{},
			true,
			undefined,
			true,
			false,
			true,
			(endpoint === DISPATCH ? 'CREATE' : 'DELETE') + '_POS_CONFIRMATION_DIALOG'));
	}
};

PurchaseOrderQueueGridPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	vendorCode: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	panelStyle: PropTypes.object,
	getColumnValue: PropTypes.func,
	formatColumnValue: PropTypes.func
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderQueueGridPanelContainer);
export default connectedComponent;