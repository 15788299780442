import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import OkCancelDialogContainer from '../../../dialogs/OkCancelDialogContainer';
import RadioGroupContainer from '../../../formelements/RadioGroupContainer';

class RemoveSkuDialog extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onRemovalOptionChanged(value){
		if(this.props.onRemovalOptionChanged){
			this.props.onRemovalOptionChanged(value);
		}
	}
	removeSkuClicked(){
		if(this.props.removeSkuClicked){
			this.props.removeSkuClicked();
		}
	}
	render() {
		return (
			<OkCancelDialogContainer 
				screenId={ this.props.screenId } 
				title={'Remove SKU'}
				message={'Remove SKU ' + this.props.sku}
				okButtonText={'Remove SKU'}
				handleOkClicked={this.removeSkuClicked.bind(this)}
				hasCancelButton>
				<Container fluid={true}>
					<Row noGutters={true}>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div style={{position:'relative', top:'8px'}}>
                            Removal Option
							</div>
						</Col>
						<Col xs={8} sm={8} md={8} lg={8} xl={8}>
							<RadioGroupContainer 
								screenId={this.props.screenId}
								fieldId={'removalOption'}
								options={
									[
										{
											name:'Only This Warehouse', 
											value: 'onlyThisWarehouse'
										},
										{
											name:'All Division Warehouses', 
											value: 'allDivisionWarehouses'
										}
									]
								}
								onChange={this.onRemovalOptionChanged.bind(this)}
							/>
							{/*<CheckBoxContainer
								screenId={this.props.screenId}
								panelId={'removeSKUDialog'}
								fieldId={'optionOnlyThisWarehouse'}
								type={'radio'}
								name={'removalOption'}
								label={'Only This Warehouse'}
								mainComponentBootstrapSize={1}
								labelPosition={'right'}
								labelStyle={{position:'relative',top:'3px'}}
								onChange={this.onRemovalOptionChanged.bind(this)}
							></CheckBoxContainer>
							<CheckBoxContainer
								screenId={this.props.screenId}
								panelId={'removeSKUDialog'}
								fieldId={'optionAllDivisionWarehouses'}
								type={'radio'}
								name={'removalOption'}
								label={'All Division Warehouses'}
								mainComponentBootstrapSize={1}
								labelPosition={'right'}
								labelStyle={{position:'relative',top:'3px'}}
								onChange={this.onRemovalOptionChanged.bind(this)}
							></CheckBoxContainer>*/}
						</Col>
					</Row>
				</Container>
			</OkCancelDialogContainer>
		);
	}
}

RemoveSkuDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	sku: PropTypes.string.isRequired,
	onRemovalOptionchanged: PropTypes.func,
	onLoad: PropTypes.func,
	removeSkuClicked: PropTypes.func
};

export default RemoveSkuDialog;