import PropTypes from 'prop-types';
import React, { Component } from 'react';

class GroupBox extends Component {
	constructor(props){
		super(props);
		this.getId = this.getId.bind(this);
	}
	getId(){
		return (this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	}
	render() {
		const span = this.props.title && this.props.title.trim() !== '' ? 
			(
				<span
					className={'text-muted px-1 text'}
					style={{
						backgroundColor: 'white',
						position: 'relative',
						top: '-8px',
						left: '3px'
					}}>
					{this.props.title}
				</span>
			) : null;
		const children = this.props.title && this.props.title.trim() !== '' 
			?(<div style={{ position:'relative', top:'-12px', left: '-3px' }}>
				{this.props.children}
			</div>) 
			:(<div>{this.props.children}</div>);
		return (
			<div className={'card'}
				style={{display: (this.props.visible !== false ? 'block':'none'), marginTop:'10px'}}
				id={this.getId()}>
				<div>
					{span}
					{children}
				</div>
			</div>
		);
	}
}

GroupBox.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	fieldId: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.any,
	visible: PropTypes.bool
};

export default GroupBox;