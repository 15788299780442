import { call } from 'redux-saga/effects';
import * as api from '../utilities/api';

export default function* getRpcDropdownOptionsSaga(action) {	
	const req = action.args;
	try{
		const response = yield call(api.request, action.route, action.endpoint, req);
		return action.fnAfterDataReceived && response !== null
			? yield call(action.fnAfterDataReceived, response) 
			: yield response;
	}
	catch(e){
		if(action.fnOnCatch !== undefined){
			action.fnOnCatch(e);
		}
	}
	finally{
		if(action.fnOnFinally !== undefined){
			action.fnOnFinally();
		}
	}
}