import { goBack } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import updateCurrentValueAction from '../../../actions/updateCurrentValueAction';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import ViewPODialogContainer from './dialogs/ViewPODialogContainer';
import MerchandisePanel from './MerchandisePanel';
import * as orderUtilities from './OrderPartScreenUtilities';
import { nodeRoutes } from '../../../constants';
import rpcAction from '../../../actions/rpcAction';
class MerchandisePanelContainer extends Component {
	render() {
		return (
			<MerchandisePanel {...this.props} 
				className={'w-100'}
			/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const selectedCategory = storeUtilities.getValue([props.screenId, 'merchGroup']);
	const polist = storeUtilities.getValue([props.screenId, 'displayPOs']) || '';
	const beginSalesDate = moment(storeUtilities.getValue([props.screenId, 'beginSalesDate'])).startOf('day').toDate();
	const endSalesDate = moment(storeUtilities.getValue([props.screenId, 'endSalesDate'])).startOf('day').toDate();
	const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
	const disableLookupPOButton = polist===undefined || polist==='';
	const enableCombineWhsList = storeUtilities.getValue([props.screenId, 'enableCombineWhsList'], false);
	const useCubes = selectedCategory===undefined || selectedCategory==='' 
		? undefined 
		: !['Pillows'].includes(selectedCategory);
	const disableGetSuggestedOrdersButton = validationErrors === undefined ||
		'vendor' in validationErrors
		|| 'txtBuyerInitials' in validationErrors
		|| 'orderDate' in validationErrors
		|| 'merchGroup' in validationErrors
		|| 'whsNum' in validationErrors
		|| ('maxPO' in validationErrors && useCubes === false)
		|| ('poCube' in validationErrors && useCubes === true)
		|| 'beginSalesDate' in validationErrors
		|| 'endSalesDate' in validationErrors
		|| 'salesDays' in validationErrors
		|| 'prodTime' in validationErrors
		|| 'transitTime' in validationErrors
		|| 'daysToNextOrder' in validationErrors
		|| 'daysToStock' in validationErrors
		|| 'marketAdjustment' in validationErrors
		|| 'POAdjustment' in validationErrors
		|| ('combineWhsList' in validationErrors && enableCombineWhsList === true);
	const whsIsSavannah = storeUtilities.getValue([props.screenId, 'whsIsSavannah'], false);
	const whsOptions = getWhsOptions(props.divisions); 
	const disableCombineWhsList = storeUtilities.getValue([props.screenId, 'enableCombineWhsList'], false) === false || props.divisions.includes('PR') || whsIsSavannah === true;
	const disableCombineWhsListCheckbox = (Array.isArray(props.divisions) && props.divisions.includes('PR')) || whsIsSavannah === true;
	return {
		useCubes,
		beginSalesDate,
		endSalesDate,
		disableGetSuggestedOrdersButton,
		disableLookupPOButton,
		whsOptions,
		disableCombineWhsList,
		disableCombineWhsListCheckbox,
		whsIsSavannah
	};
};
const getWhsOptions = (divisions) => {
	const whsList = sharedUtilities.getWhsList();
	return Array.isArray(whsList) 
		? whsList
			.filter(whs => {
				return Array.isArray(divisions) ? divisions.includes(whs.division) : false;
			})
			.sort(sharedUtilities.whsDropdownComparator)
			.map(whs => {
				return {
					name: whs.whsName, value: whs.whsId
				};
			})
		: [];
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: (callback) => {
			const skuList = props.skusList ? props.skusList.replace(/\s/g, ''):'';
			const selectedSkusTextArea = props.skusList ? props.skusList : '';
			const numOfSelectedSKUsCountLabel = props.partSearchSelectedRows !== undefined && Array.isArray(props.partSearchSelectedRows) 
				? props.partSearchSelectedRows.length 
				: 0;
			const vendor = props.vendor;
			storeUtilities.updateValue(dispatch, [props.screenId, 'skuList'], skuList, true);
			storeUtilities.updateValue(dispatch, [props.screenId, 'displayPOs'], props.displayPOs, true);
			storeUtilities.updateValue(dispatch, [props.screenId, 'selectedSkusTextArea'], selectedSkusTextArea);
			storeUtilities.updateValue(dispatch, [props.screenId, 'numOfSelectedSKUsCountLabel'], numOfSelectedSKUsCountLabel);
			storeUtilities.updateValue(dispatch, [props.screenId, 'vendor'], vendor);
			storeUtilities.updateValue(dispatch, [props.screenId, 'excludeRatios'], false);
			const whsIsSavannah = Array.isArray(props.divisions) ? (props.divisions.includes('FL') && props.divisions.includes('SE')) : false;
			storeUtilities.updateValue(dispatch, [props.screenId, 'whsIsSavannah'], whsIsSavannah);
			const today = moment();
			const thirtyDaysAgo = moment().add(-30, 'days');
			storeUtilities.updateValue(dispatch, [props.screenId, 'beginSalesDate'], thirtyDaysAgo.toDate());
			storeUtilities.updateValue(dispatch, [props.screenId, 'endSalesDate'], today.toDate());
			storeUtilities.updateValue(dispatch, [props.screenId, 'salesDays'], (today.diff(thirtyDaysAgo, 'days')).toString() );
			if(whsIsSavannah === true){
				storeUtilities.updateValue(dispatch,[props.screenId, 'whsNum'], 'SE_89');
			}
			if(callback){
				callback();
			}
		},
		goBack: () => {
			dispatch(goBack());
			storeUtilities.deleteValue(dispatch, [props.screenId]);
		},
		calculateTotalDaysAndLeadDate: () => {
			const prodTime = storeUtilities.getValue([props.screenId, 'prodTime'], 0);
			const transitTime = storeUtilities.getValue([props.screenId, 'transitTime'], 0);
			const daysToNextOrder = storeUtilities.getValue([props.screenId, 'daysToNextOrder'], 0);
			const daysToStock = storeUtilities.getValue([props.screenId, 'daysToStock'], 0);
			const sum = Number(prodTime || 0) + Number(transitTime || 0) + Number(daysToNextOrder || 0) + Number(daysToStock || 0);
			const orderDate = storeUtilities.getValue([props.screenId, 'orderDate'], undefined);
			if (orderDate !== undefined) {
				const momentLead = moment(orderDate).add(sum, 'day');

				dispatch(updateCurrentValueAction([props.screenId, 'txtTotalDays'], sum.toString()));
				dispatch(updateCurrentValueAction([props.screenId, 'dtsCal'], momentLead.toDate()));
			}
		},
		onGetSuggestedOrdersClicked: () => {
			const whsIsSavannah = storeUtilities.getValue([props.screenId, 'whsIsSavannah'], false);
			if(whsIsSavannah === true){
				dispatch(showDialogAction(
					<OkCancelDialogContainer
						screenId={props.screenId + '_getSuggestedOrdersForSavannahComingSoon'}
						title={'INFO'}
						message={'Functionality Coming Soon!'}
						handleOkClicked={()=>{}}>
						GetSuggestedOrders has not been implemented for Savannah yet.
					</OkCancelDialogContainer>,
					200,
					325,
					() => { }));
				return;
			}
			getSuggestedOrders(dispatch, props.screenId, props.partSearchSelectedRows);
		},
		handleWarehouseChanged: () => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'transitTime']);
			dispatch(callRpcAction({div:props.division, divWhsOrder:true}, nodeRoutes.IFRService, 'getWhs', (data) => {
				if (data && Array.isArray(data)){
					const whsid = storeUtilities.getValue([props.screenId, 'whsNum']);
					const selectedWarehouse = data.find(w => w.WHS_ID === whsid);
					storeUtilities.updateValue(dispatch, [props.screenId, 'selectedWarehouse'], selectedWarehouse);
				}
			}, false, 'POST'));
		},
		lookupPOBtnClicked: () => {
			dispatch(showDialogAction(
				<ViewPODialogContainer 
					polist={props.displayPOs} 
					screenId={props.screenId} 
					panelId={'ViewPODialogPanel'}
					dialogId={'viewPODialogContainer'}
					parentPath={props.path}
					path={props.path}
					onDialogClosed={()=>{
						dispatch(closeDialogAction());
					}}/>, 
				325, 
				500, 
				() => {
					dispatch(closeDialogAction());
				}));
		}
	};
};

const getSuggestedOrders = (dispatch, screenId, partSearchSelectedRows) => {
	storeUtilities.updateValue(dispatch, [screenId, 'disableSuggestedPOsPanel'], true);
	storeUtilities.deleteValue(dispatch, [screenId, 'SuggestedPOs']);
	storeUtilities.deleteValue(dispatch, [screenId, 'OrderLinesRowData']);
	storeUtilities.deleteValue(dispatch, [screenId, 'OrderLinesRowDataOriginal']);
	storeUtilities.deleteValue(dispatch, [screenId, 'PrimaryOrderLine']);
	const dateFormat = 'YYYY-MM-DD';
	const transitTime = storeUtilities.getValue([screenId, 'transitTime'], 0);
	const prodTime = storeUtilities.getValue([screenId, 'prodTime'], 0);
	const leadTime = Number(transitTime) + Number(prodTime);
	const whsNum = storeUtilities.getValue([screenId, 'whsNum']);
	const whsList = storeUtilities.getValue([screenId, 'combineWhsList'], '').toString();
	const combineWhsCheckbox = storeUtilities.getValue([screenId, 'enableCombineWhsList'], false);

	const request = {
		skuList: storeUtilities.getValue([screenId, 'skuList'], ''),
		whsList: combineWhsCheckbox ? whsList : whsNum,
		beginSalesDate: moment(storeUtilities.getValue([screenId, 'beginSalesDate'])).format(dateFormat),
		endSalesDate: moment(storeUtilities.getValue([screenId, 'endSalesDate'])).format(dateFormat),
		prodTime: prodTime,
		transitTime: transitTime,
		leadTime: leadTime,
		daysToStock: storeUtilities.getValue([screenId, 'daysToStock'], 0),
		threshold: 0,
	};
	dispatch(rpcAction({
		args: request,
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Parts/SuggestedOrders',
		method: 'GET',
		callback: (data) => {
			if (data && Array.isArray(data)) {
				let totalOrderQty = 0;
				data.forEach((row) => {
					const selectedRows = Array.isArray(partSearchSelectedRows) ? partSearchSelectedRows.filter(selectedRow => selectedRow.sku === row.partSku) : undefined;	
					if(Array.isArray(selectedRows) && selectedRows.length === 1 ){
						row['inactiveSku'] = selectedRows[0].inactiveSku;
						row['discontinuedSku'] = selectedRows[0].discontinuedSku;
						row['toBeDropped'] = selectedRows[0].toBeDropped;
					}
					const orderValue = parseInt(isNaN(row['calculatedOrderQuantity']) ? 0 : row['calculatedOrderQuantity']);
					if(orderValue > 0){
						totalOrderQty += orderValue;
					}
				});
				const { primaryOrderLine } = orderUtilities.getOrderLinesFromJsonArrayResults(data);
				storeUtilities.updateValue(dispatch, [screenId, 'OrderLinesRowData'], data);
				storeUtilities.updateValue(dispatch, [screenId, 'OrderLinesRowDataOriginal'], cloneDeep(data));
				storeUtilities.updateValue(dispatch, [screenId, 'PrimaryOrderLine'], primaryOrderLine);
				storeUtilities.updateValue(dispatch, [screenId, 'whsNumInUse'], whsNum, true);
				storeUtilities.updateValue(dispatch, [screenId, 'totalOrderQty'], totalOrderQty);
				const sizeColumnsToFit = storeUtilities.getValue([screenId, 'sizeSuggestedOrdersOrdersTabGridColumnsToFit'], undefined);
				if (sizeColumnsToFit !== undefined) {
					sizeColumnsToFit();
				}
			}
		}
	}));
};
MerchandisePanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	vendor: PropTypes.string.isRequired,
	onGetSuggestedOrdersClicked: PropTypes.func,
	partSearchSelectedRows: PropTypes.array.isRequired,
	defaultValues: PropTypes.object,
	disableGetSuggestedOrdersButton: PropTypes.bool,
	disableLookupPOButton: PropTypes.bool,
	handleCategoryChanged: PropTypes.func,
	wareHouse: PropTypes.string,
	onBeforeLoad: PropTypes.func,
	skusList: PropTypes.string.isRequired,
	displayPOs: PropTypes.string,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MerchandisePanelContainer);
connectedComponent.defaultProps = {
	defaultValues: {
		intraOrder: false
	}
};

export default connectedComponent;