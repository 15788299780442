import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import AllocationsTab from './AllocationsTab';

class AllocationsTabContainer extends Component {
	render() {
		return (
			<AllocationsTab {...this.props}/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const allocationsData = storeUtilities.getValue([props.screenId, 'AllocationsData'], []);

	for (var i=0; i<allocationsData.length; i++) {
		allocationsData[i].week = moment(allocationsData[i].week).format('YYYY-MM-DD');
	}

	const data = {
		labels: allocationsData.map(x => x.week),
		datasets: [
			{
				type: 'line',
				label: 'allocation',
				borderColor: 'darkblue',
				backgroundColor: 'darkblue',
				borderWidth: 3,
				data: allocationsData.map(x => x.allocation),
			},
			{
				type: 'bar',
				label: 'shipments',
				backgroundColor: '#ADD8E6',
				borderWidth: 0,
				data: allocationsData.map(x => x.shipments)//labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
			}
		],
	};

	return { data };
};

AllocationsTabContainer.propTypes = 	{
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	forwardRef: PropTypes.any,
	height: PropTypes.number
};

export default connect(mapStateToProps, null)(AllocationsTabContainer);