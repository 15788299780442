import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';

class LookupBtn extends Component {
	constructor(props) {
		super(props);
		this.getId = this.getId.bind(this);
	}

	onClick() {
		if (this.props.onClick)
			this.props.onClick(this.props.fieldId);
	}
	getId() {
		return (this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	}

	render() {
		const visibility = this.props.visible !== false ? 'block' : 'none';
		let style = { whiteSpace: 'normal', display: visibility };
		if (this.props.width !== undefined) {
			style.width = this.props.width;
		}
		if (this.props.height !== undefined) {
			style.height = this.props.height;
		}
		if (this.props.style) {
			style = Object.assign(style, this.props.style);
		}
		return (
			<button
				type='button'
				ref={this.props.forwardRef}
				className="btn btn-light"
				style={style}
				onClick={this.onClick.bind(this)}
				disabled={this.props.disabled}
				id={this.getId()}>
				<FontAwesomeIcon size="lg" icon={faBinoculars} title="Lookup PO Sku Quantities" />
			</button>
		);
	}

};

LookupBtn.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	fill: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	text: PropTypes.string,
	visible: PropTypes.bool,
	height: PropTypes.number,
	width: PropTypes.number,
	top: PropTypes.string,
	left: PropTypes.string,
	buttonClasses: PropTypes.string,
	forwardRef: PropTypes.any,
	tooltip: PropTypes.string,
	style: PropTypes.object
};

export default LookupBtn;