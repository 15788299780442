import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import { nodeRoutes } from '../../../constants';
import POLookUpDialogContainer from '../../dialogs/POLookUpDialogContainer';
import SearchPartsListPanel from './SearchPartsListPanel';
import rpcAction from '../../../actions/rpcAction';

class SearchPartsListPanelContainer extends Component {
	render() {
		return (
			<SearchPartsListPanel {...this.props} />
		);
	}
}

const mapStateToProps = (state, props) => {
	const poListRaw = storeUtilities.getValue([props.screenId, 'POSearchResultsGrid', 'selectedRows'], undefined);
	const poList = poListRaw !== undefined && poListRaw.trim !== undefined ? poListRaw.trim() : undefined;

	const selectedDivisions = storeUtilities.getValue([props.screenId, 'Region'], []);
	const divisionOptions = sharedUtilities.getMultiSelectDivisionOptions(selectedDivisions);
	const isSavannah = selectedDivisions.includes('FL') && selectedDivisions.includes('SE');
	
	const vendorEmailInfos = storeUtilities.getValue([props.screenId, 'VendorEmailInfos'], undefined);
	const vendorCode = storeUtilities.getValue([props.screenId, 'VendorCode'], undefined);
	const poVendorCode = storeUtilities.getValue([props.screenId, 'poVendorCode'], undefined);

	const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
	const searchButtonHasValidationErrors = validationErrors !== undefined && (
		'Region' in validationErrors
	|| 	'VendorCode' in validationErrors);
	const searchButtonDisabled = !sharedUtilities.vendorHasValidVendorEmailInfos(vendorCode, vendorEmailInfos) || searchButtonHasValidationErrors;
	const poSearchButtonHasValidationErrors = validationErrors !== undefined && (
		'poVendorCode' in validationErrors
	|| 	'poList' in validationErrors
	|| 	'poRegion' in validationErrors);

	const poSearchButtonDisabled = !sharedUtilities.vendorHasValidVendorEmailInfos(poVendorCode, vendorEmailInfos) || poSearchButtonHasValidationErrors;
	
	return {
		poList,
		searchButtonDisabled,
		poSearchButtonDisabled,
		divisionOptions,
		sortCodeDisabled: isSavannah,
		discontinuedSKUSDisabled: isSavannah,
		unavailableSKUSDisabled: isSavannah,
		toBeDroppedDisabled: isSavannah,
		poLookupButtonDisabled: isSavannah
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			dispatch(updateDefaultValuesAction([props.screenId], props.defaultValues));
		},
		onPOLookupClicked: () => {
			const divisions = storeUtilities.getValue([props.screenId, 'Region'], []);
			if(divisions.length > 1){
				window.alert('PO Lookup is not available for multiple divisions. Please select a single division.');
				return;
			}
			const division = divisions.length === 1 ? divisions[0] : undefined;
			const vendorCode = storeUtilities.getValue([props.screenId, 'VendorCode']);
			dispatch(showDialogAction(
				<POLookUpDialogContainer
					screenId={props.screenId}
					panelId={'POLookUpDialogPanel'}
					path={[props.screenId, 'POLookUpDialog']}
					onDialogClosed={() =>{
						dispatch(closeDialogAction());
					}}
					division={division}
					vendorCode={vendorCode}
				/>, 
				400,
				850,
				() => {
					dispatch(closeDialogAction());
				}));
		},
		onSearchClicked: () => {
			storeUtilities.deleteValue(dispatch, [props.screenId , 'PartSearchResultsGrid', 'selectedRows']);
			storeUtilities.deleteValue(dispatch, [props.screenId , 'PartSearchResultsGrid', 'rowData']);
			const selectedRegions = storeUtilities.getValue([props.screenId, 'Region'], []);
			const vendorCode = storeUtilities.getValue([props.screenId, 'VendorCode']);
			const region = selectedRegions.length === 2 && selectedRegions.includes('FL') && selectedRegions.includes('SE') ? 'FL' : (selectedRegions.length === 1 ? selectedRegions[0] : undefined);

			if(vendorCode.trim && vendorCode.trim().length === 4 && selectedRegions.length > 0){
				const args = {
					BeginSKU: storeUtilities.getValue([props.screenId, 'BeginSKU']),
					Color: storeUtilities.getValue([props.screenId, 'Color']),
					Description: storeUtilities.getValue([props.screenId, 'Description']),
					EndSKU: storeUtilities.getValue([props.screenId, 'EndSKU']),
					Family: storeUtilities.getValue([props.screenId, 'Family']),
					Finish: storeUtilities.getValue([props.screenId, 'Finish']),
					SortCode: storeUtilities.getValue([props.screenId, 'SortCode'], 'ALL'),
					Style: storeUtilities.getValue([props.screenId, 'Style']),
					VendorCode: storeUtilities.getValue([props.screenId, 'VendorCode']),
					Division: region,
					Discontinued: storeUtilities.getValue([props.screenId, 'DiscontinuedSKUS']),
					Inactive: storeUtilities.getValue([props.screenId, 'UnavailableSKUS']),
					Factory: storeUtilities.getValue([props.screenId, 'Factory']),
					ToBeDropped: storeUtilities.getValue([props.screenId, 'ToBeDropped'])
				};
				const prunedArgs = sharedUtilities.pruneObject(args);
				dispatch(rpcAction({
					args: prunedArgs, 
					nodeRoute: nodeRoutes.IFRServiceNET, 
					endpoint: '/Parts/Skus', 
					methos: 'GET',
					hideLoadingMaskOnComplete: true,
					showLoadingMask: true,
					callback: (rowData) => {
						if(Array.isArray(rowData)){
							storeUtilities.updateValue(dispatch, [props.screenId, 'PartSearchResultsGrid', 'rowData'], rowData, true);
							storeUtilities.updateValue(dispatch, [props.screenId, 'VendorCodeInUse'], vendorCode);
							storeUtilities.updateValue(dispatch, [props.screenId, 'DivisionsInUse'], selectedRegions);
							const sizeColumnsToFit = storeUtilities.getValue([props.screenId, 'sizePartSearchResultsGridColumnsToFit'], undefined);
							if(sizeColumnsToFit !== undefined){
								sizeColumnsToFit();
							}
						}
					}
				}));
			}
		},
		onPOSearchClicked: () => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'PartSearchResultsGrid', 'selectedRows']);
			storeUtilities.deleteValue(dispatch, [props.screenId, 'PartSearchResultsGrid', 'rowData']);
			const args = {
				VendorCode: storeUtilities.getValue([props.screenId, 'poVendorCode']),
				Division: storeUtilities.getValue([props.screenId, 'poRegion']),
				poList: storeUtilities.getValue([props.screenId, 'poList']).replace(/ /g,'')
			};
			const prunedArgs = sharedUtilities.pruneObject(args);
			dispatch(rpcAction({
				args: prunedArgs, 
				nodeRoute: nodeRoutes.IFRServiceNET, 
				endpoint: '/Parts/Skus', 
				hideLoadingMaskOnComplete: true,
				showLoadingMask: true,
				callback: (rowData) => {
					if(rowData && Array.isArray(rowData)){
						storeUtilities.updateValue(dispatch, [props.screenId, 'PartSearchResultsGrid', 'rowData'], rowData, true);
						const sizeColumnsToFit = storeUtilities.getValue([props.screenId, 'sizePartSearchResultsGridColumnsToFit'], undefined);
						storeUtilities.updateValue(dispatch, [props.screenId, 'VendorCodeInUse'], args.VendorCode);
						storeUtilities.updateValue(dispatch, [props.screenId, 'DivisionsInUse'], [args.Division]);
						if(sizeColumnsToFit !== undefined){
							sizeColumnsToFit();
						}
					}
				}
			}));
		},
		onVendorChanged: (vendorId) => {
			onVendorChanged(dispatch, props.screenId, vendorId, storeUtilities.getValue([props.screenId, 'Region'], []));
		},
		onPoVendorChanged: (vendorId) => {
			const poRegion = storeUtilities.getValue([props.screenId, 'poRegion'], undefined);
			onVendorChanged(dispatch, props.screenId, vendorId, (poRegion ? [poRegion] : []));
		},
		onDivisionChanged: (selectedRegions) => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'VendorEmailInfos']);
			const paths = [
				[props.screenId, 'SortCode'],
				[props.screenId, 'DiscontinuedSKUS'],
				[props.screenId, 'UnavailableSKUS'],
				[props.screenId, 'ToBeDropped']
			];
			if(selectedRegions.length === 2 && selectedRegions.includes('FL') && selectedRegions.includes('SE')){
				storeUtilities.stashValues(dispatch, paths);
				//overwrite with new values
				storeUtilities.updateValue(dispatch, [props.screenId, 'SortCode'], 'ALL');
				storeUtilities.updateValue(dispatch, [props.screenId, 'DiscontinuedSKUS'], 'EXCLUDE');
				storeUtilities.updateValue(dispatch, [props.screenId, 'UnavailableSKUS'], 'EXCLUDE');
				storeUtilities.updateValue(dispatch, [props.screenId, 'ToBeDropped'], 'EXCLUDE');
			}
			else {
				storeUtilities.restoreStashedValues(dispatch, paths);
			}
			const vendorId = storeUtilities.getValue([props.screenId, 'VendorCode']);
			getVendorEmailInfos(dispatch, props.screenId, vendorId, selectedRegions, false);
		},
		onPoDivisionChanged: (selectedRegion) => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'VendorEmailInfos']);
			const vendorId = storeUtilities.getValue([props.screenId, 'poVendorCode']);
			getVendorEmailInfos(dispatch, props.screenId, vendorId, [selectedRegion], false);
		},
		getVendorEmailInfos: () => {
			const selectedRegions = storeUtilities.getValue([props.screenId, 'Region'], []);
			const vendorId = storeUtilities.getValue([props.screenId, 'VendorCode']);
			getVendorEmailInfos(dispatch, props.screenId, vendorId, selectedRegions, false);
		},
		getPoVendorEmailInfos: () => {
			const poRegion = storeUtilities.getValue([props.screenId, 'poRegion'], undefined);
			const vendorId = storeUtilities.getValue([props.screenId, 'poVendorCode']);
			getVendorEmailInfos(dispatch, props.screenId, vendorId, (poRegion ? [poRegion] : []), false);
		}
	};
};
const getVendorEmailInfos = (dispatch, screenId, vendorId, selectedRegions, showErrorDialog) => {
	if(vendorId?.length === 4)
	{
		sharedUtilities.getVendorEmailInfos(dispatch, [screenId, 'VendorEmailInfos'], selectedRegions, vendorId, 
			async (response)=>{
				if(response.status === 404){
					return '';
				}
				else {
					return await sharedUtilities.parseResponse(response);
				}
			}, showErrorDialog);
	}
};
const onVendorChanged = (dispatch, screenId, vendorId, selectedRegions) => {
	//storeUtilities.updateValue(dispatch, [screenId, 'VendorCodeInUse'], vendorId);
	storeUtilities.deleteValue(dispatch, [screenId, 'VendorEmailInfos']);
	getVendorEmailInfos(dispatch, screenId, vendorId, selectedRegions);
};
SearchPartsListPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SearchPartsListPanelContainer);

connectedComponent.defaultProps = {
	defaultValues: {
		Region: ['FL'],
		DivisionsInUse: ['FL'],
		DiscontinuedSKUS: 'EXCLUDE',
		UnavailableSKUS: 'EXCLUDE',
		ToBeDropped: 'EXCLUDE',
		SortCode: 'ALL'
	}
};

export default connectedComponent;