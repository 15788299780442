import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../utilities/storeUtilities';
import Label from './Label';

class LabelContainer extends Component {
	render() {
		return (
			<Label 
				screenId={this.props.screenId}
				panelId={this.props.panelId}
				fieldId={this.props.fieldId}
				value={this.props.valueGetter !== undefined ? this.props.valueGetter(this.props.value) : this.props.value}
				visible={this.props.visible}
				onLoad={this.props.onLoad}
				showRequiredAsterisk={this.props.showRequiredAsterisk}
				style={this.props.style}>
				{this.props.children}
			</Label>
		);
	}
}
export const mapStateToProps = (state, props) => {
	const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
	const value = (path && props.useStore === true) ? storeUtilities.getValue(path, '') : (props.value !== undefined ? props.value : '');
	return {
		value: value.toString !== undefined ? value.toString() : value,
		visible: props.visible
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			if(props.value !== undefined){
				const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
				storeUtilities.updateValue(dispatch, path, props.value, false, props.persistValue);
			}
		}
	};
};
LabelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	fieldId: PropTypes.string.isRequired,
	value: PropTypes.string,
	visible: PropTypes.bool,
	overridePath: PropTypes.array,
	useStore: PropTypes.bool,
	style: PropTypes.object,
	valueGetter: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(LabelContainer);

connectedComponent.defaultProps = {
	visible: true,
	useStore: false
};

export default connectedComponent;