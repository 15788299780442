import PropTypes from 'prop-types';
import React from 'react';
import withID from '../../../higherordercomponents/WithID';
const XIcon = ({color, height, width, id, visible=true}) => {
	return (
		visible 
		? <svg 
			id={id}
			x={'0px'} 
			y={'0px'} 
			width={width ? width : '16px'} 
			height={height ? height : '16px'} 
			viewBox={'0 0 357 357'} 
			enableBackground={'new 0 0 357 357'}>
			<polygon 
				points={'357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 214.2,178.5'}
				fill={color ? color : '#888888'}
			/>
		</svg>
		: <></>
	);
};
XIcon.propTypes = {
	color: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	visible: PropTypes.any
};
export default withID(XIcon);