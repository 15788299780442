import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { 
	ButtonContainer,
	CheckBoxCellRendererContainer, 
	DataGridContainer,
	DatePickerContainer,
	DeleteColumnHeader,
	DropdownContainer
} from '@rtg/shared-react-components';
import TextBoxContainer from '../../formelements/TextBoxContainer';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import * as validators from '../../../utilities/commonValidators';
import store from '../../../configureStore';
import '../../../css/DatePickerOverrides.css';
class WarehouseOrderQueueDialog extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onGridReady(params){
		params.api.sizeColumnsToFit();
	}
	onDeleteRowClicked(gridApi, node, value){
		if(this.props.onDeleteRowClicked){
			this.props.onDeleteRowClicked(gridApi, node, value);
		}
	}
	onDeleteColumnHeaderClicked(){
		if(this.props.onDeleteColumnHeaderClicked){
			this.props.onDeleteColumnHeaderClicked();
		}
	}
	handleCancelClicked(){
		if(this.props.handleCancelClicked){
			this.props.handleCancelClicked();
		}
	}
	handleCreatePurchaseOrdersClicked(){
		if(this.props.handleCreatePurchaseOrdersClicked){
			this.props.handleCreatePurchaseOrdersClicked();
		}
	}
	render() {
		const gridName = 'warehouseOrderQueueGrid';
		return (        
			<Card className={'h-100 w-100'} style={{width: this.props.width, height: this.props.height}}>
				<Card.Header className={'dlgdraggable'}>
					<h4>Order Queue</h4>
				</Card.Header>
				<Container fluid={true} className={'h-100 w-100 m-0 p-0'}>
					<Row noGutters={true} className={'h-100 m-0 p-0'}>
						<Col xs={5} sm={5} md={5} lg={5} xl={5}  className={'m-0 p-0'} style={{borderRight:'1px solid gainsboro'}}>
							<Row className={'mb-1 mr-0 ml-0 mt-2'}>
								<Col>
									<TextBoxContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'BuyerInitials']}
										fieldId={'BuyerInitials'}
										label={'Buyer Initials'}
										validators={[validators.required]} 
										showRequiredAsterisk
										persistValue
										mainComponentBootstrapSize={5}
										trimValue
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DropdownContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'Terms']}
										fieldId={'Terms'}
										label={'Terms'}
										options={[{value:'WIRE',name:'WIRE'},{value:'NET',name:'NET'}]}
										hasBlankOption
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DropdownContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'ShipVia']}
										fieldId={'ShipVia'}
										label={'Ship Via'}
										options={[{value:'TRUCK',name:'TRUCK'},{value:'OCEAN',name:'OCEAN'},{value:'OTHER',name:'OTHER'}]}
										hasBlankOption
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DatePickerContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'ShipDate']}
										fieldId={'ShipDate'}
										label={'Ship Date'}
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DatePickerContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'ArrivalDate']}
										fieldId={'ArrivalDate'}
										label={'Arrival Date'}
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<CheckBoxContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'SendToBvFaxQueue']}
										fieldId={'SendToBvFaxQueue'}
										label={'Send to BluVista Fax Queue'}
										mainComponentBootstrapSize={5}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<CheckBoxContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'reqConfirmation'}
										overridePath={[this.props.screenId, this.props.panelId, 'reqConfirmation']}
										label={'Request Email Confirmation'}
										mainComponentBootstrapSize={5}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<TextBoxContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'confirmationEmailAddress'}
										overridePath={[this.props.screenId, this.props.panelId, 'confirmationEmailAddress']}
										label={'Confirmation Email Address'}
										showRequiredAsterisk
										validators={[validators.required, validators.isValidEmail]}
										mainComponentBootstrapSize={5}
									/>
								</Col>
							</Row>
						</Col>
						<Col className={'m-0 p-0'} xs={7} sm={7} md={7} lg={7} xl={7}>
							<DataGridContainer
								fieldId={gridName} 
								panelId={this.props.panelId}
								screenId={this.props.screenId} 
								defaultColDef={{resizable:true}}
								columnDefs={[
									{
										headerName: 'SKU',
										field: 'SKU',
										colId: 'SKU',
										sortable: true,
										width: 65,
										minWidth: 65
									},
									{
										headerName: 'Vendor',
										field: 'VENDOR',
										colId: 'VENDOR',
										sortable: true,
										width: 40,
										minWidth: 40
									},
									{
										headerName: 'Store',
										field: 'WHS',
										colId: 'WHS',
										sortable: true,
										width: 50,
										minWidth: 50
									},
									{
										headerName: 'Order',
										field: 'QTY',
										colId: 'QTY',
										sortable: true,
										width: 40,
										minWidth: 40,
										type: 'numericColumn'
									},
									{ 
										headerName: '',     
										field: 'DELETE',      
										colId: 'DELETE',
										sortable: false,
										headerComponent: DeleteColumnHeader,
										headerComponentParams: {
											screenId: this.props.screenId,
											panelId: this.props.panelId,
											fieldId: 'deleteHeaderButton',
											gridName: gridName,
											onClick: this.onDeleteColumnHeaderClicked.bind(this)
										},
										cellRenderer: CheckBoxCellRendererContainer, 
										cellRendererParams: {
											getDisabled: () => {return false;},
											getTooltips: () => {return ['Delete'];},
											onChange: this.onDeleteRowClicked.bind(this),
											gridName: gridName,
											visible: true,
											screenId: this.props.screenId,
											panelId: this.props.panelId,
											tooltipPlacement: 'right'
										},
										width:40,
										minWidth:40,
										maxWidth:40
									}
								]}
								rowData={this.props.rowData}
								onGridReady={this.onGridReady.bind(this)}
								onModelUpdated={this.onGridReady.bind(this)}
								store={store}
							/>
						</Col>
					</Row>
				</Container>
				<Card.Footer>
					<Container fluid={true}>
						<Row noGutters={true}>
							<Col>
								<Row>
									<Col>
										<ButtonContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId='btnCancel' 
											text={'Cancel'} 
											buttonClasses={'btn-light'} 
											onClick={this.handleCancelClicked.bind(this)}
											fill/>
									</Col>
									<Col>
										<ButtonContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'createPurchaseOrdersButton'}
											text={'Create Purchase Orders'}
											disabled={this.props.createPurchaseOrdersButtonDisabled === true}
											onClick={this.handleCreatePurchaseOrdersClicked.bind(this)}
											fill 
											store={store}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</Card.Footer>
			</Card>);
	}
}
WarehouseOrderQueueDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	handleCancelClicked: PropTypes.func,
	onDeleteRowClicked: PropTypes.func,
	onDeleteColumnHeaderClicked: PropTypes.func,
	handleCreatePurchaseOrdersClicked: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	onLoad: PropTypes.bool
};
export default WarehouseOrderQueueDialog;