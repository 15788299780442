import { isArray } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import mergeRanges from 'merge-ranges';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../actions/closeDialogAction';
import updateValidationErrorsAction from '../../actions/updateValidationErrorsAction';
import * as persistentValues from '../../utilities/persistentValues';
import * as storeUtilities from '../../utilities/storeUtilities';
import DatesPickerDialog from './DatesPickerDialog';
const SCREEN_ID = 'datesPickerDialog';
const PANEL_ID = 'datesPickerDialogPanel';
class DatesPickerDialogContainer extends Component {
	render() {
		return (
			<DatesPickerDialog {...this.props}
				screenId={this.props.screenId || SCREEN_ID} 
				panelId={this.props.panelId || PANEL_ID}
			/>
		);
	}
}
const mapStateToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const panelId = props.panelId || PANEL_ID;
	const isSingleDate = isSingleDateSelected([screenId, panelId]);
	const momentRanges = storeUtilities.getValue([screenId, panelId, 'momentRanges'], []);
	const dateRangeStart = storeUtilities.getValue([screenId, panelId, 'dateRangeStart'], moment().toDate());
	const dateRangeEnd = storeUtilities.getValue([screenId, panelId, 'dateRangeEnd'], moment().toDate());
	const validationErrors = storeUtilities.getValidationErrors([screenId, panelId]);
	const disableBtnAdd = isSingleDate 
		? false 
		: (validationErrors !== undefined && (
			'dateRangeStart' in validationErrors
			||'dateRangeEnd' in validationErrors
		));
	return {
		isSingleDate,
		momentRanges,
		dateRangeStart,
		dateRangeEnd,
		disableBtnAdd
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const panelId = props.panelId || PANEL_ID;
	return {
		onLoad: () => {
			const now = moment().toDate();
			storeUtilities.updateValue(dispatch, [screenId, panelId, 'dateRangeStart'], now);
			storeUtilities.updateValue(dispatch, [screenId, panelId, 'dateRangeEnd'], now);
			storeUtilities.updateValue(dispatch, [screenId, panelId, 'singleDate'], now);
			
			const persistedValues = persistentValues.get(props.momentRangesPath);
			if(persistedValues !== undefined && Array.isArray(persistedValues)){
				const momentRanges = persistedValues.map(dateRange => {
					return {
						start: moment(dateRange.start), 
						end: moment(dateRange.end)
					};
				});
				storeUtilities.updateValue(dispatch, [screenId, panelId, 'momentRanges'], momentRanges, true, true);
			}
		},
		onAddClicked: () => {
			const now = moment().toDate();
			const start = isSingleDateSelected([screenId, panelId]) 
				? moment(storeUtilities.getValue([screenId, panelId, 'singleDate'], now)).set({ hour: 0, minute: 0, second: 0, millisecond: 0})
				: moment(storeUtilities.getValue([screenId, panelId, 'dateRangeStart'], now)).set({ hour: 0, minute: 0, second: 0, millisecond: 0});
			const end = isSingleDateSelected([screenId, panelId]) 
				? moment(storeUtilities.getValue([screenId, panelId, 'singleDate'], now)).set({ hour: 23, minute: 59, second: 59, millisecond: 999})
				: moment(storeUtilities.getValue([screenId, panelId, 'dateRangeEnd'], now)).set({ hour: 23, minute: 59, second: 59, millisecond: 999});
			const newRange = {
				start,
				end
			};
			const existingRanges = storeUtilities.getValue([screenId, panelId, 'momentRanges'], []);
			const mergedMomentRanges = mergeNewMomentRange(newRange, existingRanges);
			const condensedMomentRanges = condenseContiguousMomentRanges(mergedMomentRanges);
			storeUtilities.updateValue(dispatch, [screenId, panelId, 'momentRanges'], condensedMomentRanges);
		},
		onCancelClicked: () => {
			storeUtilities.updateValue(dispatch, props.momentRangesPath, [], false, false);
			dispatch(closeDialogAction());
			if(props.onCancelClicked){
				props.onCancelClicked();
			}
		},
		validateOtherDateField: (otherFieldId, otherValidationMsg, otherFieldIsValid, isInitialValidationCheck) => {
			const validationObject = storeUtilities.getValidationErrors([screenId, otherFieldId]);
			const errors = cloneDeep((validationObject !== undefined && Array.isArray(validationObject.errors)) ? validationObject.errors :[]);
			if(errors.includes(otherValidationMsg) === false){
				errors.push(otherValidationMsg);
			}
			const filteredErrors = (otherFieldIsValid === true) ? errors.filter((error) => { return error !== otherValidationMsg; }) : errors;
			dispatch(updateValidationErrorsAction([screenId, otherFieldId], filteredErrors, isInitialValidationCheck));
		},
		onSaveChangesClicked: () => {
			const momentRanges = storeUtilities.getValue([screenId, panelId, 'momentRanges'], []);
			storeUtilities.updateValue(dispatch, props.momentRangesPath, momentRanges, false, true);
			dispatch(closeDialogAction());
			if(props.onSaveChangesClicked){
				props.onSaveChangesClicked();
			}
		},
		onDeleteDateButtonClicked: (momentRange) => {
			const momentRanges = storeUtilities.getValue([screenId, panelId, 'momentRanges'], []);
			if(Array.isArray(momentRanges)){
				const filtered = momentRanges.filter(range => {
					return range.start.isSame(momentRange.start, 'day') === false;
				});
				storeUtilities.updateValue(dispatch, [screenId, panelId, 'momentRanges'], filtered);
			}
		}
	};
};
const isSingleDateSelected = (path) => {
	return storeUtilities.getValue([...path, 'singleOrDateRangeRadio'], 'singleDate') === 'singleDate';
};
const mergeNewMomentRange = (newMomentRange, existingMomentRanges) => {
	const dateRanges = [];
	if(isArray(existingMomentRanges)){
		existingMomentRanges.forEach((existingRange) => {
			dateRanges.push([existingRange.start.toDate(), existingRange.end.toDate()]);
		});
	}
	dateRanges.push([newMomentRange.start.toDate(), newMomentRange.end.toDate()]);
	const mergedDateRanges = mergeRanges(dateRanges);
	const mergedMomentRanges = [];
	mergedDateRanges.forEach((dateRange) => {
		mergedMomentRanges.push({
			start: moment(dateRange[0]),
			end: moment(dateRange[1])
		});
	});
	return mergedMomentRanges;
};
const condenseContiguousMomentRanges = (momentRanges, result=[]) => {
	const momentRangesClone = cloneDeep(momentRanges);
	const resultClone = cloneDeep(result);
	if(Array.isArray(momentRangesClone)){
		if(momentRangesClone.length > 1){
			const momentRange1 = momentRangesClone[0];
			const momentRange2 = momentRangesClone[1];
			const firstRangeEndMoment = momentRange1.end;
			const secondRangeStartMoment = momentRange2.start;

			const firstRangeEndMomentClone = cloneDeep(firstRangeEndMoment);
			firstRangeEndMomentClone.add(1, 'days');

			const areSameDay = firstRangeEndMomentClone.isSame(secondRangeStartMoment, 'day');
			if(areSameDay === true){
				const newRange = {
					start: momentRange1.start,
					end: momentRange2.end
				};
				momentRangesClone.shift(); 
				momentRangesClone.shift(); //intentional double-shift
				momentRangesClone.unshift(newRange);
				return condenseContiguousMomentRanges(momentRangesClone, resultClone);
			}
			else {
				const shiftedRange = momentRangesClone.shift();
				resultClone.push(shiftedRange);
				return condenseContiguousMomentRanges(momentRangesClone, resultClone);
			}
		}
		else if (momentRangesClone.length === 1){
			resultClone.push(momentRangesClone[0]);
			return resultClone;
		}
		else{
			return resultClone;
		}
	}
	return resultClone;
};
DatesPickerDialogContainer.propTypes = {
	title: PropTypes.string,
	momentRangesPath: PropTypes.array.isRequired,
	cancelButtonText: PropTypes.string,
	okButtonText: PropTypes.string,
	onSaveChangesClicked: PropTypes.func,
	onCancelClicked: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(DatesPickerDialogContainer);

export default connectedComponent;