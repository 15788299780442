import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../../actions/showDialogAction';
import '../../../components/widgets/css/NumberTextBoxCell.css';
import * as gridUtilities from '../../../utilities/gridUtilities';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as orderUtilities from './OrderProductScreenUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import SuggestedOrdersOrdersTab from './SuggestedOrdersOrdersTab';
import SingleSkuAltSkuDialogContainer from './dialogs/SingleSkuAltSkuDialogContainer';
import urljoin from 'url-join';
export const DEFAULT_ROW_HEIGHT = 30;
export const EXPANDED_ROW_HEIGHT = 60;
class SuggestedOrdersOrdersTabContainer extends Component {
	render() {
		return (
			<SuggestedOrdersOrdersTab {...this.props}/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const primaryOrderLine = storeUtilities.getValue([props.screenId, 'PrimaryOrderLine'], undefined);
	const rowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], []);
	const selectedRowCount = storeUtilities.getValue([props.screenId, 'suggestedOrdersOrdersGridSelectedRowsCount'], 0);
	const usePDMPieceCountInUse = storeUtilities.getValue([props.screenId, 'usePDMPieceCountInUse'], false);
	rowData.forEach(row => {
		row['PRIMARYSKU'] = row.sku === primaryOrderLine?.SKU;
	});
	return {
		primaryOrderLine,
		rowData,
		selectedRowCount,
		usePDMPieceCountInUse
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const whsList = sharedUtilities.getWhsList();
	return {
		onLoad: () => {
			const totalOrderQtyPath = [props.screenId, 'totalOrderQty'];
			storeUtilities.updateValue(dispatch, totalOrderQtyPath, 0);
		},
		cannotBePrimary: (node) => {
			const order = numberUtilities.getNumberOrDefault(node?.data?.ORDERLINE?.Order, 0);
			return order <= 0;
		},
		getTooltips: (cellProps) => {
			if(cellProps && cellProps.node && cellProps.node.data && cellProps.column){
				switch(cellProps.colDef.field){
					case 'PRIMARYSKU':
						return cellProps.node.data.ORDERLINE.Order === 0 ? ['Order must have a non-zero value to designate a SKU as Primary']: [];
					case 'ORDER':
						return getOrderCellTooltips(cellProps);
					default: 
						return [];
				}
			}
		},
		getHasNoSalesHistory: (cellProps) => {
			return cellProps !== undefined && cellProps.data !== undefined && cellProps.data.ORDERLINE !== undefined
				? numberUtilities.getNumberOrDefault(cellProps.data.ORDERLINE.WeeklySales) <= 0
				: true;
		},
		handlePrimaryChanged: (gridApi, changedRowNode, newValue) => {
			const changedRowSku = changedRowNode.data.ORDERLINE.SKU;
			let primaryNode = undefined;
			gridApi.forEachNode(node => {
				if(changedRowSku === node.data.ORDERLINE.SKU && newValue === true){
					primaryNode = node;
				}
			});
			const primaryNodeOrderLine = primaryNode ? primaryNode.data.ORDERLINE : undefined;
			orderUtilities.setPrimarySku(orderUtilities.getOrderLinesFromGrid(gridApi), primaryNodeOrderLine, gridApi);
			if(primaryNode !== undefined){
				storeUtilities.updateValue(dispatch, [props.screenId, 'PrimaryOrderLine'], primaryNodeOrderLine);
			}
			else {
				storeUtilities.deleteValue(dispatch, [props.screenId, 'PrimaryOrderLine']);
			}
			orderUtilities.refreshSuggestedOrdersGrids({ordersGridApi: gridApi, detailsGridApi: props?.detailsGridRef?.api, excludeRatios: props.excludeRatios, changedRowNode });
		},
		onDescriptionColumnExpandCollapseClicked: (gridApi, isExpanded) => {
			const rowHeight = isExpanded ? EXPANDED_ROW_HEIGHT : DEFAULT_ROW_HEIGHT;
			storeUtilities.updateValue(dispatch, [props.screenId, 'rowHeight'], rowHeight);
			gridApi.forEachNode(node => {
				node.setRowHeight(rowHeight);
			});
			gridApi.resetRowHeights();
		},
		compareDescriptions: (desc1, desc2, node1, node2, isInverted) => {
			if(desc1.detail === desc2.detail){
				return 0;
			}
			return isInverted ? (desc1.detail >= desc2.detail ? 1 : -1) : (desc1.detail < desc2.detail ? -1 : 1);
		},
		onOrderChanged: (gridApi, changedRowNode, newValue) => {
			const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], undefined);
			orderUtilities.handleOrderChanged({
				dispatch, 
				path: [props.screenId], 
				newValue,
				orderLine: changedRowNode.data.ORDERLINE,
				callback: (modifiedOrderLine) => {
					const primaryOrderLine = storeUtilities.getValue([props.screenId, 'PrimaryOrderLine'], undefined);
					if(primaryOrderLine?.SKU === changedRowNode.data.ORDERLINE.SKU && modifiedOrderLine.Order === 0){
						changedRowNode.setDataValue('PRIMARYSKU', false);
						storeUtilities.deleteValue(dispatch, [props.screenId, 'PrimaryOrderLine']);
					}
					orderUtilities.refreshSuggestedOrdersGrids({ordersGridApi: gridApi, detailsGridApi: props?.detailsGridRef?.api, excludeRatios: props.excludeRatios, changedRowNode});
					orderUtilities.calculateAndUpdateTotalOrderQty(dispatch, [props.screenId], gridApi);
				},
				orderLinesRowData
			});
		},
		onRatioChanged: (gridApi, changedRowNode, newValue) => {
			orderUtilities.handleRatioChanged(gridApi, changedRowNode, newValue, props.excludeRatios,
				(modifiedOrderLine) => {
					changedRowNode.setDataValue('AAOR', modifiedOrderLine.Ratio);
					changedRowNode.setDataValue('ORDERLINE', modifiedOrderLine);
					changedRowNode.setDataValue('ORDER', modifiedOrderLine.Order);
					const primaryOrderLine = storeUtilities.getValue([props.screenId, 'PrimaryOrderLine'], undefined);
					if(primaryOrderLine?.SKU === modifiedOrderLine.SKU && modifiedOrderLine.Order === 0){
						changedRowNode.setDataValue('PRIMARYSKU', false);
						storeUtilities.deleteValue(dispatch, [props.screenId, 'PrimaryOrderLine']);
					}
					orderUtilities.refreshSuggestedOrdersGrids({ordersGridApi: gridApi, detailsGridApi: props?.detailsGridRef?.api, excludeRatios: props.excludeRatios, changedRowNode});
					orderUtilities.calculateAndUpdateTotalOrderQty(dispatch, [props.screenId], gridApi);
				});
		},
		onAAOChanged: (gridApi, changedRowNode, newValue) => {
			const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], undefined);
			orderUtilities.handleAAOChanged({
				dispatch, 
				path: [props.screenId], 
				newValue, 
				orderLine: 
				changedRowNode.data.ORDERLINE, 
				callback: (modifiedOrderLine) => {
					const expectedOrderValue = Number(modifiedOrderLine.AvailableAfterOrder || 0) - Number(modifiedOrderLine.LTAVAIL);
					const primaryOrderLine = storeUtilities.getValue([props.screenId, 'PrimaryOrderLine'], undefined);
					if(primaryOrderLine?.SKU === changedRowNode.data.ORDERLINE.SKU && expectedOrderValue === 0){
						changedRowNode.setDataValue('PRIMARYSKU', false);
						storeUtilities.deleteValue(dispatch, [props.screenId, 'PrimaryOrderLine']);
					}
					orderUtilities.refreshSuggestedOrdersGrids({ordersGridApi: gridApi, detailsGridApi: props?.detailsGridRef?.api, excludeRatios: props.excludeRatios, changedRowNode});
					orderUtilities.calculateAndUpdateTotalOrderQty(dispatch, [props.screenId], gridApi);
				},
				orderLinesRowData
			});
		},
		getIsCellHidden: (cellProps) => {
			switch(cellProps.colDef.field){
				case 'AAOR':
					return (cellProps.node && cellProps.node.data && cellProps.node.data.PRIMARYSKU) || props.excludeRatios;
				default: 
					return false;
			}
		},
		getColumnValue: (obj) => {
			if(obj.data && obj.data.ORDERLINE){
				return orderUtilities.getSuggestedOrdersOrdersGridValueFromOrderLine(props.screenId, obj.data.ORDERLINE, obj.colDef.field, props.excludeRatios);
			}
		},
		getFormattedColumnValue: (obj) => {
			switch(obj.colDef.field){
				case 'AVAILABLE':
				case 'EXPRAVAIL':
				case 'AAO':
				case 'EXPRAAO':
				case 'NEWWOS':
				case 'ORDER':
					return gridUtilities.formatDecimal(Math.ceil(obj.value), 0, true);
				case 'WEEKLYSALES':
					return gridUtilities.formatDecimal(Math.round(obj.value), 0, true);
				case 'SALESRATIO':
				case 'AAOR':
					return String(obj.value === '' ? '' : gridUtilities.formatDecimal(obj.value, 2, true));
				default:
					return undefined;
			}
		},
		onCellClicked: (obj) => {
			if(obj.event.ctrlKey === true){
				return;
			}
			const callHandleOrderChanged = (orderValue, orderLine, altOrderLine, altSkuType) => {
				const changedRowNode = orderUtilities.getNode(obj.api, orderLine.SKU);
				const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], undefined);
				orderUtilities.handleOrderChanged({
					dispatch,
					path: [props.screenId],
					newValue: orderValue,
					orderLine,
					altOrderLine,
					altSkuType,
					callback: () => {
						changedRowNode.setDataValue('ORDER', orderValue);
						const primaryOrderLine = storeUtilities.getValue([props.screenId, 'PrimaryOrderLine'], undefined);
						const value = numberUtilities.getNumberOrDefault(orderValue, 0);
						if(primaryOrderLine?.SKU === changedRowNode.data.ORDERLINE.SKU && value === 0){
							changedRowNode.setDataValue('PRIMARYSKU', false);
							storeUtilities.deleteValue(dispatch, [props.screenId, 'PrimaryOrderLine']);
						}
						orderUtilities.refreshSuggestedOrdersGrids({ordersGridApi: obj?.api, detailsGridApi: props?.detailsGridRef?.api, excludeRatios: props.excludeRatios, changedRowNode });
						orderUtilities.calculateAndUpdateTotalOrderQty(dispatch, [props.screenId], obj.api);
					},
					orderLinesRowData
				});
			};
			if(obj.column.colId === 'SKU'){
				const path = orderUtilities.getAltSkuOverridePath(props.screenId, obj.data.sku);
				dispatch(showDialogAction(
					<SingleSkuAltSkuDialogContainer 
						screenId={props.screenId} 
						panelId={'AltSkuDialogPanel'}
						parentPanelId={props.panelId}
						path={path}
						orderLine={cloneDeep(obj.data.ORIGINAL_ORDERLINE)}
						originalOrderLine={cloneDeep(obj.data.ORIGINAL_ORDERLINE)}
						onSkuTypeChanged={(orderValue, orderLine, altOrderLine, altSkuType) =>{ 
							callHandleOrderChanged(orderValue, orderLine, altOrderLine, altSkuType);
						}}
						onDialogClosed={(orderValue, orderLine, altOrderLine, altSkuType) => {
							callHandleOrderChanged(orderValue, orderLine, altOrderLine, altSkuType);
						}}
					/>, 
					400, 
					780, 
					()=>{},
					true,
					undefined,
					true,
					false,
					true));
			}
		},
		onGridReady: (obj) => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'sizeSuggestedOrdersOrdersTabGridColumnsToFit'], () => {
				obj.api.sizeColumnsToFit(); 
			});
			if(props.onGridReady){
				props.onGridReady(obj);
			}
		},
		tabToNextCell: (params) => {
			const primaryOrderLine = storeUtilities.getValue([props.screenId, 'PrimaryOrderLine'], undefined);
			const primarySKURowIndex = orderUtilities.getRowIndex(orderUtilities.getOrderLinesFromGrid(params.api), primaryOrderLine ? primaryOrderLine.SKU : undefined);
			
			const editableColumns = params.api.getAllDisplayedColumns().filter(x => x.colDef.editable).map(x => x.colId);
			const canMoveTo = (pos) => {
				if(props.excludeRatios || pos.y === primarySKURowIndex){
					const colId = editableColumns[pos.x];
					const colDef = params.api.getColumn(colId).colDef;
					const cellProps = {
						node: params.api.getRowNode(pos.y),
						colDef
					};
					const isHidden = colDef?.cellRendererParams?.getIsHidden 
						? colDef.cellRendererParams.getIsHidden(cellProps) 
						: false;
					return isHidden === false;
				}
				return true;
			};
			const boundary = {
				xMin: 0,
				xMax: editableColumns.length - 1,
				yMin: 0,
				yMax: params.api.getDisplayedRowCount() - 1
			};
			const prevPos = {
				y: params.previousCellPosition.rowIndex,
				x: editableColumns.findIndex(x => x === params.previousCellPosition.column.colId)
			};
			const nextPos = gridUtilities.getNextCellPos(prevPos, boundary, canMoveTo, params.backwards);
			const nextCol = params.api.getColumn(editableColumns[nextPos.x]);
			const tabToNextCellParams = {
				rowIndex: nextPos.y,
				column: nextCol,
				rowPinned: params.previousCellPosition.rowPinned
			};
			return tabToNextCellParams;
		},
		getRowHeight: () => {
			const rowHeight = storeUtilities.getValue([props.screenId, 'rowHeight'], DEFAULT_ROW_HEIGHT);
			if(props.getRowHeight){
				return props.getRowHeight(rowHeight);
			}
			return rowHeight;
		},
		getOrderColumnAttentionMessage: (cellProps) => {
			if(typeof cellProps?.data?.ORDERLINE?.ExcessJson === 'string'
			&& cellProps.data.ORDERLINE.ExcessJson.trim() !== ''){
				const obj = JSON.parse(cellProps.data.ORDERLINE.ExcessJson);
				const listItems = Object.values(obj).map(v => {
					const whsId = v.division + '_' + v.whsNumber;
					const matches = whsList.filter(x => x.whsId === whsId);
					const whs = matches.length === 1 ? matches[0].whsName : whsId;
					return <li key={whsId}>{whs} - {v.excessNetAvailable}</li>;
				});
				return <ul>{listItems}</ul>;
			}
			return undefined;
		},
		onDataChanged: (gridApi) => {
			orderUtilities.refreshSuggestedOrdersGrids({ordersGridApi: gridApi, detailsGridApi: props?.detailsGridRef?.api, excludeRatios: props.excludeRatios});
		},
		postSortRows: (params) => {
			const currentTab = storeUtilities.getValue([props.screenId, 'suggestedOrdersTabShown'], 'Orders');
			const rowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], []);
			if(currentTab === 'Details' || rowData.length <= 0){
				return;
			}
			const orderedRowData = [];
			if(params?.nodes?.forEach){
				params.nodes.forEach((node) => {
					if(node?.data?.ORDERLINE?.SKU !== undefined){
						const row = rowData.find(x => x.ORDERLINE.SKU === node.data.ORDERLINE.SKU);
						if(row !== undefined){
							orderedRowData.push(row);
						}
					}
				});
			}
			const detailsGridRef = props.getDetailsGridRef();
			if(detailsGridRef?.api !== undefined){
				const detailsGridColumnState = detailsGridRef.api.getColumnState();
				detailsGridColumnState.forEach((column) => {
					column.sort = null;
				});
				detailsGridRef.api.applyColumnState({state: detailsGridColumnState, defaultState: { sort: null }});
			}
			window.setTimeout(() => {
				storeUtilities.updateValue(dispatch, [props.screenId, 'OrderLinesRowData'], orderedRowData.length > 0 ? orderedRowData : rowData);
			}, 500);
		},
		getFlowAllLink: (params) => {
			const flowUrl = storeUtilities.getValue([props.screenId, 'flowAllURL'], undefined);
			if(flowUrl === undefined){
				return '';
			}
			const link = urljoin(flowUrl, params.node.data.ORDERLINE.SKU);
			return link;
		}
	};
};

const getOrderCellTooltips = (cellProps) => {
	const tooltips = [];
	if(cellProps.data.ORDERLINE.WeeklySales <= 0){
		tooltips.push('WARNING: No Sales History');
	}

	return tooltips;
};
SuggestedOrdersOrdersTabContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	excludeRatios: PropTypes.bool,
	disablePanel: PropTypes.bool,
	height: PropTypes.number,
	forwardRef: PropTypes.any,
	ordersGridRef: PropTypes.object,
	detailsGridRef: PropTypes.object,
	selectedRowCount: PropTypes.number,

	//functions
	cannotBePrimary: PropTypes.func,
	compareDescriptions: PropTypes.func,
	getTooltips: PropTypes.func,
	handlePrimaryChanged: PropTypes.func,
	getColumnValue: PropTypes.func,
	getFormattedColumnValue: PropTypes.func,
	getHasNoSalesHistory: PropTypes.func,
	getIsCellHidden: PropTypes.func,
	getOrderColumnAttentionMessage: PropTypes.func,
	getRowHeight: PropTypes.func,
	handleRowClicked: PropTypes.func,
	onCellClicked: PropTypes.func,
	onDescriptionColumnExpandCollapseClicked: PropTypes.func,
	onGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	onAAOChanged: PropTypes.func,
	onOrderChanged: PropTypes.func,
	onRatioChanged: PropTypes.func,
	tabToNextCell: PropTypes.func,
	getDetailsGridRef: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(SuggestedOrdersOrdersTabContainer);