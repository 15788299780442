import React, { Component } from 'react';
import { Provider } from 'react-redux';
import MainContainer from './components/main/MainContainer';
import store, { history } from './configureStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import './components/widgets/css/ag-theme-balham-overrides.css';
import './css/App.css';
/***
 * Todo: figure out how to get rollup in the @rtg/shared-react-components project to export 
 * the following css files so copies of them do not have to be maintained here.
 */
import './css/Dropdown.css';
import './css/MultiSelectDropdown.css';

class App extends Component {
	render() {
		return (
			<Provider store={store} style={{height:'100%'}}>
				<MainContainer history={history}/>
			</Provider>
		);
	}
}

export default App;
