import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import AddClearanceSkusSearchProductsListResultsPanel from './AddClearanceSkusSearchProductsListResultsPanel';
export const PANEL_ID = 'addClearanceSkusSearchProductsListResultsPanel';

class AddClearanceSkusSearchProductsListResultsPanelContainer extends Component {
	render() {
		return <AddClearanceSkusSearchProductsListResultsPanel
			screenId={this.props.screenId}
			panelId={PANEL_ID}
			rowData={this.props.rowData}
			onLoad={this.props.onLoad}
			onGridReady={this.props.onGridReady}
			addNewSkusTabPath={this.props.addNewSkusTabPath}
			handleRowClicked={this.props.handleRowClicked}
		/>;
	}
}

const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue(props.addNewSkusTabPath.concat(['ProductSearchResultsGrid', 'rowData']));
	return {
		rowData
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {   
		onGridReady: (params) => {
			params.api.sizeColumnsToFit();
			storeUtilities.updateValue(dispatch, [...props.addNewSkusTabPath, 'sizeProductSearchResultsGridColumnsToFit'], () => {
				params.api.sizeColumnsToFit(); 
			}, true, false);
		},
		handleRowClicked: (params) => {
			storeUtilities.updateValue(dispatch, [...props.addNewSkusTabPath, 'selectedRows'], params.api.getSelectedRows(), true, false);
		}
	};
};

AddClearanceSkusSearchProductsListResultsPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	onGridReady: PropTypes.func,
	rowData: PropTypes.array,
	onLoad: PropTypes.func,
	addNewSkusTabPath: PropTypes.array.isRequired,
	handleRowClicked: PropTypes.func
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AddClearanceSkusSearchProductsListResultsPanelContainer);
export default connectedComponent;
