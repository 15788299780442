import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import updateDefaultValuesAction from '../../../../actions/updateDefaultValuesAction';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import ViewPODialog from './ViewPODialog';
import { nodeRoutes } from '../../../../constants';
import rpcAction from '../../../../actions/rpcAction';
class ViewPODialogContainer extends Component {
	render() {
		return (
			<ViewPODialog {...this.props} />
		);
	}
}
const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, 'POListResultsGrid', 'rowData']);
	
	return {
		rowData: rowData !== undefined && Array.isArray(rowData) ? rowData : []
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			dispatch(updateDefaultValuesAction([props.screenId], props.defaultValues));
			if (!(props.polist===undefined || props.polist==='')){
				getPOSKUQty(props, dispatch);
			}
		},
		handleCloseClicked: (callback) => {
			storeUtilities.deleteValue(dispatch, props.path);
			if(callback !== undefined){
				callback();
			}
		},
		handleOkClicked: (callback) => {
			storeUtilities.deleteValue(dispatch, props.path);
			if(callback !== undefined){
				callback();
			}
		},
		onGridReady: (obj) => {
			obj.api.sizeColumnsToFit();
			storeUtilities.updateValue(dispatch, [props.screenId, 'sizePOSearchResultsGridColumnsToFit'], () => {
				obj.api.sizeColumnsToFit(); 
			});
		}
	};
};

const getPOSKUQty = (props, dispatch) => {
	dispatch(rpcAction({
		args: { PurchaseOrderNumbers: props.polist },
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: 'Warehouse/PurchaseOrdersSkuQuantity',
		method: 'GET',
		showLoadingMask: true,
		hideLoadingMaskOnComplete: true,
		callback: (rowData) => {
			if(rowData && Array.isArray(rowData)){
				storeUtilities.updateValue(dispatch, [props.screenId, 'POListResultsGrid', 'rowData'], rowData, true);
				const sizeColumnsToFit = storeUtilities.getValue([props.screenId, 'sizePOListResultsGridColumnsToFit'], undefined);
				if(sizeColumnsToFit !== undefined){
					sizeColumnsToFit();
				}
			}
		}

	}));
};

ViewPODialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	polist: PropTypes.string.isRequired,
	handleOkClicked: PropTypes.func,
	handleCloseClicked: PropTypes.func,
	parentPath: PropTypes.array.isRequired,
	path: PropTypes.array.isRequired,
	onDialogClosed: PropTypes.func,
	onGridReady: PropTypes.func,
	onLoad: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ViewPODialogContainer);

connectedComponent.defaultProps = {
	isDialogOpen: false
};

export default connectedComponent;