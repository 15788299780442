/**
 * @module User
 * 
 * A User has 4 possible permissions represented by the flags field.
 * 
 * The permissions are stored as 4 bits.
 * [bit 4][bit 3][bit 2][bit 1][bit 0]
 * bit 4: ADMIN
 * bit 3: BUYER
 * bit 2: PARTS
 * bit 1: CLEARANCE
 * bit 0: READONLY
 * 
 * If you have Admin access, you will have access to everything (including any settings-related screens added to this app in the future).
 * If you have Buyer access, you will have access to everything EXCEPT for the Maintenance Manager screen (including *no access* to any settings-related screens added to this app in the future).
 * If you have Parts access, you will have access to the Parts screens and the Daily Order Log screen.
 * If you have Clearance access, you will have access to the Clearance screen.
 * If you have ReadOnly access, you will have the ability to view the Product screen, but you won't be able to create POs or modify any records of any kind, whatsoever
 * 
 * If your permissions are: 00111 and you bitwise-and it with 8, you will get 0. This means you DO NOT have admin access.
 * 		example: 	7 & 8 => 00111 bitwise-and 01000
 *  				0 and 1 = 0
 * 					1 and 0	= 0
 * 					1 and 0 = 0
 * 					1 and 0 = 0
 * 		===> 00000 = 0
 * If your permissions are: 01010 and you bitwise-and it with 8, you will get 8. This means you have admin access.
 * 		example: 	10 & 8 => 01010 bitwise-and 01000
 *  				1 and 1 = 1
 * 					0 and 0	= 0
 * 					1 and 0 = 0
 * 					0 and 0 = 0
 * 		===> 01000 = 8
 * 
 *  So, in general: 
 *  -if you bitwise-and some value with 16 (10000), and you get 16 back, then you have admin access.
 *  -if you bitwise-and some value with 16 (10000), and you DON'T get 16 back, then you DO NOT have admin access.
 * 	
 *  -if you bitwise-and some value with 8 (01000), and you get 8 back, then you have buyer access.
 *  -if you bitwise-and some value with 8 (01000), and you DON'T get 8 back, then you DO NOT have buyer access.
 * 
 * 	-if you bitwise-and some value with 4 (00100), and you get 4 back, then you have parts access.
 *  -if you bitwise-and some value with 4 (00100), and you DON'T get 4 back, then you DO NOT have parts access.
 * 	
 *  -if you bitwise-and some value with 2 (00010), and you get 2 back, then you have clearance access.
 *  -if you bitwise-and some value with 2 (00010), and you DON'T get 2 back, then you DO NOT have clearance access.
 * 
 * 	-if you bitwise-and some value with 1 (00001), and you get 1 back, then you have readonly access.
 *  -if you bitwise-and some value with 1 (00001), and you DON'T get 1 back, then you DO NOT have readonly access.
 * 
 *  CHEAT SHEET 
 * [--------ADMIN--------] [not necessary to check membership in other groups]
 * 1 in the first column
 * 10000 = 16,	11000 = 24		
 * 10001 = 17,	11001 = 25
 * 10010 = 18,	11010 = 26
 * 10011 = 19,	11011 = 27
 * 10100 = 20,	11100 = 28
 * 10101 = 21,	11101 = 29
 * 10110 = 22,	11110 = 30
 * 10111 = 23,	11111 = 31
 *  
 * [--------BUYER--------]
 * 1 in the second column
 * 01000 =  8
 * 01001 =  9
 * 01010 = 10
 * 01011 = 11
 * 01100 = 12
 * 01101 = 13
 * 01110 = 14
 * 01111 = 15
 * 
 * [--------PARTS--------]
 * 1 in the third column
 * 00100 = 4
 * 00101 = 5
 * 00110 = 6
 * 00111 = 7
 * 
 * [--------CLEARANCE--------]
 * 1 in the fourth column
 * 00010 = 2
 * 00011 = 3
 * 
 * [--------READONLY--------]
 * 1 in the fifth column
 * 00001 = 1 
 */
export default class User {
	constructor(){
		this.userID = 0;
		this.rtgID = '';
		this.firstName = '';
		this.lastName = '';
		this.fullName = '';
		this.email = '';
		this.flags = 0;
	}
	hasAdminAccess = () => {
		return (this.flags & 16) === 16;
	};
	hasBuyerAccess = () => {
		return (this.flags & 8) === 8; 
	};
	hasPartsAccess = () => {
		return (this.flags & 4) === 4; 
	};
	hasClearanceAccess = () => {
		return (this.flags & 2) === 2; 
	};
	hasReadOnlyAccess = () => {
		return (this.flags & 1) === 1;
	};
}