import { put, delay } from 'redux-saga/effects';
import updateGlobalAppStateValueAction from '../actions/updateGlobalAppStateValueAction';
import pushDialogAction from '../actions/pushDialogAction';

export default function* showDialogSaga(showDialogAction) {
	yield put(pushDialogAction(showDialogAction));
	yield delay(50);
	yield put(updateGlobalAppStateValueAction(['beginShowDialogAnimation'], true));
	yield delay(50);
	yield put(updateGlobalAppStateValueAction(['beginShowDialogAnimation'], false));
}