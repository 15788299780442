import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import * as ramda from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../../actions/showDialogAction';
import updateValidationErrorsAction from '../../../actions/updateValidationErrorsAction';
import { nodeRoutes } from '../../../constants';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import RemoveSkuDialogContainer from './removeclearanceskudialog/RemoveSkuDialogContainer';
import VendorClearanceOrderGridPanel from './VendorClearanceOrderGridPanel';
import rpcAction from '../../../actions/rpcAction';

class VendorClearanceOrderGridPanelContainer extends Component {
	render() {
		return (
			<VendorClearanceOrderGridPanel 
				screenId={this.props.screenId}
				panelId={this.props.panelId}
				rowData={this.props.rowData}
				vendorCode={this.props.vendorCode}
				index={this.props.index}
				panelStyle={this.props.panelStyle}
				getColumnValue={this.props.getColumnValue}
				getFormattedColumnValue={this.props.getFormattedColumnValue}
				onValueChanged={this.props.onValueChanged}
				onEnterPressed={this.props.onEnterPressed}
				getIsInvalid={this.props.getIsInvalid}
				getTooltips={this.props.getTooltips}
				onDeleteRowClicked={this.props.onDeleteRowClicked}
				gridRef={this.props.gridRef}
				onModelUpdated={this.props.onModelUpdated}
				cubesOverLimit={this.props.cubesOverLimit}
				onCubesLimitChanged={this.props.onCubesLimitChanged}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const cubesOverLimit = validateCubesLimit(props.screenId, props.vendorCode);
	return {
		panelId: getPanelId(props.vendorCode),
		cubesOverLimit
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		getColumnValue: (params) => {
			if(params.colDef.colId === 'AAO'){
				return numberUtilities.getNumberOrDefault(params.data.ROWMODEL.AVAILABLE, 0) + numberUtilities.getNumberOrDefault(params.data.ROWMODEL.ORD, 0);
			}
			return (params.colDef.colId in params.data.ROWMODEL) ? params.data.ROWMODEL[params.colDef.colId] : undefined;
		},
		getFormattedColumnValue: (params) => {
			return String(params.value);
		},
		onValueChanged: (params) => {
			/**
			 * It is critically important that you only change the values in params.node.data.ROWMODEL.
			 * Since we *do not* save these values to the store (the grid itself is the store), we will 
			 * compare the values in params.node.data.ROWMODEL to their corresponding values in params.node.data.ROWMODEL_ORIGINAL.
			 * If they are different and the node has not been saved, then it will show the tooltip that it needs to be saved.
			 * ONLY once saved, will we update the value in params.node.data.ROWMODEL_ORIGINAL.
			 */
			const data = params.node.data;
			switch(params.colId){
				case 'QTYONDISPLAY':
				case 'DAYSTOSTOCK':
				case 'TARGETQTY':
				case 'ORD':
					data.ROWMODEL[params.colId] = numberUtilities.getNumberOrDefault(params.value, 0);
					break;
				default:
					data.ROWMODEL[params.colId] = params.value;
			}
			params.node.setData(data);
			params.gridApi.refreshCells({rowNodes: [params.node], force:true});
			updateTotalCubes(dispatch, [props.screenId, getPanelId(props.vendorCode), 'cubesTotal'], params.gridApi);
		},
		onEnterPressed: (params) => {
			const warehouse = storeUtilities.getValue([props.screenId, 'whsNum']);
			const qty = numberUtilities.getNumberOrDefault(params.value, 0);
			const sku = params.node.data.ROWMODEL.SKU;
			const args = { warehouse, sku };
			let requestType = 'PUT';
			
			if(params.colId === 'TARGETQTY'){
				args.targetQty = qty;
			}
			else if(params.colId === 'QTYONDISPLAY'){
				args.storeId = warehouse;
				args.displayQuantity = qty;
				requestType = 'POST';
			}
			else if(params.colId === 'DAYSTOSTOCK'){
				args.qty = qty;
			}

			params.disable();
			const updateFunction = (endpontPath) => {
				dispatch(rpcAction({
					args: args, 
					nodeRoute: nodeRoutes.IFRServiceNET, 
					endpoint: endpontPath, 
					method: requestType,
					showLoadingMask: false,
					callback: () => {
					const data = params.node.data;
					switch(params.colId){
						case 'QTYONDISPLAY':
						case 'DAYSTOSTOCK':
						case 'TARGETQTY':
							data.ROWMODEL_ORIGINAL[params.colId] = numberUtilities.getNumberOrDefault(params.value);
							break;
						default:
							data.ROWMODEL_ORIGINAL[params.colId] = params.value;
					}
					const rowModel = data.ROWMODEL;

					const tempAvailable = rowModel.QOH - rowModel.OPENORDERS - rowModel.QTYONDISPLAY + rowModel.QTYONORDER;
					const dailySalesRate = rowModel.T30SALES / 30;
					const dailySales = dailySalesRate * rowModel.DAYSTOSTOCK;

					if(rowModel.TARGETQTY > dailySales && (rowModel.TARGETQTY - tempAvailable) > 0)
					{
						rowModel.ORD = Math.ceil(rowModel.TARGETQTY - tempAvailable);
						rowModel.AVAILABLE = tempAvailable;	
					}					
					else if((dailySales - tempAvailable) > 0)
					{
						rowModel.ORD = Math.ceil(dailySales - tempAvailable);
						rowModel.AVAILABLE = tempAvailable;
					}	
					else
					{
						rowModel.ORD = 0;
						rowModel.AVAILABLE = tempAvailable;
					}
					params.node.setDataValue('ROWMODEL', rowModel);
					params.node.setDataValue('ROWMODEL_ORIGINAL', cloneDeep(rowModel));
					params.gridApi.refreshCells({rowNodes: [params.node], force: true});
					params.enable();
					params.validate();
					}
				}));
			};
			switch(params.colId){
				case 'QTYONDISPLAY':
					updateFunction('InventoryTarget/UpdateDisplayQuantity');
					break;
				case 'DAYSTOSTOCK':
					updateFunction('InventoryTarget/DaysToStock/Update');
					break;
				case 'TARGETQTY':
					updateFunction('InventoryTarget/SkuTargetQuantity/Update');
					break;
				default:
					return;
			}
		},
		getIsInvalid: (params) => {
			return cellIsUnsaved(params);
		},
		getTooltips: (params, isRenderer) => {
			return cellIsUnsaved(params) 
				? (isRenderer ? ['Click cell to save'] : ['Press \'Enter\' to save'])
				: []; 
		},
		onDeleteRowClicked: (node) => {
			dispatch(showDialogAction(
				<RemoveSkuDialogContainer
					screenId={props.screenId}
					sku={node.data.ROWMODEL.SKU}/>,
				200, 
				350, 
				()=>{},
				false,
				undefined,
				true,
				false));
		},
		onModelUpdated: (gridApi) => {
			updateTotalCubes(dispatch, [props.screenId, getPanelId(props.vendorCode), 'cubesTotal'], gridApi);
		},
		onCubesLimitChanged: () => {
			const cubesOverLimit = validateCubesLimit(props.screenId, props.vendorCode);
			const path = [props.screenId, getPanelId(props.vendorCode), 'cubesTotal'];
			if(cubesOverLimit === true){
				dispatch(updateValidationErrorsAction(path, ['Cubes over limit'], false));
			}
			else {
				dispatch(updateValidationErrorsAction(path, [], false));
			}
		}
	};
};
const cellIsUnsaved = (params) => {
	const original = numberUtilities.getNumberOrDefault(params.data.ROWMODEL_ORIGINAL[params.column.colId]);
	const updated = numberUtilities.getNumberOrDefault(params.data.ROWMODEL[params.column.colId]);
	const isUnsaved = original !== updated;
	return isUnsaved;
};
const updateTotalCubes = (dispatch, path, gridApi) => {
	let totalCubes = 0;
	gridApi.forEachNode((node)=>{
		const order = parseFloat(ramda.pathOr(0, ['data', 'ROWMODEL', 'ORD'])(node));
		const cube = parseFloat(ramda.pathOr(0, ['data', 'ROWMODEL', 'CUBE'])(node));
		const cubes = order * cube;
		if(cubes >= 0){
			totalCubes += cubes;
		}
	});
	storeUtilities.updateValue(dispatch, path, String(totalCubes.toFixed ? totalCubes.toFixed(2) : 0));
};
const getPanelId = (vendorCode) => {
	return 'panel_' + vendorCode;
};
const validateCubesLimit = (screenId, vendorCode) => {
	const cubesLimit = storeUtilities.getValue([screenId, getPanelId(vendorCode), 'cubesLimit'], undefined);
	const cubesTotal = storeUtilities.getValue([screenId, getPanelId(vendorCode), 'cubesTotal'], undefined);
	let cubesOverLimit = false;
	if(cubesLimit !== undefined && cubesTotal !== undefined){
		cubesOverLimit = numberUtilities.getNumberOrDefault(cubesTotal, 0) > numberUtilities.getNumberOrDefault(cubesLimit) ? true : false;
	}
	return cubesOverLimit;
};

VendorClearanceOrderGridPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	vendorCode: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	panelStyle: PropTypes.object,
	gridRef: PropTypes.any
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(VendorClearanceOrderGridPanelContainer);
export default connectedComponent;