import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import AddClearanceSkusSearchProductsListPanelContainer from './AddClearanceSkusSearchProductsListPanelContainer';
import AddClearanceSkusSearchProductsListResultsPanelContainer from './AddClearanceSkusSearchProductsListResultsPanelContainer';
class AddClearanceSkusSearchProductsListTab extends Component {
	render() {
		return (
			<Container fluid={true} className={'h-100 m-0 p-0 pt-2 pb-2'}>
				<Row noGutters className={'h-100 m-0 p-0'}>
					<Col xs={3} sm={3} md={3} lg={3} xl={3} className={'h-100'}>
						<AddClearanceSkusSearchProductsListPanelContainer 
							screenId={this.props.screenId}
							addNewSkusTabPath={this.props.addNewSkusTabPath}
						/>
					</Col>
					<Col xs={9} sm={9} md={9} lg={9} xl={9} className={'pl-2'}>
						<AddClearanceSkusSearchProductsListResultsPanelContainer 
							screenId={this.props.screenId}
							addNewSkusTabPath={this.props.addNewSkusTabPath}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

AddClearanceSkusSearchProductsListTab.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	addNewSkusTabPath: PropTypes.array.isRequired,
	onBeforeLoad: PropTypes.func
};
export default AddClearanceSkusSearchProductsListTab;
