import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { keyCodes } from '../../constants';
import withLabel from '../../higherordercomponents/WithLabel';
import withTooltip from '../../higherordercomponents/WithTooltip';
class TextBox extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad((val) => {
				this.validate({value: val, label: this.props.label}, true);
			});
		}
	}
	equals = (val, otherVal) => {
		const _val = val === undefined ? '' : val;
		const _otherVal = otherVal === undefined ? '' : otherVal;
		return _val === _otherVal;
	};
	componentDidUpdate(prevProps){
		if(prevProps.disabled !== true && this.props.disabled === true){
			this.hideValidationErrors(true);
		}
		else if (prevProps.disabled === true && this.props.disabled !== true) {
			this.hideValidationErrors(false);
		}
		else if(this.equals(prevProps.value, this.props.value) === false){
			this.change(this.props.value);
		}
	}
	hideValidationErrors = (blnHide) => {
		if(this.props.hideValidationErrors !== undefined){
			this.props.hideValidationErrors(this.props.path, this.props.disabled, ()=> {
				this.validate({value: this.props.value, label: this.props.label}, blnHide);
			});
		}
	};
	onChange = (e) => {
		if(this.props.maxCharacters !== undefined && !isNaN(this.props.maxCharacters) && this.props.maxCharacters.toString && this.props.maxCharacters.toString().trim() !== ''){
			const maxCharacters = parseInt(this.props.maxCharacters);
			if(e.target.value.length > maxCharacters){
				e.preventDefault();
				e.stopPropagation();
				return;
			}
		}
		this.change(e.target.value);
	};
	change = (v) => {
		const value = this.props.type === 'number' && v !== undefined && v.trim !== undefined && v.trim() === '' ? undefined : v;
		if(this.props.onChange){
			this.props.onChange(value);
		}
		this.validate({value, label: this.props.label}, false);
	};
	onKeyUp = (e) => {
		if(e.keyCode === keyCodes.ENTER && this.props.onEnterPressed){
			this.props.onEnterPressed(e.target.value);
		}
	};
	getId = () => {
		return (this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	};
	validate = (args, hideValidationErrors) => {
		if(this.props.disabled){
			return;
		}
		if(this.props.validate){
			this.props.validate({value: args.value, label: args.label}, hideValidationErrors);
		}
	};
	onBlur = (e) => {
		let value = undefined;
		if((this.props.type === 'number' || this.props.trimValue) && e.target.value !== undefined && e.target.value.trim !== undefined){
			value = e.target.value.trim();
		}
		else {
			value = e.target.value;
		}
		this.change(value);
	};
	render() {	
		const wrapperStyle = {display: (this.props.visible !== false ? 'block' : 'none')};
		if(this.props.height !== undefined){
			wrapperStyle.height = this.props.height;
		}
		const input = <Form.Control
			type={this.props.type === 'number' ? 'number' : 'text'} 
			style={{textAlign:  this.props.type === 'number' ? 'right' : 'left'}}
			onChange={this.onChange} 
			onKeyUp={this.onKeyUp} 
			value={this.props.value || ''}
			disabled={this.props.disabled}
			size={this.props.size}
			id={this.getId()}
			data-testid={this.getId()}
			isInvalid={this.props.isInvalid}
			onBlur={this.onBlur}
		/>;
	
		return <div style={wrapperStyle}>{input}</div>;
	}
}
TextBox.propTypes = {
	fieldId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	screenId: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onEnterPressed: PropTypes.func,
	onLoad: PropTypes.func,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	visible: PropTypes.bool,
	size: PropTypes.string,
	height: PropTypes.string,
	label: PropTypes.string,
	inputMinWidth: PropTypes.string,
	showRequiredAsterisk: PropTypes.bool,
	mainComponentBootstrapSize: PropTypes.number,
	maxCharacters: PropTypes.number,
	validate: PropTypes.func.isRequired,
	isInvalid: PropTypes.bool,
	tooltips: PropTypes.arrayOf(PropTypes.string),
	trimValue: PropTypes.bool,
	hideValidationErrors: PropTypes.func,
	path: PropTypes.array
};

export default withLabel(withTooltip(TextBox));
