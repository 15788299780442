import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import deleteCurrentValuesAction from '../../../actions/deleteCurrentValuesAction';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import SearchDailyOrdersPanel from './SearchDailyOrdersPanel';
import { nodeRoutes } from '../../../constants';
const PANEL_ID = 'searchDailyOrdersPanel';
class SearchDailyOrdersPanelContainer extends Component {
	render() {
		return (
			<SearchDailyOrdersPanel {...this.props}
				panelId={this.props.panelId || PANEL_ID}
			/>
		);
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		handleSearchClicked: () => {
			const orderMomentFrom = moment(storeUtilities.getValue([props.screenId, 'OrderDateFrom'], new Date()));
			const orderMomentTo = moment(storeUtilities.getValue([props.screenId, 'OrderDateTo'], new Date()));
			const formattedOrderDateFrom = formatDate(orderMomentFrom);
			const formattedOrderDateTo = formatDate(orderMomentTo.add(1, 'days'));
			const args = sharedUtilities.pruneObject({
				BuyerInitials: storeUtilities.getValue([props.screenId, 'BuyerInitials'], undefined),
				OrderDateFrom: formattedOrderDateFrom,
				OrderDateTo: formattedOrderDateTo,
				Sku: storeUtilities.getValue([props.screenId, 'Sku'], undefined),
				Vendor: storeUtilities.getValue([props.screenId, 'Vendor'], undefined),
				selectProducts: storeUtilities.getValue([props.screenId, 'selectProducts'], false),
				selectParts: storeUtilities.getValue([props.screenId, 'selectParts'], false)
			});
			dispatch(callRpcAction(args, nodeRoutes.IFRService, 'SearchDailyLog', (data) => {
				if(data && Array.isArray(data)){
					storeUtilities.updateValue(dispatch, [props.screenId, 'SearchDailyOrdersResultsGrid', 'rowData'], data, true, false);
				}
			}));
		},
		handleClearFormClicked: () => {
			dispatch(deleteCurrentValuesAction([props.screenId]));
		}
	};
};
const formatDate = (moment) => {
	return moment.format('YYYY-MM-DD 00:00:00');
};

SearchDailyOrdersPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	handleSubmitClicked: PropTypes.func,
	handleRowClicked: PropTypes.func,
	handleSelectAllClicked: PropTypes.func,
	handleDeselectAllClicked: PropTypes.func,
	submitButtonDisabled: PropTypes.bool,
	selectAllButtonDisabled: PropTypes.bool,
	deselectAllButtonDisabled: PropTypes.bool,
	rowData: PropTypes.array,
	handleClearFormClicked: PropTypes.func,
	onLoad: PropTypes.func
};
const connectedComponent = connect(null, mapDispatchToProps)(SearchDailyOrdersPanelContainer);

export default connectedComponent;