import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import withLabel from '../../higherordercomponents/WithLabel';
import withTooltip from '../../higherordercomponents/WithTooltip';

const DEFAULT_HEIGHT = 100;
class TextArea extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad((val) => {
				this.validate({value: val, label: this.props.label}, true);
			});
		}
	}
	componentDidUpdate(prevProps){
		if(prevProps.disabled !== true && this.props.disabled === true){
			this.hideValidationErrors(true);
		}
		else if (prevProps.disabled === true && this.props.disabled !== true) {
			this.hideValidationErrors(false);
		}
	}
	hideValidationErrors = (blnHide) => {
		if(this.props.hideValidationErrors !== undefined){
			this.props.hideValidationErrors(this.props.path, this.props.disabled, ()=> {
				this.validate({value: this.props.value, label: this.props.label}, blnHide);
			});
		}
	}
	onChange = (e) => {
		if(this.props.onChange){
			this.props.onChange(e.target.value);
		}
		this.validate(false);
	}
	getId = () => {
		return (this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	}
	validate = (isInitialValidationCheck) => {
		if(this.props.disabled){
			return;
		}
		if(this.props.validate){
			this.props.validate({value: this.props.value, label: this.props.label}, isInitialValidationCheck);
		}
	}
	render() {
		const textArea = (
			<Form.Control 
				as='textarea' 
				className={'pt-2'} 
				style={
					{
						display: this.props.visible !== false 
							? 'block'
							: 'none', 
						height: this.props.height 
							? this.props.height
							: DEFAULT_HEIGHT,
						maxHeight: this.props.maxHeight,
						maxWidth: this.props.maxWidth
					}
				} 
				value={this.props.value || ''}
				onChange={this.onChange}
				disabled={this.props.disabled}
				onBlur={this.validate}
				id={this.getId()}
				isInvalid={this.props.isInvalid}
			/>
		);
		return textArea;
	}
}

TextArea.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onLoad: PropTypes.func,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	height: PropTypes.string,
	width: PropTypes.string,
	maxHeight: PropTypes.string,
	maxWidth: PropTypes.string,
	validators: PropTypes.arrayOf(PropTypes.func),
	label: PropTypes.string,
	showRequiredAsterisk: PropTypes.bool,
	mainComponentBootstrapSize:PropTypes.number,
	validate: PropTypes.func.isRequired,
	isInvalid: PropTypes.bool,
	tooltips: PropTypes.arrayOf(PropTypes.string)
};

export default withTooltip(withLabel(TextArea));