import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import MaintenanceIcon from '../../formelements/ImageComponents/MaintenanceIcon';

class MaintenanceViewScreen extends Component {
	render() {
		return (
			<Container className={'h-100'} fluid={true}>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div style={{ height: '500px', width: '500px' }} >
						<h1 className='text-center text-sm-center text-md-center text-lg-center text-xl-center pr-2'>IFR is currently down for maintenance.</h1>
						<h5 className='text-center text-sm-center text-md-center text-lg-center text-xl-center pr-2'>Service will be restored as soon as possible.</h5>
						<p>&nbsp;</p>
						<MaintenanceIcon
							screenId={'N/A'}
							panelId={'N/A'}
							fieldId={'MainenanceIcon'}
						/></div>
				</div>
			</Container>
		);
	}
}
export default MaintenanceViewScreen;