import { UPDATE_VALIDATION_ERRORS_ACTION } from '../actionTypes';

const updateValidationErrorsAction = (path, errors, hideValidationErrors) => {
	return {
		type: UPDATE_VALIDATION_ERRORS_ACTION,
		path,
		errors,
		hideValidationErrors
	};
};
export default updateValidationErrorsAction;