import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import PurchaseOrderQueueScreen from './PurchaseOrderQueueScreen';
import * as poQueueUtilities from './PurchaseOrderQueueScreenUtilities';
const SCREEN_ID = 'purchaseOrderQueueScreen';

class PurchaseOrderQueueScreenContainer extends Component {
	render() {
		return (
			<PurchaseOrderQueueScreen {...this.props}
				screenId={this.props.screenId || SCREEN_ID} 
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const user = storeUtilities.getUser();
	const purchaseOrderQueueData = storeUtilities.getValue([screenId, 'purchaseOrderQueueData'], undefined);
	return {
		user,
		purchaseOrderQueueData
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	return {
		onLoad: () => {
			poQueueUtilities.reloadQueue(dispatch, screenId);
		}
	};
};

PurchaseOrderQueueScreenContainer.propTypes = {
	purchaseOrderQueueData: PropTypes.array
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderQueueScreenContainer);

export default connectedComponent;