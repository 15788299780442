import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { DataGridContainer } from '@rtg/shared-react-components';
import store from '../../../../configureStore';
class AddClearanceSkusSearchProductsListResultsPanel extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onGridReady(params) {
		if(this.props.onGridReady !== undefined){
			this.props.onGridReady(params);
		}
	}
	handleRowClicked(params){
		if(this.props.handleRowClicked){
			this.props.handleRowClicked(params);
		}
	}
	render() {
		const resultCount = this.props.rowData ? this.props.rowData.length : 0;
		return (
			<Card className={'h-100'}>
				<Card.Header>
					<h5 className='text-left'>Results Found: { resultCount }</h5>
				</Card.Header>
				<div className='h-100 w-100' style={{minHeight:'300px'}}>
					<DataGridContainer
						screenId={this.props.screenId} 
						panelId={this.props.panelId}
						fieldId='SearchProductsGrid' 
						defaultColDef={{resizable: true, sortable: true, filter: true}}
						rowSelection={'multiple'}
						columnDefs={
							[
								{ headerName: 'SKU', field: 'sku', colId: 'SKU'}, 
								{ headerName: 'Vendor', field: 'vendor', colId: 'VENDOR'},
								{ headerName: 'Style', field: 'style', colId: 'STYLE'},
								{ headerName: 'Description', field: 'description', colId: 'DESCRIPTION'},
								{ headerName: 'Color', field: 'color', colId: 'COLOR'},
								{ headerName: 'Finish', field: 'finish', colId: 'FINISH'},
								{ headerName: 'Family', field: 'family', colId: 'FAMILY'}
							]
						}
						onGridReady={this.onGridReady.bind(this)}
						rowData={this.props.rowData || []}
						onRowClicked={this.handleRowClicked.bind(this)}
						overridePath={this.props.addNewSkusTabPath.concat(['SearchProductsGrid'])}
						store={store}
					/>
				</div>
			</Card>
		);
	}
}

AddClearanceSkusSearchProductsListResultsPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	addNewSkusTabPath: PropTypes.array.isRequired,
	onLoad: PropTypes.func,
	onGridReady: PropTypes.func,
	handleRowClicked: PropTypes.func
};
export default AddClearanceSkusSearchProductsListResultsPanel;
