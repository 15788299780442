const consoleLog = (msg) => {
	//uncomment the following line to debug
	//window.console.log(msg);
	return true;
};
export const getNumberOrDefault = (value, defaultValue, fnAdditionalRequirements) => {
	const debugMessages = [
		'Passed-in value and defaultValue are both invalid. Returning 0.',
		'Passed-in value is invalid, defaultValue is valid and satisfies additional requirements. Returning defaultValue: ',
		'Passed-in value is invalid, passed-in defaultValue is valid but does not satisfy additional requirements. Returning 0.',
		'Passed-in value is valid and satisfies additional requirements. Returning value: ',
		'Passed-in value does not satisfy additional requirements, passed-in defaultValue is invalid. Returning 0.',
		'Passed-in value is valid but does not satisfy additional requirements. Passed-in defaultValue is valid and satisfies additional requirements. Returning defaultValue: ',
		'Passed-in defaultValue does not satisfy additional requirements. Returning 0.'
	];
	const _value = Number(value);
	const _defaultValue = Number(defaultValue); 
	const _valueIsValid = !window.isNaN(_value);
	const _defaultValueIsValid = !window.isNaN(_defaultValue);
	const _fnAdditionalRequirements = fnAdditionalRequirements === undefined ? () => { return true; } : fnAdditionalRequirements;

	if(_valueIsValid === false){
		//_value is invalid...check defaultValue
		if(_defaultValueIsValid === false){
			consoleLog(debugMessages[0]);
			return 0;
		}
		else{
			if(_fnAdditionalRequirements(_defaultValue)){
				consoleLog(debugMessages[1] + _defaultValue);
				return _defaultValue;
			}
			else{
				consoleLog(debugMessages[2]);
				return 0;
			}
		}
	}
	else { //passed-in value is valid
		if(_fnAdditionalRequirements(_value)){
			consoleLog(debugMessages[3] + _value);
			return _value;
		}
		else{
			if(_defaultValueIsValid === false){
				consoleLog(debugMessages[4]);
			}
			else{
				if(_fnAdditionalRequirements(_defaultValue)){
					consoleLog(debugMessages[5] + _defaultValue);
					return _defaultValue;
				}
				else {
					consoleLog(debugMessages[6]);
					return 0;
				}
			}
		}
	}
};
