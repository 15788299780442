import * as types from '../actionTypes';
import initialState from '../initialState';

export default function(state = initialState, action) {
	switch(action.type){
		case types.UPDATE_CURRENT_VALUE_ACTION:
			return state.setIn(['currentValues'].concat(action.path), action.value);
		case types.UPDATE_UNTRACKED_VALUE_ACTION:
			return state.setIn(['untrackedValues'].concat(action.path), action.value);
		case types.UPDATE_UNTRACKED_VALUES_ACTION:
			return state.setIn(['untrackedValues'].concat(action.path), action.values);
		case types.UPDATE_DEFAULT_VALUE_ACTION:
			return state.setIn(['defaultValues'].concat(action.path), action.value);
		case types.UPDATE_DEFAULT_VALUES_ACTION:
			return state.mergeIn(['defaultValues'].concat(action.path), action.values);
		case types.UPDATE_RPC_DROPDOWN_OPTIONS_ACTION:
			return state.setIn(['rpcDropdowns', 'Options'].concat(action.path), action.options);
		case types.UPDATE_RPC_DROPDOWN_ARGS_ACTION:
			return state.setIn(['rpcDropdowns', 'Args'].concat(action.path), action.args);
		case types.UPDATE_RPC_DROPDOWN_STATE_ACTION:
			return state.setIn(['rpcDropdowns', 'State'].concat(action.path), action.state);
		case types.DELETE_UNTRACKED_VALUE_ACTION:
		case types.DELETE_UNTRACKED_VALUES_ACTION:
			return state.deleteIn(['untrackedValues'].concat(action.path));
		case types.DELETE_CURRENT_VALUE_ACTION:
		case types.DELETE_CURRENT_VALUES_ACTION:
			return state.deleteIn(['currentValues'].concat(action.path));
		case types.DELETE_DEFAULT_VALUE_ACTION:
		case types.DELETE_DEFAULT_VALUES_ACTION:
			return state.deleteIn(['defaultValues'].concat(action.path));
		case types.UPDATE_GLOBAL_APP_STATE_VALUE_ACTION:
			return state.setIn(['globalAppState'].concat(action.path), action.value);
		case types.PUSH_DIALOG_ACTION:
			return state.setIn(['dialogs'], pushDialog(state, action));
		case types.POP_DIALOG_ACTION:
			return state.setIn(['dialogs'], popDialog(state));
		case types.SET_USER_ACTION:
			return state.setIn(['user'], action.user);
		case types.DELETE_GLOBAL_APP_STATE_VALUE_ACTION:
			return state.deleteIn(['globalAppState'].concat(action.path));
		case types.UPDATE_VALIDATION_ERRORS_ACTION:
			if (action.errors !== undefined && Array.isArray(action.errors) && action.errors.length > 0){
				const errors = action.errors.filter(error => error !== undefined);
				if(errors !== undefined && Array.isArray(errors) && errors.length > 0){
					return state.setIn(['validationErrors'].concat(action.path), {errors, hideValidationErrors:action.hideValidationErrors});
				}
			}
			return state.deleteIn(['validationErrors'].concat(action.path));
		default: 
			return state;
	}
}
function pushDialog(state, action){
	let dialogs = state.getIn(['dialogs']);
	let dialogsClone;
	if(Array.isArray(dialogs) && dialogs.length > 0)
		dialogsClone = dialogs.slice();
	else dialogsClone = [];
	dialogsClone.push({
		dialogId: action.dialogId, 
		dialogContent: action.dialogContent, 
		height: action.height, 
		width: action.width, 
		handleDialogClosed: action.handleDialogClosed,
		enableResizing: action.enableResizing,
		position: action.position,
		doSlideInEffect: action.doSlideInEffect,
		closeOnOverlayClick: action.closeOnOverlayClick,
		closeOnEscapeKeyPressed: action.closeOnEscapeKeyPressed
	});
	return dialogsClone;
}
function popDialog(state){
	let dialogs = state.getIn(['dialogs']);
	let dialogsClone;
	if(Array.isArray(dialogs) && dialogs.length > 0)
		dialogsClone = dialogs.slice();
	else dialogsClone = [];
	if(dialogsClone.length > 0)
		dialogsClone.pop();
	return dialogsClone;
}