import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

class MessageBar extends Component {
	getId(screenId, fieldId){
		return (screenId && fieldId) ? screenId + '_' + fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	}
	render() {
		return (
			<div style={{top:0, position:'sticky', zIndex:50}} className={'px-3 my-3'}>
				<Container 
					fluid={true} 
					className={'d-flex justify-content-center align-items-center messageBar p-2'} 
					style={{backgroundColor:'#ff6961', color:'white'}}>
					{this.props.message}
				</Container>
			</div>
		);
	}
}

MessageBar.propTypes = {
	message: PropTypes.string
};

export default MessageBar;
