import PropTypes from 'prop-types';
import { Component, default as React } from 'react';
import { connect } from 'react-redux';
import * as persistentValues from '../../utilities/persistentValues';
import * as storeUtilities from '../../utilities/storeUtilities';
import RadioGroup from './RadioGroup';

class RadioGroupContainer extends Component {
	render() {
		return (
			<RadioGroup 
				screenId={this.props.screenId}
				fieldId={this.props.fieldId}
				options={this.props.options}
				tooltips={this.props.tooltips}
				value={this.props.value}
				onChange={this.props.onChange}
				horizontal={this.props.horizontal}
			/>
		);
	}
}

export const mapStateToProps = (state, props) => {
	const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
	const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
	const hasValidationErrors = storeUtilities.hasValidationErrors(path);
	const validationErrors = storeUtilities.getValidationErrors(path);
	const tooltips = hasValidationErrors === true && validationErrors.hideValidationErrors === false
		? validationErrors.errors 
		: (props.defaultTooltip !== undefined ? [props.defaultTooltip] : []);
	const isInvalid = hasValidationErrors === true && validationErrors.hideValidationErrors === false;
	return {
		value: valueInStore,
		tooltips,
		isInvalid,
		path
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
	return {
		onLoad: () => {
			const persistedValue = persistentValues.get(path);
			const persistValue = props.persistValue && persistedValue !== undefined && persistedValue.trim && persistedValue.trim() !== '';
			const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
			const value = valueInStore !== undefined ? valueInStore : (persistValue === true ? persistedValue : undefined);
			storeUtilities.initializeValue(dispatch, path, value, persistValue);
		},
		onChange: (value) => {
			storeUtilities.updateValue(dispatch, path, value);
			if(props.onChange){
				props.onChange(value);
			}
		}
	};
};
RadioGroupContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	options: PropTypes.array,
	horizontal: PropTypes.any
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(RadioGroupContainer);

export default connectedComponent;