import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import MaintenanceIcon from '../../formelements/ImageComponents/MaintenanceIcon';
import TextAreaContainer from '../../formelements/TextAreaContainer';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import {
	ButtonContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';

const strMaint = '[maintenance]';
class MaintenanceManagerScreen extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	handleChkAllChanged(value){
		if(this.props.handleChkAllChanged){
			this.props.handleChkAllChanged(value);
		}
	}
	handleActivateMaintenanceMode(){
		if(this.props.handleActivateMaintenanceMode){
			this.props.handleActivateMaintenanceMode();
		}
	}
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| !this.props.user.hasAdminAccess()){
			return <NoAccessToScreenPanel />;
		}
		return (
			<Container className={'h-100'} fluid={true}>
				<Card>
					<Card.Header>
						<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Maintenance Manager</h5>
					</Card.Header>
					<Card.Body>
						<Row noGutters={true} className={'m-0 p-0'}>
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<div style={{maxWidth:'300px', height:'100%', margin:'auto'}}>
									<MaintenanceIcon
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'MaintenanceIcon'}
									/>
								</div>
							</Col>
							<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								<Row noGutters={true} className={'m-0 p-0'}>
									<Col xs={10} sm={10} md={10} lg={8} xl={8} className={'text'}>Please provide a reason for setting the application into Maintenance Mode:</Col>
									<Col xs={2} sm={2} md={2} lg={4} xl={4}></Col>
								</Row>
								<Row noGutters={true} className={'m-0 p-0'}>
									<Col xs={10} sm={10} md={10} lg={8} xl={8}>
										<TextAreaContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'maintNotes'}
										>
										</TextAreaContainer>
									</Col>
									<Col xs={2} sm={2} md={2} lg={4} xl={4}></Col>
								</Row>
								<Row noGutters={true} className={'m-0 p-0'}>
									<Col xs={10} sm={10} md={10} lg={8} xl={8} className={'text'}>Activate/deactivate Maintenance Mode to the following divisions:</Col>
									<Col xs={2} sm={2} md={2} lg={4} xl={4}></Col>
								</Row>
								<Row noGutters={true} className={'m-0 p-0'}>
									<Col xs={10} sm={10} md={10} lg={4} xl={4}>
										<Row noGutters={true} className={'m-0 p-0'}>
											<Col className={'p-0 m-0'}>
												<CheckBoxContainer
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'chkAll'}
													label={'All Divisions'}
													mainComponentBootstrapSize={1}
													onChange={this.handleChkAllChanged.bind(this)}>
												</CheckBoxContainer>
												<span>&nbsp;</span>
											</Col>
											<Col className={'p-0 m-0'}>
											</Col>
										</Row>
										<Row noGutters={true} className={'m-0 p-0'}>
											<Col className={'p-0 m-0'}>
												<CheckBoxContainer
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'chkFL'}
													label={'Florida'}
													mainComponentBootstrapSize={1}
													disabled={this.props.chkAllSelected}>
												</CheckBoxContainer>
											</Col>
											<Col className={'p-0 m-0'}>
												<div style={{color:'#dc3545'}}>&nbsp;{this.props.flInMaint ? strMaint : ''}</div>
											</Col>
										</Row>
										<Row noGutters={true} className={'m-0 p-0'}>
											<Col className={'p-0 m-0'}>
												<CheckBoxContainer
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'chkSE'}
													label={'Southeast'}
													mainComponentBootstrapSize={1}
													disabled={this.props.chkAllSelected}>
												</CheckBoxContainer>
											</Col>
											<Col className={'p-0 m-0'}>
												<div style={{color:'#dc3545'}}>&nbsp;{this.props.seInMaint ? strMaint : ''}</div>
											</Col>
										</Row>
										<Row noGutters={true} className={'m-0 p-0'}>
											<Col className={'p-0 m-0'}>
												<CheckBoxContainer
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'chkTX'}
													label={'Texas'}
													mainComponentBootstrapSize={1}
													disabled={this.props.chkAllSelected}>
												</CheckBoxContainer>
											</Col>
											<Col className={'p-0 m-0'}>
												<div style={{color:'#dc3545'}}>&nbsp;{this.props.txInMaint ? strMaint : ''}</div>
											</Col>
										</Row>
										<Row noGutters={true} className={'m-0 p-0'}>
											<Col className={'p-0 m-0'}>
												<CheckBoxContainer
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'chkPR'}
													label={'Puerto Rico'}
													mainComponentBootstrapSize={1}
													disabled={this.props.chkAllSelected}>
												</CheckBoxContainer>
											</Col>
											<Col className={'p-0 m-0'}>
												<div style={{color:'#dc3545'}}>&nbsp;{this.props.prInMaint ? strMaint : ''}</div>
											</Col>
										</Row>
									</Col>
									<Col xs={2} sm={2} md={2} lg={8} xl={8}></Col>
								</Row>
								<Row noGutters={true} className={'m-0 p-0'}>
									<Col xs={10} sm={10} md={10} lg={8} xl={8}>
										<ButtonContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'maintBtn'}
											text={'Apply Changes'}
											onClick={this.handleActivateMaintenanceMode.bind(this)}
											fill
											store={store}
										/>
									</Col>
									<Col xs={2} sm={2} md={2} lg={4} xl={4}></Col>
								</Row>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		);
	}
}

MaintenanceManagerScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	chkAllSelected: PropTypes.bool,
	handleChkAllChanged: PropTypes.func,
	handleActivateMaintenanceMode: PropTypes.func,
	user: PropTypes.object.isRequired,
	onLoad: PropTypes.func,
	flInMaint: PropTypes.bool,
	seInMaint: PropTypes.bool,
	txInMaint: PropTypes.bool,
	prInMaint: PropTypes.bool
};

export default MaintenanceManagerScreen;