import PropTypes from 'prop-types';
import React from 'react';
import withID from '../../../higherordercomponents/WithID';
/**
 * imageOrientation
 *   vertical:   ^ AND v
 *   horizontal: > AND <
 */
export const imageOrientation = {
	vertical:'vertical',
	horizontal:'horizontal'
};
const ExpandCollapseChevronIcon = ({isExpanded, fill, height, width, orientation=imageOrientation.vertical}) => { 
	const transform = orientation === imageOrientation.vertical ? 'rotate(0)' : 'rotate(-90)';
	return isExpanded === true
		? <svg 
			enableBackground={'new 0 0 32 32'} 
			height={height ? height : '32px'}
			viewBox={'0 0 32 32'} 
			width={width ? width : '32px'}
			transform={transform}>
			<path 
				d={'M16.714,11.297c-0.389-0.389-1.039-0.389-1.429,0l-8.999,8.976  c-0.394,0.394-0.394,1.033,0,1.426c0.394,0.394,1.034,0.394,1.428,0L16,13.436l8.285,8.264c0.395,0.394,1.034,0.394,1.429,0  c0.394-0.394,0.394-1.033,0-1.426L16.714,11.297z'}
				fill={fill ? fill : '#121313'} 
			/>
		</svg>
		: <svg 
			enableBackground={'new 0 0 32 32'} 
			height={height ? height : '32px'}
			viewBox={'0 0 32 32'}  
			width={width ? width : '32px'}
			transform={transform}>
			<path 
				d={'M24.285,11.284L16,19.571l-8.285-8.288c-0.395-0.395-1.034-0.395-1.429,0  c-0.394,0.395-0.394,1.035,0,1.43l8.999,9.002l0,0l0,0c0.394,0.395,1.034,0.395,1.428,0l8.999-9.002  c0.394-0.395,0.394-1.036,0-1.431C25.319,10.889,24.679,10.889,24.285,11.284z'}
				fill={fill ? fill : '#121313'}
			/>
		</svg>;
};
ExpandCollapseChevronIcon.propTypes = {
	isExpanded: PropTypes.bool,
	fill: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string
};
export default withID(ExpandCollapseChevronIcon);