import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../utilities/commonValidators';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import HiddenFieldContainer from '../../formelements/HiddenFieldContainer';
import LabelContainer from '../../formelements/LabelContainer';
import TextAreaContainer from '../../formelements/TextAreaContainer';
import TextBoxContainer from '../../formelements/TextBoxContainer';
import * as numberUtilities from '../../../utilities/numberUtilities';
import LookupBtn from './LookupBtn';
import SalesHistoryGroupBoxContainer from '../orderproductsscreen/SalesHistoryGroupBoxContainer';
import withID from '../../../higherordercomponents/WithID';
import {
	ButtonContainer,
	DatePickerContainer,
	DropdownContainer,
	MultiSelectDropdownContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import '../../../css/DatePickerOverrides.css';
class MerchandisePanel extends Component {
	componentDidMount() {
		if (this.props.onLoad) {
			this.props.onLoad(() => {
				this.props.calculateTotalDaysAndLeadDate();
			});
		}
	}
	goBack() {
		if (this.props.goBack) {
			this.props.goBack();
		}
	}
	calculateTotalDaysAndLeadDate() {
		if (this.props.calculateTotalDaysAndLeadDate) {
			this.props.calculateTotalDaysAndLeadDate();
		}
	}
	handleWarehouseChanged(e) {
		if (this.props.handleWarehouseChanged) {
			this.props.handleWarehouseChanged(e);
		}
	}
	onGetSuggestedOrdersClicked() {
		if (this.props.onGetSuggestedOrdersClicked) {
			this.props.onGetSuggestedOrdersClicked();
		}
	}
	lookupPOBtnClicked() {
		if (this.props.lookupPOBtnClicked) {
			this.props.lookupPOBtnClicked();
		}
	}
	render() {
		return (
			<Container className={'h-100 m-0 p-0'} id={this.props.id} data-testid={this.props.id}>
				<Card className={'h-100'}>
					<Card.Header>
						<Row className='p-0 m-0' noGutters={true}>
							<Col xs={12} sm={12} md={12} lg={6} xl={6}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'> Order Parts </h5>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none' />
							<Col xs={8} sm={8} md={6} lg={6} xl={6} className={'pr-1 mb-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'btnReturnToSearch'}
									onClick={this.goBack.bind(this)}
									text='Return to Search'
									fill
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none' />
						</Row>
					</Card.Header>
					<Card.Body>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'vendor'}
									label={'Vendor'}
									mainComponentBootstrapSize={7}
									disabled />
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<DatePickerContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'orderDate'}
									onChange={this.calculateTotalDaysAndLeadDate.bind(this)}
									label={'Date'}
									mainComponentBootstrapSize={7}
									disabled
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<DropdownContainer 
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'whsNum'}
									onChange={this.handleWarehouseChanged.bind(this)}
									label={'Ship-To Warehouse'}
									validators={[validators.required]}
									options={this.props.whsOptions}
									showRequiredAsterisk
									hasBlankOption
									disabled={this.props.whsIsSavannah}
									store={store}
								/>
							</Col>
						</Row>
						<Row noGutters={true} className={'mb-1'}>
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<LabelContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'combineWhsListLabel'}
									value={'Combine DCs'}
									showRequiredAsterisk={this.props.disableCombineWhsList === false}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'enableCombineWhsList'}
									disabled={this.props.disableCombineWhsListCheckbox}
								/>
							</Col>
							<Col xs={7} sm={7} md={7} lg={7} xl={7}>
								<MultiSelectDropdownContainer
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'combineWhsList'}
									options={this.props.whsOptions}
									disabled={this.props.disableCombineWhsList}
									validators={[validators.required]}
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'salesNo'}
									type={'number'}
									label={'Sales Number'}
									maxCharacters={10}
									mainComponentBootstrapSize={7}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'serviceNo'}
									type={'number'}
									label={'Service Number'}
									maxCharacters={10}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectedPO'}
									type={'string'}
									label={'Purchase Order'}
									maxCharacters={10}
								/>
							</Col>
						</Row>
						<Row className={'mb-1 mt-3'}>
							<Col>
								<SalesHistoryGroupBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}

									startDateFieldId={'beginSalesDate'}
									startDatePath={[this.props.screenId, 'beginSalesDate']}
									startDateLabel={'Begin'}
									startDatePersist

									endDateFieldId={'endSalesDate'}
									endDatePath={[this.props.screenId, 'endSalesDate']}
									endDateLabel={'End'}
									endDatePersist

									excludeDatesButtonFieldId={'excludeDatesLink'}
									excludeDatesButtonText={''}
									excludeDatesButtonTooltips={[]}

									totalDaysTextBoxFieldId={'salesDays'}
									totalDaysTextBoxPath={[this.props.screenId, 'salesDays']}
									totalDaysTextBoxLabel={'Total Days'}

									excludedMomentRangesPath={[this.props.screenId, 'excludedMomentRanges']}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'prodTime'} 
									type={'number'}
									onChange={this.calculateTotalDaysAndLeadDate.bind(this)}
									label={'Prod Lead Time'}
									showRequiredAsterisk={this.props.useTotalDays === false}
									mainComponentBootstrapSize={3}
									maxCharacters={3}
									persistValue
									disabled={this.props.useTotalDays === true}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'transitTime'} 
									type={'number'}
									onChange={this.calculateTotalDaysAndLeadDate.bind(this)}
									label={'Transit Lead Time'}
									mainComponentBootstrapSize={3}
									maxCharacters={3}
									persistValue
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'txtTotalDays'}
									type={'number'}
									label={'Total Days'} 
									disabled={this.props.useTotalDays === false}
									mainComponentBootstrapSize={3}
									validators={[validators.required, (args)=>{
										const { value, label } = args;
										const isValid = this.props.useTotalDays === true ? (numberUtilities.getNumberOrDefault(value, 0) > 0) : true;
										const defaultMessage = 'Value must be greater than 0';
										let msg = defaultMessage;
										if(label !== undefined && label.toString && label.toString().trim().length > 0){
											msg = label + ' value must be greater than 0';
										}
										return isValid === true ? undefined : msg;
									}]}
									showRequiredAsterisk={this.props.useTotalDays === true}
									onChange={this.calculateTotalDaysAndLeadDate.bind(this)}
								/>
							</Col>
						</Row>
						<Row className="mb-2 mr-1">
							<Col>
								<DatePickerContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'dtsCal'}
									disabled
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1 ml-0'}>
							<Col xs={1} sm={1} md={1} lg={1} xl={1} />
							<Col xs={10} sm={10} md={10} lg={9} xl={9} className='pl-0'>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'btnGetSuggestedOrders'}
									onClick={this.onGetSuggestedOrdersClicked.bind(this)}
									text='Get Suggested Parts Orders'
									disabled={this.props.disableGetSuggestedOrdersButton === true}
									fill
									store={store}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1} />
						</Row>
						<hr />
						<Row className={'mb-3 align-items-end'}>
							<Col xs={3} sm={3} md={3} lg={6} xl={6}>
								<LabelContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'numOfSelectedSKUsLabel'}
									value={'Number of Selected SKUs: '}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3} xl={3}>
								<LabelContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'numOfSelectedSKUsCountLabel'}
									useStore
								/>
							</Col>
						</Row>
						<Row className={'mb-1 align-items-end'}>
							<Col>
								<TextAreaContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectedSkusTextArea'}
									label={'Selected SKUs: '}
									height={'55px'}
									mainComponentBootstrapSize={9}
									disabled
								/>
							</Col>
						</Row>
						<Row className={'mb-1'} noGutters={true}>
							<Col xs={3} sm={3} md={3} lg={3} xl={3} >
								<LabelContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectedPOsLabel'}
									value={'Selected POs: '}
								/><br />
								<LookupBtn
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'LookupPOBtn'}
									tooltip={'Lookup PO Sku Qty'}
									onClick={this.lookupPOBtnClicked.bind(this)}
									disabled={this.props.disableLookupPOButton === true}
								/>
							</Col>
							<Col xs={9} sm={9} md={9} lg={9} xl={9}>
								<TextAreaContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'displayPOs'}
									height={'55px'}
									mainComponentBootstrapSize={9}
									disabled
								/>
							</Col>
						</Row>

					</Card.Body>
				</Card>
				{/* the api expects the list of skus to have no spaces, so we must strip out all of the spaces */}
				<HiddenFieldContainer
					screenId={this.props.screenId}
					panelId={this.props.panelId}
					fieldId={'skuList'}
				/>
			</Container>
		);
	}
}

MerchandisePanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	goBack: PropTypes.func,
	calculateTotalDaysAndLeadDate: PropTypes.func,
	handleWarehouseChanged: PropTypes.func,
	useCubes: PropTypes.bool,
	skusList: PropTypes.string,
	displayPOs: PropTypes.string,
	onGetSuggestedOrdersClicked: PropTypes.func,
	partSearchSelectedRows: PropTypes.array,
	beginSalesDate: PropTypes.instanceOf(Date),
	endSalesDate: PropTypes.instanceOf(Date),
	disableGetSuggestedOrdersButton: PropTypes.bool,
	disableLookupPOButton: PropTypes.bool,
	onBeforeLoad: PropTypes.func,
	combineDCsDisabled: PropTypes.bool,
	whsOptions: PropTypes.array,
	disableCombineWhsList: PropTypes.bool,
	disableCombineWhsListCheckbox: PropTypes.bool,
	lookupPOBtnClicked: PropTypes.func,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withID(MerchandisePanel);
