import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { SizeMe } from 'react-sizeme';
import RadioGroupContainer from '../formelements/RadioGroupContainer';
import { 
	ButtonContainer,
	DatePickerContainer
} from '@rtg/shared-react-components';
import store from '../../configureStore';
import '../../css/DatePickerOverrides.css';
class DatesPickerDialog extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onAddClicked = () => {
		if(this.props.onAddClicked){
			this.props.onAddClicked();
		}
	};
	onCancelClicked = () => {
		if(this.props.onCancelClicked){
			this.props.onCancelClicked();
		}
	};
	isSameOrBefore = (date1, date2) => {
		const moment1 = moment(date1);
		const moment2 = moment(date2);
		return moment1.isSameOrBefore(moment2);
	};
	validateOtherDateField = (otherFieldId, otherValidationMsg, otherFieldIsValid, isInitialValidationCheck) => {
		if(this.props.validateOtherDateField){
			this.props.validateOtherDateField(otherFieldId, otherValidationMsg, otherFieldIsValid, isInitialValidationCheck);
		}
	};
	onSaveChangesClicked = () => {
		if(this.props.onSaveChangesClicked){
			this.props.onSaveChangesClicked();
		}
	};
	onDeleteDateButtonOut = (e) => {
		e.target.style.color = '';
	};
	onDeleteDateButtonOver = (e) => {
		e.target.style.color = 'red';
	};
	onDeleteDateButtonClicked = (momentRange) => {
		if(this.props.onDeleteDateButtonClicked){
			this.props.onDeleteDateButtonClicked(momentRange);
		}
	};
	render() {
		let content = undefined;
		const startValidationMsg = 'Start date must occur before End date';
		const endValidationMsg = 'End date must occur after Start date';
		if(this.props.isSingleDate === true){
			content = (
				<Row>
					<Col>
						<DatePickerContainer 
							screenId={this.props.screenId}
							fieldId={'singleDate'}
							label={'Date'}
							mainComponentBootstrapSize={7}
							overridePath={[this.props.screenId, this.props.panelId, 'singleDate']}
							store={store}
						/>
					</Col>
				</Row>
			);
		}
		else {
			const {dateRangeStart, dateRangeEnd} = {...this.props};
			content = (
				<>
					<Row noGutters={true} className={'p-0 m-0'}>
						<Col>
							<DatePickerContainer 
								screenId={this.props.screenId}
								fieldId={'dateRangeStart'}
								label={'Start'}
								validators={[(args, isInitialValidationCheck)=>{
									const isValid = this.isSameOrBefore(args.value, dateRangeEnd) === true;
									this.validateOtherDateField(
										'dateRangeEnd', 
										endValidationMsg, 
										isValid, 
										isInitialValidationCheck);
									return isValid === true ? undefined : startValidationMsg;
								}]}
								mainComponentBootstrapSize={7}
								overridePath={[this.props.screenId, this.props.panelId, 'dateRangeStart']}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mt-2'}>
						<Col>
							<DatePickerContainer 
								screenId={this.props.screenId}
								fieldId={'dateRangeEnd'}
								label={'End'}
								validators={[(args, isInitialValidationCheck)=>{
									const isValid = this.isSameOrBefore(dateRangeStart, args.value) === true;
									this.validateOtherDateField(
										'dateRangeStart',
										startValidationMsg,
										isValid,
										isInitialValidationCheck);
									return isValid === true ? undefined : endValidationMsg;
								}]}
								mainComponentBootstrapSize={7}
								overridePath={[this.props.screenId, this.props.panelId, 'dateRangeEnd']}
								store={store}
							/>
						</Col>
					</Row>
				</>
			);
		}
		return (
			<SizeMe monitorHeight={true}> 
				{({size}) => {
					const height = (size.height || 0);
					return (
						<div className={'w-100 h-100'}>
							<Card style={{height: height + 'px'}}>
								<Card.Header className={'dlgdraggable d-flex'}>
									<h4 className={'align-self-center'}>{this.props.title}</h4>
								</Card.Header>
								<Card.Body>
									<Row noGutters={true} className={'m-0 p-0'}>
										<Col className={'pr-2'}>
											<Row noGutters={true} className={'m-0 p-0'}>
												<Col>
													<Card>
														<Card.Header>
															<h5>{this.props.isSingleDate === true ? 'Single Date' : 'Date Range'}</h5>
														</Card.Header>
														<Container fluid={true} className={'p-2 m-0'}>
															<Row className={'mb-2'}>
																<Col>
																	<RadioGroupContainer 
																		screenId={this.props.screenId}
																		fieldId={'singleOrDateRangeRadio'}
																		options={[
																			{ name: 'Single Date', value:'singleDate' }, 
																			{ name: 'Date Range', value: 'dateRange'}
																		]}
																		tooltips={[]}
																		horizontal
																		overridePath={[this.props.screenId, this.props.panelId, 'singleOrDateRangeRadio']}
																	/>
																</Col>
															</Row>
															{content}
														</Container>
													</Card>
												</Col>
											</Row>
											<Row noGutters={true} className={'m-0 p-0'}>
												<Col className={'d-flex'}>
													<div className={'ml-auto mt-2'}>
														<ButtonContainer
															screenId={this.props.screenId}
															fieldId={'btnAdd'}
															text={this.props.isSingleDate === true ? 'Add Single Date' : 'Add Date Range'}
															onClick={this.onAddClicked}
															disabled={this.props.disableBtnAdd===true}
															store={store}
														/>
													</div>
												</Col>
											</Row>
										</Col>
										<Col>
											<Card>
												<Card.Header>
													<h5>Selected Dates</h5>
												</Card.Header>
												<div className={'w-100'} style={{height: (height - 135) + 'px', overflow:'auto'}}>
													{
														this.props.momentRanges.map((momentRange)=>{
															return (<div className={'pill-button m-1'} key={Math.random()}>
																{ 
																	momentRange.start.isSame(momentRange.end, 'day')
																		? momentRange.start.format('L')
																		: momentRange.start.format('L') + ' - ' + momentRange.end.format('L')
																}
																<span 
																	style={{cursor: 'pointer', position:'relative', top:'-1px'}}
																	onMouseOver={this.onDeleteDateButtonOver}
																	onMouseOut={this.onDeleteDateButtonOut}
																	onClick={() => {this.onDeleteDateButtonClicked(momentRange);}}
																> x</span></div>);
														})
													}
												</div>
											</Card>
										</Col>
									</Row>
								</Card.Body>
								<Card.Footer>
									<Row noGutters={true}>
										<Col className={'pr-2'}>
											<ButtonContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'btnCancel'}
												text={this.props.cancelButtonText ? this.props.cancelButtonText : 'Include Selected Dates'}
												variant={'light'}
												onClick={this.onCancelClicked}
												fill
												store={store}
											/>
										</Col>
										<Col>
											<ButtonContainer 
												screenId={this.props.screenId}
												fieldId={'btnSave'}
												text={this.props.okButtonText ? this.props.okButtonText : 'Include Selected Dates'}
												onClick={this.onSaveChangesClicked}
												fill
												store={store}
											/>
										</Col>
									</Row>
								</Card.Footer>
							</Card>
						</div>			
					);}}
			</SizeMe>
		);
	}
}

DatesPickerDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	isSingleDate: PropTypes.bool.isRequired,
	onLoad: PropTypes.func,
	validateOtherDateField: PropTypes.func,
	disableBtnAdd: PropTypes.bool,
	onCancelClicked: PropTypes.func.isRequired,
	onSaveChangesClicked: PropTypes.func.isRequired,
	onDeleteDateButtonClicked: PropTypes.func,
	cancelButtonText: PropTypes.string,
	okButtonText: PropTypes.string
};

export default DatesPickerDialog;