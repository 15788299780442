import PropTypes from 'prop-types';
import React, { Component } from 'react';
import XIcon from './ImageComponents/XIcon';

const DEFAULT_HEIGHT = 15;
const DEFAULT_WIDTH = 15;
class CloseButton extends Component {
	constructor(props){
		super(props);
		this.state = {
			color: 'darkgray',
			disabled: this.props.disabled,
			cursor: 'auto'
		};
	}
	onMouseOver(){
		if(this.state.disabled === true)
			return;
		this.setState({color: '#dc3545', cursor: 'pointer'});
	}
	onMouseOut(){
		if(this.state.disabled === true)
			return;
		this.setState({color: 'darkgray', cursor: 'auto'});
	}
	onMouseDown(){
		if(this.state.disabled === true)
			return;
		this.setState({color: '#710000'});
	}
	onMouseUp(){
		if(this.state.disabled === true)
			return;
		this.setState({color:'#dc3545'});
	}
	onClick(){
		if(this.props.onClick)
			this.props.onClick();
	}
	render() {
		const height= this.props.height ? this.props.height : DEFAULT_HEIGHT;
		const width= this.props.width ? this.props.width : DEFAULT_WIDTH;
		return (
			<div style={{position:'relative', top:this.props.top, left:this.props.left, cursor: this.state.cursor, height: (height + 'px'), width: (width + 'px'), overflow:'hidden'}}
				onMouseOver={this.onMouseOver.bind(this)} 
				onMouseOut={this.onMouseOut.bind(this)} 
				onMouseDown={this.onMouseDown.bind(this)}
				onMouseUp={this.onMouseUp.bind(this)}
				onClick={this.onClick.bind(this)}>
				<XIcon
					screenId={this.props.screenId}
					panelId={this.props.panelId}
					fieldId={this.props.fieldId}
					color={this.state.color}
					height={(height - 2) + 'px'}
					width={(width - 2) + 'px'}
					visible={this.props.visible}
				/>
			</div>
		);
	}
}

CloseButton.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	height: PropTypes.number,
	width: PropTypes.number,
	top: PropTypes.string,
	left: PropTypes.string,
	visible: PropTypes.bool
};

export default CloseButton;
