import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withID from './WithID';
const withGridCellProps = (WrappedComponent) => {
	class WithGridCellProps extends Component {
		constructor(props){
			super(props);
			this.state = {
				disabled: this.props.disabled,
				cursor: 'auto'
			};
		}
		onMouseOver(){
			if(this.state.disabled === true){
				return;
			}
			this.setState({cursor: 'pointer'});
			if(this.props.onMouseOver){
				this.props.onMouseOver();
			}
		}
		onMouseOut(){
			if(this.state.disabled === true){
				return;
			}
			this.setState({cursor: 'auto'});
			if(this.props.onMouseOut){
				this.props.onMouseOut();
			}
		}
		onMouseDown(){
			if(this.state.disabled === false && this.props.onMouseDown){
				this.props.onMouseDown();
			}
		}
		onMouseUp(){
			if(this.state.disabled === false && this.props.onMouseUp){
				this.props.onMouseUp();
			}
		}
		render(){
			return (
				this.props.getIsHidden && this.props.node && this.props.getIsHidden(this.props.node) !== true &&
				<div
					onMouseOver={this.onMouseOver.bind(this)} 
					onMouseOut={this.onMouseOut.bind(this)} 
					onMouseDown={this.onMouseDown.bind(this)}
					onMouseUp={this.onMouseUp.bind(this)}
					style={{cursor: this.state.cursor, position:'relative', top: '-1px'}}> 
					<WrappedComponent {...this.props}/>
				</div>
			);
		}
	}
	return withID(WithGridCellProps);
};
withGridCellProps.propTypes = {
	disabled: PropTypes.bool,
	getIsHidden: PropTypes.func
};
export default withGridCellProps;