import * as gridUtilities from '../../../utilities/gridUtilities';
import XGridCellIcon from '../../../components/formelements/ImageComponents/XGridCellIcon';
const numericValueFormatter = (params) => {
	return gridUtilities.formatDecimal(params.value, 2); 
};
const suggestedOrdersDetailsGridColumnDefs = (screenId, getIsXCellHidden) =>
	[
		{
			headerName: '',
			field: 'ORDERLINE',
			colId: 'ORDERLINE',
			hide: true,
			valueFormatter: () => {}
		},
		{
			headerName: 'SKU',
			field: 'SKU',
			colId: 'SKU',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'Product ID (PDM)',
			field: 'PDMProductId',
			colId: 'PDMProductId',
			sortable: true,
			width: 125,
			minWidth: 125,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'Alias',
			field: 'ALIAS',
			colId: 'ALIAS',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'FOB',
			field: 'FOB',
			colId: 'FOB',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'Color',
			field: 'Color',
			colId: 'Color',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'Factory Color',
			field: 'FactoryColor',
			colId: 'FactoryColor',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'Theme',
			field: 'Theme',
			colId: 'Theme',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'Description',
			field: 'VendorDescription',
			colId: 'VendorDescription',								
			width: 300,
			minWidth: 200,
			sortable: true
		},
		{
			headerName: 'Family',
			field: 'Family',
			colId: 'Family',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Cube',
			field: 'Cube',
			colId: 'Cube',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Avg Daily Sales',
			field: 'DailyMean',
			colId: 'DailyMean',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Weekly Sales',
			field: 'WeeklySales',
			colId: 'WeeklySales',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Total Sales',
			field: 'TotalSales',
			colId: 'TotalSales',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'QOH',
			field: 'BVQOH',
			colId: 'BVQOH',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Inbound',
			field: 'BVINBOUND',
			colId: 'BVINBOUND',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Available',
			field: 'LTAVAIL',
			colId: 'LTAVAIL',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Weeks of Stock',
			field: 'WeeksOfStock',
			colId: 'WeeksOfStock',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Daily STDEV',
			field: 'DailyStdDev',
			colId: 'DailyStdDev',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Safe Stock',
			field: 'SafetyStock',
			colId: 'SafetyStock',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Replenishment Stock',
			field: 'ReplenishmentStock',
			colId: 'ReplenishmentStock',
			sortable: true,
			width: 125,
			minWidth: 125,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Order Point',
			field: 'Trigger',
			colId: 'Trigger',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'ADTS',
			field: 'ADTS',
			colId: 'ADTS',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Days To Next Order',
			field: 'DaysToNextOrder',
			colId: 'DaysToNextOrder',
			sortable: true,
			width: 125,
			minWidth: 125,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'WOSOH',
			field: 'WOSOH',
			colId: 'WOSOH',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Qty To WHS',
			field: 'QtyToWHS',
			colId: 'QtyToWHS',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Qty From WHS',
			field: 'QtyFromWHS',
			colId: 'QtyFromWHS',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Calc. Order',
			field: 'CalcOrder',
			colId: 'CalcOrder',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Order',
			field: 'Order',
			colId: 'Order',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'L_Order',
			field: 'LogOrder',
			colId: 'LogOrder',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Store QOH',
			field: 'STOREQTY',
			colId: 'STOREQTY',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: '#/Box',
			field: 'PieceCount',
			colId: 'PieceCount',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: '# of Skus in Carton (PDM)',
			field: 'PDMPieceCount',
			colId: 'PDMPieceCount',
			sortable: true,
			width: 175,
			minWidth: 175,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Avail Today',
			field: 'BVAVAIL',
			colId: 'BVAVAIL',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'EOL AVAIL',
			field: 'EOLAVAIL',
			colId: 'EOLAVAIL',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Avail After Order',
			field: 'AvailableAfterOrder',
			colId: 'AvailableAfterOrder',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Order Ratio',
			field: 'Ratio',
			colId: 'Ratio',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Production Time',
			field: 'ProductionTime',
			colId: 'ProductionTime',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Transit Time',
			field: 'TransitTime',
			colId: 'TransitTime',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Intradivisional',
			field: 'IntraDivisional',
			colId: 'IntraDivisional',
			sortable: true,
			width: 100,
			minWidth: 100,
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'Load Factor',
			field: 'LoadFactor',
			colId: 'LoadFactor',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: numericValueFormatter
		},
		{
			headerName: 'SKU Group',
			field: 'SkuGroup',
			colId: 'SkuGroup',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'left'}
		},
		{
			headerName: 'Discontinued',
			field: 'DiscontinuedSKU',
			colId: 'DiscontinuedSKU',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'center'},
			headerClass: 'ag-center-header',
			cellRenderer: XGridCellIcon, 
			cellRendererParams:
			{
				screenId,
				height: '16px', 
				width: '16px',
				getIsHidden: (params) => getIsXCellHidden(params),
				color: 'red'
			}
		},
		{
			headerName: 'Inactive',
			field: 'InactiveSKU',
			colId: 'InactiveSKU',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'center'},
			headerClass: 'ag-center-header',
			cellRenderer: XGridCellIcon, 
			cellRendererParams:
		{
			screenId,
			height: '16px', 
			width: '16px',
			getIsHidden: (params) => getIsXCellHidden(params),
			color: 'red'
		}
		},
		{
			headerName: 'To Be Dropped',
			field: 'ToBeDropped',
			colId: 'ToBeDropped',
			sortable: true,
			width: 100,
			minWidth: 100,		
			cellStyle: {textAlign:'center'},
			headerClass: 'ag-center-header',
			cellRenderer: XGridCellIcon, 
			cellRendererParams:
			{
				screenId,
				height: '16px', 
				width: '16px',
				getIsHidden: (params) => getIsXCellHidden(params),
				color: 'red'
			}
		}
	];
export default suggestedOrdersDetailsGridColumnDefs;