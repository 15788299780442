import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../actions/showDialogAction';
import * as sharedUtilities from '../../utilities/sharedUtilities';
import * as storeUtilities from '../../utilities/storeUtilities';
import OkCancelDialogContainer from '../dialogs/OkCancelDialogContainer';
import POLookUpDialog from './POLookUpDialog';
import { nodeRoutes } from '../../constants';
import rpcAction from '../../actions/rpcAction';

class POLookUpDialogContainer extends Component {
	render() {
		return (
			<POLookUpDialog {...this.props} />
		);
	}
}

const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, 'POSearchResultsGrid', 'rowData']);
	const selectedRows = storeUtilities.getValue([props.screenId, 'POSearchResultsGrid', 'selectedRows']) || '';
	const vendorValidationErrors = storeUtilities.getValidationErrors([props.screenId, 'POLookUpDialog', 'VendorCode']);
	const searchButtonDisabled = vendorValidationErrors !== undefined 
        && vendorValidationErrors.errors !== undefined 
        && Array.isArray(vendorValidationErrors.errors) 
        && vendorValidationErrors.errors.length > 0;
	//TODO: const disableClearButton;
	//TODO: const disableClearFormButton;
    
	return {
		searchButtonDisabled,
		disableSelectButton: !selectedRows || !Array.isArray(selectedRows) || selectedRows.length <= 0,
		rowData: rowData !== undefined && Array.isArray(rowData) ? rowData : []};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			storeUtilities.updateValue(dispatch, [...props.path, 'division'], props.division);
			storeUtilities.updateValue(dispatch, [...props.path, 'VendorCode'], props.vendorCode);
		},
		handleCloseClicked: (callback) => {
			storeUtilities.deleteValue(dispatch, props.path);
			if(callback !== undefined){
				callback();
			}
		},
		handleSelectClicked: (selectedRows) => {
			if(selectedRows.length === 0){
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						screenId={props.screenId + '_errorDialog'} 
						title={'ERROR'}
						height={250}
						width={400}>
							Please select one or more POs to continue.
					</OkCancelDialogContainer>, 
					250, 
					400, 
					()=>{}));
			} else {
				updateSelectedRowsInStore(props.screenId, selectedRows, dispatch);
				getPartsFromPOs(props, selectedRows, dispatch);
			}
		},

		handleSearchClicked: () => {
			const vendorCode = storeUtilities.getValue([...props.path, 'VendorCode'], undefined);
			const division = storeUtilities.getValue([...props.path, 'division'], undefined);	
			const args = {
				vendor: vendorCode,
				division: division,
				startdate: storeUtilities.getValue([...props.path, 'beginSalesDate']),
				enddate: storeUtilities.getValue([...props.path, 'endSalesDate']),
				factory: storeUtilities.getValue([...props.path, 'factory']),
				style: storeUtilities.getValue([...props.path, 'style']),
			};
			const prunedArgs = sharedUtilities.pruneObject(args);
			dispatch(rpcAction({
					args: prunedArgs,
					nodeRoute: nodeRoutes.IFRServiceNET,
					endpoint: 'Orders/Search',
					method: 'GET',
					hideLoadingMaskOnComplete: true,
					showLoadingMask: true,
					callback: (rowData) => {
						if (rowData && Array.isArray(rowData)){
							storeUtilities.updateValue(dispatch, [props.screenId, 'POSearchResultsGrid', 'rowData'], rowData, true);
							const sizeColumnsToFit = storeUtilities.getValue([props.screenId, 'sizePOSearchResultsGridColumnsToFit'], undefined);
							if(sizeColumnsToFit !== undefined){
								sizeColumnsToFit();
							}
						}
					}
				}));
		},
		handleRowClicked: (selectedRows) => {
			updateSelectedRowsInStore(props.screenId, selectedRows, dispatch);
		},
		handleClearSelectedClicked: (selectedRows) => {
			updateSelectedRowsInStore(props.screenId, selectedRows, dispatch);
		},
		onGridReady: (obj) => {
			obj.api.sizeColumnsToFit();
			storeUtilities.updateValue(dispatch, [props.screenId, 'sizePOSearchResultsGridColumnsToFit'], () => {
				obj.api.sizeColumnsToFit(); 
			});
		}
	};
};

export const getPOsfromRows = (selectedRows) => {
	let polist = [];
	for (let i = 0; i < selectedRows.length; i++){
		polist.push(selectedRows[i].purchaseOrderNumber);
	}
	return polist;
};

const getPartsFromPOs = (props, selectedRows, dispatch) => {
	const polist = getPOsfromRows(selectedRows);
	const vendorCode = storeUtilities.getValue([...props.path, 'VendorCode'], undefined);
	const division = storeUtilities.getValue([...props.path, 'division'], undefined);	
	const args = {
		VendorCode: vendorCode,
		Division: division,
		polist: polist
	};
	const prunedArgs = sharedUtilities.pruneObject(args);
	dispatch(rpcAction({
		args: { VendorId: args.VendorCode, Region: args.Division },
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Vendor/EmailInfo',
		method: 'GET',
		hideLoadingMaskOnComplete: false,
		showLoadingMask: true,
		callback: (vendorEmailInfo) => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'VendorEmailInfos'], [vendorEmailInfo]);
			storeUtilities.updateValue(dispatch, [props.screenId, 'DivisionsInUse'], [args.Division]);
			storeUtilities.updateValue(dispatch, [props.screenId, 'VendorCodeInUse'], args.VendorCode);
			dispatch(rpcAction({
				args: prunedArgs, 
				nodeRoute: nodeRoutes.IFRServiceNET, 
				endpoint: '/Parts/Skus', 
				method: 'GET',
				hideLoadingMaskOnComplete: true,
				showLoadingMask: true,
				callback: (rowData) => {
					if(rowData && Array.isArray(rowData)){
						storeUtilities.updateValue(dispatch, [props.screenId, 'PartSearchResultsGrid', 'rowData'], rowData, true);
						const sizeColumnsToFit = storeUtilities.getValue([props.screenId, 'sizePartSearchResultsGridColumnsToFit'], undefined);
						if(sizeColumnsToFit !== undefined){
							sizeColumnsToFit();
						}
					}
				}
			}));
		}
	}));
};

const updateSelectedRowsInStore = (screenId, selectedRows, dispatch) => {
	storeUtilities.updateValue(dispatch, [screenId, 'POSearchResultsGrid', 'selectedRows'], selectedRows);
};

POLookUpDialogContainer.protoTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	path: PropTypes.array.isRequired,
	handleSearchClicked: PropTypes.func,
	handleClearFormClicked: PropTypes.func,
	handleClearSelectedClicked: PropTypes.func,
	handleSelectClicked: PropTypes.func,
	handleCloseClicked: PropTypes.func,
	searchButtonDisabled: PropTypes.bool,
	disableSelectButton: PropTypes.bool,
	onGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	division: PropTypes.string,
	vendorCode: PropTypes.string
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(POLookUpDialogContainer);

export default connectedComponent;