import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import { 
	DataGridContainer,
	NumberTextBoxCellEditorContainer,
	NumberTextBoxCellRendererContainer 
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
class SuggestedOrdersOrdersTab extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	cannotBePrimary(params){
		return this.props.cannotBePrimary ? this.props.cannotBePrimary(params) : false;
	}
	getHasNoSalesHistory(params){
		if(this.props.getHasNoSalesHistory)
			return this.props.getHasNoSalesHistory(params);
		return false;
	}
	onGridReady(params){
		this.api = params.api;
		if(this.props.onGridReady)
			this.props.onGridReady(params);
	}
	getColumnValue(params){
		return this.props.getColumnValue ? this.props.getColumnValue(params) : undefined;
	}
	getIsCellHidden(params){
		return this.props.getIsCellHidden ? this.props.getIsCellHidden(params) : undefined;
	}
	handleOrderChanged({gridApi, node, value}){
		if(this.props.handleOrderChanged)
			this.props.handleOrderChanged(gridApi, node, value);
	}
	onCellClicked(params){
		if(this.props.onCellClicked)
			this.props.onCellClicked(params);
	}
	tabToNextCell(params){
		if(this.props.tabToNextCell){
			return this.props.tabToNextCell(params, this.api);
		}
	}
	getFormattedColumnValue(params){
		if(this.api){
			const allDisplayedColumns = this.api.getAllDisplayedColumns();
			let visible = false;
			if(Array.isArray(allDisplayedColumns)){
				allDisplayedColumns.forEach((column)=>{
					if(column.colDef.colId === params.colDef.colId){
						visible = true;
					}
				});
			}
			if(visible === false || (typeof params.value === 'object')){
				return params.value;
			}
			return String(params.value);
		}
		return '';
	}
	render() {
		return (
			<DataGridContainer
				screenId={this.props.screenId} 
				panelId={this.props.panelId}
				fieldId='SuggestedOrdersOrdersGrid' 
				forwardRef={this.props.forwardRef} 
				defaultColDef={{resizable:true}}
				rowHeight={this.props.showDetailedDescription ? 60 : 30}
				onCellClicked={this.onCellClicked.bind(this)}
				tabToNextCell={this.tabToNextCell.bind(this)}
				columnDefs=
					{
						[
							{ 
								headerName: '', 
								field: 'ORDERLINE', 
								colId: 'ORDERLINE', 
								hide: true, 
								valueFormatter: (obj) => obj
							}, 
							{ 
								headerName: 'Part SKU', 
								field: 'PARTSKU', 
								colId: 'PARTSKU', 
								sortable: true, 
								width:100, 
								minWidth:100, 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							}, 
							{ 
								headerName: 'Description', 
								field: 'PARTDESCRIPTION', 
								colId: 'PARTDESCRIPTION', 
								sortable: true, 
								width:400, 
								minWidth:200, 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							}, 
							{ 
								headerName: 'Avg Dy Ship', 
								field: 'AVERAGEDAILYSHIPMENTS', 
								colId: 'AVERAGEDAILYSHIPMENTS', 
								sortable: true, 
								width:100, 
								minWidth:100, 
								type:'numericColumn', 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'Avg Wk Ship', 
								field: 'AVERAGEWEEKLYSHIPMENTS', 
								colId: 'AVERAGEWEEKLYSHIPMENTS', 
								sortable: true, 
								width:100, 
								minWidth:100, 
								type:'numericColumn', 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'Tot Ship', 
								field: 'TOTALSHIPMENTS', 
								colId: 'TOTALSHIPMENTS', 
								sortable: true, 
								width:80, 
								minWidth:80, 
								type:'numericColumn', 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'Pend Ship', 
								field: 'PENDINGSHIPMENTS', 
								colId: 'PENDINGSHIPMENTS', 
								sortable: true, 
								width:80, 
								minWidth:80, 
								type:'numericColumn', 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'QOH', 
								field: 'QUANTITYONHAND', 
								colId: 'QUANTITYONHAND', 
								sortable: true, 
								width:80, 
								minWidth:80, 
								type:'numericColumn', 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'InB Qty', 
								field: 'INBOUNDQUANTITY', 
								colId: 'INBOUNDQUANTITY', 
								sortable: true, 
								width:100, 
								minWidth:100, 
								type:'numericColumn', 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'Avail Qty', 
								field: 'AVAILABLEQUANTITY', 
								colId: 'AVAILABLEQUANTITY', 
								sortable: true, 
								width:100, 
								minWidth:100, 
								type:'numericColumn', 
								valueGetter: this.getColumnValue.bind(this), 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'Wks InB', 
								field: 'WEEKSOFSTOCKINBOUND', 
								colId: 'WEEKSOFSTOCKINBOUND', 
								sortable: true, 
								width:80, 
								minWidth:80, 
								valueGetter: this.getColumnValue.bind(this), 
								type:'numericColumn', 
								valueFormatter: this.getFormattedColumnValue.bind(this)
							},
							{ 
								headerName: 'Order', 
								field: 'CALCULATEDORDERQUANTITY', 
								colId: 'CALCULATEDORDERQUANTITY',
								sortable: true, 
								width:90, 
								minWidth:90,
								editable: true, 
								singleClickEdit:true, 
								headerClass: 'ag-center-header',
								cellRenderer: NumberTextBoxCellRendererContainer, 
								cellRendererParams:
									{
										getIsInvalid: this.getHasNoSalesHistory.bind(this),
										getIsHidden: this.getIsCellHidden.bind(this),
										dataType: sharedUtilities.dataTypes.integer,
										gridName: 'SuggestedOrdersOrdersGrid',
										screenId: this.props.screenId,
										panelId: this.props.panelId,
										tooltipPlacement: 'left'
									},
								cellEditor: NumberTextBoxCellEditorContainer, 
								cellEditorParams: 
									{ 
										getIsInvalid: this.getHasNoSalesHistory.bind(this),
										getIsHidden: this.getIsCellHidden.bind(this),
										dataType: sharedUtilities.dataTypes.integer,
										gridName: 'SuggestedOrdersOrdersGrid',
										screenId: this.props.screenId,
										panelId: this.props.panelId,
										tooltipPlacement: 'left',
										onChange: this.handleOrderChanged.bind(this)
									}, 
								valueGetter: this.getColumnValue.bind(this),
								valueFormatter: this.getFormattedColumnValue.bind(this),
								type:'numericColumn'
							},
							{ 
								headerName: '', 
								field: 'spacer', 
								colId: 'spacer', 
								sortable: false, 
								width: 30, 
								minWidth: 30, 
								flex: 1, 
								resizable: false 
							}
						]
					}
				rowData={this.props.rowData}
				onGridReady={this.onGridReady.bind(this)}
				excludeColumnsFromColumnsPanel={['CALCULATEDORDERQUANTITY']}
				showColumnsPanel
				store={store}
			/>
		);
	}
}

SuggestedOrdersOrdersTab.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	showDetailedDescription: PropTypes.bool,
	disablePanel: PropTypes.bool,
	height: PropTypes.number,
	cannotBePrimary: PropTypes.func,
	rowData: PropTypes.array,
	getHasNoSalesHistory: PropTypes.func,
	tabToNextCell: PropTypes.func,
	onLoad: PropTypes.func
};
export default SuggestedOrdersOrdersTab;