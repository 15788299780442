import { cloneDeep } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../../../actions/closeDialogAction';
import rpcAction from '../../../../actions/rpcAction';
import updateGlobalAppStateValueAction from '../../../../actions/updateGlobalAppStateValueAction';
import { altSkuType, nodeRoutes } from '../../../../constants';
import * as persistentValues from '../../../../utilities/persistentValues';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import * as orderUtilities from '../OrderProductScreenUtilities';
import * as sharedUtilities from '../../../../utilities/sharedUtilities';
import MultiSkuAltSkuDialog from './MultiSkuAltSkuDialog';
class MultiSkuAltSkuDialogContainer extends Component {
	render() {
		return (
			<MultiSkuAltSkuDialog {...this.props}/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const skusRowData = storeUtilities.getValue([...props.path, 'skusRowData'], []);
	const disableSelectButtons = storeUtilities.getValue([...props.path, 'disableSelectButtons'], true);
	return {
		disableSelectButtons,
		skusRowData
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			const orderLines = cloneDeep(props.orderLines || []);
			const originalOrderLines = (props.originalOrderLines || []);

			const skusRowData = orderLines.map(orderLine => {
				const defaultAltSku = orderUtilities.getDefaultAltSku(props.screenId, orderLine, props.skuAltSkuMappingPath);
				const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, orderLine.SKU);
				altSkuInfo.altSku = altSkuInfo?.altSku || defaultAltSku;
				altSkuInfo.ORIG_chkSubstitute = altSkuInfo.chkSubstitute || false;
				altSkuInfo.ORIG_chkCombination = altSkuInfo.chkCombination || false;
				const matchingOOLs = originalOrderLines.filter(ool => ool.SKU === orderLine.SKU);
				const ool = matchingOOLs.length === 1 ? matchingOOLs[0] : orderLine;
				const rowData = {
					ORIGINAL_ORDERLINE: ool,
					ALT_ORDERLINE: altSkuInfo.altOrderLine,
					ALTSKU: altSkuInfo.altSku,
					PRODUCT_EXISTS: undefined,//we don't know yet
					SKU: orderLine.SKU,
					VENDORDESCRIPTION: orderLine.VendorDescription,
					CHKCOMBINATION: altSkuInfo?.altSkuType === altSkuType.COMBINATION,
					CHKSUBSTITUTE: altSkuInfo?.altSkuType === altSkuType.SUBSTITUTION
				};
				updateSingleAltSkuValues(dispatch, props.screenId, orderLine.SKU, altSkuInfo);
				return rowData;
			});
			storeUtilities.updateValue(dispatch, [...props.path, 'skusRowData'], skusRowData);
		},
		onAltSkuChanged: (params) => {
			const newAltSku = params?.value;
			const existingAltSku = params?.node?.data?.ALT_ORDERLINE?.SKU;
			const sku = params.node.data.ORIGINAL_ORDERLINE.SKU;
			const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, sku);
			if(String(newAltSku) !== String(existingAltSku)){
				params.node.setDataValue('ALT_ORDERLINE', undefined);
				params.node.setDataValue('ALTSKU', newAltSku);
				params.node.setDataValue('CHKCOMBINATION', false);
				params.node.setDataValue('CHKSUBSTITUTE', false);
				params.node.setDataValue('PRODUCT_EXISTS', undefined);
				altSkuInfo.altSku = newAltSku;
				altSkuInfo.altOrderLine = undefined;
				altSkuInfo.altSkuType = 'DEFAULT';
				altSkuInfo.altSubQty = undefined;
				altSkuInfo.altComQty = undefined;
				altSkuInfo.chkSubstitute = false;
				altSkuInfo.chkCombination = false;
				updateSingleAltSkuValues(dispatch, props.screenId, sku, altSkuInfo);
			}
			params.gridApi.refreshCells({rowNodes: [params.node], columns: ['ALTSKU', 'CHKSUBSTITUTE', 'CHKCOMBINATION', 'ALT_ORDERLINE'], force:true});
			enableDisableSelectButtons(dispatch, params.gridApi, [...props.path, 'disableSelectButtons']);
		},
		onLoadSkuClicked: (params) => {
			const altSku = params?.value;
			if(altSku === undefined)
				return;
			checkIfProductExists(dispatch, altSku, (response) => {
				params.node.setDataValue('PRODUCT_EXISTS', response === true);
				if(response === true){
					getSuggestedOrders(dispatch, props.screenId, [altSku], (gsoResponse) => {
						handleGetSuggestedOrdersResponse(dispatch, props.screenId, params.gridApi, gsoResponse, () => {
							enableDisableSelectButtons(dispatch, params.gridApi, [...props.path, 'disableSelectButtons']);
						});
					});
				}
				else {
					params.gridApi.refreshCells({rowNodes: [params.node], columns: ['ALTSKU', 'CHKSUBSTITUTE', 'CHKCOMBINATION'], force:true});
					dispatch(updateGlobalAppStateValueAction(['showLoadingMask'], false));
				}
			});
		},
		onChkCombinationChanged: (gridApi, rowNode, chkCombination) => {
			const altOrderLine = rowNode?.data?.ALT_ORDERLINE;
			let orderValue = rowNode?.data?.ORIGINAL_ORDERLINE?.Order;
			const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, rowNode.data.ORIGINAL_ORDERLINE.SKU);
			altSkuInfo.chkCombination = chkCombination;
			if(chkCombination === true){
				altSkuInfo.chkSubstitute = false;
			}
			altSkuInfo.altComQty = orderUtilities.getAltComQty(rowNode.data.ORIGINAL_ORDERLINE, altOrderLine);
			altSkuInfo.altSubQty = orderUtilities.getAltSubQty(rowNode.data.ORIGINAL_ORDERLINE, altOrderLine);
			altSkuInfo.altOrderLine = altOrderLine;
			altSkuInfo.altSku = altOrderLine?.SKU;
			altSkuInfo.altSkuTextBox = altOrderLine?.SKU;
			altSkuInfo.altSkuType = chkCombination === true ? altSkuType.COMBINATION : (altSkuInfo.chkSubstitute === true ? altSkuType.SUBSTITUTION : altSkuType.DEFAULT);

			orderValue = altSkuInfo.altComQty;

			if(props.onSkuTypeChanged){
				props.onSkuTypeChanged(orderValue > 0 ? orderValue : 0, cloneDeep(rowNode.data.ORIGINAL_ORDERLINE), altOrderLine, altSkuInfo.altSkuType);
			}
			rowNode.setDataValue('CHKSUBSTITUTE', altSkuInfo.chkSubstitute);
			gridApi.refreshCells({rowNodes: [rowNode], columns:['CHKSUBSTITUTE'], force: true});
			//update the values for the original AltSkuDialog - makes it so that the original AltSkuDialog can open each record individually and edit them
			updateSingleAltSkuValues(dispatch, props.screenId, rowNode.data.SKU, altSkuInfo);
		},
		onChkSubstituteChanged: (gridApi, rowNode, chkSubstitute) => {
			const altOrderLine = rowNode?.data?.ALT_ORDERLINE;
			let orderValue = rowNode?.data?.ORIGINAL_ORDERLINE?.Order;
			const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, rowNode.data.ORIGINAL_ORDERLINE.SKU);
			altSkuInfo.chkSubstitute = chkSubstitute;
			if(chkSubstitute === true){
				altSkuInfo.chkCombination = false;
			}
			altSkuInfo.altComQty = orderUtilities.getAltComQty(rowNode.data.ORIGINAL_ORDERLINE, altOrderLine);
			altSkuInfo.altSubQty = orderUtilities.getAltSubQty(rowNode.data.ORIGINAL_ORDERLINE, altOrderLine);
			altSkuInfo.altOrderLine = altOrderLine;
			altSkuInfo.altSku = altOrderLine?.SKU;
			altSkuInfo.altSkuTextBox = altOrderLine?.SKU;
			altSkuInfo.altSkuType = chkSubstitute === true ? altSkuType.SUBSTITUTION : (altSkuInfo.chkCombination === true ? altSkuType.COMBINATION : altSkuType.DEFAULT);

			orderValue = altSkuInfo.altComQty;

			if(props.onSkuTypeChanged){
				props.onSkuTypeChanged(orderValue > 0 ? orderValue : 0, cloneDeep(rowNode.data.ORIGINAL_ORDERLINE), altOrderLine, altSkuInfo.altSkuType);
			}
			rowNode.setDataValue('CHKCOMBINATION', altSkuInfo.chkCombination);
			gridApi.refreshCells({rowNodes: [rowNode], columns:['CHKCOMBINATION'], force: true});
			//update the values for the original AltSkuDialog - makes it so that the original AltSkuDialog can open each record individually and edit them
			updateSingleAltSkuValues(dispatch, props.screenId, rowNode.data.SKU, altSkuInfo);
		},
		onBtnSetAllToCombinationClicked: (gridApi) => {
			const nodes = [];
			gridApi.forEachNode(node => {  
				nodes.push(node);
				const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, node.data.ORIGINAL_ORDERLINE.SKU);
				const altOrderLine = node.data.ALT_ORDERLINE;
				altSkuInfo.chkCombination = altOrderLine ? true : false;
				altSkuInfo.chkSubstitute = false;
				altSkuInfo.altComQty = orderUtilities.getAltComQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				altSkuInfo.altSubQty = orderUtilities.getAltSubQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				altSkuInfo.altSkuType = altSkuType.COMBINATION;
				altSkuInfo.altOrderLine = altOrderLine;
				altSkuInfo.altSku = altOrderLine?.SKU;
				altSkuInfo.altSkuTextBox = altOrderLine?.SKU;

				node.setDataValue('CHKCOMBINATION', altSkuInfo.chkCombination);
				node.setDataValue('CHKSUBSTITUTE', altSkuInfo.chkSubstitute);
				if(props.onSkuTypeChanged){
					props.onSkuTypeChanged(altSkuInfo.altComQty > 0 ? altSkuInfo.altComQty : 0, cloneDeep(node.data.ORIGINAL_ORDERLINE), altOrderLine, altSkuInfo.altSkuType);
				}
				//update the values for the original AltSkuDialog - makes it so that the original AltSkuDialog can open each record individually and edit them
				updateSingleAltSkuValues(dispatch, props.screenId, node.data.SKU, altSkuInfo);
			});
			gridApi.refreshCells({rowNodes: nodes, columns:['CHKCOMBINATION', 'CHKSUBSTITUTE'], force: true});
		},
		onBtnSetAllToSubstituteClicked: (gridApi) => {
			const nodes = [];
			gridApi.forEachNode(node => {
				nodes.push(node);
				const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, node.data.ORIGINAL_ORDERLINE.SKU);
				const altOrderLine = node.data.ALT_ORDERLINE;
				altSkuInfo.chkCombination = false;
				altSkuInfo.chkSubstitute = true;
				altSkuInfo.altComQty = orderUtilities.getAltComQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				altSkuInfo.altSubQty = orderUtilities.getAltSubQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				altSkuInfo.altSkuType = altSkuType.SUBSTITUTION;
				altSkuInfo.altOrderLine = altOrderLine;
				altSkuInfo.altSku = altOrderLine?.SKU;
				altSkuInfo.altSkuTextBox = altOrderLine?.SKU;
				
				node.setDataValue('CHKCOMBINATION', false);
				node.setDataValue('CHKSUBSTITUTE', true);
				if(props.onSkuTypeChanged){
					props.onSkuTypeChanged(altSkuInfo.altSubQty > 0 ? altSkuInfo.altSubQty : 0, cloneDeep(node.data.ORIGINAL_ORDERLINE), altOrderLine, altSkuInfo.altSkuType);
				}
				//update the values for the original AltSkuDialog - makes it so that the original AltSkuDialog can open each record individually and edit them
				updateSingleAltSkuValues(dispatch, props.screenId, node.data.SKU, altSkuInfo);
			});
			gridApi.refreshCells({rowNodes: nodes, columns:['CHKCOMBINATION', 'CHKSUBSTITUTE'], force: true});
		},
		onBtnClearAllClicked: (gridApi) => {
			const nodes = [];
			gridApi.forEachNode(node => {
				nodes.push(node);
				const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, node.data.ORIGINAL_ORDERLINE.SKU);

				altSkuInfo.chkCombination = false;
				altSkuInfo.chkSubstitute = false;
				altSkuInfo.altComQty = undefined;
				altSkuInfo.altSubQty = undefined;
				altSkuInfo.altSkuType = altSkuType.DEFAULT;
				altSkuInfo.altSku = undefined;
				altSkuInfo.altSkuTextBox = undefined;
				//not modifying altSkuInfo.altOrderLine

				node.setDataValue('CHKSUBSTITUTE', false);
				node.setDataValue('CHKCOMBINATION', false);
				const orderValue = node.data.ORIGINAL_ORDERLINE.Order;
				if(props.onSkuTypeChanged){
					props.onSkuTypeChanged(orderValue > 0 ? orderValue : 0, cloneDeep(node.data.ORIGINAL_ORDERLINE));
				}
				//update the values for the original AltSkuDialog - makes it so that the original AltSkuDialog can open each record individually and edit them
				updateSingleAltSkuValues(dispatch, props.screenId, node.data.SKU, altSkuInfo);
			});
			gridApi.refreshCells({rowNodes: nodes, columns:['CHKCOMBINATION', 'CHKSUBSTITUTE'], force: true});
		},
		onOkClicked: (gridApi) => {
			gridApi.forEachNode(node => {
				const sku = node.data.ORIGINAL_ORDERLINE.SKU;
				const altSku = node.data.ALTSKU;
				const skuAltSkuObj = persistentValues.get(props.skuAltSkuMappingPath);
				const skuAltSkuArray = Array.isArray(skuAltSkuObj) ? skuAltSkuObj : [];
				const skuAltSkuMap = new Map(skuAltSkuArray);
				if(sku){
					skuAltSkuMap.set(sku, altSku);
					persistentValues.set(props.skuAltSkuMappingPath, [...skuAltSkuMap]);
				}
			});
			dispatch(closeDialogAction());
		},
		onCancelClicked: (gridApi) => {
			gridApi.forEachNode(node => {
				const altSkuInfo = orderUtilities.getAltSkuInfo(props.screenId, node.data.ORIGINAL_ORDERLINE.SKU);
				const altOrderLine = altSkuInfo?.altOrderLine;

				altSkuInfo.chkCombination = altSkuInfo['ORIG_chkCombination'] || false;
				altSkuInfo.chkSubstitute = altSkuInfo['ORIG_chkSubstitute'] || false;
				altSkuInfo.altComQty = orderUtilities.getAltComQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				altSkuInfo.altSubQty = orderUtilities.getAltSubQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				altSkuInfo.altSkuType = altSkuInfo.chkCombination === true ? altSkuType.COMBINATION : (altSkuInfo.chkSubstitute === true ? altSkuType.SUBSTITUTION : altSkuType.DEFAULT);
				altSkuInfo.altSku = altOrderLine?.SKU;
				altSkuInfo.altSkuTextBox = altOrderLine?.SKU;

				let orderValue = node.data.ORIGINAL_ORDERLINE.Order;
				if(altSkuInfo.chkSubstitute){
					orderValue = orderUtilities.getAltSubQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				}
				else if (altSkuInfo.chkCombination){
					orderValue = orderUtilities.getAltComQty(node.data.ORIGINAL_ORDERLINE, altOrderLine);
				}
				if(props.onSkuTypeChanged){
					props.onSkuTypeChanged(orderValue > 0 ? orderValue : 0, cloneDeep(node.data.ORIGINAL_ORDERLINE), altOrderLine, altSkuInfo.altSkuType);
				}
				updateSingleAltSkuValues(dispatch, props.screenId, node.data.SKU, altSkuInfo);
			});
			dispatch(closeDialogAction());
		},
		onBtnLoadAllAltSkusClicked: (gridApi) => {
			let responseCount = 0;
			const rowCount = gridApi.getDisplayedRowCount();
			const altSkus = [];
			gridApi.forEachNode(node => {
				if(!altSkus.includes(node.data.ALTSKU)){
					altSkus.push(node.data.ALTSKU);
				}
				checkIfProductExists(dispatch, node.data.ALTSKU, (response) => {
					responseCount++;
					node.setDataValue('PRODUCT_EXISTS', response === true);
					gridApi.refreshCells({rowNodes: [node], columns: ['ALTSKU', 'CHKSUBSTITUTE', 'CHKCOMBINATION'], force:true});
					if(responseCount >= rowCount){
						getSuggestedOrders(dispatch, props.screenId, altSkus, (gsoResponse) => {
							handleGetSuggestedOrdersResponse(dispatch, props.screenId, gridApi, gsoResponse, () => {
								enableDisableSelectButtons(dispatch, gridApi, [...props.path, 'disableSelectButtons']);
							});
						});
					}
				});
			});
		}
	};
};
const enableDisableSelectButtons = (dispatch, gridApi, disableSelectButtonsPath) => {
	let disableSelectButtons = false;
	gridApi.forEachNode(node => {
		disableSelectButtons = disableSelectButtons || node.data.ALT_ORDERLINE === undefined;
	});
	storeUtilities.updateValue(dispatch, disableSelectButtonsPath, disableSelectButtons);
};
const updateSingleAltSkuValues = (dispatch, screenId, sku, altSkuInfo) => {
	const skuAltSkuPath = orderUtilities.getAltSkuOverridePath(screenId, sku);
	if(altSkuInfo?.altOrderLine){
		storeUtilities.updateValue(dispatch, skuAltSkuPath, altSkuInfo);
	}
	else {
		storeUtilities.deleteValue(dispatch, skuAltSkuPath);
	}
};
const checkIfProductExists = (dispatch, sku, callback) => {
	dispatch(rpcAction({
		args: {sku: sku},
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: 'Products/Exists',
		method: 'GET',
		hideLoadingMaskOnComplete: false,
		showLoadingMask: true,
		callback,
		customResponseHandler: async (response) => {
			if(response.status === 400){
				return false;
			}
			return await sharedUtilities.parseResponse(response);
		}
	}));
};
const handleGetSuggestedOrdersResponse = (dispatch, screenId, gridApi, gsoResponse, callback) => {
	if(Array.isArray(gsoResponse)){
		const {orderLines} = orderUtilities.getOrderLinesFromJsonArrayResults(gsoResponse);
		const nodes = [];
		orderLines.forEach(orderLine => {
			gridApi.forEachNode(node => {
				if(orderLine.SKU === node.data.ALTSKU){
					nodes.push(node);
					const altOrderLine = cloneDeep(orderLine);
					node.setDataValue('ALT_ORDERLINE', altOrderLine);
					const sku =  node.data.ORIGINAL_ORDERLINE.SKU;
					const altSkuInfo = orderUtilities.getAltSkuInfo(screenId, sku);
					altSkuInfo.altOrderLine = altOrderLine;
					altSkuInfo.ORIG_chkSubstitute = altSkuInfo.chkSubstitute || false;
					altSkuInfo.ORIG_chkCombination = altSkuInfo.chkCombination || false;
					updateSingleAltSkuValues(dispatch, screenId, sku, altSkuInfo);
				}
			});
		});
		gridApi.refreshCells({rowNodes: nodes, columns: ['CHKSUBSTITUTE', 'CHKCOMBINATION', 'ALT_ORDERLINE'], force:true});
		if(callback){
			callback();
		}
	}
};
const getSuggestedOrders = (dispatch, screenId, skus, callback) => {
	const whsNum = storeUtilities.getValue([screenId, 'whsNumInUse']);
	const transitTime = storeUtilities.getValue([screenId, 'transitTime'], 0);
	const prodTime = storeUtilities.getValue([screenId, 'prodTime'], 0);
	const combineDCsSelected = storeUtilities.getValue([screenId, 'enableCombineWhsList'], false);
	const useTotalDays = storeUtilities.getValue([screenId, 'cbUseTotalDaysInUse'], false);
	const dateFormat = 'YYYY-MM-DD';
	const args = {
		skuList: skus,
		warehouseList: combineDCsSelected === true ? storeUtilities.getValue([screenId, 'combineWhsList'], [whsNum]) : [whsNum],
		combineWarehouse: combineDCsSelected,
		salesStart: moment(storeUtilities.getValue([screenId, 'beginSalesDate'])).format(dateFormat),
		salesEnd: moment(storeUtilities.getValue([screenId, 'endSalesDate'])).format(dateFormat),
		productionLeadDays: prodTime,
		transitLeadDays: transitTime,
		daysToNextOrder: storeUtilities.getValue([screenId, 'daysToNextOrder']),
		safetyDays: storeUtilities.getValue([screenId, 'daysToStock'], 0),
		orderMethod: storeUtilities.getValue([screenId, 'merchGroup']),
		marketAdjustment: storeUtilities.getValue([screenId, 'marketAdjustment'], 0),
		poAdjustment: storeUtilities.getValue([screenId, 'POAdjustment'], 0),
		includeIntraDivisionOrders: storeUtilities.getValue([screenId, 'intraOrder'], false),
		excludePromotionalAvailable: storeUtilities.getValue([screenId, 'showPromoAvail'], false),
		excludeDates: orderUtilities.getExcludedMomentRanges([screenId, 'excludedMomentRanges'], 'YYYY-MM-DD'),
		useTotalDays
	};
	dispatch(rpcAction({
		args,
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Orders/suggested',
		method: 'POST',
		hideLoadingMaskOnComplete: true,
		showLoadingMask: true,
		callback
	}));
};
MultiSkuAltSkuDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	path: PropTypes.array.isRequired,
	skuAltSkuMappingPath: PropTypes.array.isRequired,
	parentPanelId: PropTypes.string.isRequired,
	orderLines: PropTypes.array.isRequired,
	originalOrderLines: PropTypes.array.isRequired,
	onSkuTypeChanged: PropTypes.func,
	onLoadSkuClicked: PropTypes.func,
	onChkCombinationChanged: PropTypes.func,
	onChkSubstituteChanged: PropTypes.func,
	onBtnSetSelectedToCombinationClicked: PropTypes.func,
	onBtnSetSelectedToSubstituteClicked: PropTypes.func,
	onBtnClearSelectedClicked: PropTypes.func,
	onOkClicked: PropTypes.func,
	onCancelClicked: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MultiSkuAltSkuDialogContainer);

export default connectedComponent;