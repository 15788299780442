import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { DataGridContainer } from '@rtg/shared-react-components';
import moment from 'moment';
import * as validators from '../../utilities/commonValidators';
import { 
	ButtonContainer,
	TextBoxContainer,
	DatePickerContainer,
	DropdownContainer
} from '@rtg/shared-react-components';
import store from '../../configureStore';
import '../../css/DatePickerOverrides.css';

class POLookUpDialog extends Component {
	constructor(props){
		super(props);
		this.dataGrid = React.createRef();
	}
	componentDidMount(){
		if (this.props.onLoad){
			this.props.onLoad();
		}
		if(this.dataGrid.current && this.dataGrid.current.api && this.dataGrid.current.api.sizeColumnsToFit){
			this.dataGrid.current.api.sizeColumnsToFit();
		}
	}
	handlekeyDown = (e) => {
		if(e.ctrlKey && e.which === 65) {
			e.preventDefault();
			this.dataGrid.current.api.selectAll();
		}
		
	};
	getPath = (fieldKey) => {
		if (fieldKey){
			return [...this.props.path, fieldKey];
		} else {
			return this.props.path;
		}
	};
	handleClearSelectedClicked = () => {
		this.dataGrid.current.api.deselectAll();
		if(this.props.handleClearSelectedClicked){
			this.props.handleClearSelectedClicked(this.dataGrid.current.api.getSelectedRows());
		}
	};
	handleSelectClicked = () => {
		if(this.props.handleSelectClicked && !this.props.disableSelectButton){
			this.props.handleSelectClicked(this.dataGrid.current.api.getSelectedRows());
			if(this.props.onDialogClosed){
				this.props.onDialogClosed(true);
			}
		}
	};
	handleCloseClicked = () => {
		if(this.props.handleCloseClicked) {
			this.props.handleCloseClicked(() => {
				if(this.props.onDialogClosed){
					this.props.onDialogClosed(true);
				}
			});
		}
	};
	handleSearchClicked = () => {
		if(this.props.handleSearchClicked && !this.props.searchButtonDisabled) {
			this.props.handleSearchClicked();
		}
	};
	handleClearFormClicked = () => {
		if(this.props.handleClearFormClicked) {
			this.props.handleClearFormClicked(() => {
				if (this.props.handleClearFormClicked) {
					this.props.handleClearFormClicked(true);
				}
			});
		}
	};
	handleRowClicked = () => {
		if(this.props.handleRowClicked){
			this.props.handleRowClicked(this.dataGrid.current.api.getSelectedRows());
		}
	};
	onGridReady = (obj) => {
		if(this.props.onGridReady !== undefined){
			this.props.onGridReady(obj);
		}
	};

	render(){
		return (
			<Card className={'h-200 mr-3'}>
				<Card.Header>
					<h5 className='text-left'>Select a Purchase Order...</h5>
				</Card.Header>
				<Card.Body>
					<Row className={'mb-1'}>
						<Col>
							<DropdownContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'division'}
								options={[{value:'FL', name:'Florida'},{value:'SE', name:'Southeast'},{value:'TX', name:'Texas'},{value:'PR', name:'Puerto Rico'}]}
								label={'Division'}
								showRequiredAsterisk
								overridePath={[...this.props.path, 'division']}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'VendorCode'}
								label={'Vendor Code'}
								validators={[validators.required]}
								showRequiredAsterisk
								store={store}
								overridePath={[...this.props.path, 'VendorCode']}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'factory'}
								label={'Factory'}
								store={store}
								overridePath={[...this.props.path, 'factory']}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'style'}
								label={'Style'}
								store={store}
								overridePath={[...this.props.path, 'style']}
							/>
						</Col>
					</Row>
					<Row className='mb-1'>
						<Col>
							<Row className='mb-1'>
								<Col>
									<DatePickerContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'beginSalesDate'}
										label={'Start Date'}
										maxDate={this.props.beginSalesDate}
										validators={[
											(args) => validators.isInDateRange({...args, maxDate: this.props.endSalesDate})
										]}
										persistValue
										overridePath={[...this.props.path, 'beginSalesDate']}
										store={store}
									/>
								</Col>
							</Row>
							<Row className='mb-1'>
								<Col>
									<DatePickerContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'endSalesDate'}
										label={'End Date'}
										minDate={this.props.endSalesDate}
										validators={[
											(args) => validators.isInDateRange({...args, minDate: this.props.beginSalesDate})
										]}
										persistValue
										overridePath={[...this.props.path, 'endSalesDate']}
										store={store}
									/>
								</Col>
							</Row>
							<Row><Col>&nbsp;</Col></Row>
							<Row>
								<Col>&nbsp;</Col>
								<Col>
									<div className={'d-flex justify-content-center'}>
										<ButtonContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'btnSearch'} 
											onClick={this.handleSearchClicked.bind(this)} 
											disabled={this.props.searchButtonDisabled}
											text='Search'
											fill
											width={'100px'}
											store={store}
										/>
									</div>
								</Col>
							</Row>
							<Row><Col>&nbsp;</Col></Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className='h-100 w-100' style={{minHeight:'200px'}} onKeyDown={this.handlekeyDown}>
								<DataGridContainer
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId='POSearchResultsGrid' 
									forwardRef={this.dataGrid} 
									defaultColDef={{resizable:true, filter:true}}
									rowSelection={'multiple'}
									columnDefs={[ 
										{ headerName: 'PO Num', field: 'purchaseOrderNumber', sortable: true, width: 95, minWidth: 95}, 
										{ headerName: 'Division', field: 'division', sortable: true, width: 75, minWidth: 75}, 
										{ headerName: 'Vendor', field: 'vendor', sortable: true, width: 85, minWidth: 85},
										{ headerName: 'Style', field: 'style', sortable: true, width: 120, minWidth: 120},
										{ headerName: 'Ship Date', field: 'shipDate', sortable: true, width: 95, minWidth: 95,
											valueFormatter: (params) => { return moment(params.value).format('MM/DD/YYYY'); }
										},
										{ headerName: 'Location', field: 'whs', sortable: true, width: 95, minWidth: 95},
										{ headerName: 'Factory', field: 'factory', sortable: true, width: 270, minWidth: 270}
									]}
									rowData={this.props.rowData}
									onRowClicked={this.handleRowClicked}
									onGridReady={this.onGridReady.bind(this)}
									store={store}
								/>
							</div>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>
					<Container fluid={true}>
						<Row noGutters={true}>
							<Col>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId='clearSelectedButton' 
									text={'Clear Selected POs'} 
									onClick={this.handleClearSelectedClicked.bind(this)}
									fill 
									width={'120px'}
									disabled={this.props.disableSelectButton}
									store={store}
								/>
							</Col>
							<Col>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId='selectButton' 
									text={'Select'} 
									onClick={this.handleSelectClicked.bind(this)}
									fill 
									width={'100px'}
									disabled={this.props.disableSelectButton}
									store={store}
								/>
							</Col>
							<Col>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId='closeButton' 
									text={'Close'} 
									onClick={this.handleCloseClicked.bind(this)}
									fill 
									width={'100px'}
									store={store}
								/>
							</Col>
						</Row>
					</Container>
				</Card.Footer>
			</Card>
		);
	}
}
POLookUpDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	path: PropTypes.array.isRequired,
	handleSearchClicked: PropTypes.func,
	handleClearFormClicked: PropTypes.func,
	handleRowClicked: PropTypes.func,
	handleClearSelectedClicked: PropTypes.func,
	handleSelectClicked: PropTypes.func,
	handleCloseClicked: PropTypes.func,
	rowData: PropTypes.array,
	onGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	searchButtonDisabled: PropTypes.bool,
	disableSelectButton: PropTypes.bool
};

export default POLookUpDialog;