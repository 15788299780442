import React from 'react';
import withID from '../../../higherordercomponents/WithID';
const MaintenanceIcon = ({id}) => {
	return (
		<svg id={id} 
			x={'0px'}
			y={'0px'}
			viewBox={'0 0 512.883 512.883'} style={{enableBackground:'new 0 0 512.883 512.883'}}>
			<g transform={'translate(0 -1)'}>
				<g>
					<path style={{fill:'#E0E0E0'}} d={'M350.455,313.202l-52.966-52.966c-7.062,3.531-14.124,7.062-16.772,10.593 c-7.945,7.062-7.062,21.186-7.062,30.897l-5.297,5.297l44.138,44.138c23.835,23.834,52.966,66.207,75.034,99.752l0.883-0.883 c0-19.421,9.71-38.841,27.366-51.2c9.71-7.062,22.069-9.71,33.545-10.593l0.883-0.883 C416.662,366.168,374.29,337.037,350.455,313.202'}/>
					<path style={{fill:'#E0E0E0'}} d={'M211.862,239.933c9.71,0.883,23.835,0.883,30.897-6.179c3.531-3.531,7.062-9.71,10.593-16.772 l-52.966-52.966c-30.014-30.014-55.614-61.793-75.034-99.752l-0.883,0.883c0,19.421-9.71,38.841-27.366,51.2 c-9.71,5.297-21.186,8.828-33.545,8.828l-0.883,0.883c37.959,19.421,69.738,45.021,99.752,75.034l44.138,44.138L211.862,239.933 L211.862,239.933z'}/>
				</g>
				<g>
					<path style={{fill:'#C3C7CB'}} d={'M56.497,1.589l27.366,47.669l-1.766,4.414c-7.062,15.007-22.952,23.834-38.841,22.952h-4.414 L11.476,29.837C-6.179,56.32-0.883,94.278,27.366,114.582c20.303,15.007,48.552,15.007,69.738,0.883 c33.545-22.952,36.193-67.972,9.71-95.338C92.69,6.002,75.034-0.177,56.497,1.589'}/>
					<path style={{fill:'#C3C7CB'}} d={'M456.386,511.823l-27.365-47.669l1.766-4.414c7.062-15.007,22.952-23.834,38.841-22.952h4.414 l26.483,46.786c17.655-26.483,12.359-64.441-15.89-84.745c-20.303-15.007-48.552-15.007-69.738-0.883 c-33.545,22.952-36.193,67.972-9.71,95.338C420.193,507.409,438.731,513.589,456.386,511.823'}/>
				</g>
				<g>
					<path style={{fill:'#E15649'}} d={'M323.09,253.175l-62.676-62.676L442.262,9.533c10.593-10.593,28.248-10.593,38.841,0l23.834,23.834 c10.593,10.593,10.593,28.248,0,38.841L323.09,253.175z'}/>
					<path style={{fill:'#E15649'}} d={'M248.938,327.326l-62.676-62.676l25.6-24.717l61.793,61.793L248.938,327.326z'}/>
				</g>
				<path style={{fill:'#BC342E'}} d={'M273.655,301.726c-0.883-9.71,0-23.835,7.062-30.897c7.062-7.062,32.662-18.538,42.372-17.655 l-62.676-62.676c0.883,9.71-9.71,35.31-17.655,42.372c-6.179,7.945-21.186,7.062-30.897,7.062L273.655,301.726z'}/>
				<path style={{fill:'#C3C7CB'}} d={'M5.297,471.216L0,513.589l43.255-6.179l26.483-38.841l-25.6-24.717L5.297,471.216z'}/>
				<path style={{fill:'#E0E0E0'}} d={'M204.8,284.071L44.138,444.733l24.717,24.717l160.662-160.662L204.8,284.071z'}/>
				<path style={{fill:'#BC342E'}} d={'M317.793,204.623c-2.648,0-4.414-0.883-6.179-2.648c-3.531-3.531-3.531-8.828,0-12.359 L461.683,39.547c3.531-3.531,8.828-3.531,12.359,0s3.531,8.828,0,12.359L323.972,201.975 C322.207,203.74,320.441,204.623,317.793,204.623'}/>
				<g>
					<path style={{fill:'#C3C7CB'}} d={'M344.276,356.457l-13.241,13.241c3.531,4.414,7.062,8.828,10.593,14.124c0,0,0.883,0,0.883-0.883 l14.124-14.124c3.531-3.531,3.531-8.828,0-12.359C353.103,352.926,347.807,352.926,344.276,356.457'}/>
					<path style={{fill:'#C3C7CB'}} d={'M368.993,382.057l-16.772,16.772c3.531,4.414,7.062,9.71,10.593,14.124l0.883-0.883l17.655-17.655 c3.531-3.531,3.531-8.828,0-12.359C377.821,378.526,372.524,378.526,368.993,382.057'}/>
				</g>
			</g>
		</svg>
        
	);
};
export default withID(MaintenanceIcon);