import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SuggestedTransfersMainScreen extends Component {
	render() {
		return (
			<div id='divElement'>
				<br />
				<br />
				<p>Insert Suggested Transfers Here</p>
			</div>
		);
	}
}

SuggestedTransfersMainScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired
};

export default SuggestedTransfersMainScreen;