import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import CloseButton from '../../formelements/CloseButton';
import HiddenFieldContainer from '../../formelements/HiddenFieldContainer';
import { 
	DataGridContainer,
	DatePickerContainer,
	DropdownContainer,
	NumberTextBoxCellEditorContainer,
	NumberTextBoxCellRendererContainer
} from '@rtg/shared-react-components';
import EditPurchaseOrderIconButton from './EditPurchaseOrderIconButton';
import UndoButton from '../../formelements/UndoButton';
import store from '../../../configureStore';
import '../../../css/DatePickerOverrides.css';
const DEFAULT_ICON_HEIGHT = 20;
const DEFAULT_ICON_WIDTH = 20;
class SuggestedPOGridPanel extends React.Component {
	constructor(props){
		super(props);
		this.formatCurrency = this.formatCurrency.bind(this);
		this.gridRef = props.gridRef;
		this.state = { showTooltip: false };
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	getColumnValue(node){
		return this.props.getColumnValue ? this.props.getColumnValue(node) : undefined;
	}
	getFormattedColumnValue(node){
		return this.props.getFormattedColumnValue ? this.props.getFormattedColumnValue(node) : undefined;
	}
	editInstructionsClicked(){
		if(this.props.editInstructionsClicked){
			this.props.editInstructionsClicked(this.props.poNumber);
		}
	}
	onGridReady(params){
		params.api.sizeColumnsToFit();
	}
	isQtyCellInvalid(params){
		if(this.props.isQtyCellInvalid){
			return this.props.isQtyCellInvalid(params);
		}
		return false;
	}
	qtyCellTooltips(params){
		if(this.props.qtyCellTooltips){
			return this.props.qtyCellTooltips(params);
		}
	}
	onQtyChange({gridApi, node, value}){
		if(this.props.onQtyChange){
			this.props.onQtyChange(gridApi, node, value);
		}
	}
	formatCurrency(params){
		if(this.props.formatCurrency){
			return this.props.formatCurrency(params.value);
		}
		return params.value;
	}
	onTooltipVisibleChange(visible){
		this.setState({showTooltip: visible});
	}
	deleteSuggestedPO(){
		if(this.props.deleteSuggestedPO){
			this.props.deleteSuggestedPO(this.props.poNumber, this.props.whsNum);
		}
	}
	undoDeleteSuggestedPO() {
		if (this.props.undoDeleteSuggestedPO)
			this.props.undoDeleteSuggestedPO(this.props.poNumber, this.props.whsNum);
	}
	onShipDateChange(){
		if(this.props.onShipDateChange){
			this.props.onShipDateChange();
		}
	}
	render() {
		const poOverlayStyle = { height: '100%', zIndex: '-700', opacity: '1', pointerEvents: 'auto' };
		if(this.props.deleted === true){
			poOverlayStyle.zIndex = '700';
			poOverlayStyle.opacity = '0.1';
			poOverlayStyle.pointerEvents = 'none'; 
		}
		if(this.props.isQueuedPO){
			poOverlayStyle.zIndex = '0';
			poOverlayStyle.opacity = '1';
			poOverlayStyle.pointerEvents = 'auto';
		}
		const cubesStyle = this.props.cubesOverLimit === true ? {color:'#dc3545'} : {};
		const buttonTableStyle = {position:'relative', tableLayout:'fixed'};
		buttonTableStyle.width = this.props.hideDeletePOButton ? '25px':'50px';
		buttonTableStyle.right = this.props.hideDeletePOButton ? '-60px':'-35px';
		const instructions = this.props.instructions === undefined 
			? [] 
			: [(this.props.instructions.length && this.props.instructions.length > 100 
				? (this.props.instructions.substring(0, 100) + '...') 
				: this.props.instructions)];
		const overAllocatedDivID = 'overAllocatedWarning_' + this.props.poNumber;
		return (
			<div style={{height:'400px'}}>
				<div style={poOverlayStyle}>
					<Container ref={this.panelRef} fluid={true} className={'h-100 w-100 pl-0 pr-0 mb-1'}>
						<Card className={'h-100 w-100'}>
							<Card.Header className={'w-100'}>
								<Row className={'mx-1'}>
									<div className={'d-flex h-100 w-100'}>
										<div className={'m-0 p-0 w-100 h-100'}>
											<Row noGutters={true} className={'m-0 p-0'}>
												<Col className={'m-0 p-0'} xs={5} sm={5} md={5} lg={5} xl={5}>
													<h5>{this.props.isQueuedPO ? this.props.title : ('PO #' + parseInt(this.props.poNumber))}</h5>
												</Col>
												<Col className={'m-0 p-0'} xs={7} sm={7} md={7} lg={7} xl={7}>
													<Tooltip
														id={this.props.screenId + '_' + this.props.panelId + '_tooltip'}
														visible={this.props.cubesOverLimit === true && this.state.showTooltip === true}
														trigger={['hover','click','focus']}
														onVisibleChange={this.onTooltipVisibleChange.bind(this)}
														mouseLeaveDelay={3}
														placement={'right'}
														overlay={<div style={{maxWidth:'200px', paddingLeft:'10px', paddingRight:'5px', paddingBottom: '3px'}}>Cubes are over limit</div>}
														destroyTooltipOnHide={true}>
														<Col>
															<Row id={'cubesLabel'} data-testid={'cubesLabel'} className={'text text-muted small'}>Cubes:&nbsp;<span style={cubesStyle}>{(this.props.useLoadFactor === true ? 0 : (this.props.cubes || 0)).toFixed(2)}</span></Row>
															<Row id={'loadFactorLabel'} data-testid={'loadFactorLabel'} className={'text text-muted small'}>Load Factor:&nbsp;<span style={cubesStyle}>{this.props.useLoadFactor === true ? (this.props.cubes || 0).toFixed(2) : 0}</span></Row>
														</Col>
													</Tooltip>
												</Col>
											</Row>
											<Row noGutters={true} className={'m-0 p-0'}>
												<Col className={'m-0 p-0'} xs={5} sm={5} md={5} lg={5} xl={5}>
													{ 
														<span>{this.props.group}</span>
													}
												</Col>
												<Col className={'m-0 p-0'} xs={7} sm={7} md={7} lg={7} xl={7}>
													<span className={'text text-muted small'}>Cost:&nbsp;<span id={this.props.screenId+'_poCost_'+this.props.poNumber}>{this.formatCurrency({value:this.props.poCost})}</span></span>
												</Col>
											</Row>
										</div>
										<div className={'ml-auto'} style={{position:'relative', right: (this.props.isQueuedPO ? '55px': '25px')}}>
											<table cellPadding="0" cellSpacing="0" border="0" style={buttonTableStyle}>
												<tbody>
													<tr>
														<td style={{width:'25px'}}>
															<EditPurchaseOrderIconButton 
																screenId={this.props.screenId}
																panelId={this.props.panelId}
																fieldId={'suggestedPOPanelEditButton'}
																onClick={this.editInstructionsClicked.bind(this)}
																tooltips={instructions}
																height={DEFAULT_ICON_HEIGHT}
																width={DEFAULT_ICON_WIDTH}
																tooltipPlacement={'top'}
															/>
														</td>
														{!this.props.hideDeletePOButton && 
														<td style={{width:'25px'}}>
															<CloseButton
																screenId={this.props.screenId}
																panelId={this.props.panelId}
																fieldId={'suggestedPOPanelCloseButton'} 
																height={DEFAULT_ICON_HEIGHT}
																width={DEFAULT_ICON_WIDTH}
																onClick={this.deleteSuggestedPO.bind(this)}
															/>
														</td>
														}
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</Row>
							</Card.Header>
							{this.props.showOverAllocationWarning && <div data-testid={overAllocatedDivID} id={overAllocatedDivID} style={{ 'backgroundColor': 'red', 'color': 'white', 'padding': '5px 5px 5px 5px', 'fontWeight': 'bold' }}>WARNING: Ship Date Over Allocated</div>}
							<DataGridContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'po_' + this.props.poNumber}
								height={'250px'}
								forwardRef={this.gridRef}
								columnDefs={
									[
										{ headerName: 'SKU', field: 'SKU', colId: 'SKU', sortable: true, minWidth:75 },
										{ 
											headerName: 'QTY', 
											field: 'QTY', 
											colId: 'QTY', 
											sortable: true, 
											width: 90,
											minWidth: 90, 
											editable: true,
											singleClickEdit: true,
											headerClass: 'ag-center-header',
											cellRenderer: NumberTextBoxCellRendererContainer, 
											cellRendererParams:
											{
												dataType:  sharedUtilities.dataTypes.integer,
												gridName:  'PurchaseOrderGrid',
												screenId:  this.props.screenId,
												panelId:  this.props.panelId,
												getIsHidden:  () => false,
												getIsInvalid: this.isQtyCellInvalid.bind(this),
												getTooltips: this.qtyCellTooltips.bind(this)
											},
											cellEditor: NumberTextBoxCellEditorContainer, 
											cellEditorParams: 
											{ 
												onChange: this.onQtyChange.bind(this),
												dataType: sharedUtilities.dataTypes.integer,
												gridName: 'PurchaseOrderGrid',
												getIsHidden:  () => false,
												screenId:  this.props.screenId,
												panelId:  this.props.panelId,
												fieldId:  'QTY',
												getIsInvalid: this.isQtyCellInvalid.bind(this),
												getTooltips: this.qtyCellTooltips.bind(this)
											},
											valueFormatter: this.getFormattedColumnValue.bind(this),
											type:'numericColumn',
										},
										{ 
											headerName: 'NEEDED BY', 
											field: 'NEEDBYDATE', 
											colId: 'NEEDBYDATE', 
											sortable: true, 
											width: 90,
											minWidth:90, 
											valueGetter: this.getColumnValue.bind(this),
											valueFormatter: this.getFormattedColumnValue.bind(this)
										},
										{ headerName: 'EACH', field: 'FIRSTCOST', colId: 'FIRSTCOST', valueFormatter: this.formatCurrency, minWidth:60, type:'numericColumn'},
										{ headerName: '', field: 'ORDERLINE', colId: 'ORDERLINE', hide: true, valueFormatter: (obj) => obj },
										{ headerName: '', field: 'ROWINDEX', colId: 'ROWINDEX', hide: true }
									]
								}
								rowData={this.props.rowData}
								onGridReady={this.onGridReady.bind(this)}
								store={store}
							/>
							<Container className={'w-100 mt-2'} fluid={true} style={{height:'250px'}}>
								<Row className={'mb-2'}>
									<Col>
										<DropdownContainer
											screenId={this.props.screenId} 
											panelId={this.props.panelId}
											fieldId={'po' + this.props.poNumber + '_deliverTo'}
											options={this.props.deliverToOptions}
											hasBlankOption={false}
											label={'Deliver To'}
											overridePath={[...this.props.path, 'deliverTo']}
											mainComponentBootstrapSize={7}
											store={store}
										/>	
									</Col>
								</Row>
								<Row className={'mb-2'}>
									<Col>
										<DatePickerContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'po' + this.props.poNumber + '_shipDate'}
											overridePath={[...this.props.path, 'shipDate']}
											position={this.props.datePickerPosition}
											label={'Ship Date'}
											mainComponentBootstrapSize={7}
											scrollContainerRef={this.props.scrollContainerRef}
											onChange={this.onShipDateChange.bind(this)}
											store={store}
										/>
									</Col>
								</Row>
								<Row className={'mb-2'}>
									<Col>
										<DatePickerContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'po' + this.props.poNumber + '_arrivalDate'}
											overridePath={[...this.props.path, 'arrivalDate']}
											position={this.props.datePickerPosition}
											label={'Arrival Date'}
											mainComponentBootstrapSize={7}
											scrollContainerRef={this.props.scrollContainerRef}
											store={store}
										/>
									</Col>
								</Row>
							</Container>
							<HiddenFieldContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'fob'} 
								overridePath={[...this.props.path, 'fob']}
							/>
						</Card>
					</Container>
				</div>
				{this.props.deleted === true && (
					<div className={'h-100 w-100 d-flex justify-content-center align-items-center'} style={{position:'absolute', top: '0px', left: '0px'}}>
						<UndoButton 
							screenId={this.props.screenId}
							panelId={'suggestedPOPanel_' + this.props.poNumber}
							fieldId={'undoButton_' + this.props.poNumber}
							onClick={this.undoDeleteSuggestedPO.bind(this)}
							height={50}
							width={50}
							tooltips={['Undo Delete']}
						/>
					</div>
				)}
			</div>
		);
	}								
}
SuggestedPOGridPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	cubes: PropTypes.number,
	cubesOverLimit: PropTypes.bool,
	datePickerPosition: PropTypes.string, //either 'top' or 'bottom'
	deleted: PropTypes.bool,
	deleteSuggestedPO: PropTypes.func,
	deliverToOptions: PropTypes.array,
	editInstructionsClicked: PropTypes.func,
	formatCurrency: PropTypes.func,
	getColumnValue: PropTypes.func,
	getFormattedColumnValue: PropTypes.func,
	gridRef: PropTypes.any,
	group: PropTypes.string,
	hideDeletePOButton: PropTypes.any,
	instructions: PropTypes.string,
	isQtyCellInvalid: PropTypes.func,
	isQueuedPO: PropTypes.bool,
	onQtyChange:  PropTypes.func,
	onShipDateChange: PropTypes.func,
	path: PropTypes.array,
	poCost: PropTypes.number,
	poNumber: PropTypes.number.isRequired,
	qtyCellTooltips: PropTypes.func,
	rowData: PropTypes.array,
	scrollContainerRef: PropTypes.any,
	title: PropTypes.string,
	undoDeleteSuggestedPO: PropTypes.func,
	whsNum: PropTypes.string,
	useLoadFactor: PropTypes.bool
};
export default SuggestedPOGridPanel;