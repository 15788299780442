import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReportsScreen from './ReportsScreen';

class ReportsScreenContainer extends Component {
	render() {
		return (
			<ReportsScreen
				screenId={this.props.screenId}
				panelId={this.props.panelId}
			/>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = () => {
	return {};
};

ReportsScreenContainer.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsScreenContainer);