import { all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { CALL_RPC_ACTION, CLOSE_DIALOG_ACTION, GET_RPC_DROPDOWN_OPTIONS_ACTION, SHOW_DIALOG_ACTION, RPC_ACTION } from '../actionTypes';
import callRpcSaga from './callRpcSaga';
import closeDialogSaga from './closeDialogSaga';
import getRpcDropdownOptionsSaga from './getRpcDropdownOptionsSaga';
import showDialogSaga from './showDialogSaga';
import rpcSaga from './rpcSaga';

export default function* rootSaga() {
	yield all([
		fork(callRpcSagaWatcher),
		fork(rpcSagaWatcher),
		fork(getDropdownOptionsRpcSagaWatcher),
		fork(showDialogSagaWatcher),
		fork(closeDialogSagaWatcher)
	]);
}
/* Put your watcher function here, and added it above like this: fork(mySuperAwesomeSagaWatcher). Think of this as "registering" your saga. */
export function* callRpcSagaWatcher() { yield takeEvery(CALL_RPC_ACTION, callRpcSaga); }
export function* rpcSagaWatcher() { yield takeEvery(RPC_ACTION, rpcSaga); }
export function* getDropdownOptionsRpcSagaWatcher() { yield takeLatest(GET_RPC_DROPDOWN_OPTIONS_ACTION, getRpcDropdownOptionsSaga); }
export function* showDialogSagaWatcher() { yield takeLatest(SHOW_DIALOG_ACTION, showDialogSaga); }
export function* closeDialogSagaWatcher() { yield takeEvery(CLOSE_DIALOG_ACTION, closeDialogSaga); }
