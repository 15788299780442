export default class VendorWarehouseOrderLine {
	constructor(){	
		this.PARTSKU = '';
		this.PARTMOD = '';
		this.PARTDESCRIPTION = '';
		this.AVERAGEDAILYSHIPMENTS = 0;
		this.ALIAS = '';
		this.AVERAGEWEEKLYSHIPMENTS = 0;
		this.TOTALSHIPMENTS = 0;
		this.PENDINGSHIPMENTS = 0;
		this.QUANTITYONHAND = 0;
		this.INBOUNDQUANTITY = 0;
		this.AVAILABLEQUANTITY = 0;
		this.WEEKSOFSTOCKINBOUND = 0;
		this.VENDOR = '';
	}
}