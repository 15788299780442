import * as storeUtilities from '../../../utilities/storeUtilities';
import callRpcAction from '../../../actions/callRpcAction';
import { nodeRoutes } from '../../../constants';
export const reloadQueue = (dispatch, screenId) => {
	dispatch(callRpcAction({}, nodeRoutes.IFRService, 'GetPurchaseOrderQueue', (queue) => {
		if(queue && Array.isArray(queue))
		{
			storeUtilities.updateValue(dispatch, [screenId, 'purchaseOrderQueueData'], queue);
		}})
	);
};