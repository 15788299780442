import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import deepEqual from 'deep-equal';
import SuggestedPOGridPanelContainer from './SuggestedPOGridPanelContainer';
import * as orderUtilities from './OrderProductScreenUtilities';
class SuggestedPOsTabs extends Component {
	constructor(props){
		super(props);
		this.createRow = this.createRow.bind(this);
		this.getRowCount = this.getRowCount.bind(this);
		this.rowRenderer = this.rowRenderer.bind(this);
		this.onSuggestedPosTotalCostChange = this.onSuggestedPosTotalCostChange.bind(this);
		this.getTabContent = this.getTabContent.bind(this);
		this.onTabChanged = this.onTabChanged.bind(this);
		this.getPOCountForSingleWhs = this.getPOCountForSingleWhs.bind(this);
		this.checkAllQtyCells = this.checkAllQtyCells.bind(this);
		this.handleRef=this.handleRef.bind(this);

		this.tabContentRef = React.createRef();
		this.gridRefs = {};
	}
	shouldComponentUpdate(nextProps){
		return orderUtilities.functionRunner(() => {
			return !deepEqual(this.props.poCountsPerWhs, nextProps.poCountsPerWhs)
			|| this.props.viewPort !== nextProps.viewPort;
		});
	}
	getPOCountForSingleWhs(whsNum){
		let count = 0;
		for(const [whs, cnt] of Object.entries(this.props.poCountsPerWhs)){
			if(whsNum === whs){
				count = cnt;
			}
		}
		return count;
	}
	checkAllQtyCells(){
		if(this.props.checkAllQtyCells){
			this.props.checkAllQtyCells(Object.values(this.gridRefs));
		}
	}
	handleRef(gridRef, poNum){
		this.gridRefs[poNum] = gridRef;
	}
	createRow(numberOfPOsInRow, rowIndex=0, whsNum) {
		const posToRender = [];
		/** determine the starting po number we need for the row*/
		const firstPONumberInRow = (numberOfPOsInRow * rowIndex) + 1;
		for (let i = 0; i < numberOfPOsInRow; i++) {
			const poNum = (firstPONumberInRow + i);
			const paddingAndMargin = 'p-0 mr-0 ml-' + (i === 0 ? '0' : '1') + ' mt-0 mb-0';
			if(poNum <= this.getPOCountForSingleWhs(whsNum)){
				posToRender.push(
					<Col key={i}
						className={paddingAndMargin + ' SuggestedPO-border'}>
						<SuggestedPOGridPanelContainer
							screenId={this.props.screenId}
							panelId={'suggestedPOGridPanel'}
							whsNum={whsNum}
							poNumber={poNum}
							key={poNum}
							path={[this.props.screenId, 'SuggestedPOs', 'purchaseOrders', whsNum, poNum]}
							onSuggestedPosTotalCostChange={this.onSuggestedPosTotalCostChange}
							scrollContainerRef={this.tabContentRef !== undefined ? this.tabContentRef.current : undefined}
							gridRef={(r) => this.handleRef(r, poNum)}
							divisions={this.props.divisions}
							checkAllQtyCells={this.checkAllQtyCells}
						/>
					</Col>);
			}
			else {
				posToRender.push(
					<Col className={paddingAndMargin + ' SuggestedPOFiller-border'} key={'rowKey_' + rowIndex + '_positionIndexInRow_' + i}>
						<div>
							&nbsp;
						</div>
					</Col>);
			}
		}
		return (
			<div className={'row'} style={{width: '100%', marginLeft: '1px', marginBottom: '2px' }} key={rowIndex}>
				{posToRender}
			</div>
		);
	}
	getRowCount(whsNum){
		if(whsNum === undefined){
			return 0;
		}
		let rowCount = this.getPOCountForSingleWhs(whsNum);

		if(rowCount === 0){
			return 0;
		}
		switch(this.props.viewPort){
			case 'xs':
				rowCount /= 2;
				break;
			case 'sm':
			case 'md':
			case 'lg':
				rowCount /= 3;
				break;
			case 'xl': 
				rowCount /= 4;
				break;
			default: 
				rowCount /=4;
		}
		return rowCount;
	}
	rowRenderer(rowIndex, whsNum, tabIndex) {
		switch (this.props.viewPort) {
			case 'xs':
				return this.createRow(2, rowIndex, whsNum, tabIndex);
			case 'sm':
			case 'md':
			case 'lg':
				return this.createRow(3, rowIndex, whsNum, tabIndex);
			case 'xl':
				return this.createRow(4, rowIndex, whsNum, tabIndex);
			default:
				return this.createRow(1);
		}
	}
	onSuggestedPosTotalCostChange(costDiff){
		if(this.props.onSuggestedPosTotalCostChange){  
			this.props.onSuggestedPosTotalCostChange(costDiff);  
		}
	}
	getTabContent(whsNum, tabIndex){
		const panelStyle = {
			height: this.props.height,
			overflowY:'auto', 
			overflowX:'hidden',
			position:'relative',
			top:'0px'};
		var rowsToRender = [];
		for(var rowIndex = 0; rowIndex < this.getRowCount(whsNum); rowIndex++){
			rowsToRender.push(this.rowRenderer(rowIndex, whsNum, tabIndex));
		}
		return (<div
			style={panelStyle}>{rowsToRender}
		</div>);
	}
	onTabChanged(){
		if(Array.isArray(this.gridRefs)){
			this.gridRefs.forEach(gridRef => {
				if(gridRef && gridRef.current && gridRef.current.api && gridRef.current.api.sizeColumnsToFit){
					gridRef.current.api.sizeColumnsToFit();
				}
			});
		}
	}
	render(){
		let tabIndex = 0;
		let returnValue = this.getTabContent(this.props.whsNum, tabIndex);
		return <div ref={this.tabContentRef}>{returnValue}</div>;
	}
}
SuggestedPOsTabs.propTypes = {
	screenId: PropTypes.string,
	poCountPerWhs: PropTypes.number,
	whsList: PropTypes.array,
	rowsToRender: PropTypes.array,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	checkAllQtyCells: PropTypes.func
};
export default SuggestedPOsTabs;