export default class PartOrderLine {
	constructor(){
		this.ADDITIONALDAYSTOSTOCK = 0;
		this.ADDITIONALDAYSTOSTOCKQUANTITY = 0;
		this.AVAILABLEQUANTITY = 0;
		this.AVERAGEDAILYSHIPMENTS = 0;
		this.AVERAGEWEEKLYSHIPMENTS = 0;
		this.CALCULATEDORDERQUANTITY = 0;
		this.ORDER = 0;
		this.DAYSTONEXTORDER = 0;
		this.DiscontinuedSKU = 'N';
		this.DAYSTONEXTORDERQUANTITY = 0;
		this.ENDOFLEADTIMEAVAILABLE = 0;
		this.InactiveSKU = 'N'
		this.INBOUNDQUANTITY = 0;
		this.PARTDESCRIPTION = '';
		this.PARTSKU = '';
		this.PENDINGSHIPMENTS = 0;
		this.PRODUCTIONDAYS = 0;
		this.QUANTITYONHAND = 0;
		this.REPLINISHMENTSTOCK = 0;
		this.THRESHOLDQUANTITY = 0;
		this.ToBeDropped = 'N';
		this.TOTALSHIPMENTS = 0;
		this.TRANSITDAYS = 0;
		this.WEEKSOFSTOCKINBOUND = 0;
		this.WEEKSOFSTOCKONHAND = 0;
	}
}