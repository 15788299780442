import PropTypes from 'prop-types';
import React from 'react';
import withID from '../../../higherordercomponents/WithID';
import '../../../css/CustomFont1.css';
const NotesIcon = ({fill, pencilColor, height, width, id}) => {
	return (
		<svg 
			id={id}
			height={height ? height : '25px'}
			width={width ? width : '25px'}
			viewBox={'0 0 64 64'} 
			aria-labelledby={'title'} 
			aria-describedby={'desc'} 
			role={'img'} 
			className={'edit-hover'}>
			<path 
				data-name={'layer2'} 
				fill={'#FFFFFF'} 
				stroke={'#A9A9A9'} 
				strokeMiterlimit={'10'} 
				strokeWidth={'2'} 
				d={'M32.3 56H2V2h24l16 16v25.1'}
				strokeLinejoin={'round'} 
				strokeLinecap={'round'}
			/>
			<path 
				data-name={'layer2'} 
				fill={'none'} 
				stroke={'#A9A9A9'} 
				strokeMiterlimit={'10'} 
				strokeWidth={'2'} 
				d={'M26 2v16h16'} 
				strokeLinejoin={'round'} 
				strokeLinecap={'round'}
			/>
			<path 
				data-name={'layer1'} 
				fill={pencilColor ? pencilColor : '#A9A9A9'} 
				stroke={'#A9A9A9'} 
				strokeMiterlimit={'10'} 
				strokeWidth={'2'} 
				d={'M55.1 30L34.4 50.7 30 62l11.4-4.3L62.2 37l-7.1-7zM34.4 50.7l7 7'}
				strokeLinejoin={'round'} 
				strokeLinecap={'round'}
			/>
			<text 
				fill={fill ? fill : '#202020'} 
				transform={'matrix(0.509939, 0.003095, 0, 0.201182, 0.212956, 18.817852)'} 
				style={{
					whiteSpace: 'pre', 
					fontFamily: 'CustomFont1',
					fontSize: '96px',
					fontWeight: 'bold'
				}}
				y={'55'} 
				x={'12'}>
				<tspan x={'12'} dy={'0em'}>​</tspan>~
				<tspan x={'12'} dy={'0.5em'}>​</tspan>~
				<tspan x={'12'} dy={'0.5em'}>​</tspan>~
			</text>
		</svg>
	);
};
NotesIcon.propTypes = {
	fill: PropTypes.string,
	pencilColor: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string
};
export default withID(NotesIcon);