import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import AddClearanceSkusDialog from './AddClearanceSkusDialog';
import { nodeRoutes } from '../../../../constants';
import rpcAction from '../../../../actions/rpcAction';

export const PANEL_ID = 'addClearanceSkusDialog';
export const NEW_SKUS_PANEL_ID = 'newSkusTab';
export const INACTIVE_SKUS_PANEL_ID = 'inactiveSkusTab';

class AddClearanceSkusDialogContainer extends Component {
	render() {
		return <AddClearanceSkusDialog
			screenId={this.props.screenId}
			panelId={PANEL_ID}
			addSkusClicked={this.props.addSkusClicked}
			addNewSkusTabPath={[this.props.screenId, PANEL_ID, NEW_SKUS_PANEL_ID]}
			addInactiveSkusTabPath={[this.props.screenId, PANEL_ID, INACTIVE_SKUS_PANEL_ID]}
			handleCancelClicked={this.props.handleCancelClicked}
			onLoad={this.props.onLoad}
			onTabChanged={this.props.onTabChanged}
		/>;
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			storeUtilities.updateValue(dispatch, [props.screenId, PANEL_ID, 'displayedTab'], 'addNewClearanceSkus', true, false);
		},
		addSkusClicked: () => {
			const selectedNewSkus = storeUtilities.getValue([props.screenId, PANEL_ID, NEW_SKUS_PANEL_ID, 'selectedRows'], undefined);
			const skus = [];
			if (Array.isArray(selectedNewSkus) && selectedNewSkus.length > 0) {
				selectedNewSkus.forEach((selectedRow) => {
					skus.push(selectedRow.sku);
				});
			}
			const whsNum = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
			const user = storeUtilities.getUser();
			const args = {
				whs: whsNum,
				skuList: skus,
				user: user.rtgID
			};
			const afterSkusAdded = () => {
				dispatch(rpcAction({ 
					args: {store: whsNum }, 
					nodeRoute: nodeRoutes.IFRServiceNET, 
					endpoint: '/Clearance/Skus', 
					callback: (data) => {
						if (Array.isArray(data)) {
							storeUtilities.updateValue(dispatch, [props.screenId, 'clearanceSkuData'], data);
							storeUtilities.deleteValue(dispatch, [props.screenId, PANEL_ID]);
						}
					}
				}));
			};
			const enableInactiveSkus = () => {
				const selectedInactiveSkus = storeUtilities.getValue([props.screenId, PANEL_ID, INACTIVE_SKUS_PANEL_ID, 'selectedRows'], undefined);
				if (Array.isArray(selectedInactiveSkus) && selectedInactiveSkus.length > 0) {
					const lastSku = selectedInactiveSkus[selectedInactiveSkus.length - 1].sku;
					for (let i = 0; i < selectedInactiveSkus.length; i++) {
						const enableArgs = {warehouse: whsNum, sku: selectedInactiveSkus[i].sku, status: true};
						dispatch(rpcAction ({
							args: enableArgs,
							nodeRoute: nodeRoutes.IFRServiceNET,
							endpoint: '/Clearance/Sku/Enable',
							method: 'PUT',
							hideLoadingMaskOnComplete: true,
							showLoadingMask: true,
							callback: (data) => {
								if(Array.isArray(data.skus) && data.skus.length === 1 && lastSku === data.skus[0]){
									afterSkusAdded();
								}

							}
						}));
					}
				}
				else {
					afterSkusAdded();
				}
			};
			if(skus.length > 0){
				dispatch(rpcAction({
					args: args,
					nodeRoute: nodeRoutes.IFRServiceNET,
					endpoint: 'Clearance/Skus/Add',
					callback: () => {
						enableInactiveSkus();
					},
					showLoadingMask: true,
					hideLoadingMaskOnComplete: false,
					method: 'POST'
				}));
			}
			else{
				enableInactiveSkus();
			}
		},
		handleCancelClicked: () => {
			storeUtilities.deleteValue(dispatch, [props.screenId, PANEL_ID]);
		},
		onTabChanged: (key) => {
			storeUtilities.updateValue(dispatch, [props.screenId, PANEL_ID, 'displayedTab'], key);
		}
	};
};

AddClearanceSkusDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	onLoad: PropTypes.func
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AddClearanceSkusDialogContainer);

export default connectedComponent;
