import React from 'react';
import PropTypes from 'prop-types';
import XIcon from './XIcon';
const XGridCellIcon = (props) => {
	return <XIcon 
        screenId={props.screenId}
        height={props.height} 
        width={props.width} 
        id={props.id} 
        node={props.node}
        visible={props.getIsHidden && props.getIsHidden(props) === false}
        color={props.color}
    />;
};
XGridCellIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	onClick: PropTypes.func,
    id: PropTypes.string,
    node: PropTypes.object,
    color: PropTypes.string,
    getIsHidden: PropTypes.func
};
export default XGridCellIcon;