import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchPartsScreen from './SearchPartsScreen';
import * as storeUtilities from '../../../utilities/storeUtilities';
import { history } from '../../../configureStore';
export const SCREEN_ID = 'searchpartsscreen';
class SearchPartsScreenContainer extends Component {
	render() {
		return (
			<SearchPartsScreen {...this.props}
				screenId={this.props.screenId || SCREEN_ID}
				history={history}
			/>
		);
	}
}

const mapStateToProps = () => {
	return {
		user: storeUtilities.getUser() 
	};
};

SearchPartsScreenContainer.propTypes = {
	screenId: PropTypes.string,
	user: PropTypes.object.isRequired,
	history: PropTypes.any
};

const connectedComponent = connect(mapStateToProps, null)(SearchPartsScreenContainer);

export default connectedComponent;