import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ButtonContainer } from '@rtg/shared-react-components';
import store from '../../configureStore';

const DEFAULT_OK_BUTTON_TEXT = 'OK';
const DEFAULT_CANCEL_BUTTON_TEXT = 'Cancel';
const PANEL_ID = 'okCancelDialog';

class OkCancelDialog extends Component {
	constructor(props){
		super(props);
		this.okRef = React.createRef();
	}
	componentDidMount(){
		if(this.okRef.current !== undefined){
			this.okRef.current.focus();
		}
	}
	handleCancelClicked(){
		if(this.props.handleCancelClicked){
			this.props.handleCancelClicked();
		}
	}
	handleOkClicked(){
		if(this.props.handleOkClicked)
			this.props.handleOkClicked();
	}
	render() {
		return (
			<div id={this.props.id} className={'h-100 w-100'}>
				<Card className={'h-100 w-100'} style={{width: this.props.width, height: this.props.height}}>
					<Card.Header className={'dlgdraggable'}>
						<h4>{this.props.title}</h4>
					</Card.Header>
					{this.props.message ? (<div className={'mx-3 my-2'}><h5>{this.props.message}</h5></div>) : null}
					<div className={'text-muted'} style={{height:'100%', overflow:'auto', paddingLeft: this.props.paddingLeft !== undefined ? (this.props.paddingLeft + 'px') : '12px', paddingRight: this.props.paddingRight !== undefined ? (this.props.paddingRight + 'px') : '12px'}}>{this.props.children}</div>
					<Card.Footer>
						<Row noGutters={true}>
							<Col>
								<Row>
									<Col 
										xs={this.props.hasCancelButton ? 6 : 3} 
										sm={this.props.hasCancelButton ? 6 : 3} 
										md={this.props.hasCancelButton ? 6 : 3} 
										lg={this.props.hasCancelButton ? 6 : 3} 
										xl={this.props.hasCancelButton ? 6 : 3}>
										{this.props.hasCancelButton 
											? (
												<ButtonContainer
													screenId={this.props.screenId}
													panelId={PANEL_ID}
													fieldId={'btnCancel'} 
													text={this.props.cancelButtonText ? this.props.cancelButtonText : DEFAULT_CANCEL_BUTTON_TEXT} 
													variant={'light'} 
													onClick={this.handleCancelClicked.bind(this)}
													disabled={this.props.cancelButtonDisabled}
													fill
													store={store}
												/>) 
											: null
										}
									</Col>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<ButtonContainer
											screenId={this.props.screenId}
											panelId={PANEL_ID}
											fieldId={'btnOK'} 
											text={this.props.okButtonText ? this.props.okButtonText : DEFAULT_OK_BUTTON_TEXT} 
											buttonClasses={'mb-2'}
											onClick={this.handleOkClicked.bind(this)}
											forwardRef={this.okRef}
											disabled={this.props.okButtonDisabled}
											fill
											store={store} 
										/>
									</Col>
									<Col
										xs={this.props.hasCancelButton ? 0 : 3} 
										sm={this.props.hasCancelButton ? 0 : 3} 
										md={this.props.hasCancelButton ? 0 : 3} 
										lg={this.props.hasCancelButton ? 0 : 3} 
										xl={this.props.hasCancelButton ? 0 : 3}/>
								</Row>
							</Col>
						</Row>
					</Card.Footer>
				</Card>
			</div>
		);
	}
}

OkCancelDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	id: PropTypes.string,
	title: PropTypes.string,
	message: PropTypes.string,
	children: PropTypes.any,
	hasCancelButton: PropTypes.bool,
	okButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	minHeight: PropTypes.number,
	minWidth: PropTypes.number,
	paddingLeft: PropTypes.number,
	paddingRight: PropTypes.number,
	handleOkClicked: PropTypes.func,
	handleCancelClicked: PropTypes.func,
	okButtonDisabled: PropTypes.bool,
	cancelButtonDisabled: PropTypes.bool
};

export default OkCancelDialog;