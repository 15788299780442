import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ExpandCollapseChevronIcon from '../formelements/ImageComponents/ExpandCollapseChevronIcon';
class IFRNavBar extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	toggle(){
		const newValue =!this.props.headerIsCollapsed;
		if(this.props.onToggleHeader){
			this.props.onToggleHeader(newValue);
		}
	}
	render() {
		const search = this.props.history && this.props.history.location && this.props.history.location.search ? this.props.history.location.search : '';
		const loginWidget = this.props.userDisplayName === undefined || (this.props.userDisplayName && this.props.userDisplayName.trim && this.props.userDisplayName.trim() === '')
			? <a className='nav-link' href='https://www.google.com'>Login</a>
			: <Form.Label style={{position:'relative', top: '3px', color: '#FFFFFF'}}>{this.props.userDisplayName}</Form.Label>;
		
		const mainMenuButtons = [];
		const managementMenuButtons = [];
		
		if(this.props.security.admin || this.props.security.buyer || this.props.security.readonly){
			mainMenuButtons.push(<NavDropdown.Item key={'products'} href={ '/products' + search}>Products</NavDropdown.Item>);
		} 

		if(this.props.security.admin || this.props.security.buyer || this.props.security.parts){
			mainMenuButtons.push(<NavDropdown.Item key={'parts'} href={ '/parts' + search}>Parts</NavDropdown.Item>);
		}
	
		if(this.props.security.admin || this.props.security.clearance){
			mainMenuButtons.push(<NavDropdown.Item key={'clearance'} href={ '/clearance' + search}>Clearance</NavDropdown.Item>);
		}

		if(this.props.security.admin || this.props.security.parts){ 
			mainMenuButtons.push(<NavDropdown.Item key={'warehouse'} href={ '/warehouse' + search}>Warehouse</NavDropdown.Item>);
		}

		if((this.props.security.admin || this.props.security.buyer) && !this.props.security.readonly){
			managementMenuButtons.push(<NavDropdown.Item key={'purchaseorderqueue'} href={ '/purchaseorderqueue' + search}>Purchase Order Queue</NavDropdown.Item>);
		}
		
		if((this.props.security.admin || this.props.security.buyer || this.props.security.parts) && !this.props.security.readonly){
			managementMenuButtons.push(<NavDropdown.Item key={'dailyorderlog'} href={ '/dailyorderlog' + search}>Daily Order Log</NavDropdown.Item>);
		}

		if(this.props.security.admin && !this.props.security.readlonly){
			managementMenuButtons.push(<NavDropdown.Item key={'maintenance'} href={ '/maintenance' + search}>Maintenance Manager</NavDropdown.Item>);
		}
		
		if(this.props.security.admin && !this.props.security.readonly){
			managementMenuButtons.push(<NavDropdown.Item key={'allocationmanager'} href={ this.props.allocationManagerUrl } target={'_blank'}>Manage Allocations</NavDropdown.Item>);
		}
		if(this.props.security.admin && !this.props.security.readonly){
			managementMenuButtons.push(<NavDropdown.Item key={'maxpomanager'} href={ this.props.maxPOManagerUrl } target={'_blank'}>Manage POs Per Week</NavDropdown.Item>);
		}
		if(this.props.security.admin && !this.props.security.readonly && this.props.enableTransitTimeFeature){
			managementMenuButtons.push(<NavDropdown.Item key={'transittimemanager'} href={ this.props.transitTimeManagerUrl } target={'_blank'}>Manage Transit Times</NavDropdown.Item>);
		}
		const mainMenuNavDropdown = mainMenuButtons.length > 0 
			? <NavDropdown title={'Menu'} id={'application'} className={'mt-2'}>
				{mainMenuButtons}
			</NavDropdown>
			: null;
		const managementMenuNavDropdown = managementMenuButtons.length > 0
			?<NavDropdown title={'Management'} id={'management'} className={'mt-2'}>
				{managementMenuButtons}
			</NavDropdown>
			: null;
		const navDropDowns =	
		<>
			{mainMenuNavDropdown}
			{managementMenuNavDropdown}
		</>;
		const toggleButton = this.props.headerIsCollapsed !== undefined && (
			<div style={{position:'absolute', left:'50%', color:'white', cursor:'pointer'}} onClick={this.toggle.bind(this)}>
				<ExpandCollapseChevronIcon 
					screenId={'IFRNavBar'}
					fieldId={'ExpandCollapseButton'}
					isExpanded={this.props.headerIsCollapsed === false} 
					fill={'white'} 
					height={'16px'} 
					width={'16px'} 
				/>
			</div>
		);
		const miniLogo = (
			<div style={{color:'white', position:'relative', top:'5px'}} className={'d-flex'}>
				<a href={'./' + search}>
					<img style={{width:'17px', height:'17px'}}
						src={'./images/miniLogo.png'}
						alt="Rooms To Go"
					/>
				</a>
				<div className={'ml-auto h5'} style={{paddingLeft:'5px'}}>IFR</div>
			</div>);
		const style = this.props.maskIsShowing?{zIndex:20}:{zIndex:780};
		style.height = '20px';
		style.borderTop = this.props.headerIsCollapsed === true ? ('0') : ('1px solid white');
		return (
			<div style={{backgroundColor:'#0053a0'}}>
				{this.props.headerIsCollapsed === false && 
				<Container fluid={true}>
					<Row className={'align-items-center'}>
						<a href={'./' + search} style={{marginLeft:'10px',marginRight:'10px'}}>
							<img
								src={'./images/RoomsToGoLogo.png'}
								className="d-inline-block align-top logo"
								alt="Rooms To Go"
							/>
						</a>
						<span className={'my-1'} style={{color:'white', position: 'relative', top: '-3px'}}>
							<span style={{fontSize:'28px'}}>IFR</span><br />
							<span style={{fontSize:'15px'}}>Inventory Forecasting &amp; Replenishment</span>
						</span>
					</Row>
				</Container>
				}
				<Navbar sticky={'top'} bg={'primary'} variant={'dark'} className={'mb-2'} style={style}>
					<Navbar.Toggle aria-controls={'basic-navbar-nav'} />
					<Navbar.Collapse id={'basic-navbar-nav'}>
						{this.props.headerIsCollapsed === true &&						
						<Nav>
							{miniLogo}
						</Nav>
						}
						<Nav>
							{navDropDowns}
						</Nav>
						<Nav>
							{toggleButton}
						</Nav>
						<Nav className={'nav navbar-nav ml-auto'}>
							{loginWidget}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}
IFRNavBar.propTypes = {
	userDisplayName: PropTypes.string,
	maskIsShowing: PropTypes.bool,
	security: PropTypes.object.isRequired,
	history: PropTypes.any,
	onToggleHeader: PropTypes.func,
	headerIsCollapsed: PropTypes.bool,
	onLoad: PropTypes.func
};
export default IFRNavBar;
