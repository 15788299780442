import { PUSH_DIALOG_ACTION } from '../actionTypes';

const pushDialogAction = (showDialogAction) => {
	return {
		type: PUSH_DIALOG_ACTION,
		dialogId: showDialogAction.dialogId,
		dialogContent: showDialogAction.dialogContent,
		height: showDialogAction.height,
		width: showDialogAction.width,
		handleDialogClosed: showDialogAction.functionHandleDialogClosed,
		enableResizing: showDialogAction.enableResizing,
		position: showDialogAction.position,
		doSlideInEffect: showDialogAction.doSlideInEffect,
		closeOnOverlayClick: showDialogAction.closeOnOverlayClick,
		closeOnEscapeKeyPressed: showDialogAction.closeOnEscapeKeyPressed
	};
};
export default pushDialogAction;