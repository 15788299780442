import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../actions/callRpcAction';
import rpcAction from '../../actions/rpcAction';
import * as persistentValues from '../../utilities/persistentValues';
import * as storeUtilities from '../../utilities/storeUtilities';
import { nodeRoutes } from '../../constants';
import IFRNavBar from './IFRNavBar';
class IFRNavBarContainer extends Component {
	render() {
		return (
			<IFRNavBar {...this.props} />
		);
	}
}
const mapStateToProps = () => {
	const user = storeUtilities.getUser();
	const headerIsCollapsed = storeUtilities.getValue(['headerIsCollapsed'], undefined);
	const allocationManagerUrl = storeUtilities.getValue(['allocationManagerUrl'], '');
	const maxPOManagerUrl = storeUtilities.getValue(['maxPOManagerUrl'], '');
	const transitTimeManagerUrl = storeUtilities.getValue(['transitTimeManagerUrl'], '');
	const featureEnabledTransfers = storeUtilities.getValue(['featureEnabledTransfers'], '');
	const enableTransitTimeFeature = storeUtilities.getValue(['enableTransitTimeFeature'], false);
	return {
		userDisplayName: user.fullName ? user.fullName : '',
		maskIsShowing: storeUtilities.getGlobalAppStateValue(['showLoadingMask']),
		security: {
			admin: user.hasAdminAccess ? user.hasAdminAccess() : false,
			buyer: user.hasBuyerAccess ? user.hasBuyerAccess() : false,
			parts: user.hasPartsAccess ? user.hasPartsAccess() : false,
			clearance: user.hasClearanceAccess ? user.hasClearanceAccess() : false,
			readonly: user.hasReadOnlyAccess ? user.hasReadOnlyAccess() : false
		},
		headerIsCollapsed,
		allocationManagerUrl,
		maxPOManagerUrl,
		transitTimeManagerUrl,
		featureEnabledTransfers,
		enableTransitTimeFeature
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onLoad: () => {
			const isCollapsed = persistentValues.get(['headerIsCollapsed']);
			storeUtilities.initializeValue(dispatch, ['headerIsCollapsed'], isCollapsed === true, true);
			
			dispatch(callRpcAction({}, nodeRoutes.AllocationManagerURL, '', (data) => {
				if(data){
					storeUtilities.updateValue(dispatch, ['allocationManagerUrl'], data.url);
				}
			}, false));

			dispatch(callRpcAction({}, nodeRoutes.MaxPOManagerURL, '', (data) => {
				if(data){
					storeUtilities.updateValue(dispatch, ['maxPOManagerUrl'], data.url);
				}
			}, false));

			dispatch(callRpcAction({}, nodeRoutes.FeatureEnabledTransfers, '', (data) => {
				if(data){
					storeUtilities.updateValue(dispatch, ['featureEnabledTransfers'], data);
				}
			}, false));

			dispatch(rpcAction({ 
				nodeRoute: nodeRoutes.TransitTimeManagerURL,
				showLoadingMask: false,
				callback: (data) => {
					if(data){
						storeUtilities.updateValue(dispatch, ['transitTimeManagerUrl'], data.url);
					}
				}
			}));

			dispatch(rpcAction({ 
				nodeRoute: nodeRoutes.EnableTransitTimeFeature,
				showLoadingMask: false,
				callback: (data) => {
					if(data){
						storeUtilities.updateValue(dispatch, ['enableTransitTimeFeature'], data.data === 'true');
					}
				}
			}));
		},
		onToggleHeader: (newValue) =>{
			storeUtilities.updateValue(dispatch, ['headerIsCollapsed'], newValue, false, true);
		}
	};
};

IFRNavBarContainer.propTypes = {
	userDisplayName: PropTypes.string,
	maskIsShowing: PropTypes.bool,
	security: PropTypes.object,
	history:PropTypes.any,
	onToggleHeader: PropTypes.func,
	onLoad: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(IFRNavBarContainer);

export default connectedComponent;
