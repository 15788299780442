import { ConnectedRouter } from 'connected-react-router';
import deepEqual from 'deep-equal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import PulseLoader from 'react-spinners/PulseLoader';
import WindowSizeListener from 'react-window-size-listener';
import DialogProvider from '../dialogs/DialogProvider';
import IFRNavBarContainer from '../navigation/IFRNavBarContainer';
import Routes from '../navigation/Routes';
import MessageBar from '../widgets/MessageBar';
import LabelContainer from '../formelements/LabelContainer';
import { keyCodes } from '../../constants';
import packageInfo from '../../../package.json';

class Main extends Component {
	constructor(props){
		super(props);
		this.getMessageBar = this.getMessageBar.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.windowSizeListenerRef = React.createRef();
	}
	componentDidMount(){
		document.body.addEventListener('keyup', this.onKeyUp);
		window.addEventListener('scroll', this.onScroll, false);
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	componentWillUnmount(){
		document.body.removeEventListener('keyup', this.onKeyUp);
		window.removeEventListener('scroll', this.onScroll, false);
	}
	onScroll(){
		window.dispatchEvent(new window.Event('resize'));
	}
	onKeyUp(event){
		switch(event.keyCode){
			case keyCodes.ESCAPE:
				if(this.props.handleEscapeKeyPressed){
					this.props.handleEscapeKeyPressed();
				}
				break;
			default:
				return;
		}
	}
	componentDidUpdate(){
		let body = document.getElementsByTagName('body')[0];
		if(body && body.style){
			if(this.props.showLoadingMask && body.style.overflow !== 'hidden'){
				body.style.marginRight = (window.parseInt(body.style.marginRight) || 0) + (window.innerWidth - body.clientWidth) + 'px';
				body.style.overflow = 'hidden';
			}
			else{
				body.style.marginRight = '0px';
				body.style.overflow = 'auto';
			}
		}
	}
	onClick(){
		if(this.props.onClick)
			this.props.onClick();
	}
	getMessageBar(){
		return this.props.messageBarMessage && this.props.messageBarMessage.length > 0 
			? <MessageBar id={'applicationMessageBar'} message={this.props.messageBarMessage}/>
			: null;
	}
	setElementsSize(windowHeight, elements, htmlScrollTop){
		for(let i = 0; i < elements.length; i++){
			if(elements[i]){
				elements[i].style.height = (windowHeight + htmlScrollTop - 3) + 'px';
			}
		}
	}
	resizeElements(windowSize){
		const html = document.getElementsByTagName('html')[0];
		const htmlScrollTop = html?.scrollTop || 0;
		this.setElementsSize(windowSize.windowHeight, document.getElementsByClassName('_loading_overlay_wrapper'), htmlScrollTop);
	}
	render() {
		const screenNotReady = this.props.user === undefined || deepEqual(this.props.user, {}) || !this.props.whsListDataReceived;
		if(screenNotReady === true){
			return (<DialogProvider><div>Loading...</div></DialogProvider>);
		}
		else {
			return (
				<LoadingOverlay
					active={this.props.showLoadingMask}
					spinner={<PulseLoader color={'#FFFFFF'}/>}
					fadeSpeed={300}
					text='Loading'>
					<WindowSizeListener ref={this.windowSizeListenerRef} onResize={windowSize=>{
						this.resizeElements(windowSize);
					}}>
						<ConnectedRouter history={this.props.history}>
							<DialogProvider>
								<Card className={'h-100'}>
									<IFRNavBarContainer history={this.props.history}/>
									{this.getMessageBar()}
									<Card.Body style={{overflow:'auto'}}>
										<Routes />
									</Card.Body>
									<div className={'ml-auto'} style={{position:'relative', top:'-3px', right:'15px', height:'20px'}}>
										<LabelContainer className={'m-0 p-0'}
											screenId={'main'}
											panelId={'mainPanel'}
											fieldId={'versionLabel'}
											value={'Inventory Forecasting & Replenishment v' + packageInfo.version} 
										/>
									</div>
								</Card>
							</DialogProvider>
						</ConnectedRouter>
					</WindowSizeListener>
				</LoadingOverlay>
			);
		}
	}
}
Main.propTypes = {
	dialogContent: PropTypes.any,
	dialogHeight: PropTypes.number,
	dialogWidth: PropTypes.number,
	beginCloseDialogAnimation: PropTypes.bool,
	beginShowDialogAnimation: PropTypes.bool,
	onLoad: PropTypes.func,
	history: PropTypes.any.isRequired,
	handleEscapeKeyPressed: PropTypes.func,
	whsListDataReceived: PropTypes.bool
};
export default Main;
