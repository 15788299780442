import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import MerchandisePanelContainer from './MerchandisePanelContainer';
import SuggestedOrdersPanelContainer from './SuggestedOrdersPanelContainer';
import SuggestedPOsPanelContainer from './SuggestedPOsPanelContainer';

class OrderPartsScreen extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	handleSplitterMoved(newValue){
		if(this.props.handleSplitterMoved){
			this.props.handleSplitterMoved(100 - newValue);
		}
	}
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| this.props.user.hasBuyerAccess === undefined
			|| this.props.user.hasPartsAccess === undefined
			|| (!this.props.user.hasAdminAccess() && !this.props.user.hasBuyerAccess() && !this.props.user.hasPartsAccess())){
			return <NoAccessToScreenPanel />;
		}
		if(Array.isArray(this.props.divisions) === false
		|| (Array.isArray(this.props.divisions) === true && this.props.divisions.length === 0)){
			return <div>Loading...</div>;
		}
		return (
			<Container className={'h-100'} fluid={true}>
				<Row className={'h-100'}>
					<Col xs={12} sm={12} md={12} lg={1} xl={1} className={'w-100 mb-4 mr-2 pr-0 ml-0 pl-0'} style={{minWidth:'280px', maxWidth:'100%', flexGrow:1}}>
						<MerchandisePanelContainer
							screenId={this.props.screenId} 
							panelId={'merchandisePanel'}
							partSearchSelectedRows={this.props.selectedRows}
							vendor={this.props.vendor || ''}
							skusList={this.props.skusList}
							polist={this.props.polist}
							displayPOs={this.props.displayPOs}
							divisions={this.props.divisions}
						/>
					</Col>
					{this.props.splitterPosition && 
					<Col xs={12} sm={12} md={12} lg={9} xl={9} style={{maxWidth:'100%', flexGrow:1}}>
						<Row className={'mb-4'} style={{minHeight:'400px'}}>
							<SplitterLayout 
								vertical={true} 
								primaryMinSize={10} 
								secondaryMinSize={20} 
								secondaryInitialSize={this.props.splitterPosition !== undefined ? (100 - this.props.splitterPosition) : undefined} 
								percentage={true}
								onSecondaryPaneSizeChange={this.handleSplitterMoved.bind(this)}>
								<div id={this.props.screenId + '_splitLayout_top'} className={'h-100 pb-3'}>
									<SuggestedOrdersPanelContainer
										screenId={this.props.screenId}
										panelId={'suggestedOrdersPanel'}
										skusList={this.props.skusList}
									/>
								</div>
								<div id={this.props.screenId + '_splitLayout_bottom'} className={'pt-3'}>
									<SuggestedPOsPanelContainer 
										screenId={this.props.screenId} 
										panelId={'SuggestedPOs'}
										divisions={this.props.divisions}
										vendor={this.props.vendor}
										partSearchSelectedRows={this.props.selectedRows}
										vendorEmailInfos={this.props.vendorEmailInfos}
									/>
								</div>
							</SplitterLayout>
						</Row>
					</Col>
					}
				</Row>
			</Container>
		);
	}
}

OrderPartsScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	goBack: PropTypes.func,
	calculateSalesHistoryTotalDays: PropTypes.func,
	calculateTotalDaysAndLeadDate: PropTypes.func,
	selectedRows: PropTypes.arrayOf(PropTypes.object),
	vendor: PropTypes.string,
	useCubes: PropTypes.bool,
	skusList: PropTypes.string,
	displayPOs: PropTypes.string,
	vendorEmailInfos: PropTypes.arrayOf(PropTypes.object),
	polist: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.object.isRequired,
	onLoad: PropTypes.func,
	handleSplitterMoved: PropTypes.func,
	splitterPosition: PropTypes.number,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default OrderPartsScreen;