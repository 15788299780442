import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import cloneDeep from 'lodash/cloneDeep';

class Label extends Component {
	constructor(props){
		super(props);
		this.getId = this.getId.bind(this);
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	getId(){
		return (this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	}
	render() {
		const showRequiredAsterisk = this.props.showRequiredAsterisk ? ' showRequiredAsterisk' : '';
		let labelStyle = this.props.style ? cloneDeep(this.props.style) : {};
		labelStyle.display = this.props.visible !== false ? 'block' : 'none';
		return (
			<Form.Label 
				className={'pt-2 text-muted text' + showRequiredAsterisk} 
				style={labelStyle}
				id={this.getId()}>
				{this.props.value}
			</Form.Label>
		);
	}
}

Label.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	fieldId: PropTypes.string.isRequired,
	value: PropTypes.string,
	visible: PropTypes.bool,
	onLoad: PropTypes.func,
	showRequiredAsterisk: PropTypes.bool
};

export default Label;
