import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cancelRpcAction from '../../actions/cancelRpcAction';
import User from '../../models/User';
import * as sharedUtilities from '../../utilities/sharedUtilities.jsx';
import * as storeUtilities from '../../utilities/storeUtilities';
import Main from './Main';
import * as mainUtilities from './mainUtilities';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
class MainContainer extends Component {
	render() {
		return (
			<Main {...this.props} />
		);
	}
}
const mapStateToProps = () => {
	const showLoadingMask = storeUtilities.getGlobalAppStateValue(['showLoadingMask']);
	const messageBarMessage = storeUtilities.getGlobalAppStateValue(['messageBar']);
	const user = storeUtilities.getUser();
	const whsListDataReceived = sharedUtilities.getWhsList() !== undefined;
	return {
		showLoadingMask,
		messageBarMessage,
		user,
		whsListDataReceived
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			try {
				/***
				 * The cookie will expire in 24 hours, so the catch block will be executed. When the location is redirected to re-authenticate, 
				 * the history is lost, and after the user is re-authenticated, the user will be redirected back to this app. Since we
				 * have no history anymore, we will start them at whatever screen they have access to (either Products, Parts, Clearance).
				 */
				const querystring = props.history?.location?.search;
				if(querystring !== undefined){
					Cookies.set('querystring', querystring);
				}

				sharedUtilities.isAuthenticated((response)=>{
					const userCookie = Cookies.get('user');
					if(response?.isAuthenticated === true && userCookie !== undefined){
						try {
							const userObj = JSON.parse(userCookie);
							const user = new User();
							user.rtgID = userObj.rtgID;
							user.email = userObj.email;
							user.fullName = userObj.fullName;
							user.flags = userObj.flags;
							storeUtilities.setUser(user);
							console.log('LOGGED-IN USER: ' + JSON.stringify(user));
							sharedUtilities.getAppInsightsKey((data) => {
								if(data && data.key){
									const appInsights = new ApplicationInsights({ config: {
										instrumentationKey: data.key
									}});
									appInsights.loadAppInsights();
									appInsights.trackPageView();
									appInsights.context.user.id = user.fullName;
								}
								sharedUtilities.checkMaintenance(user, () => {
									sharedUtilities.getWhsListFromServer(() => {
										const pathname = Cookies.get('pathname');
										const querystring = Cookies.get('querystring');
										const hash = Cookies.get('hash');
										Cookies.remove('pathname');
										Cookies.remove('hash');
										mainUtilities.secureNavigation(dispatch, user, props.history, pathname, querystring, hash);
									});
								});
							});
						}
						catch(err){
							console.log('err: ' + err);
							loginAndSetCookies(props.history);
						}
					}
					else {
						loginAndSetCookies(props.history);
					}
				});
			}
			catch {
				loginAndSetCookies(props.history);
			}
		},
		handleEscapeKeyPressed: () => {
			dispatch(cancelRpcAction());
		}
	};
};
const loginAndSetCookies = (history) => {
	/**
	 *  The containerized version of this app does not need any of the *client-side* env vars that on-prem machines do (as set in ClientReact/package.json),
	 *  but, for the time being, we must accomodate both. So, if they exist, use them. If not, don't use them.
	 */
	const siteUrl = sharedUtilities.getEnvVar('REACT_APP_SITE_URL');
	const serverPort = sharedUtilities.getEnvVar('REACT_APP_SERVER_PORT');
	const pathname = history?.location?.pathname;
	const hash = history?.location?.hash;
	const querystring = history?.location?.search;
	/** 
	 * When using window.location.href = 'someUrl', the hash is not preserved after being authenticated, so we must temporarily
	 * save the hash to the cookies, and then restore it when we return (and then delete it from the cookies)
	 */
	if(pathname !== undefined){
		Cookies.set('pathname', pathname);
	}
	if(querystring !== undefined){
		Cookies.set('querystring', querystring);
	}
	if(hash !== undefined){
		Cookies.set('hash', hash);
	}
	if(siteUrl !== undefined){
		window.location.href = siteUrl + (serverPort !== undefined ? (':' + serverPort) : '') + '/auth/openid';
	}
	else{//containerized
		window.location.href = '/auth/openid';
	}
};

MainContainer.propTypes = {
	showLoadingMask: PropTypes.bool,
	messageBarMessage: PropTypes.string, 
	history: PropTypes.any.isRequired,
	onLoad: PropTypes.func,
	handleEscapeKeyPressed: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MainContainer);

export default connectedComponent;
