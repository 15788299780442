import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../utilities/storeUtilities';
import ModalContainer from './ModalContainer';

class DialogProvider extends Component {
	render() {
		return (
			<>
				{this.props.children}
				{this.props.dialogs}
			</>
		);
	}
}

const mapStateToProps = () => {
	const dialogs = getDialogs();
	return {
		dialogs
	};
};
const getDialogs = () => {
	const dialogs = storeUtilities.getDialogs() || [];
	return dialogs.map(dialog => {
		return (
			<ModalContainer 
				dialogId={dialog.dialogId}
				key={dialog.dialogId}
				dialogWidth={dialog.width}
				dialogHeight={dialog.height}
				enableResizing={dialog.enableResizing}
				position={dialog.position}
				doSlideInEffect={dialog.doSlideInEffect}
				closeOnOverlayClick={dialog.closeOnOverlayClick}
				handleDialogClosed={dialog.handleDialogClosed}
				closeOnEscapeKeyPressed={dialog.closeOnEscapeKeyPressed}>
				{dialog.dialogContent}
			</ModalContainer>
		);
	});
};
const connectedComponent = connect(mapStateToProps, undefined)(DialogProvider);

export default connectedComponent;