import { DataGridContainer } from '@rtg/shared-react-components';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import store from '../../../configureStore';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import ClearanceOrderQueueDialog from './ClearanceOrderQueueDialog';
import { nodeRoutes } from '../../../constants';
import rpcAction from '../../../actions/rpcAction';
export const PANEL_ID = 'clearanceOrderQueueDialog';
const OPEN_CLOSE_DIALOG_WAIT_MS = 300;
class ClearanceOrderQueueDialogContainer extends Component {
	render() {
		return (
			<ClearanceOrderQueueDialog
				screenId={this.props.screenId}
				panelId={PANEL_ID}
				onLoad={this.props.onLoad}
				rowData={this.props.rowData}
				handleCancelClicked={this.props.handleCancelClicked}
				onDeleteRowClicked={this.props.onDeleteRowClicked}
				onDeleteColumnHeaderClicked={this.props.onDeleteColumnHeaderClicked}
				handleCreatePurchaseOrdersClicked={this.props.handleCreatePurchaseOrdersClicked}
				createPurchaseOrdersButtonDisabled={this.props.createPurchaseOrdersButtonDisabled}
				disableConfirmationEmail={this.props.disableConfirmationEmail}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, PANEL_ID, 'rowData'], []);
	const reqConfirmation = storeUtilities.getValue([props.screenId, PANEL_ID, 'reqConfirmation'], false);
	const validationErrors = storeUtilities.getValidationErrors([props.screenId, PANEL_ID]);
	const createPurchaseOrdersButtonDisabled = validationErrors === undefined 
		|| 'BuyerInitials' in validationErrors
		|| 'Terms' in validationErrors
		|| 'ShipVia' in validationErrors
		|| 'ShipDate' in validationErrors
		|| 'ArrivalDate' in validationErrors
		|| ('confirmationEmailAddress' in validationErrors && reqConfirmation === true)
		|| rowData.length === 0;
	return {
		rowData,
		createPurchaseOrdersButtonDisabled,
		disableConfirmationEmail: reqConfirmation === false
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {   
		onLoad: () => {
			const user = storeUtilities.getUser();
			const userEmail = user !== undefined ? user.email : '';
			const defaultValues = {
				confirmationEmailAddress: userEmail
			};
			dispatch(updateDefaultValuesAction([props.screenId, PANEL_ID], defaultValues));
			window.setTimeout(() => {
				const args = { poUser: user.rtgID };
				dispatch(callRpcAction(args, nodeRoutes.IFRService, 'GetQueuedClearanceOrders', (data) => {
					if (data && Array.isArray(data)) {
						for (let idx in data) {
							data[idx].DELETE = false; 
						}
						storeUtilities.updateValue(dispatch, [props.screenId, PANEL_ID, 'rowData'], data);
					}
				}));
			}, OPEN_CLOSE_DIALOG_WAIT_MS);
		},
		handleCancelClicked: () => {
			window.setTimeout(() => {
				storeUtilities.deleteValue(dispatch, [props.screenId, PANEL_ID]);
			}, OPEN_CLOSE_DIALOG_WAIT_MS);
			dispatch(closeDialogAction());
		},
		onDeleteRowClicked: (gridApi, node, value) => {
			const rowData = storeUtilities.getValue([props.screenId, PANEL_ID, 'rowData'], []);
			let idx = 0; 
			for (idx = 0; idx < rowData.length; idx++) {
				if (rowData[idx].SKU === node.data.SKU && rowData[idx].WHS === node.data.WHS) {
					break;
				}
			}
			storeUtilities.updateValue(dispatch, [props.screenId, PANEL_ID, 'rowData', idx, 'DELETE'], value);
			node.setDataValue('DELETE', value);
			gridApi.refreshCells({rowNodes:[node], force:true});
		},
		onDeleteColumnHeaderClicked: () => {
			/* make this another loop for removing all selected skus */
			const rowData = storeUtilities.getValue([props.screenId, PANEL_ID, 'rowData'], []);
			var idx = 0;
			var deleteRows = [];
			var deleteSkusList = []; 
			for (idx = 0; idx < rowData.length; idx++) {
				if (rowData[idx].DELETE) {
					deleteRows.push(rowData[idx]);
					deleteSkusList.push(<div key={idx}>{rowData[idx].SKU}<br/></div>);
				}
			}
			dispatch(showDialogAction(
				<OkCancelDialogContainer
					screenId={props.screenId} 
					title={'Confirmation'}
					handleOkClicked={()=>{
						deleteFromQueue(dispatch, props, deleteRows);
					}}
					message={'Are you sure you want to remove these SKUs from the queue?'}
					hasCancelButton>
					<div>{deleteSkusList}</div>
				</OkCancelDialogContainer>,
				250, 
				250, 
				()=>{},
				true,
				undefined,
				true,
				false,
				true));
		},
		handleCreatePurchaseOrdersClicked: (gridApi) =>{
			const user = storeUtilities.getUser();
			const shipDate = moment(storeUtilities.getValue([props.screenId, PANEL_ID, 'ShipDate'], moment())).format('L');//L formats to 'MM/DD/YYYY' or 'DD/MM/YYYY' based on locale
			const arrivalDate = moment(storeUtilities.getValue([props.screenId, PANEL_ID, 'ArrivalDate'], moment())).format('L');
			const buyerInitials = storeUtilities.getValue([props.screenId, PANEL_ID, 'BuyerInitials']);
			const sendToFaxQueue = storeUtilities.getValue([props.screenId, PANEL_ID, 'SendToBvFaxQueue'], false);
			const terms = storeUtilities.getValue([props.screenId, PANEL_ID, 'Terms']);
			const shipVia = storeUtilities.getValue([props.screenId, PANEL_ID, 'ShipVia']);
			const reqConfirmation = storeUtilities.getValue([props.screenId, PANEL_ID, 'reqConfirmation'], false);
			const confirmationEmailAddress = storeUtilities.getValue([props.screenId, PANEL_ID, 'confirmationEmailAddress'], '');
			const storeVendorOrders = {};
			if(gridApi !== undefined){
				gridApi.forEachNode(node => {
					const storeKeys = Object.keys(storeVendorOrders);
					if(storeKeys.includes(node.data.WHS) === false){
						storeVendorOrders[node.data.WHS] = {};
					}
					const vendorKeys = Object.keys(storeVendorOrders[node.data.WHS]);
					if(vendorKeys.includes(node.data.VENDOR) === false){
						storeVendorOrders[node.data.WHS][node.data.VENDOR] = [];
					}
					const ordersArray = storeVendorOrders[node.data.WHS][node.data.VENDOR];
					if(ordersArray.includes(node.data.SKU) === false){
						ordersArray.push({
							sku: node.data.SKU,
							qty: node.data.QTY,
							ordId: node.data.ORDID
						});
					}
				});
			}
			const requests = [];
			const whsVendorEntries = Object.entries(storeVendorOrders);
			whsVendorEntries.forEach(([store, vendorOrders]) => {
				const vendorOrdersEntries = Object.entries(vendorOrders);
				vendorOrdersEntries.forEach(([vendor, orders]) => {
					const args = { 
						poUser: user.rtgID,
						emailAddress: confirmationEmailAddress,
						shipDate,
						arrivalDate,
						buyerInitials,
						sendToFaxQueue,
						terms,
						shipVia,
						reqConfirmation,
						store,
						vendor,
						orders
					};
					requests.push(args);
				});
			});
			callCreateClearancePOJsonBody(dispatch, props.screenId, requests, [], user.rtgID);
		}
	};
};
const callCreateClearancePOJsonBody = (dispatch, screenId, requestsArray, resultsArray, poUser) => {
	const requestsArrayClone = cloneDeep(requestsArray);
	const resultsArrayClone = cloneDeep(resultsArray);
	if(requestsArrayClone[0] !== undefined){
		dispatch(rpcAction({
			args: requestsArrayClone[0],
			nodeRoute: nodeRoutes.IFRService,
			endpoint: 'CreateClearancePOJsonBody',
			method: 'POST',
			showLoadingMask: true,
			hideLoadingMaskOnComplete: requestsArrayClone[1] === undefined,
			callback: (data) => {
				const leftoverRequests = requestsArrayClone.slice(1);
				if(Array.isArray(data) && data[0] !== undefined){
					resultsArrayClone.push(Object.assign(data[0], {reqConfirmation: requestsArrayClone[0].reqConfirmation}));
				}
				callCreateClearancePOJsonBody(dispatch, screenId, leftoverRequests, resultsArrayClone, poUser);
			},
			retryOnFailure: false
		}));
	}
	else {
		const purchaseOrderNumbers = [];
		resultsArrayClone.forEach(result => {
			if(!purchaseOrderNumbers.includes(result.PONUMBER)){
				purchaseOrderNumbers.push(result.PONUMBER);
			}
		});
		const reqConfirmation = storeUtilities.getValue([screenId, PANEL_ID, 'reqConfirmation'], false);
		const emailAddress = storeUtilities.getValue([screenId, PANEL_ID, 'confirmationEmailAddress'], '');
		dispatch(rpcAction({
			args: {purchaseOrderNumbers, emailAddress, requestConfirmation: reqConfirmation}, 
			nodeRoute: nodeRoutes.IFRServiceNET, 
			endpoint: 'Notifications/Send',
			method: 'POST',
			showLoadingMask: true,
			hideLoadingMaskOnComplete: true,
			callback: () => {
				const dataGrid = 
				<div className={'h-100 w-100'}>
					<DataGridContainer
						screenId={screenId}
						panelId={'randomPanelId'}
						fieldId={'clearanceOrderQueueDialogResults'}
						defaultColDef={{resizable: true, sortable: true}}
						columnDefs={
							[
								{ headerName: 'Store', field: 'WAREHOUSE', colId: 'WAREHOUSE', width: 70, minWidth: 70 },
								{ headerName: 'Vendor', field: 'VENDOR', colId: 'VENDOR', width: 70, minWidth: 70 },
								{ headerName: 'PO Number', field: 'PONUMBER', colId: 'PONUMBER', width: 150, minWidth: 150 },
								{ headerName: 'Errors', field: 'ERROR', colId: 'ERROR', width: 145, minWidth: 145 }
							]
						}
						rowData={resultsArrayClone}
						store={store}
					/>
				</div>;
				if(resultsArrayClone.length > 0){
					dispatch(showDialogAction(
						<OkCancelDialogContainer 
							id={'purchaseOrdersCreatedDialog'}
							screenId={ screenId + '_informationDialog'} 
							title={'Purchase Orders Created'}
							paddingLeft={0}
							paddingRight={0}
							handleOkClicked={()=>{
								dispatch(rpcAction({
									args: {poUser},
									nodeRoute: nodeRoutes.IFRService,
									endpoint: 'GetQueuedClearanceOrdersCount',
									showLoadingMask: false,
									callback: (data) => {
										if(data && Array.isArray(data) && data.length === 1)
										{
											const queueCount = numberUtilities.getNumberOrDefault(data[0]['QUEUECOUNT']);
											storeUtilities.updateValue(dispatch, [screenId, 'clearanceOrdering', 'QueueCount'], queueCount);
											storeUtilities.deleteValue(dispatch, [screenId, 'clearanceSkuData']);
											storeUtilities.deleteValue(dispatch, [screenId, 'whsNum']);
											dispatch(closeDialogAction());
										}
									}
								})); 
							}}
							height={300}
							width={700}>
							{dataGrid}
						</OkCancelDialogContainer>, 
						300, 
						450, 
						()=>{},
						true,
						undefined,
						true,
						false));
				}
			}}));
	}
};

const deleteFromQueue = (dispatch, props, deleteRows) => {
	var idx = 0;
	var deleteOrdIdList = ''; 
	for (idx = 0; idx < deleteRows.length; idx++) {
		if (deleteRows[idx].ORDID) {
			deleteOrdIdList += deleteRows[idx].ORDID;
			if (idx !== (deleteRows.length - 1)) {
				deleteOrdIdList += ',';
			}
		}
	}
	dispatch(callRpcAction({ordID: deleteOrdIdList}, nodeRoutes.IFRService, 'DeleteQueuedClearanceOrders', () => {
		const user = storeUtilities.getUser();
		const args = { poUser: user.rtgID };
		dispatch(callRpcAction(args, nodeRoutes.IFRService, 'GetQueuedClearanceOrders', (data) => {
			if (data && Array.isArray(data)) {
				storeUtilities.updateValue(dispatch, [props.screenId, PANEL_ID, 'rowData'], data);
				storeUtilities.updateValue(dispatch, [props.screenId, 'clearanceOrdering', 'QueueCount'], data.length);
			}
		}, false));
	}, false));
};

ClearanceOrderQueueDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	orderQueueRowData: PropTypes.array,
	onLoad: PropTypes.func,
	handleCreatePurchaseOrdersClicked: PropTypes.func,
	createPurchaseOrdersButtonDisabled: PropTypes.bool,
	disableConfirmationEmail: PropTypes.bool
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ClearanceOrderQueueDialogContainer);

export default connectedComponent;
