import React, { Component } from 'react';
import { connect } from 'react-redux';
import SuggestedTransfersMainScreen from './SuggestedTransfersMainScreen';

class SuggestedTransfersMainScreenContainer extends Component {
	render() {
		return (
			<SuggestedTransfersMainScreen
				screenId={this.props.screenId}
				panelId={this.props.panelId}
			/>
		);
	}
}

const mapStateToProps = () => {
	return {

	};
};

const mapDispatchToProps = () => {
	return {};
};

SuggestedTransfersMainScreenContainer.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedTransfersMainScreenContainer);