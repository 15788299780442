import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import AddClearanceSkusInactiveSkusListTab from './AddClearanceSkusInactiveSkusListTab';
import { nodeRoutes } from '../../../../constants';
import rpcAction from '../../../../actions/rpcAction';

class AddClearanceSkusInactiveSkusListTabContainer extends Component {
	render() {
		return <AddClearanceSkusInactiveSkusListTab
			screenId={this.props.screenId}
			panelId={this.props.panelId}
			addInactiveSkusTabPath={this.props.addInactiveSkusTabPath}
			onLoad={this.props.onLoad}
			onGridReady={this.props.onGridReady}
			rowData={this.props.rowData}
			handleRowClicked={this.props.handleRowClicked}
			displayedTab={this.props.displayedTab}
			onTabChanged={this.props.onTabChanged}
		/>;
	}
}

const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([...props.addInactiveSkusTabPath, 'InactiveClearanceSkusGrid', 'rowData'], []);
	const displayedTab = storeUtilities.getValue([props.screenId, props.panelId], undefined);
	return {
		rowData,
		displayedTab
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {   
		onLoad: () => {
			const division = storeUtilities.getValue([props.screenId, 'division'], undefined);
			const whsNum = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
			dispatch(rpcAction({
				args: { Warehouse: whsNum },
				nodeRoute: nodeRoutes.IFRServiceNET,
				endpoint: 'Clearance/InactiveClearanceSkus',
				method: 'GET',
				showLoadingMask: false,
				callback: (data) => {
					let strSkuArray = '';
					if(Array.isArray(data)){
						for(let i = 0; i < data.length; i++){
							strSkuArray += data[i].sku + (i === (data.length -1) ? '':',');
						}
					}
					dispatch(rpcAction({
						args: {Skus: strSkuArray, division}, 
						nodeRoute: nodeRoutes.IFRServiceNET, 
						endpoint: 'Clearance/Skus/Info', 
						method: 'GET',
						showLoadingMask: false,
						callback: (inactiveClearanceSkusResponse) => {
							if(inactiveClearanceSkusResponse?.skuInfoList !== undefined && Array.isArray(inactiveClearanceSkusResponse.skuInfoList)){
								storeUtilities.updateValue(dispatch, [...props.addInactiveSkusTabPath, 'InactiveClearanceSkusGrid', 'rowData'], inactiveClearanceSkusResponse.skuInfoList, true, false);
							}
						}}
					));
				}
			}));
		},
		onGridReady: (obj) => {
			if(obj?.api?.sizeColumnsToFit){
				obj.api.sizeColumnsToFit();
				storeUtilities.updateValue(dispatch, props.addInactiveSkusTabPath.concat(['sizeClearanceSkusGridColumnsToFit']), () => {
					obj.api.sizeColumnsToFit();
				}, true, false);
			}
			if(obj?.api?.applyColumnState){
				const sortModel = [
					{
						colId: 'VENDORID',
						sort: 'asc',
					},
				];
				obj.api.applyColumnState(sortModel);
			}
		},
		handleRowClicked: (params) => {
			storeUtilities.updateValue(dispatch, props.addInactiveSkusTabPath.concat(['selectedRows']), params.api.getSelectedRows(), true, false);
		},
		onTabChanged: () => {
			const sizeColumnsToFit = storeUtilities.getValue(props.addInactiveSkusTabPath.concat(['sizeClearanceSkusGridColumnsToFit']), undefined);
			if(sizeColumnsToFit !== undefined){
				sizeColumnsToFit();
			}
		}
	};
};

AddClearanceSkusInactiveSkusListTabContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	onGridReady: PropTypes.func,
	onLoad: PropTypes.func,
	addInactiveSkusTabPath: PropTypes.array.isRequired,
	onTabChanged: PropTypes.func
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AddClearanceSkusInactiveSkusListTabContainer);

export default connectedComponent;
