import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import EditEmailsDialog from './EditEmailsDialog';
import OkCancelDialogContainer from '../../../dialogs/OkCancelDialogContainer';
import showDialogAction from '../../../../actions/showDialogAction';
import emailValidator from 'email-validator';
class EditEmailsDialogContainer extends Component {
	render() {
		return (
			<EditEmailsDialog {...this.props} />
		);
	}
}
let originalRecipients = '';

const getPath = (parentPath) => {
	return [...parentPath];
};
const mapStateToProps = (state, props) => {
	return {
		path: getPath(props.parentPath)
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			originalRecipients = storeUtilities.getValue([...props.parentPath, 'recipients'], '');
			const recipients = originalRecipients;
			storeUtilities.initializeValue(dispatch, [...getPath(props.parentPath), 'recipients'], recipients, false);
		},
		handleOkClicked: (callback) => {
			if (isEmailListValid(props)){	
				const recipients = storeUtilities.getValue([...getPath(props.parentPath), 'recipients'], undefined);
				if(recipients !== undefined){
					storeUtilities.updateValue(dispatch, [...props.parentPath, 'recipients'], recipients, false);
				}
				if(callback !== undefined){
					callback();
				}
			} else {
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						screenId={props.screenId + '_errorDialog'} 
						title={'ERROR'}
						height={250}
						width={400}>
							Recipient list contains invalid values.
					</OkCancelDialogContainer>, 
					250, 
					400, 
					()=>{}));
			}
		},
		handleCancelClicked: (callback) => {
			if(originalRecipients !== undefined){
				storeUtilities.updateValue(dispatch, [...props.parentPath, 'recipients'], originalRecipients, false);
			}
			if(callback !== undefined){
				callback();
			}
		}
	};
};

const isEmailListValid = (props) => {
	let isValid = true;
	const recipients = storeUtilities.getValue([...getPath(props.parentPath), 'recipients'], undefined).replace(/\s+/g, '') ;
	const emaillist = recipients.split(',');
	emaillist.forEach((email) => {
		isValid = isValid && emailValidator.validate(email);
	});
  	
	return isValid;
};

EditEmailsDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	handleOkClicked: PropTypes.func,
	handleCancelClicked: PropTypes.func,
	parentPath: PropTypes.array.isRequired,
	path: PropTypes.array.isRequired,
	onDialogClosed: PropTypes.func,
	onLoad: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(EditEmailsDialogContainer);

export default connectedComponent;