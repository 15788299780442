import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

class NoAccessToScreen extends Component {
	render() {
		return (
			<Container 
				className={'h-100'} 
				fluid={true} 
				id={'noaccesstopanel'} 
				data-testid={'noaccesstopanel'}>
				<Card>
					<Card.Header>
						<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>No Access</h5>
					</Card.Header>
					<Card.Body>
                        You do not have access to this screen.
					</Card.Body>
				</Card>
			</Container>
		);
	}
}
export default NoAccessToScreen;