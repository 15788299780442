import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { keyCodes } from '../../../constants';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import LabelContainer from '../../formelements/LabelContainer';
import MenuButton from '../../formelements/MenuButton';
import { 
	DataGridContainer,
	TextButtonCellRenderer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
class PurchaseOrderQueueGridPanel extends Component {
	constructor(props){
		super(props);
		this.MAX_ROWS_WITHOUT_SCROLL = 10;
		this.getColumnValue = this.getColumnValue.bind(this);
		this.formatColumnValue = this.formatColumnValue.bind(this);
		this.onDeleteClicked = this.onDeleteClicked.bind(this);
		this.onDispatchClicked = this.onDispatchClicked.bind(this);
		this.onOrderClicked = this.onOrderClicked.bind(this);
		this.onViewPOClicked = this.onViewPOClicked.bind(this);
		this.onCellClicked = this.onCellClicked.bind(this);
		this.ensureRowsSelected = this.ensureRowsSelected.bind(this);
		this.onGridReady = this.onGridReady.bind(this);
		this.gridApi = undefined;
	}
	componentDidMount(){
		if(this.interval !== undefined){
			window.clearInterval(this.interval);
		}
		this.interval = window.setInterval(this.ensureRowsSelected, 50);
	}
	ensureRowsSelected(){
		if(this.gridApi){
			this.gridApi.forEachNodeAfterFilter(node1 => {
				if(node1.isSelected()){
					this.gridApi.forEachNodeAfterFilter(node2 => {
						if(node1.data.BATCHNUMBER === node2.data.BATCHNUMBER){
							node2.setSelected(true);
						}
					});
				}
			});
		}
	}
	componentWillUnmount(){
		if(this.interval !== undefined){
			window.clearInterval(this.interval);
		}
	}
	getColumnValue(params){
		if(this.props.getColumnValue){
			return this.props.getColumnValue(params);
		}
	}
	formatColumnValue(params){
		if(this.props.formatColumnValue){
			return this.props.formatColumnValue(params);
		}
	}
	onDeleteClicked(){
		if(this.props.onDeleteClicked){
			this.props.onDeleteClicked(this.gridApi);
		}
	}
	onDispatchClicked(){
		if(this.props.onDispatchClicked){
			this.props.onDispatchClicked(this.gridApi);
		}
	}
	onModelUpdated(params){
		if(this.props.onModelUpdated){
			this.props.onModelUpdated(params);
		}
	}
	onOrderClicked(){
		if(this.props.onOrderClicked){
			this.props.onOrderClicked();
		}
	}
	onViewPOClicked(node, gridApi){
		if(this.props.onViewPOClicked){
			this.props.onViewPOClicked(node, gridApi);
		}
	}
	onCellClicked(params){
		if(this.props.onCellClicked){
			this.props.onCellClicked(params);
		}
	}
	onGridReady(params){
		this.gridApi = params.api;
	}
	render() {
		const rowCount = sharedUtilities.getValueFromObject(this.props, ['rowData', 'length']);
		const panelHeight = rowCount > this.MAX_ROWS_WITHOUT_SCROLL ? ((30 * (this.MAX_ROWS_WITHOUT_SCROLL + 2)) + 2) : ((30 * (rowCount + 2)) + 3) + 50;
		return (				
			<Container style={this.props.panelStyle} fluid={true}>
				<Card>
					<Card.Header>
						<Row>
							<Col xs={11} sm={11} md={11} lg={11} xl={11}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Vendor: {this.props.vendorCode}</h5>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1}>
								<MenuButton
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'ActionsButton'}
									mainButtonText={'Actions'}
									buttons={[
										{key:'dispatch', text:'Create POs', handler: this.onDispatchClicked},
										{key:'delete', text:'Remove from Queue', handler: this.onDeleteClicked},
										{key:'onorder', text: 'On Order', handler: this.onOrderClicked}
									]}
								/>
							</Col>
						</Row>
					</Card.Header>
					<div style={{height:panelHeight + 'px'}}>
						<Container fluid={true}>
							<Row style={{height: (panelHeight-30) + 'px'}}>
								<Col className={'m-0 p-0'}>
									<DataGridContainer
										fieldId={''} 
										panelId={this.props.panelId}
										screenId={this.props.screenId} 
										defaultColDef={{
											resizable: true, 
											sortable: false, 
											valueGetter: this.getColumnValue, 
											valueFormatter: this.formatColumnValue,
											suppressKeyboardEvent: (params)=>{ 
												return params.event.type === 'keydown' && params.event.which === keyCodes.ENTER;
											}
										}}
										rowSelection={'multiple'}
										onCellClicked={this.onCellClicked}
										columnDefs={[
											{ 
												headerName: '', 
												field: 'ROWMODEL', 
												colId: 'ROWMODEL', 
												hide: true
											},
											{ 
												headerName: 'BatchNumber', 
												field: 'BATCHNUMBER', 
												colId: 'BATCHNUMBER',
												type:'numericColumn',
												width: 65,
												minWidth: 65,
												hide:true
											},
											{ 
												headerName: 'PO Queue#', 
												field: 'QUEUEID', 
												colId: 'QUEUEID',
												type:'numericColumn',
												width: 100,
												minWidth: 100
											},
											{ 
												headerName: 'Date Queued', 
												field: 'DATEQUEUEDUTC',
												colId: 'DATEQUEUEDUTC',
												width: 150,
												minWidth: 150,
												filter: true
											}, 
											{
												headerName: 'Warehouse', 
												field: 'DELIVERTO',
												colId: 'DELIVERTO',
												width: 90,
												minWidth: 90
											},
											{ 
												headerName: 'Buyer Initials', 
												field: 'BUYERINITIALS',
												colId: 'BUYERINITIALS',
												width: 100,
												minWidth: 100
											}, 
											{
												headerName: 'Order Date', 
												field: 'ORDERDATE',
												colId: 'ORDERDATE',
												width: 100,
												minWidth: 100
											},
											{ 
												headerName: 'Category', 	
												field: 'GROUPING',
												colId: 'GROUPING',
												width: 150,
												minWidth: 150
											}, 
											{
												headerName: 'Sku', 
												field: 'SKU',
												colId: 'SKU',
												width: 80,
												minWidth: 80
											},
											{ 
												headerName: 'Color',
												field: 'COLOR',	
												colId: 'COLOR',
												width: 60,
												minWidth: 60
											}, 
											{ 
												headerName: 'Factory',
												field: 'FACTORY',
												colId: 'FACTORY',
												width: 250,
												minWidth: 250
											},
											{ 
												headerName: 'Alias', 
												field: 'ALIAS',
												colId: 'ALIAS',
												width: 100,
												minWidth: 100,
												headerClass: 'vertical-header'
											},  
											{ 
												headerName: 'Flag PO As',
												field: 'POFLAG',
												colId: 'POFLAG',
												width: 100,
												minWidth: 100
											},  
											{ 
												headerName: 'Ship Via',
												field: 'SHIPVIA',
												colId: 'SHIPVIA',
												width: 100,
												minWidth: 100
											},    
											{ 
												headerName: 'Ship Date',
												field: 'SHIPDATE',
												colId: 'SHIPDATE',
												width: 100,
												minWidth: 100
											},  
											{ 
												headerName: 'Arrival Date',
												field: 'ARRIVALDATE',
												colId: 'ARRIVALDATE',
												width: 100,
												minWidth: 100
											},  
											{ 
												headerName: 'Cost',
												field: 'FIRSTCOST',
												colId: 'FIRSTCOST',
												type:'numericColumn',
												width: 100,
												minWidth: 100
											},
											{ 
												headerName: 'QTY',
												field: 'QUANTITY',
												colId: 'QUANTITY',
												type:'numericColumn',
												width: 60,
												minWidth: 60
											},
											{ 
												headerName: 'Total',
												field: 'TOTAL',
												colId: 'TOTAL',
												type:'numericColumn',
												width: 120,
												minWidth: 120
											},
											{ 
												headerName: '', 		
												field: 'VIEW',			
												colId: 'VIEW',
												cellRenderer: TextButtonCellRenderer, 
												cellRendererParams:
												{
													gridName: 'poOrderQueueGrid_' + this.props.index,
													screenId: this.props.screenId,
													panelId: this.props.panelId,
													fieldId: 'viewPO',
													onClick: this.onViewPOClicked,
													fill: true,
													height: '20px',
													text:'View/Edit Queued PO',
													style: {marginTop: '3px'},
													buttonClasses: 'btn btn-light'
												},
												width:150,
												minWidth:150
											}
										]}
										onModelUpdated={this.onModelUpdated.bind(this)}
										rowData={this.props.rowData}
										onCellFocused={ e => {
											e.api.updateGridOptions({
												suppressRowClickSelection: e?.column?.colId === 'VIEW'
											});
										}}
										getRowClass={(params) => {
											return params.data.BATCHNUMBER % 2 === 0 ? 'grid-row-even' : 'grid-row-odd';
										}}
										onGridReady={this.onGridReady}
										sizeColumnsToFit
										store={store}
									/>
								</Col>
							</Row>
							<Row style={{height:'30px'}}>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}
								>&nbsp;</Col>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'selectedTotalLabel'}
										value={'Selected Total: '}
										style={{fontSize: '16px'}}
									/>
								</Col>
								<Col xs={3} sm={3} md={3} lg={3} xl={3}>
									<div style={{position:'relative', right:'140px'}}>
										<LabelContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'selectedTotal'}
											overridePath={[this.props.screenId, this.props.panelId, 'selectedTotal']}
											type={'number'}
											useStore={true}
											style={{fontSize: '16px',fontWeight:'bold',textAlign:'right'}}
											valueGetter={(value) => {
												return sharedUtilities.formatCurrency(value);
											}}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</Card>
			</Container>);
	}
}

PurchaseOrderQueueGridPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	vendorCode: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	getColumnValue: PropTypes.func,
	formatColumnValue: PropTypes.func,
	onDeleteClicked: PropTypes.func,
	onDispatchClicked: PropTypes.func,
	onModelUpdated: PropTypes.func,
	onCellClicked: PropTypes.func,
	forwardRef: PropTypes.func
};

export default PurchaseOrderQueueGridPanel;
